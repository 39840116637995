import React, { useEffect, useState, useContext, useRef } from "react";
import './SelfServiceDetails.css';
import SCInputText from "../SCInputText";
import WizardDataContext from "../../Context/WizardDataContext";
import SCDropDownList from "../SCDropDownList";
import SCTextArea from "../SCTextArea";
import DataContext from "../../Context/DataContext";
import { TextField } from "@mui/material";




const SelfServiceDetails = () => {

    const { serviceNameValue, setServiceNameValue, regexServiceNameError, setRegexServiceNameError, selectedSolutionType, setSelectedSolutionType,
        serviceDescription, setServiceDescription, selectedTeam, setSelectedTeam, selectedDeperatmentManager, setSelectedDeperatmentManager,
        selectedSubDeperatmentManager, setSelectedSubDeperatmentManager, teamLeaderRef, teamLeaderIdRef, teamNameRef,
        ddlSubDeperatmentManager, setDdlSubDeperatmentManager, ddlDeperatmentManager, setDdlDeperatmentManager, decision, ddlSolutionType, setDdlSolutionType,
        selectedTier, setSelectedTier, ddlTier, setDdlTier } = useContext(WizardDataContext);


    const { listOfTeams, initTeams, listOfDepartments, initSubDeperatmentManager, initDeperatmentManager, initTiers } = useContext(DataContext);

    const [ddlTeams, setDdlTeams] = useState([]);


    const patternServiceName = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

    useEffect(() => {
        setDdlTier(initTiers())
    }, [])

    useEffect(() => {
        setDdlTeams(initTeams())
    }, [listOfTeams])

    useEffect(() => {

        if (listOfDepartments && listOfDepartments.length > 0) {

            setDdlDeperatmentManager(initDeperatmentManager())
            setDdlSubDeperatmentManager(initSubDeperatmentManager())

        }


    }, [listOfDepartments])

    useEffect(() => {

        switch (decision) {
            case 'aks':
                {
                    var solutionTypes = [];
                    solutionTypes.push({ name: 'WebApi', value: 'WebApi' }, { name: 'QueueProcessor', value: 'QueueProcessor' }, { name: 'CronWorker', value: 'CronWorker' })
                    setDdlSolutionType(solutionTypes)
                    break;
                }
            case 'eks':
            case 'both':
                {
                    var solutionTypes = [];
                    solutionTypes.push({ name: 'WebApi', value: 'WebApi' })
                    setDdlSolutionType(solutionTypes)
                    break;
                }


            default:
                break;
        }


    }, [decision])


    const handleChangeTier = (e) => {
        setSelectedTier(e.target.value);
    }

    const handleServiceNameChange = (e) => {
        setServiceNameValue(e.target.value)
    }
    const handleServiceNameBlur = (e) => {
        if (patternServiceName.test(e.target.value)) {
            setRegexServiceNameError(false);


        }
    }

    const handleChangeSolutionType = (e) => {
        setSelectedSolutionType(e.target.value)
    }


    const handleServiceDescription = (e) => {
        setServiceDescription(e.target.value)

    }

    const handleChangeSubDeperatmentManager = (e) => {
        setSelectedSubDeperatmentManager(e.target.value);
    }

    const handleChangeDeperatmentManager = (e) => {
        setSelectedDeperatmentManager(e.target.value);
    }

    const handleSelectedTeam = (e) => {
        setSelectedTeam(e.target.value);

        let manager = listOfTeams.filter(l => l.id === e.target.value)
        if (manager.length > 0) {
            teamLeaderRef.value = manager[0].managerName;
            teamLeaderIdRef.value = manager[0].managerUserId;
            teamNameRef.value = manager[0].name;

            setSelectedSubDeperatmentManager('')
            setSelectedDeperatmentManager('')
            let teamLeaderSubDepartmentManager = listOfDepartments.filter(l => l.id === manager[0].departmentId);
            if (teamLeaderSubDepartmentManager && teamLeaderSubDepartmentManager.length > 0) {
                if (teamLeaderSubDepartmentManager[0].parentDepartmentId !== "") {
                    setSelectedSubDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
                }
                if (teamLeaderSubDepartmentManager[0].parentDepartmentId === "") {
                    setSelectedDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
                }

                let teamLeaderDepartmentManager = listOfDepartments.filter(l => l.id === teamLeaderSubDepartmentManager[0].parentDepartmentId);

                if (teamLeaderDepartmentManager && teamLeaderDepartmentManager.length) {
                    setSelectedDeperatmentManager(teamLeaderDepartmentManager[0].id)
                }

            }


        }



    }

    return (
        <React.Fragment>
            <div className="ss-wizard-details-controler-container">
                <SCInputText id="cicd-service-name" handleInputValueBlur={handleServiceNameBlur} required={true} size="small" className="md-input"
                    label="Service Name" value={serviceNameValue}
                    handleInputValueChange={(e) => handleServiceNameChange(e)} />
            </div>

            <div className="ss-wizard-details-controler-container">
                <SCDropDownList id="cicd-solution-type" className="md-ddl-edit" label="Select Solution Type *" componentState={selectedSolutionType}
                    setComponentState={setSelectedSolutionType}
                    size="1000" items={ddlSolutionType} handleChange={(e) => handleChangeSolutionType(e)} value={selectedSolutionType} />
            </div>

            <div className="ss-wizard-details-controler-container">
                <SCTextArea rows={1.9} size="small" name='description' className="md-input" label="Service Description *"
                    handleInputValueChange={handleServiceDescription} value={serviceDescription} />
            </div>

            <div className="ss-wizard-details-controler-container">
                <SCDropDownList id="cicd-selected-team" className="md-ddl-edit" label="Team Name *" componentState={selectedTeam} setComponentState={setSelectedTeam}
                    size="1000" items={ddlTeams} handleChange={(e) => handleSelectedTeam(e)} value={selectedTeam} />
            </div>

            <div className="ss-wizard-details-controler-container">
                <SCDropDownList id="cicd-department-manager" className="md-ddl-edit" label="Department Manager *" componentState={selectedDeperatmentManager} setComponentState={setSelectedDeperatmentManager}
                    size="1000" items={ddlDeperatmentManager} handleChange={(e) => handleChangeDeperatmentManager(e)} value={selectedDeperatmentManager} />
            </div>

            <div className="ss-wizard-details-controler-container">

                <SCDropDownList id="cicd-sub-department-manager" className="md-ddl-edit" label="Sub Department Manager *" componentState={selectedSubDeperatmentManager} setComponentState={setSelectedSubDeperatmentManager}
                    size="1000" items={ddlSubDeperatmentManager} handleChange={(e) => handleChangeSubDeperatmentManager(e)} value={selectedSubDeperatmentManager} />
            </div>

            <div className="ss-wizard-details-controler-container">
                <TextField

                    disabled={true}
                    sx={{
                        "& .MuiInputBase-root": {
                            width: 400
                        }
                    }}
                    inputProps={
                        { readOnly: true }

                    } value={teamLeaderRef.value} label="Team Leader *" InputLabelProps={{ shrink: true }} />
            </div>
            <div className="ss-wizard-details-controler-container">
                <SCDropDownList id="cicd-tier" className="md-ddl-edit" label="Tier *" componentState={selectedTier} setComponentState={setSelectedTier}
                    size="1000" items={ddlTier} handleChange={(e) => handleChangeTier(e)} value={selectedTier} />
            </div>
        </React.Fragment>
    )
}

export default SelfServiceDetails
