import React, { useState, useEffect, useContext, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import './SCNotification.css';
import bell from '../Images/bell.svg';
import DataContext from "../Context/DataContext";
import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';
import NotificationModal from '../Modals/NotificationModal';



const SCNotification = () => {

    const { token } = useContext(DataContext);
    const { notification, setNotification, connection, requestQueue, setTabsAutoCICDValue,tabsAutoCICDValue, requestCICDItems, setPipelineState } = useContext(DataContext);
    const [unreadNotifications, setUnreadNotifications] = useState([])


    useEffect(() => {

        if (connection) {
            connection.start()
                .then(result => {

                    connection.on('ProvisionMessage', message => {

                        handleNotificationMessage(message)
                    });
                })
                .catch(e => console.log('App Service Connection failed: ', e));

        }


    }, [connection]);




    const handleNotificationMessage = (notification) => {


        const deserializedObject = JSON.parse(notification);
         if (deserializedObject.statusCode.toLowerCase() != "ok") {

            setNotification((prevNotifications) => [...prevNotifications, deserializedObject]);
            requestQueue.remove({ repositoryName: deserializedObject.repoName, applicationName: deserializedObject.appName });
            const ErrorMsg = () => (
                <div>{deserializedObject.message}</div>
            )
            toast.error(<ErrorMsg />)
            
            
            setTabsAutoCICDValue((prevState) => ({ TabValue: '1' }));


            closeConnection();

        }
        if (deserializedObject.statusCode.toLowerCase() == "ok") {


            setNotification((prevNotifications) => [...prevNotifications, deserializedObject]);
            requestQueue.remove({ repositoryName: deserializedObject.repoName, applicationName: deserializedObject.appName });

            if (deserializedObject.content?.approved === "false") {
                const ErrorMsg = () => (
                    <div>
                        <div>The manager declined your request.</div>
                    </div>
                )
                toast.error(<ErrorMsg />)
            }

            //if(deserializedObject.content?.approved === "true"){
            else {
                const SuccessMsg = () => (
                    <div>
                        <div>New CI/CD environment was created successfully.</div>
                    </div>
                )
                toast.success(<SuccessMsg />)
            }
                       
           setTabsAutoCICDValue((prevState) => ({ TabValue: '1' }));

            closeConnection();

        }


    };


    const closeConnection = () => {
        if (connection?._connectionState === 'Connected' && requestQueue.size() === 0) {
            connection.stop();
            
        }

    }


    useEffect(() => {
        if (requestCICDItems && requestCICDItems.data.length > 0) {
            let results = requestCICDItems.data.filter(i => i.readByServiceCatalog === 'unread' && (i.approvalStatus?.toLowerCase() === 'approved' || i.approvalStatus?.toLowerCase() === 'declined'))
            setUnreadNotifications([...results]);
        }
    }, [requestCICDItems])



    return (
        <div>
            <div>
                {
                    unreadNotifications && unreadNotifications.length === 0 ?
                        (
                            <div>No Notifications</div>
                        ) :
                        (
                            <div className='outer-notifications'>
                                <div className='circle'>
                                    {unreadNotifications.length}
                                </div>
                            </div>
                        )
                }
            </div>


        </div>
    )
}

export default SCNotification
