import SCTable from '../Components/SCTable';
import './InfraPackagesRelations.css';
import { useContext, React, useState, useEffect, useRef, useCallback } from "react";
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import _ from "lodash";
import * as consts from '../consts';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SCDropDownList from "../Components/SCDropDownList";
import { toast } from 'react-toastify';

const InfraPackagesRelations = () => {

    const { services, branchDetails, setCurrentPage, getServiceBrachesInfo, getServicesWithInfraNugets,setBranchDetails,
        infraNugetsServices , setInfraNugetsServices} = useContext(DataContext);

    const [rowServicesItems, setRowServicesItems] = useState([]);    
    const [rowProjectsItems, setRowProjectsItems] = useState([]);
    const [disabledBranchesDdl, setDisabledBranchesDdl] = useState(true)
    const [ddlInfraPackages, setDdlInfraPackages] = useState([]);
    const [selectedInfraPackage, setSelectedInfraPackage] = useState('');
    const [serviceGrouped, setServiceGrouped] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [ddlBranches, setDdlBranches] = useState([]);

    const columnsServiceItems = [{ display: true, name: "Service name", key: "ServiceName" },{ display: false, name: "Projects", key: "Projects" }]    
    const columnsProjectItems = [{ display: true, name: "Project name", key: "ProjectName" }, { display: true, name: "Current version", key: "CurrentVersion" },
    { display: true, name: "Installed version", key: "InstalledVersion" }]




    useEffect(() => {
        setCurrentPage('infraRelations')
               
        window.addEventListener('unload', handleEndConcert)
        return () => {
       
          window.removeEventListener('unload', handleEndConcert)
          handleEndConcert()
        }

    }, [])

    const handleEndConcert = () =>{
        
        setInfraNugetsServices([]);
        setBranchDetails({});
        setDisabledBranchesDdl(true)
    }

    useEffect(() => {
        
        if (services) {
            
        
            var service = services.filter(s => s.name.toLowerCase() === consts.INFRA_PKG_NAME);
        
            if (service && service.length > 0 && service[0]?.branches[0]) {
                
                getServiceBrachesInfo(service[0]?.id, service[0]?.branches[0]);
            }
        }

    }, [services]);

    useEffect(() => {

        var nugetItems = []
        if (Object.keys(branchDetails).length > 0) {

            var data = branchDetails?.data.filter(d => d.generatePackageOnBuild)
            data.forEach(item => {
                nugetItems.push(item.name)
            });
            setDdlInfraPackages(nugetItems)

        }

    }, [branchDetails]);

    useEffect(() => {

        clearStates();
        if (Object.keys(branchDetails).length > 0 && selectedInfraPackage != '') {
            var data = branchDetails?.data.filter(b => b.name === selectedInfraPackage.newValue);
        
            getServicesWithInfraNugets(data[0].name)

        }

    }, [selectedInfraPackage])

    useEffect(() => {



        if (selectedBranch) {

         
            setRowServicesItems([])
            var items = []
           
            if(serviceGrouped[selectedBranch] && serviceGrouped[selectedBranch].length >0)
            {
                serviceGrouped[selectedBranch].forEach((item) => {
                    items.push({ ServiceName: item.name, Projects: JSON.stringify(item.projects) })
    
                });

                setRowServicesItems(items)
            }
           

           
        }

    }, [selectedBranch])


    useEffect(() => {

        if (infraNugetsServices && infraNugetsServices?.data?.services) {
       
            setDdlBranches([]);
            var branchList = []
            var grouped = _.groupBy(infraNugetsServices.data.services, "branch");
            setServiceGrouped(grouped)
            Object.entries(grouped).forEach(([key, value]) => {

                branchList.push({ name: key, value: key });
            })           
            setDdlBranches([...branchList]);
            checkForDisableBranchDDL();
        }
       
       

    }, [infraNugetsServices])

    const clearFilter = (e, newValue) => {

        if (Object.keys(newValue).length === 0 && Object.keys(branchDetails).length > 0) {
            clearStates();
        }
    }


    const handleServiceRowClick = (e) => {

        setRowProjectsItems([])
        var projects = JSON.parse(e.Projects);

        var items = []
        projects.forEach(proj => {

            if (proj?.packages && proj?.packages.length > 0) {
                proj.packages.forEach(pkg => {
                    if (pkg.name === selectedInfraPackage.newValue) {
                        items.push({ ProjectName: proj.name, CurrentVersion: pkg.currentVersion, InstalledVersion: pkg.installedVersion })
                    }
                });
            }

        });
        setRowProjectsItems(items);
    }

    const handleBranchChange = (e) => {
        setSelectedBranch(e.target.value);

    }

    

    const clearStates = () => {
        setRowServicesItems([]);       
        setRowProjectsItems([]);
    }

    const checkForDisableBranchDDL = () =>{
        if(Object.entries(infraNugetsServices).length > 0)
        {
            if(Object.entries(infraNugetsServices?.data?.services).length > 0)
            {
                return setDisabledBranchesDdl(false);
            }
        }
        
        const WarnMsg = () => (
            <div>
                <div>There are no services that are associated with this package.</div>
            </div>
        )
        toast.warn(<WarnMsg />)

        return setDisabledBranchesDdl(true);          
    }

    return (
        <>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main">
                <div className='container-fluid'>
                    <div className='ipr-ddl-container'>
                        <Autocomplete

                            disablePortal
                            id="combo-box-demo"
                            options={ddlInfraPackages}
                            sx={{ width: '400px' }}
                            renderInput={(params) => <TextField {...params} label="Select infra package" />}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setSelectedInfraPackage({
                                        newValue,
                                    });
                                }
                            }}
                            onInputChange={(event, newValue) => {
                                clearFilter(event, newValue);
                            }}
                        />
                    </div>

                    <div className='ipr-ddl-branch-container'>
                        <SCDropDownList className="adv-md-ddl" label="Select branch" componentState={selectedBranch}
                            setComponentState={setSelectedBranch}                                                        
                            disabled={disabledBranchesDdl} size="small"
                            items={ddlBranches} handleChange={(e) => handleBranchChange(e)} value={selectedBranch} />
                    </div>



                    <div className='ipr-table-container'> <SCTable title="Services"
                        handleRowClick={(e) => handleServiceRowClick(e)}
                        columnsItems={columnsServiceItems} rowItems={rowServicesItems}
                        includesRemoveButton={false} includesSelectButton={false}
                    /> </div>

                    {/*
                    <div className='ipr-table-container'> <SCTable title="Branches"
                        handleRowClick={(e) => handleBranchRowClick(e)}
                        columnsItems={columnsBranchItems} rowItems={rowBranchesItems}
                        includesRemoveButton={false} includesSelectButton={false}
                    /> </div>
                  */}

                    <div className='ipr-table-container'> <SCTable title="Projects"

                        columnsItems={columnsProjectItems} rowItems={rowProjectsItems}
                        includesRemoveButton={false} includesSelectButton={false}
                    /> </div>
                </div>
            </div>
        </>
    )
}

export default InfraPackagesRelations
