import React, { useContext, useEffect, useState } from 'react';
import './SCFloatingButton.css'; // Your CSS file for styling
import DataContextHome from '../Context/DataContextHome';



const SCFloatingButton = ({ scroll }) => {
   
    return (

        <div >
            {
            scroll === 0 ? (
                <div className="sc-floating-button-report-floating-button">
                    <div className="sc-floating-button-report-arrow-floating-button" ></div>
                </div>
            ):
            (
                <span></span>
            )
        }
        </div>
    );
};

export default SCFloatingButton;
