
import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import SelfServiceWizard from "../../Components/SelfService/SelfServiceWizard";
import SCTop from "../../Components/SCTop";





const SelfServicePage = () => {
 

    return (
        <React.Fragment>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div>
                <SelfServiceWizard />
            </div>


        </React.Fragment>
    )
}

export default SelfServicePage
