import React, { useContext, useEffect, useRef, useState } from 'react';
import DataContextConsoleAI from '../../Context/DataContextConsoleAI';
import { v4 as uuid } from 'uuid';
import _, { set } from "lodash";
import DataContext from '../../Context/DataContext';
import { DataContextChat } from '../../Context/DataContextChat';
import './ActionProvider.css'
import ServiceCatalogModule from './Services/ServiceCatalogModule';
import ServiceAnalyzerModule from './Services/ServiceAnalyzerModule';

import ServiceUtils from './Services/ServiceUtils';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useCallback } from 'react';
import HTMLModal from '../../Modals/HTMLModal';
import IncidentReportButtonWidget from './IncidentReportButtonWidget';

const useDebouncedStateUpdate = (setState, delay) => {
  return useCallback(
    _.debounce((updateFn) => {
      setState((prevState) => updateFn(prevState));
    }, delay),
    [setState, delay]
  );
};



const ActionProvider = ({ createChatBotMessage, createClientMessage, setState, state, children, ...props }) => {

  const debouncedSetState = useDebouncedStateUpdate(setState, 10);
  const { chatModuleLocation, connection, signalrMessages, setSignalrMessages, isNewRequest, threadMessageId, setSignalRStatus
    , setChatInit, fromIncident, chatInit, convertMarkdown, signalRStatus, linkToReportMD, setLinkToReportMD, selfServiceReceiveJsonMessage
   } = useContext(DataContextChat);
  const context = useContext(DataContextChat);
  const prevChatModuleLocation = useRef(chatModuleLocation);
  const lastSeq = useRef(0)
  const setStateRef = useRef(null)
  const serviceUtils = new ServiceUtils(setState, state, context)
  const [count, setCount] = useState(1)

  useEffect(() => {
    if (fromIncident !== '') {
      context.setFromIncident('')
      let service = new ServiceAnalyzerModule(createChatBotMessage, setState, state, context)
      service.createSilenceeUserMessage("send me a data regarding this id: " + fromIncident)
    }

  }, [fromIncident])


  useEffect(() => {
 
    return () => {
   
      setState((prevState) => ({
        ...prevState,
        messages: [], // Clear chat history
      }));
      setSignalrMessages(null)
      setSignalRStatus([])
    


    };
  }, []);





  useEffect(() => {
    if(selfServiceReceiveJsonMessage!== null && signalRStatus.includes("Saving token usage")){    
      handleSelfServiceAutoFillShow()
    }
  }, [signalRStatus,selfServiceReceiveJsonMessage])


  useEffect(() => {

    if (linkToReportMD !== null && signalRStatus.includes("Saving token usage")) {
      handleReportShow()
    }
  }, [signalRStatus, linkToReportMD])


  const handleSelfServiceAutoFillShow = () => {

    const message = createChatBotMessage({ message: '', dataForWidget: selfServiceReceiveJsonMessage, type: 'self-service-widget' });


    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));

   

  }

  const handleReportShow = () => {


    const message = createChatBotMessage({ message: '', dataForWidget: linkToReportMD, type: 'report-widget' });


    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));

    setLinkToReportMD(null)
  };


  useEffect(() => {




    if (signalrMessages !== null && signalrMessages !== undefined && signalrMessages !== "") {


      let convertedMessage = convertMarkdown(signalrMessages.message?.content);
      const message = createChatBotMessage({ message: convertedMessage, type: 'html', id: threadMessageId.current });

      lastSeq.current = signalrMessages.sequenceNumber;
      handleMessage(message)


    }


  }, [count, signalrMessages])



  const handleMessage = (message) => {



    debouncedSetState((prevState) => {


      // const existingMessageIndex = prevState.messages.findIndex(msg => msg.message.id === 'signalr-message');
      const existingMessageIndex = prevState.messages.findIndex(msg => msg.message.id === threadMessageId.current);


      if (existingMessageIndex === -1 || isNewRequest.current === true) {
        isNewRequest.current = false;
        serviceUtils.removeTypingMessage()
        return { ...prevState, messages: [...prevState.messages, message] };
      }
      else {
        const updatedMessages = [...prevState.messages];
        updatedMessages[existingMessageIndex].message.message = message.message.message;
        return { ...prevState, messages: updatedMessages };
      }
    });
  }






  //Module for analyzer that call to analyzer copilot service
  const handleAnalyzerModule = async (message) => {
    let service = new ServiceAnalyzerModule(createChatBotMessage, setState, state, context)
    let response = null;
    if (chatInit === null) {
      response = await service.handleCreateAnalyzerChat('new session')
    }
    let cID = chatInit === null ? response.chatSession.id : chatInit.chatSession.id

    await service.handleSendMessage(message, cID);

  };


  //Module for catalog that call to catalog copilot service
  const handleCatalogModule = async (message) => {
    let service = new ServiceCatalogModule(createChatBotMessage, setState, state, context)
    await service.handleSendMessage(message);

  };


  const removeAllMessageFromChatBot = () => {
    setState(() => ({
      messages: [""],
    }));

  }






  // Put the handleHello and handleDog function in the actions object to pass to the MessageParser
  return (
    <React.Fragment>
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {
              removeAllMessageFromChatBot,
              handleAnalyzerModule,
              handleCatalogModule,
              handleReportShow,
              handleSelfServiceAutoFillShow
            },
          });
        })}
      </div>

    </React.Fragment>
  );
};

export default ActionProvider;