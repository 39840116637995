import React, { useEffect, useState, useContext } from 'react'
import './SAAnalyzerIncidentSummary.css';
import './MarkdownReportStyle.css';
import share from '../../../Images/share.svg'
import jira from '../../../Images/jira.svg'
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import CloseIncidentModal from '../../../Modals/CloseIncidentModal';
import SAPDFModal from './SAPDFModal';
import SARenderSummary from './SARenderSummary';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; 

const SAAnalyzerIncidentSummary = ({ modules, service, requestId, from, to ,manageStatus = false, ready}) => {


    const { handleCopyToClipboard  } = useContext(DataContextAnalyzer);
    const [modalCloseIncidentShow,setModalCloseIncidentShow] = useState(false)
    const [showPDFModal,setShowPDFModal] = useState(false)

    const [summary, setSummary] = useState(null)
   

    const md = new MarkdownIt({
        highlight: (str, lang) => {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) {}
          }
          return ''; // use external default escaping
        }
      });



    const parseMarkdownInHtml=(markdown) =>{
        const html = marked(markdown);
    
        // Create a temporary DOM element to manipulate the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
    
        // Recursively parse inner HTML content
        function recursivelyParse(element) {
          element.childNodes.forEach(child => {
            if (child.nodeType === Node.ELEMENT_NODE) {
              recursivelyParse(child);
            } else if (child.nodeType === Node.TEXT_NODE) {
              const parsedContent = marked.parseInline(child.textContent);
              const sanitizedContent = DOMPurify.sanitize(parsedContent);
              const tempSpan = document.createElement('span');
              tempSpan.innerHTML = sanitizedContent;
              child.replaceWith(...tempSpan.childNodes);
            }
          });
        }
    
        recursivelyParse(tempDiv);
    
        return tempDiv.innerHTML;
      }



    useEffect(()=>{
        if(service?.summarization){
            let newSummary = service?.summarization.replace(/class="container"/g, 'class="container-html-report-and-pdf"');            
            //setSummary(newSummary)

           // let htmlContent = parseMarkdownInHtml(newSummary)
          //  setSummary(htmlContent)
            

           // const htmlContent1 = md.render(service?.summarizatio);
          //  setSummary(htmlContent1)
            //return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;

            //************************ */
            //https://markdown-it.github.io/markdown-it/
            //npm install highlight.js - התקנתי
            //npm install markdown-it  - התקנתי

            /*
            let htmlContent = marked(newSummary);            
            const cleanHtmlString = DOMPurify.sanitize(htmlContent);              
            const parser = new DOMParser();
            const doc = parser.parseFromString(cleanHtmlString, 'text/html');
            htmlContent = doc.documentElement.outerHTML;
            setSummary(htmlContent)
            */
        
        }
    },[])

    const MarkdownRenderer = () => {
        if(service?.summarization){
            const postMortem = "# Post Mortem Report"
            const htmlContent = md.render(service?.summarization.replace(postMortem, ''));
            return <div className="markdown-content" dangerouslySetInnerHTML={{ __html: htmlContent }} />;
        }
        else{
            return <div>No summary</div>
        }
        
      };

    const handleCopyShortLink = () =>{
       
        let fromDate = from === undefined ? service.from : from;
       let toDate = to === undefined ? service.to : to;
       
        let link = window.location.href +"?serviceId=" + service.serviceId +  "&requestId=" + requestId + "&from=" + fromDate + "&to=" + toDate + "&short=incident";
        
        handleCopyToClipboard(link)

    }

    const handleCloseIncident = () =>{
        setModalCloseIncidentShow(true)
    }


    return (
        <div>
             <div className='sa-analyzer-incident-summary-title-box-right'>
                <img className='menu-icon summary-share-icon' src={share} alt="share" title="copy the incident link to clipboard" onClick={()=>handleCopyShortLink()} />
                <img className='menu-icon summary-jira-icon' src={jira} alt="jira" />
                {
                    ready === true ?
                    (
                        <span className='sa-analyzer-incident-summary-pdf-btn sa-analyzer-incident-summary-pointer' onClick={()=>setShowPDFModal(true)} ><PictureAsPdfIcon style={{ fontSize: 30 }} /></span>
                    ):
                    (
                        <span className='sa-analyzer-incident-summary-pdf-btn' ><PictureAsPdfIcon style={{ fontSize: 30, color: 'lightgray' }} /></span>

                    )
                }

                </div>
            {/*<div dangerouslySetInnerHTML={{ __html: summary }} />*/}
            <MarkdownRenderer  />
            {/*
            <div className='sa-analyzer-incident-summary-title'>
                <div className='sa-analyzer-incident-summary-title-box-left'>
                    <span>Status:</span><span className='sa-analyzer-incident-summary-status sa-analyzer-incident-summary-status-open'>Open</span>
                    <span className='sa-analyzer-incident-summary-details' onClick={()=>handleCloseIncident()}>Close Incident</span>
                </div>
                <div className='sa-analyzer-incident-summary-title-box'>Incident Summary (Generated by Generative AI)</div>
                <div className='sa-analyzer-incident-summary-title-box-right'>
                <img className='menu-icon summary-share-icon' src={share} alt="share" title="copy the incident link to clipboard" onClick={()=>handleCopyShortLink()} />
                <img className='menu-icon summary-jira-icon' src={jira} alt="jira" />
                {
                    ready === true ?
                    (
                        <span className='sa-analyzer-incident-summary-pdf-btn sa-analyzer-incident-summary-pointer' onClick={()=>setShowPDFModal(true)} ><PictureAsPdfIcon style={{ fontSize: 30 }} /></span>
                    ):
                    (
                        <span className='sa-analyzer-incident-summary-pdf-btn' ><PictureAsPdfIcon style={{ fontSize: 30, color: 'lightgray' }} /></span>

                    )
                }

                </div>
            </div>
            {
                service && service?.incident && (service.incident?.summarization && service.incident?.summarization !== '') ||
                service.summarization !== null && service.summarization !== "" ?
                    (
                        <div className='sa-analyzer-incident-summary-container'> <SARenderSummary service={service} /> </div>                        
                    ) :
                    (
                        <div className='sa-analyzer-incident-summary-desc'>No summary was found</div>
                    )
            }
                */}
            <CloseIncidentModal modalCloseIncidentShow={modalCloseIncidentShow} setModalCloseIncidentShow={setModalCloseIncidentShow} />
            <SAPDFModal modules={modules} service={service} showPDFModal={showPDFModal} setShowPDFModal={setShowPDFModal} valueFrom={from} valueTo={to} />
        </div>
    )
}

export default SAAnalyzerIncidentSummary