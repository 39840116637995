import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './HTMLModal.css';

const HTMLModal = ({ modalShow, setModalShow,  title, data }) => {


    const handleClose = () => {
        setModalShow(false)

     
    }




    return (
        <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: data }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
    );
};

export default HTMLModal;
