import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext } from "react";
import './SCBreadCrumbs.css';
import arrow from '../Images/arrow.png';

import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import useQuery from '../Hooks/useQuery';




const SCBreadCrumbs = ({ totalAfterFilter }) => {
    const { breadcrumbs, services, setMenuSelectedItem, handleBreadCrumbs, selectedService, currentPage, servicesCached } = useContext(DataContext);
    const history = useHistory();
    const query = useQuery();
    useEffect(() => {
        history?.listen((location, action) => {
            // location is an object like window.location


            switch (location?.pathname?.toLowerCase()) {
                case '/':
                    {
                        handleBreadCrumbs('Home', '')
                        break;
                    }
                case '/catalog':
                    {
                        handleBreadCrumbs('Catalog', location?.pathname)
                        break;
                    }
                case '/nodes':
                    {
                        handleBreadCrumbs('Nodes', location?.pathname)
                        break;
                    }
                case '/advanced':
                    {
                        handleBreadCrumbs('Advanced Search', location?.pathname)
                        break;
                    }
                case '/group-services':
                    {
                        handleBreadCrumbs('Manage Group', location?.pathname)
                        break;
                    }
                case '/manage-users':
                    {
                        handleBreadCrumbs('Admin', location?.pathname)
                        break;
                    }
                case '/dynamic-permission':
                    {
                        handleBreadCrumbs('Permission Self Service', location?.pathname)
                        break;
                    }
                case '/auto-cicd':
                    {
                        handleBreadCrumbs('DevOps Self Service', location?.pathname)
                        break;
                    }
                case '/infrarelations':
                    {
                        handleBreadCrumbs('Relations of infra packages', location?.pathname)
                        break;
                    }
                case '/cronjobs-monitor':
                    {
                        handleBreadCrumbs('CronJobs Monitor', location?.pathname)
                        break;
                    }
                case '/analyzer':
                    {
                        handleBreadCrumbs('Analyzer', location?.pathname)
                        break;
                    }
                default:
                    return '';
            }

        });
    }, [selectedService])

    const redirect = (text, route) => {

        handleBreadCrumbs(text, route);
        if (text != "/" && text.toLowerCase() != "services") {


            let result = route.includes("@");
            if (result) {
                //user got back to service page not from home page              
                history.push('service');
                setMenuSelectedItem('services')

            }
            else {

                history.push(route);
            }
        }
        else {
            history.push('/');
            setMenuSelectedItem('services')
        }

    }


    return (

        <div className="breadcrumbs-container">

            {

                breadcrumbs && breadcrumbs.map((b, index) => (

                    <div className="sc-breadcrumbs" key={index} style={{ backgroundColor: 'orange' }}>
                        <div className="breadcrumbs-item">

                            <div style={{ display: index < breadcrumbs.length - 1 ? 'inline-block' : 'none' }}>
                                <span style={{ textDecoration: index < breadcrumbs.length - 1 ? 'underline' : 'none', cursor: index < breadcrumbs.length - 1 ? 'pointer' : 'auto' }}>
                                    <span className="black-link" onClick={() => redirect(b.name, b.route)}>{b.name}</span>
                                </span>
                            </div>
                            <div style={{ display: index < breadcrumbs.length - 1 ? 'none' : 'inline-block' }}>
                                <div>
                                    {b.name}
                                </div>{/*style={{display:currentPage === 'services'  ? 'inline-block' : 'none'}}*/}
                                <div className="service-count" style={{ display: index === 0 && currentPage === 'services' ? 'inline-block' : 'none' }}>
                                    {servicesCached && servicesCached.length} Services
                                    {
                                        totalAfterFilter && totalAfterFilter > 0 ?
                                            (
                                                <span className="breadcrumbs-search-results" >(Total items displayed: {totalAfterFilter})</span>
                                            ) :
                                            (
                                                servicesCached && servicesCached.length > 0 ?
                                                    (
                                                        <span className="breadcrumbs-search-results" >(Total items displayed: {servicesCached?.filter(s => s.excludedFromBackstage === false).length})</span>
                                                    ) :
                                                    (
                                                        <span></span>
                                                    )

                                            )
                                    }

                                </div>
                            </div>


                        </div>
                        <span className="arrow breadcrumbs-item" style={{ display: breadcrumbs[index + 1] ? 'inline-block' : 'none' }} >
                            <img src={arrow} alt="Arrow" />
                        </span>
                    </div>



                ))

            }


        </div>

    )
}

export default SCBreadCrumbs
