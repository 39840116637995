import React, { useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import _ from "lodash";
import { createClientMessage, createChatBotMessage } from 'react-chatbot-kit';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import '../../ServiceAnalyzer/refactor/MarkdownReportStyle.css'
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


var typingId = null;
var typing = null;

const md = new MarkdownIt({
  highlight: (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (__) { }
    }
    return ''; // use external default escaping
  }
});




class ServiceUtils {



  constructor(setState, state, context) {
    this.setState = setState;
    this.state = state;
    this.context = context

  }

  


  async createUserMessage(userMessage, id = new Date().getTime()) {

    const message = createClientMessage(userMessage);
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));

  };


  async createTypingMessage() {
    let currentUUID = uuid()
    typingId = currentUUID;
    const messageTyping = createChatBotMessage({ message: "", type: 'typing', id: currentUUID });
    typing = messageTyping
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, messageTyping]
    }));

  }


  async removeTypingMessage() {
    if (typing.message.id === typingId) {
      typing.message.message = 'done';
    }
  }

  parseMarkdownInHtml(markdown) {
    const html = marked(markdown);

    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Recursively parse inner HTML content
    function recursivelyParse(element) {
      element.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          recursivelyParse(child);
        } else if (child.nodeType === Node.TEXT_NODE) {
          const parsedContent = marked.parseInline(child.textContent);
          const sanitizedContent = DOMPurify.sanitize(parsedContent);
          const tempSpan = document.createElement('span');
          tempSpan.innerHTML = sanitizedContent;
          child.replaceWith(...tempSpan.childNodes);
        }
      });
    }

    recursivelyParse(tempDiv);

    return tempDiv.innerHTML;
  }


  // Example function to convert and sanitize Markdown content
  //taltal
  convertAndSanitizeMarkdown(markdownContent) {
    // Convert Markdown to HTML
    const htmlContent = marked(markdownContent);

    // Sanitize the HTML content
    const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

    return sanitizedHtmlContent;
  }

  async sendMessage(reciveMessage) {
    /*
    console.log('6', this.context.signalrMessages)
    const postMortem = "# Post Mortem Report"
    if (reciveMessage?.data?.data?.chatResponse.includes(postMortem)) {
  
      const htmlContent = md.render(reciveMessage?.data?.data?.chatResponse.replace(postMortem, ''));
  
      const message = createChatBotMessage({ message: htmlContent, type: 'report' });
      console.log("*************message****************",message)
      this.removeTypingMessage();
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message]
      }));
      return;
    }
    

  
    try {
      if (reciveMessage?.data?.data?.chatResponse) {

        const regex = /<html lang="en">/;
        let htmlContent = null;
        if (regex.test(reciveMessage?.data?.data?.chatResponse)) {
          htmlContent = reciveMessage?.data?.data?.chatResponse;
       
          //remove all the <br>
          // htmlContent = htmlContent.replace(/<br\s*\/?>/gi, '');

          //htmlContent = this.context.markdownToHtml(htmlContent)
          const cleanHtmlString = DOMPurify.sanitize(htmlContent);
          const parser = new DOMParser();
          const doc = parser.parseFromString(cleanHtmlString, 'text/html');

     

          // Convert the modified DOM back to HTML string
          htmlContent = doc.documentElement.outerHTML;
        }
        else {


          const parsedHtml = this.parseMarkdownInHtml(reciveMessage?.data?.data?.chatResponse);
          // Sanitize the final HTML output
          const cleanHtmlString = DOMPurify.sanitize(parsedHtml);

          // Parse the sanitized HTML string with DOMParser
          const parser = new DOMParser();
          const doc = parser.parseFromString(cleanHtmlString, 'text/html');
          htmlContent = this.context.markdownToHtml(doc.documentElement.outerHTML);




       
          // Convert and sanitize the Markdown content
          const sanitizedHtmlContent = this.convertAndSanitizeMarkdown(reciveMessage?.data?.data?.chatResponse);

          // Create a DOMParser instance if needed

          const doc1 = parser.parseFromString(sanitizedHtmlContent, 'text/html');
          const htmlContent1 = doc1.documentElement.outerHTML;
      


        }

        const message = createChatBotMessage({ message: htmlContent, type: 'html' });
        console.log("*************message****************",message)
        this.removeTypingMessage();
        this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message]
        }));
      }
      else {

        const htmlContent = this.context.parseRecivedMessage("Sorry i didn't have an answer to you...")
        const message = createChatBotMessage({ message: htmlContent, type: 'html' });
        console.log("*************message****************",message)
        this.removeTypingMessage();
        this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message]
        }));
      }
    }
    catch (error) {
      this.removeTypingMessage();
      const htmlContent = this.context.parseRecivedMessage("Sorry i didn't have an answer to you...")
      const message = this.createChatBotMessage({ message: htmlContent, type: 'html' });
      console.log("*************message****************",message)
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message]
      }));
    }
*/
  }




}


export default ServiceUtils;