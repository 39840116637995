import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext, useRef } from "react";
import './ServiceScore.css';
import SCTable from './SCTable';
import { breakpoints } from "@mui/system";



const ServiceScore = () => {
    const { selectedService, scoreRules, setScoreRules, getRules } = useContext(DataContext);
    const [ruleDetails, setRuleDetails] = useState([]);
    const [ruleRowItems, setRuleRowItems] = useState([]);
    const [selectedRule, setSelectedRule] = useState('No rule was selected');


    const columnsItems = [{ display: false, name: "ruleId", key: "ruleId" },
    { display: false, name: "ruleScoreSet", key: "ruleScoreSet" },
    { display: true, name: "Rule Name", key: "ruleName" },
    { display: true, name: "Current Value", key: "currentValue" },
    { display: true, name: "Condition Result", key: "conditionResult" }, { display: true, name: "Score", key: "score" }, {display:false, name:"ruleMaxScore" , key:"ruleMaxScore"}];



    const columnsRuleDetailsItems = [{ display: true, name: "Rule Details", key: "ruleDetails" }];



    const handleRowClick = (e) => {
        const rowRuleDetailsItems = []
        setSelectedRule(e.ruleName)
        var scoreSet = JSON.parse(e.ruleScoreSet);
       
        var rule = scoreRules.filter(r => r.id === e.ruleId);
        var value = ''
        var score = 0;
        var ruleMaxScore = 0;

        if (rule && rule.length > 0) {
            value = rule[0].description;
            score = e.score;
            ruleMaxScore = e.ruleMaxScore;
        }
        var item = {
            ruleDetails: value 
        }
        calcCurrentValue(score, ruleMaxScore);
        rowRuleDetailsItems.push(item);
        setRuleDetails([...rowRuleDetailsItems]);
    }

    const calcCurrentValue = (score, maxScore) =>{      
        return (score * 100) / maxScore;        
    }

    const buildScoreRowItems = () => {
        const rowItems = []
        if (selectedService) {

            if (selectedService?.serviceScore?.scoreItems) {

                selectedService?.serviceScore?.scoreItems.forEach(item => {
                    var percentage = calcCurrentValue(item.score,item.ruleMaxScore)
                    var htmlRes = '';
                    if(percentage === 100)
                    {
                        htmlRes = <div className="condition-valid"></div>;
                    }
                    if(percentage < 50)
                    {
                        htmlRes = <div className="condition-invalid"></div>
                    }
                    if(percentage >= 50 && percentage <=70)
                    {
                        htmlRes = <div className="condition-avg"></div>
                    }
                    rowItems.push({
                        ruleId: item.ruleId,
                        ruleName: item.ruleName,
                        ruleScoreSet: JSON.stringify(item.ruleScoreSet),
                        currentValue: item.currentValue,
                        conditionResult: htmlRes,/*calcCurrentValue(item.score,item.ruleMaxScore) ? <div className="condition-invalid"></div> : <div className="condition-valid"></div>,*/
                        score: item.score + '/' + item.ruleMaxScore,
                        ruleMaxScore:item.ruleMaxScore
                    })
                });
            }
        }
        setRuleRowItems(rowItems)
    }

    useEffect(() => {

        if (scoreRules && scoreRules.length === 0) {
            Promise.all([getRules()])
                .then((results) => {
                    const res = results[0];
                    if (results) {
                        if (res?.status === 200) {
                            setScoreRules(res.data)
                            buildScoreRowItems();
                        }
                    }
                });
        }
        else {
            buildScoreRowItems();
        }

    }, [])

    return (
        <>
            <div className="total-score-container"><span className="total-score">Total Score: </span><span>{selectedService?.serviceScore?.totalScore}</span></div>
            <div> <SCTable title="Sonar Qube rules details" columnsItems={columnsItems} rowItems={ruleRowItems}
                includesRemoveButton={false} includesSelectButton={false} handleRowClick={handleRowClick}
            /> </div>
            <div className="rule-details">

                <SCTable title={"Rule Details (" + (selectedRule) + ")"} columnsItems={columnsRuleDetailsItems} rowItems={ruleDetails}
                    includesRemoveButton={false} includesSelectButton={false}
                />
            </div>
        </>
    )
}

export default ServiceScore
