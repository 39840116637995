import './CardsGridView.css';
import SCCard from "./SCCard";
import DataContext from "../Context/DataContext";
import { useHistory } from 'react-router-dom';
import React, { memo } from 'react';
import { useEffect, useRef, useState, useContext } from 'react';
import _ from "lodash";
import DataContextHome from '../Context/DataContextHome';


const CardsGridView = () => {
    const { services, setSelectedService } = useContext(DataContext);
    const { loadMore, setLoadMore,chunkedServices, setChunkedServices,
        isLoadMoreLoading, setIsLoadMoreLoading ,calcServices,handleScroll} = useContext(DataContextHome);

    const history = useHistory();   
    const scrollerRef = useRef(null);


    const handleCardClick = (service) => {


        setSelectedService(service);
        history.push({
            pathname: '/Service',
            display: 'ServiceChild',
            search: '?name=' + service.name
        })

    }

    useEffect(() => {
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Clean up event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        if (loadMore === 1) {
            calcServices()
        }

    }, [services])

   

    useEffect(() => {
      
        calcServices();
    }, [loadMore])

    return (

        <div>


            
            <div
                ref={scrollerRef}
                style={{position:'relative'}}
            >

                <div className="card-container-services">
                    {
                        chunkedServices && chunkedServices.map((s, index) => (

                            <div key={index} className="card-item-container pointer" onClick={() => handleCardClick(s)}>
                                <SCCard key={index} item={s} />
                            </div>

                        ))
                    }

                </div>

                {isLoadMoreLoading && <div className='card-view-loading'>Loading more services...</div>}
            </div>

        </div>

    )
}

export default memo(CardsGridView)
