import { useEffect, useState, useContext } from "react";
import './SelfServiceDB.css';




const SelfServiceDB = () => {


    return (
       <div>DB</div>
    )
}

export default SelfServiceDB
