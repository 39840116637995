import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerCreateApplicationForm.css';
import _ from "lodash";
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import DataContext from '../../Context/DataContext';
import SCButton from '../SCButton';
import SCTable from '../SCTable';
import SCContainer from '../SCContainer';
import SCInputText from '../SCInputText';
import SCTextArea from '../SCTextArea';
import SCDropDownList from '../SCDropDownList';
import SCToggle from '../SCToggle';




const ServiceAnalyzerCreateApplicationForm = ({ buttonText, handleSubmit, selectedCronPattern, setSelectedCronPattern, ddlIncident, setDdlIncident,
    incidentProbability, setIncidentProbability, newRelicEventTitle, setNewRelicEventTitle, optionsCached, setOptionsCached,
    selectedServices, setSelectedServices, options, setOptions, applicationDescription, setApplicationDescription, setDdlCron, ddlCron,
    applicationName, setApplicationName, selectedApp, slackNotification, setSlackNotification }) => {

    const { servicesCached } = useContext(DataContext);

    const [isEventTitleInValid, setIsEventTitleInValid] = useState(false)
    const [regexAppNameError, setRegexAppNameError] = useState(false)

    useEffect(() => {
        buildFilteredServices();
        initDdls();
    }, [])

    const columnsItems = [
        { display: true, name: "serviceId", key: "serviceId" },
        { display: true, name: "name", key: "name" },
        { display: true, name: "project", key: "project" },
        { display: true, name: "type", key: "type" },
        { display: true, name: "team", key: "team" }
    ]

    const applicationsItems = [
        { display: false, name: "id", key: "id" },
        { display: true, name: "name", key: "name" },
        { display: true, name: "description", key: "description" },
        { display: true, name: "serviceIds", key: "serviceIds" },
        { display: true, name: "schedulerCronPattern", key: "schedulerCronPattern" },
        { display: true, name: "incidentProbability", key: "incidentProbability" },
        { display: true, name: "incidentNewRelicEventTitle", key: "incidentNewRelicEventTitle" }
    ]


    const initDdls = () => {


        var incident = [];
        incident.push({ name: 'High', value: 'High' }, { name: 'Medium', value: 'Medium' }, { name: 'Low', value: 'Low' })
        setDdlIncident(incident)


    }

    const buildFilteredServices = () => {

        if (servicesCached && servicesCached.length > 0) {
            var services = []
            let filterServicesCached = servicesCached?.filter(s =>
                (!s.serviceType.toLowerCase().startsWith('aks') &&
                    !s.serviceType.toLowerCase().startsWith('eks') &&
                    isNotNullUndefinedOrEmpty(s.serviceMetadata?.newRelicAppName) &&
                    isNotNullUndefinedOrEmpty(s.portalUrl)
                ) || (s.serviceType.toLowerCase().startsWith('aks') || s.serviceType.toLowerCase().startsWith('eks'))) //portal url is for the AppServiceResourceId

            filterServicesCached.map((p) => {

                if (p.serviceType.toLowerCase().startsWith('aks') || p.serviceType.toLowerCase().startsWith('eks')) {
                    if (p.serviceType.toLowerCase().startsWith('eks')) {
                        services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: "", clusterName: p?.eksInfo?.clusterName })
                    }
                    else {
                        services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: "", clusterName: p?.aksInfo?.clusterName })
                    }

                }
                else {
                    services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: p.portalUrl, team: p?.serviceMetadata?.team })
                }

            })
            setOptions(services)
            setOptionsCached(services)
        }


    }
    const isNotNullUndefinedOrEmpty = (str) => {
        return str !== null && str !== undefined && str.trim() !== '';
    }



    const handleAutoChange = (value) => {

        if (value === '') {
            buildFilteredServices();
            return;
        }
        const autoCompleteArray = _.filter(optionsCached, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
        setOptions(autoCompleteArray)

    }

    const getOptionLabel = (option) => {
        return `${option.id} - ${option.name} (${option.type})`;
    };


    const handleRequestRemove = (e) => {

        let removeItem = [...selectedServices];
        removeItem.splice(e, 1);
        setSelectedServices([...removeItem])
    }


   
    const handleCronPattern = (e) => {

        setSelectedCronPattern(e.target.value.toString())

    }

    const handleIncidentProbability = (e) => {

        setIncidentProbability(e.target.value)
    }

    const handleNewRelicEventTitleChange = (e) => {

        if (/^[A-Za-z]+$/.test(e.target.value)) {
            setIsEventTitleInValid(false)
            setNewRelicEventTitle(e.target.value)
        } else {
            setIsEventTitleInValid(true)
        }
    }



    const handleUserClick = (e, value) => {

        let servicesArr = [...selectedServices];
        let selectedUserService = {
            serviceId: value.serviceId,
            name: value.name,
            project: value.project,
            type: value.type,
            team: value.team

        }

        servicesArr.push(selectedUserService);
        setSelectedServices([...servicesArr])



    }
    const handleApplicationDescription = (e) => {
        setApplicationDescription(e.target.value)
    }

    const handleApplicationNameChange = (e) => {

        const inputValue = e.target.value;

        // Regex pattern to match only digits
        const regex = /^[a-zA-Z0-9]*$/;

        // Check if input value matches the regex pattern
        if (regex.test(inputValue)) {
            setRegexAppNameError(false)
            setApplicationName(inputValue);
        }
        else {
            setRegexAppNameError(true)

        }
    }


    const handleSlackActiveChange = () => {
        setSlackNotification(!slackNotification)
    }

    return (
        <React.Fragment>

            <div className='analyzer-group-div'>

                <SCInputText required={true} size="small" className="md-input" label="Application Name (digits and characters only)" value={applicationName}
                    handleInputValueChange={(e) => handleApplicationNameChange(e)}

                />
                {
                    regexAppNameError &&
                    (
                        <FormHelperText className='cicd-field-error'>Please enter letters and digits only.</FormHelperText>
                    )

                }


            </div>
            <div className='analyzer-group-div'>

                <SCTextArea rows={1.9} size="small" name='description' className="md-input" label="Application Description"
                    handleInputValueChange={(e) => handleApplicationDescription(e)} value={applicationDescription} />

            </div>
            <div className='analyzer-group-div'>
                <SCContainer children={

                    <Autocomplete
                        freeSolo
                        id="search-services"
                        disableClearable
                        options={options}
                        onChange={(e, value) => handleUserClick(e, value)}
                        getOptionLabel={getOptionLabel}

                        filterOptions={(options) => options}

                        renderInput={(params) => (
                            <TextField
                                className='deployment-changes-services'
                                {...params}
                                label="Search Service *"
                                InputProps={{
                                    ...params.InputProps,
                                    type: "search"
                                }}
                                onChange={(event) =>
                                    handleAutoChange(event.target.value)
                                }
                            />
                        )}


                    />
                } loading={optionsCached.length === 0} />
            </div>
            <div className='analyzer-group-div'>
                <SCTable title="Services (at least 1 service)" columnsItems={columnsItems} rowItems={selectedServices}
                    handleRemove={(event) => handleRequestRemove(event)}
                    includesRemoveButton={true} />
            </div>

            {/*
            <div className='analyzer-group-div'>
                <SCDropDownList id="cicd-solution-type" className="md-ddl-edit" label="Scheduler cron pattern *" componentState={selectedCronPattern}
                    setComponentState={setSelectedCronPattern}
                    size="1000" items={ddlCron} handleChange={(e) => handleCronPattern(e)} value={selectedCronPattern} />
            </div>
                        */}

            <div className='analyzer-group-div'>
                <SCDropDownList id="cicd-solution-type" className="md-ddl-edit" label="Incident Probability" componentState={incidentProbability}
                    setComponentState={setIncidentProbability}
                    size="1000" items={ddlIncident} handleChange={(e) => handleIncidentProbability(e)} value={incidentProbability} />
            </div>

            <div className='analyzer-group-div'>
                <SCInputText size="small" className="md-input" label="NewRelic Event Title *" value={newRelicEventTitle}
                    text={'Only english characters, lower or upper case, any special tags are not allow'}
                    handleInputValueChange={(e) => handleNewRelicEventTitleChange(e)}
                    /* handleInputValueBlur={(e) => handleNewRelicEventTitleOnBlur(e)}*/
                    regex='[A-Za-z]+$' invalid={isEventTitleInValid}
                />
            </div>
            <div className='analyzer-group-div'>
                <SCToggle handleToggleChange={() => handleSlackActiveChange()} title="Active/InActive slack notifications:" isChecked={slackNotification} />
            </div>
            <div className='analyzer-group-div'>
                {
                    !isEventTitleInValid && selectedServices.length > 0 && applicationName !== '' && newRelicEventTitle !== '' && !regexAppNameError ?
                        (
                            <SCButton customClass="analyzer-application-group-button" size="large" text={buttonText} handleButtonClick={() => handleSubmit()} />
                        ) :
                        (
                            <SCButton customClass="analyzer-application-group-button-invalid" size="large" text={buttonText} />
                        )
                }

            </div>

        </React.Fragment>
    )
}

export default ServiceAnalyzerCreateApplicationForm