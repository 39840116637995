import { useEffect } from 'react';
import './SCCheckBox.css';

const SCCheckBox = ({ customClass, handleClick, text = "", enable = true }) => {



    return (

        text === "" ?
            (
                <div className={customClass} onClick={handleClick} />
            ) :
            (
                enable ?
                    (
                        <div>
                            <span className={`table-check-box ${customClass} sc-div`} onClick={handleClick}></span>
                            <span className='sc-div-text'>{text}</span>
                        </div>
                    ) :
                    (
                        <div className='sc-div-disable'>
                            <span className={`table-check-box ${customClass} sc-div`} onClick={handleClick}></span>
                            <span className='sc-div-text'>{text}</span>
                        </div>

                    )
            )

    )
}

export default SCCheckBox
