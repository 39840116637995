import React, { useEffect, useState, useContext } from 'react'
import './SAAnalyzerSummary.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DataContext from '../../../Context/DataContext';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import SAAnalyzerTimeline from './SAAnalyzerTimeline';

const SAAnalyzerSummary = ({ targetRef, services, data, service, setValue, progressBar, fromHistory = false }) => {

    const { servicesCached, formatDateTime } = useContext(DataContext);
    const { LuxonDateFormatString, translateAnalyzerCodes } = useContext(DataContextAnalyzer);
    const [modules, setModules] = useState({})

    const scrollToTarget = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(async () => {
        if (!data) {
            return;
        }


        let cloneModules = { ...data };
        if (cloneModules.hasOwnProperty("Summary")) {
            delete cloneModules["Summary"];
            setModules(cloneModules)
        }

    }, [data])



    const RenderDateTime = (values) => {

        if (values.values.length > 0) {
            return (
                <div id="sa-summary-report-table-date">{LuxonDateFormatString(values.values[0].occurredAt)}</div>
            )
        }
        return (
            <div>-</div>
        )
    }

    const RenderDetectedChanges = (values) => {
        if (values.values.length > 0) {
            return (
                <div><CheckBoxIcon /></div>
            )
        }
        return (
            <div><CheckBoxOutlineBlankIcon /></div>
        )
    }

    const handleChangeTab = (key) => {

        scrollToTarget()
        switch (key) {
            case "Configuration":
                {
                    setValue(2)
                    break;
                }
            case "Database":
                {
                    setValue(3)
                    break;
                }
            case "Deployments":
                {
                    setValue(4)
                    break;
                }
            case "Diagnostics":
                {
                    setValue(5)
                    break;
                }
            case "Logs":
                {
                    setValue(6)
                    break;
                }
            case "Metrics":
                {
                    setValue(7)
                    break;
                }
            case "ResourceChanges":
                {
                    setValue(8)
                    break;
                }


            default:
                break;
        }
    }



    const RenderTimeline = () => {

        if (service && service.report && service.report.length > 0) {
            const modulesToCheck = ['deployments', 'configuration', 'database', 'metrics', 'resourcechanges'];
            const hasModule = service.report.some(item => modulesToCheck.includes(item.module.toLowerCase()));
            if (hasModule) {

                return (
                    <div className='service-analyzer-summary-timeline-container'>
                        {/*<div className='service-analyzer-summary-timeline-container-title'>Timeline</div>*/}
                        <div className='service-analyzer-summary-timeline-contorller'>
                            {
                                !fromHistory ?
                                    (
                                        <SAAnalyzerTimeline service={service} services={services} />
                                    ) :
                                    (
                                        <span className='service-analyzer-summary-timeline-no-data'>There is no timeline when you ask for history report</span>
                                    )
                            }
                        </div>
                    </div>
                )

            } else {

                return (
                    <span>We have not found any insight that can be displayed on the timeline</span>
                )

            }
        }
        return (
            <React.Fragment></React.Fragment>
        )


    }


    const RenderFlowTable = () => {
        if (!data) {
            return (
                <div></div>
            )
        }

        let clonedData = { ...data }
        delete clonedData.RootCause;

        // Extract all values from the dictionary object into a flat array
        const flatArray = Object.values(clonedData).reduce((acc, val) => acc.concat(val), []);

        // Sort the flat array by the createdOn property
        const sortedArray = flatArray.sort((a, b) => new Date(a.occurredAt) - new Date(b.createdOn));
        sortedArray.sort((a, b) => new Date(a.occurredAt) - new Date(b.occurredAt));


        return (

            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>When</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sortedArray && sortedArray.length > 0 ?
                                (
                                    sortedArray.map((s, index) => (
                                        <TableRow>
                                            <TableCell>{LuxonDateFormatString(s.occurredAt, 'dd-MM-yyyy hh:mm:ss a')}</TableCell>
                                            <TableCell>{s.module}</TableCell>
                                            <TableCell>{translateAnalyzerCodes(s)}</TableCell>
                                            <TableCell id={`sa-summary-report-table-details-active-` + s.module + ' ' + index} onClick={() => handleChangeTab(s.module)}><div className='service-analyzer-summary-details'>Details</div></TableCell>
                                        </TableRow>
                                    ))
                                ) :
                                (
                                    <div></div>
                                )
                        }

                    </TableBody>
                </Table>
                {
                    (progressBar && progressBar.state === 7) || fromHistory ?
                        (
                        
                             <RenderTimeline />
                          
                        ) :
                        (
                            <div className='service-analyzer-summary-details-timeline-msg'>The Timeline has not been generated yet. Please wait until the end of the scanning.</div>
                        )
                }
            </TableContainer>

        )
    }

    return (
        <React.Fragment>

            <RenderFlowTable />



            {/*
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date Time</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Changes detected</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(modules).map(([key, values], index) => (
                                <React.Fragment>
                                    <TableRow id={`sa-summary-report-table-row-` + index} key={`name-element` + index}>
                                        <TableCell >
                                            <RenderDateTime values={values} />
                                        </TableCell>
                                        {
                                            key !== "Summary" ?
                                                (

                                                    key === "Metrics" ?
                                                        (
                                                            <TableCell>Metrics(Traffic, Response Time, CPU, Memory)</TableCell>
                                                        ) :
                                                        key === "Logs" ?
                                                            (
                                                                <TableCell>Logs Error</TableCell>
                                                            ) :
                                                            (
                                                                <TableCell>{key}</TableCell>
                                                            )


                                                ) :
                                                (
                                                    <React.Fragment />
                                                )

                                        }
                                        <TableCell>
                                            <RenderDetectedChanges values={values} />
                                        </TableCell>
                                        <TableCell >
                                            {
                                                values.length > 0 ?
                                                    (
                                                        <div id={`sa-summary-report-table-details-active-` + key} className='service-analyzer-summary-details' onClick={() => handleChangeTab(key)} >Details</div>
                                                    ) :
                                                    (
                                                        <div id={`sa-summary-report-table-details-inactive-` + key} className='service-analyzer-summary-details-disable'  >Details</div>
                                                    )
                                            }
                                        </TableCell>


                                    </TableRow>
                                </React.Fragment>

                            ))


                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                (progressBar && progressBar.state === 7) || fromHistory ?
                    (
                        <RenderTimeline />
                    ) :
                    (
                        <div className='service-analyzer-summary-details-timeline-msg'>The Timeline has not been generated yet. Please wait until the end of the scanning.</div>
                    )
            }


*/}

        </React.Fragment>
    )
}

export default SAAnalyzerSummary