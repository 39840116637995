import DataContext from "../Context/DataContext";
import React, { useEffect, useState, useContext } from "react";
import './SCHeader.css';
import SearchServices from "./SearchServices";
import takeaway from '../Images/takeaway.png';
import tele from '../Images/chart-relation.svg';
import ic from '../Images/ic-icon.png';




const SCHeader = () => {
    const { } = useContext(DataContext);
    const buildVersion = process.env.REACT_APP_BUILD_VERSION;

    return (
        <>
            <div className="header-container">
               
                <div className="row">
                    <div className="text col-1">
                        <img src={ic} alt="icon" className="sc-icon" />

                    </div>
                    <div className="text col-3">
                        <span>Infrastructure Console</span>
                    </div>
                    <div className="search col-4" >
                        <SearchServices />
                    </div>
                    <div className="col-4" style={{ textAlign: 'right', paddingRight: '4rem' }}>
                        <img className="takeaway" src={takeaway} alt="icon" title={buildVersion} />

                    </div>

                </div>
                

            </div>
        </>
    )
}

export default SCHeader
