import { useContext, React, useState, useEffect, useRef, useCallback } from "react";
import './TreeNodes.css';
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import { useHistory } from 'react-router-dom';
import ReactFlow, { addEdge, MiniMap, Controls, Background, useNodesState, useEdgesState, useReactFlow } from 'react-flow-renderer';
import _ from "lodash";
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Header from '../Home/Header';
import { Button } from 'react-bootstrap';

import * as consts from '../consts';
import SCDropDownList from "../Components/SCDropDownList";
import SCButton from "../Components/SCButton";
import SCLinkButton from "../Components/SCLinkButton";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



const TreeNodes = () => {
    const { services, groupPackagesInstance, getServicesWithInfraNugets, infraNugetsServices,
        setGroupedPackages, groupedPackages, getServiceBrachesInfo, branchDetails, setCurrentPage } = useContext(DataContext);

    const [isRootNodes, setIsRootNodes] = useState(true);
    const [projectsNodes, setProjectsNodes, onNodesChange] = useState([]);
    const [selectedNode, setSelectedNode] = useState({});
    const [serviceGrouped, setServiceGrouped] = useState([]);
    const [edges, setEdges, onEdgesChange] = useState([]);

    const [nodeHeight, setNodeHeight] = useState([]);
    const [nodeID, setNodeID] = useState([]);

    const [nodeInfraHeight, setNodeInfraHeight] = useState([]);
    const [nodeInfraID, setNodeInfraID] = useState([]);
    const [nodeInfraValue, setNodeInfraValue] = useState([]);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const [selectedInfraPackage, setSelectedInfraPackage] = useState('');
    const [ddlInfraPackages, setDdlInfraPackages] = useState([]);


    const onConnect = (params) => setEdges((eds) => addEdge(params, eds));
    const nodeFixedWidth = '30rem';
    const paddingLeftStart = 50;
    const positionCenter = 600;
    const nodeStyle = {
        /*background: '#EEEEEE', color: '#333', border: '1px solid #222138',
        width: nodeFixedWidth,
        maxWidth:'30rem',
        minHeight:'4vw',
        maxHeight:'8vw',
        fontSize: '1.5rem',
        textAlign: 'center',        
        wordBreak: 'break-all'*/

        background: '#EEEEEE', color: '#333', border: '1px solid #222138',
        // width:'auto',
        maxWidth: '20rem',
        minWidth:  window.screen.width <= 1280 ? '30rem' : '25rem',
        //height:'auto',
        minHeight: '4rem',
        fontSize: window.screen.width <= 1280 ? '1.5rem' : '1rem',
        textAlign: 'center',
        wordBreak: 'break-all'


      

    }


    useEffect(() => {
        setCurrentPage('treenodes')
    }, [])

    useEffect(() => {
        if (services) {

            var service = services.filter(s => s.name.toLowerCase() === consts.INFRA_PKG_NAME);
            if (service && service.length > 0 && service[0]?.branches[0]) {
                getServiceBrachesInfo(service[0]?.id, service[0]?.branches[0]);
            }
        }

    }, [services]);

    useEffect(() => {


        if (Object.keys(branchDetails).length > 0) {


            setEdges([]);
            setProjectsNodes([]);
            setServiceGrouped([]);
            generateInfrafilterValues();
            buildInfraPackagesNodes(null);
        }

    }, [branchDetails]);

    const generateInfrafilterValues = () => {
        var packageList = []


        if (Object.keys(branchDetails).length > 0) {

            branchDetails?.data?.forEach(p => {
                packageList.push(p.name)
            });
            setDdlInfraPackages(packageList);
        }
    }

    useEffect(() => {


        if (Object.keys(branchDetails).length > 0 && selectedInfraPackage != '') {

            var data = branchDetails?.data.filter(b => b.name === selectedInfraPackage.newValue);
            buildInfraPackagesNodes(data);
        }

    }, [selectedInfraPackage])




    useEffect(() => {

        if (infraNugetsServices && infraNugetsServices?.data?.services && selectedNode?.id) {


            //manipulation on infra nodes only
            if (selectedNode.data.type === 'infra-packages') {
                //remove all the infra packages execpt for the node that clicked
                projectsNodes?.filter(p => p.data.type === 'infra-packages' && p.id != selectedNode.id).map(x => x.data.active = false);

                //place the node in the center of the page
                selectedNode.position.x = 600;
                selectedNode.position.y = 50;

                BuildServicesNodes(infraNugetsServices?.data?.services)


                projectsNodes?.filter(p => p.id === nodeInfraID).map(x => x.position.y = 50)


                const edges = []
                var count = 0;
                infraNugetsServices?.data?.services.forEach(() => {
                    count++;
                    var edge = { id: 'infra-edge-' + count, source: selectedNode.id, target: 'srv-' + count, active: true }
                    edges.push(edge);
                })
                setEdges([...edges])

            }


        }

    }, [infraNugetsServices, selectedNode]);


    const onInit = (reactFlowInstance) => {

        setReactFlowInstance(reactFlowInstance);

    }


    const scrollToTop = () => {

        document.getElementById("flow-chart").scrollTop = 0;
    }
    const getPackagesRelatedToInfraNuget = (v) => {
        if (v.packages.filter(i => i.name === nodeInfraValue).length > 0) {
            return true;
        }
        else {
            return false
        }
    }
    const buildProjectsByBranch = (value) => {

        if (value && value.length) {
            /*   var allProjectsWithSelectedNuget = value.map(p => p.packages.map(v => v.name === nodeInfraValue));*/


            var filtered = value.filter((obj) => {
                return getPackagesRelatedToInfraNuget(obj);
            });




            var count = 0;
            var height = 350
            var paddingLeft = paddingLeftStart;
            var tempNode = {};
            var edgeCount = 0;


            filtered.forEach((item) => {

                var packageItem = item.packages.filter(x => x.name === nodeInfraValue)
                count++;
                edgeCount++;
                var node = {
                    id: 'prj-' + count,
                    data: {
                        label: (
                            <>
                                <div><strong>Project: </strong>{item.name}</div>
                                <div><strong>Current Version: </strong>{packageItem[0].currentVersion}</div>
                                <div><strong>Installed Version: </strong>{packageItem[0].installedVersion}</div>
                            </>


                        ),
                        key: item.branch,
                        value: item.projects,
                        type: 'branches', active: true, clickable: false
                    },
                    position: { x: paddingLeft, y: height },

                    targetPosition: 'top',
                    style: nodeStyle
                }


                if (count % 3 === 0) {


                    height = height + 300;
                    paddingLeft = paddingLeftStart;
                    edgeCount = 0;
                }
                else {
                    paddingLeft = paddingLeft + 550;

                }

                tempNode = node;
                projectsNodes.push(node)
            })
            setProjectsNodes([...projectsNodes])


        }
    }

    const handleNodeClick = async (e, node) => {
        //get all the services which are related to infra pkg that was clicked by the user
        setIsRootNodes(false);
        scrollToTop();

        setSelectedNode(node);

        if (node.data.clickable && node.data.type === 'infra-packages') {
            setNodeInfraValue(node.data.value);
            getServicesWithInfraNugets(node.data.value);

        }
        if (node.data.clickable && node.data.type === 'services') {
            //remove all the infra packages execpt for the node that clicked
            projectsNodes?.filter(p => p.data.type === 'services' && p.id != node.id).map(x => x.data.active = false);

            //place the node in the center of the page
            node.position.x = 600;
            node.position.y = 150;

            var group = serviceGrouped[node.data.key];


            BuildServicesBranchesNodes(group);


            const edges = []
            var count = 0;
            group.forEach(() => {
                count++;
                var edge = { id: 'service-edge-' + count, source: node.id, target: 'brc-' + count, active: true }
                edges.push(edge);
            })
            setEdges([...edges])


        }
        if (node.data.clickable && node.data.type === 'branches') {

            projectsNodes?.filter(p => p.data.type === 'branches' && p.id != node.id).map(x => x.data.active = false);

            //place the node in the center of the page
            node.position.x = 600;
            node.position.y = 250;

            buildProjectsByBranch(node.data.value);



            const edges = []
            var count = 0;
            node.data.value.forEach(() => {
                count++;
                var edge = { id: 'branch-edge-' + count, source: node.id, target: 'prj-' + count, active: true }
                edges.push(edge);
            })
            setEdges([...edges])
        }

    }

    const buildInfraPackagesNodes = (filteredData) => {


        setIsRootNodes(true);
        scrollToTop();
        var nodeList = []
        var count = 0;
        var height = 50
        var paddingLeft = paddingLeftStart;
        //var data = branchDetails?.data;
        var data = branchDetails?.data.filter(d=>d.generatePackageOnBuild)

        if (filteredData) {
            data = filteredData
        }

        data?.forEach(element => {

            count++;
            var node = {
                id: 'pkg-' + count,
                type: 'input',
                data: {
                    label: (<div><strong>Package: </strong>{element.name}</div>), key: element.name, value: element.name,
                    type: 'infra-packages', active: true, clickable: true
                },
                position: { x: paddingLeft, y: height },
                style: nodeStyle
            }
            if (count % 3 === 0) {


                height = height + 100;
                paddingLeft = paddingLeftStart;
            }
            else {
                paddingLeft = paddingLeft + 550;
            }
            nodeList.push(node);
        });
        setProjectsNodes(nodeList)

    }
    const BuildServicesBranchesNodes = (branches) => {

        var count = 0;
        var height = 250
        var paddingLeft = paddingLeftStart;
        var tempNode = {};
        var edgeCount = 0;


        branches.forEach((item) => {

            count++;
            edgeCount++;
            var node = {
                id: 'brc-' + count,
                data: {
                    label: (<div><strong>Branch: </strong>{item.branch}</div>),
                    key: item.branch,
                    value: item.projects,
                    type: 'branches', active: true, clickable: true
                },
                position: { x: paddingLeft, y: height },
                //targetPosition: getTargetPosition(edgeCount),
                targetPosition: 'top',
                style: nodeStyle
            }


            if (count % 3 === 0) {

                //height = height + 50;
                height = height + 100;
                paddingLeft = paddingLeftStart;
                edgeCount = 0;
            }
            else {
                paddingLeft = paddingLeft + 550;

            }

            tempNode = node;
            projectsNodes.push(node)
        })
        setProjectsNodes([...projectsNodes])


    }
    const BuildServicesNodes = (value) => {

      
        var grouped = _.groupBy(value, "name");
        setServiceGrouped(grouped)




        var count = 0;
        var height = 150
        var paddingLeft = paddingLeftStart;
        var tempNode = {};
        var edgeCount = 0;


        Object.entries(grouped).forEach(([key, value]) => {

            count++;
            edgeCount++;
            var node = {
                id: 'srv-' + count,
                data: {
                    label: (<div><strong>Service: </strong>{key}</div>), key: key, value: value,
                    type: 'services', active: true, clickable: true
                },
                position: { x: paddingLeft, y: height },
                //targetPosition: getTargetPosition(edgeCount),
                targetPosition: 'top',
                style: nodeStyle
            }


            if (count % 3 === 0) {

                //height = height + 50;
                height = height + 100;
                paddingLeft = paddingLeftStart;
                edgeCount = 0;
            }
            else {
                paddingLeft = paddingLeft + 550;

            }

            tempNode = node;
            projectsNodes.push(node)
        })


        //if we have only 1 service which is related to the infra package
        if (count === 1) {
            projectsNodes.filter(p => p.id === tempNode.id).map(n => n.position.x = positionCenter);
        }

        setProjectsNodes([...projectsNodes])



    }
    const clearFilter = (e, newValue) => {

        if (Object.keys(newValue).length === 0 && Object.keys(branchDetails).length > 0) {

            buildInfraPackagesNodes(null);

        }
    }

    return (
        <>

            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main">
                <div className="row">
                    <div className="col-12 nodes-filter" >
                        <Autocomplete
                            style={{ margin: 'auto' }}
                            disablePortal
                            id="combo-box-demo"
                            options={ddlInfraPackages}
                            sx={{ width: '400px' }}
                            renderInput={(params) => <TextField {...params} label="Filter packages" />}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setSelectedInfraPackage({
                                        newValue,
                                    });
                                }
                            }}
                            onInputChange={(event, newValue) => {
                                clearFilter(event, newValue);
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="tree-nodes-back-button col-12" style={{ display: isRootNodes ? 'none' : 'inline' }}>
                        <SCLinkButton text="Back" handleLinkClick={() => buildInfraPackagesNodes(null)}></SCLinkButton>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div id="flow-chart" className='react-flow-container'>
                            <ReactFlow
                                fitView
                                style={{ position: 'absolute', left: '8%' }}
                                nodes={projectsNodes && projectsNodes.length ? projectsNodes.filter(x => x.data.active == true) : []}
                                edges={edges && edges.length ? edges.filter(x => x.active === true) : []}
                                onNodeClick={(e, node) => handleNodeClick(e, node)}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onInit={onInit}
                                zoomOnScroll={false}
                                zoomOnPinch={false}
                                zoomOnDoubleClick={false}
                                nodesDraggable={false}
                                panOnDrag={false}
                                nodesConnectable={false}
                                attributionPosition="top-center"
                                preventScrolling={false}

                            >

                                <Background color="#aaa" gap={16} />

                            </ReactFlow>

                        </div>


                    </div>
                </div>
            </div>
        </>
    );


}

export default TreeNodes
