import { Modal, Button } from "react-bootstrap";
import './YesNoModal.css';


const YesNoModal = ({ setYesNoResult, modalAlertShow, setModalAlertShow  }) => {
  

    const handleCloseModal = () => {
        setModalAlertShow(false)

    }

    

    const handleYes = () =>{
        setYesNoResult(true)
        setModalAlertShow(false)
    }

    const handleNo = () =>{
        setYesNoResult(false)
        setModalAlertShow(false)
    }


    return (
        <div>

            <Modal
                show={modalAlertShow}
                onHide={handleCloseModal}               
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="analyzer-group-alert-modal-title">
                       Warning!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Some changes have been made. Do you want to save these changes?</div>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="outline-success" onClick={() => handleYes()}>Yes</Button>
                <Button variant="outline-success" onClick={() => handleNo()}>No</Button>
            </Modal.Footer>
            </Modal>           
        </div>

    )
}

export default YesNoModal
