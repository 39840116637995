import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useState, useEffect } from "react"

const ErrorModal = ({ show}) => {
  const { modalErrorShow, setModalErrorShow } =
  useContext(DataContext);
  //const [show, setShow] = useState(true);
  
  useEffect(()=>{
   if(setModalErrorShow)
    {
    
      setModalErrorShow(true)
    }
  },[])

    return (
        <>
      <Modal     
      show={modalErrorShow} 
      onHide={() => setModalErrorShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Something went wrong!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="col-3" style={{float:'left'}}>
            More Details:
            <Container>                   
                <Row>
                    <Col>Error</Col>
                    <Col>58</Col>
                </Row>              
            </Container>
         </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={() => setModalErrorShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
        </>
    )
}

export default ErrorModal
