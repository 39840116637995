
import React from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import _ from "lodash";
import './ServiceAnalyzerReportInfraModal.css';
import { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ServiceAnalyzerResourceChangeModal from "./ServiceAnalyzerResourceChangeModal";
import * as consts from '../../consts';
import DataContextAnalyzer from "../../Context/DataContextAnalyzer";
import { useContext } from "react";
import SCDropDownList from '../../Components/SCDropDownList';
const { DateTime } = require('luxon');





const ServiceAnalyzerReportInfraModal = ({ show, setShowAnalyzerInfraReport, modules, serviceName }) => {

    const { LuxonDateFormatString } = useContext(DataContextAnalyzer);
    const [value, setValue] = React.useState("0");
    const [groupedData, setGroupedData] = useState({});
    const [showAnalyzerResourceChange, setShowAnalyzerResourceChange] = useState(false)
    const [resourceChangesData, setResourceChangesData] = useState({});
    const [selectedType, setSelectedType] = useState('');
    const [orderedInsights, setOrderedInsights] = useState([]);

    const objectRef = useRef('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (show) {
            setValue("0")
        }

    }, [show])

    useEffect(() => {


        if (Object.entries(modules).length === 0) {
            return;
        }
        let groupedDataCloned = { ...modules };

        if (typeof groupedDataCloned.health === 'undefined') {

            groupedDataCloned.health = []
        }

        if (typeof groupedDataCloned.infra !== 'undefined') {

            groupedDataCloned.infra = groupedDataCloned.infra.filter(item => item.data.resourceGroup.toLowerCase() !== consts.INFRA_CHANGES_EXCLUDE);

        } else {
            // Render your component with the defined object
            groupedDataCloned.infra = []
        }




        setGroupedData(groupedDataCloned)

    }, [modules])

    const handleResourceChangeClick = (value) => {
        setShowAnalyzerResourceChange(true)
        setResourceChangesData(value)
    }

    const RenderDataByCode = ({ code, value }) => {


        switch (code.toString().toLowerCase()) {



            case "impact": {

                if (Array.isArray(value.data)) {
                    return (
                        value.data.map((d) => {
                            <ul>
                                <li><span className="service-analyzer-report-infra-health-title">Header:</span><span>{d.header}</span></li>
                                <li><span className="service-analyzer-report-infra-health-title">Id:</span><span>{d.id}</span></li>
                                <li><span className="service-analyzer-report-infra-health-title">Level:</span><span>{d.level}</span></li>
                                <li><span className="service-analyzer-report-infra-health-title">Title:</span><span>{d.title}</span></li>
                            </ul>
                        })
                    )

                }
                else if (value.data instanceof Object) {
                    return (
                        <ul>
                            <li><span className="service-analyzer-report-infra-health-title">Header:</span><span>{value.data.header}</span></li>
                            <li><span className="service-analyzer-report-infra-health-title">Id:</span><span>{value.data.id}</span></li>
                            <li><span className="service-analyzer-report-infra-health-title">Level:</span><span>{value.data.level}</span></li>
                            <li><span className="service-analyzer-report-infra-health-title">Title:</span><span>{value.data.title}</span></li>
                        </ul>
                    )
                }



                return (
                    <span></span>
                )
            }

            default:
                {

                    return (
                        <span></span>
                    )
                }

        }
    }
    const handleChangeType = (e) => {
        objectRef.current = e.target.value
        setSelectedType(e.target.value)

    }

    /*useEffect(()=>{        
        objectRef.current = selectedType
    },[selectedType])*/

    const RenderTableView = ({ name, insights }) => {



        if (name === "infra") {

            if (insights.length === 0) {
                return (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="report-infra-modal-td-width">Target Resource Type</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Change Type</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Resource Group</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Occurred At</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Changes Count</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Changes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} >No Data</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }

            const groupedAndOrderedData = _.mapValues(_.groupBy(insights, 'data.targetResourceType'), (group) =>
                _.orderBy(group, 'occurredAt', 'desc')
            );



            var resourceType = [];

            Object.entries(groupedAndOrderedData).forEach(([key, value]) => {
                resourceType.push({
                    name: key,
                    value: key
                })
            })
            if (objectRef.current === "") {
                objectRef.current = resourceType[0].name;
                setSelectedType(resourceType[0].name)
            }


            return (

                <React.Fragment>

                    <div className="service-analyzer-report-infra-health-dropdown">
                        <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Filter by type" componentState={selectedType}
                            setComponentState={setSelectedType} withDeleteOption={false}
                            size="1000" items={resourceType} handleChange={(e) => handleChangeType(e)} value={selectedType} />
                    </div>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="report-infra-modal-td-width">Target Resource Type</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Change Type</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Resource Group</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Occurred At</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Changes Count</TableCell>
                                    <TableCell className="report-infra-modal-td-width">Changes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {


                                    groupedAndOrderedData[objectRef.current]?.map((value, innerIndex) => (
                                        <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            <TableCell >{value.data.targetResourceType}</TableCell>
                                            <TableCell >{value.data.changeType}</TableCell>
                                            <TableCell >{value.data.resourceGroup}</TableCell>
                                            <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            <TableCell >{value.data.changesCount}</TableCell>
                                            <TableCell>
                                                <div className="data-by-code-box data-by-code-box-link" onClick={() => handleResourceChangeClick(value.data)}>Changes</div>
                                            </TableCell>

                                        </TableRow>
                                    ))

                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>

            )

        }

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>OccurredAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {

                            insights.map((value, innerIndex) => (
                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                    <TableCell >{value.code}</TableCell>
                                    <TableCell>
                                        <RenderDataByCode code={value.code} value={value} />
                                    </TableCell>
                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                </TableRow>
                            ))

                        }

                    </TableBody>
                </Table>
            </TableContainer>
        )



    }

    const DisplayTabs = () => {

        if (groupedData) {

            return (
                <div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value.toString()}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    {Object.entries(groupedData).map(([key, values], index) => (

                                        values.length > 0 ?
                                            (
                                                <Tab label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            ) :
                                            (
                                                <Tab disabled label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            )


                                    ))}
                                </TabList>
                            </Box>
                            {
                                Object.entries(groupedData).map(([key, values], index) => (
                                    <TabPanel value={index.toString()} key={`panel-element` + index}>
                                        <RenderTableView name={key} insights={values} />
                                    </TabPanel>
                                ))
                            }

                        </TabContext>
                    </Box>
                </div>
            )
        }
        return (
            <div>NO DATA</div>
        )



    }

    const AnalyzerReportModalClose = () => {

        setShowAnalyzerInfraReport(false)
    }




    return (

        <React.Fragment>
            <Modal
                fullscreen={true}
                show={show}
                onHide={() => AnalyzerReportModalClose()}

                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Incident Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="notification-modal-container">
                    <div>
                        <DisplayTabs />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => AnalyzerReportModalClose()}>Close</Button>
                </Modal.Footer>
            </Modal>

            <ServiceAnalyzerResourceChangeModal data={resourceChangesData} show={showAnalyzerResourceChange} setShowAnalyzerResourceChange={setShowAnalyzerResourceChange} />
        </React.Fragment>



    );


};

export default ServiceAnalyzerReportInfraModal;
