import * as React from 'react';
import TextField from '@mui/material/TextField';




const SCTextArea = ({ name,label, value, text, className, size, handleInputValueChange, refName, readOnly = false , rows = 6 }) => {


    return (
        <>
          <div style={{ display: refName ? 'inline-flex' : 'none' }}>
          <TextField                
                label={label}                           
                helperText={text}
                className={className}
                size={size}
                multiline
                name={name}
                onChange={handleInputValueChange}
                rows={rows}
                inputProps={
					{ readOnly: readOnly }
				}
                inputRef={refName}
               
            />
          </div>
          <div style={{ display: !refName ? 'inline-flex' : 'none' }}>
          <TextField                
                label={label}               
               // value={value ? value : ''}
               value={value}
                helperText={text}
                className={className}
                size={size}
                name={name}
                multiline
                onChange={handleInputValueChange}
                rows={rows}
                inputProps={
					{ readOnly: readOnly }
				}              
               
            />
          </div>
         
        </>
    )
}

export default SCTextArea
