import './ViewMetaData.css';
import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext } from "react";
import SCTable from './SCTable';
import { v4 as uuid } from 'uuid';
import appService from '../Images/app-service.svg';
import functionApp from '../Images/functions.svg';
import library from '../Images/library.svg';
import useRefTeamLeaderCallback from '../Hooks/Ref/useRefTeamLeaderCallback'
import useRefTeamCallback from '../Hooks/Ref/useRefTeamCallback'
import { CSVLink, CSVDownload } from "react-csv";
import SCButton from './SCButton';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import * as consts from '../consts';
import axios from '../axios';
import _ from "lodash";




const ViewMetaData = () => {
  const columnsItems = [{ display: false, name: "Id", key: "Id" }, { display: true, name: "Type", key: "Type" },
  { display: true, name: "Description", key: "Description" }, { display: true, name: "Url", key: "Url" }];

  const columnsSharedServicesItems = [{ display: false, name: "Id", key: "id" }, { display: true, name: "Name", key: "name" }, { display: true, name: "Type", key: "type" }]

  const { selectedService, servicesCached, setSelectedService, setExcludeServices,mdEditRowTable, setMdEditRowTable,excludeServices,
     handleTeamNameById, getJsonFile, token, isEditorUser, isAdminUser, listOfTeams, servicesMetaData } = useContext(DataContext);
  const [teamLeaderRef] = useRefTeamLeaderCallback();
  const [teamRef] = useRefTeamCallback();
  const history = useHistory();
  const [csvData, setCsvData] = useState([])

  const [displayLink, setDisplayLink] = useState(false)
  const [swaggerLinks, setSwaggerLinks] = useState([]);
  const [rowsSharedServicesItems, setRowsSharedServicesItems] = useState([]);
  const [excludeChecked, setExcludeChecked] = useState(false);

  useEffect(() => {



    setExcludeChecked(selectedService.excludedFromBackstage)
    if (selectedService.serviceMetadata?.links) {
      let link = selectedService.serviceMetadata?.links.filter(l => l.type === 'Swagger');
      if (link) {
        setSwaggerLinks([...link])
      }
    }


    if (selectedService.serviceMetadata) {
      let teamDetailsObj = handleTeamNameById(selectedService?.serviceMetadata?.teamId);

      teamLeaderRef.value = teamDetailsObj.manager;
      teamRef.value = teamDetailsObj.team;
      var tempArr = [];
      selectedService?.serviceMetadata?.links?.forEach(element => {
        var rowItem = { id: uuid(), type: element.type, description: element.description, url: element.url };
        tempArr.push(rowItem);
      });

      setMdEditRowTable([...tempArr])
    }


    //build rowsSharedServicesItems
    if (selectedService?.shareTheSameRepoWith) {
      let items = []
      selectedService.shareTheSameRepoWith.map((relatedService) => {
        if (relatedService.name !== selectedService.name) {
          items.push({ id: relatedService.id, name: relatedService.name, type: relatedService.serviceType })
        }
      })
      setRowsSharedServicesItems(items)
    }

  }, [selectedService])




  const parseScheme = (route, jsonDataResponse) => {


    let url = route.split('#/components/schemas/')[1];
    if (Object.keys(jsonDataResponse).length > 0 && jsonDataResponse?.components?.schemas) {

      for (const [key, value] of Object.entries(jsonDataResponse.components.schemas)) {
        if (key === url) {
          return value.properties
        }

      }


      return null;

    }


  }



  const exportFileButtonClick = async () => {

    let currentArray = []
    let counter = 0;
    for (const swagger of swaggerLinks) {

      counter++;
      let res = await exportFile(swagger, counter);
      currentArray.push(res)
    }
    setCsvData(currentArray)



  }


  const exportFile = async (swaggerLink, counter) => {

    if (token) {
      let response = await getJsonFile(swaggerLink.url.replace('index.html', 'v1/swagger.json'));
      let jsonData = response?.data;


      let dataCollection = [];
      if (jsonData?.paths) {

        setDisplayLink(true)


        let headerDataItem = ["END POINT", "GET", "DELETE", "PUT", "POST"];
        dataCollection.push(headerDataItem);



        let rowDataItem = []
        let tempGetArray = []
        let tempDeleteArray = []
        let tempPutArray = []
        let tempPostArray = []


        for (const [key, value] of Object.entries(jsonData.paths)) {

          for (const [internalKey, internalValue] of Object.entries(value)) {


            let endPointValues = `Route: ${key} \n\rSummary: ${internalValue?.summary ? internalValue?.summary : ''} \n\rMethod Type:  ${internalKey} `;
            rowDataItem.push(endPointValues);

            buildExcelObject(internalKey, internalValue, tempGetArray, tempDeleteArray, tempPutArray, tempPostArray, jsonData);


            if (rowDataItem.length > 0) {
              if (tempGetArray.length > 0) {
                rowDataItem.push(tempGetArray[0])
                tempGetArray = []

              }
              else {
                rowDataItem.push('')
              }

              if (tempDeleteArray.length > 0) {
                rowDataItem.push(tempDeleteArray[0])
                tempDeleteArray = []

              }
              else {
                rowDataItem.push('')
              }


              if (tempPutArray.length > 0) {
                rowDataItem.push(tempPutArray[0])
                tempPutArray = []

              }
              else {
                rowDataItem.push('')
              }


              if (tempPostArray.length > 0) {
                rowDataItem.push(tempPostArray[0])
                tempPostArray = []

              }
              else {
                rowDataItem.push('')
              }

              dataCollection.push(rowDataItem)
              rowDataItem = [];
            }
          }
        }
        return dataCollection;
      }
    }


  }



  const buildExcelObject = (internalKey, internalValue, tempGetArray, tempDeleteArray, tempPutArray, tempPostArray, jsonData) => {
    switch (internalKey) {
      case "get":
        {

          let str = "";
          if (internalValue?.parameters) {


            internalValue?.parameters?.map((p) => {
              str += ' name: ' + p.name + ' type: ' + p.schema?.type + '\n\r'
            })


          }
          else {


            if (internalValue?.summary) {
              str += 'No GET params for ' + internalValue?.summary + ' API.'
            }
            else if (!internalValue?.summary && internalValue?.tags) {
              let tag = internalValue?.tags[0]
              str += 'No GET params for ' + tag
            }
            else {
              str += 'No GET params';
            }

          }
          tempGetArray.push(str);


          break;
        }
      case "delete":
        {
          let str = "";
          if (internalValue?.parameters) {


            internalValue?.parameters?.map((p) => {
              str += ' name: ' + p.name + ' type: ' + p.schema?.type + '\n\r'
            })


          }
          else {
            str += 'No Delete Params'
          }
          tempDeleteArray.push(str);


          break;
        }
      case "put":
        {
          let str = "";
          if (internalValue?.parameters) {


            internalValue?.parameters?.map((p) => {
              str += ' name: ' + p.name + ' type: ' + p.schema?.type + '\n\r'
            })


          }
          else {
            str += 'No Put Params'
          }
          tempPutArray.push(str);


          break;
        }
      case "post":
        {


          if (internalValue?.parameters) {

            let str = "";
            internalValue?.parameters?.map((p) => {
              str += ' name: ' + p.name + ' type: ' + p.schema?.type + '\n\r'
            })
            tempPostArray.push(str);

          }
          else if (internalValue?.requestBody?.content) {



            for (const [a, b] of Object.entries(internalValue?.requestBody?.content)) {

              let results = "";
              if (b.schema?.$ref) {
                results = parseScheme(b.schema?.$ref, jsonData);
              }

              if (results && results !== "") {

                let str = '';
                for (const [k, v] of Object.entries(results)) {
                  str += ' name: ' + k + ' type: ' + v.type + '\n\r';
                }
                tempPostArray.push(str);
              }
              if (b.schema?.type) {
                let str = ' type: ' + b.schema?.type;
                tempPostArray.push(str);
              }

            }

          }
          else {
            let str = 'No Post Params'
            tempPostArray.push(str);
          }


          break;
        }

      default:
        {

          break;
        }
    }

  }

  const handleRelatedServiceClick = (object) => {

    let service = servicesCached?.filter(s => s.id === object.id)[0]

    setSelectedService(service);
    history.push({
      pathname: '/Service',
      display: 'ServiceChild',
      search: '?name=' + service.name
    })
  }

  const handleExcludeChange = () => {


    if (excludeChecked === false) {
      setExcludeChecked(true)
      updateIfServiceExcludeFromBackstage(true)

      //post
    }
    else {
      setExcludeChecked(false)
      updateIfServiceExcludeFromBackstage(false)
      //post
    }

  }

  const updateIfServiceExcludeFromBackstage = (isExclude) => {

    let url = isExclude ? consts.requests.updateExcludeInBackstage.toString().replace('{serviceId}'
      , selectedService.id) : consts.requests.updateIncludeInBackstage.toString().replace('{serviceId}'
        , selectedService.id)
    axios.post(url, {}, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {

      selectedService.excludedFromBackstage = isExclude

    
    }).catch((error) => {
      console.log('updateIfServiceExcludeFromBackstage - error', error)
    })
  }

  return (
    <>
      <div className='md-row'>
        {
          swaggerLinks && swaggerLinks.length > 0 ?
            (
              <SCButton text="Generate CSV" handleButtonClick={() => exportFileButtonClick()} size='medium'></SCButton>
            ) :
            (
              <div></div>
            )
        }

        {
          displayLink ?
            (

              csvData?.map((item, index) => (

                <div key={index}>
                  <CSVLink
                    key={index}
                    data={item}
                    filename={`export_data_${index}.csv`}
                  >
                    Download CSV {index}
                  </CSVLink>
                </div>

              ))
            ) :
            (
              <div></div>
            )

        }
      </div>
      <div className='md-row checkbox-exclude-backstage-row-container' style={{ display:  isAdminUser ? 'flex' : 'none' }}>
        <span className='md-title checkbox-exclude-backstage-container'>
          <Checkbox className="checkbox-exclude-backstage" color="default" checked={excludeChecked}
            onChange={handleExcludeChange} />
          Exclude from Backstage.IO</span>
      </div>
      <div className='md-row'>
        <span className='md-title'>Resource:</span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'appservice' ? 'inline-block' : 'none' }}>
          <a href={selectedService?.portalUrl} target="_blank">App Service</a>
        </span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'functionapp' ? 'inline-block' : 'none' }}>
          <a href={selectedService?.portalUrl} target="_blank">Azure Function</a>
        </span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'library' ? 'inline-block' : 'none' }}>
          <a href={selectedService?.portalUrl} target="_blank">Library</a>
        </span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'jslibrary' ? 'inline-block' : 'none' }}>
          <a href={selectedService?.portalUrl} target="_blank">WebSite / JsLibrary</a>
        </span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'aksapp' ? 'inline-block' : 'none' }}>
          <a href={selectedService?.portalUrl} target="_blank">AKS</a>
        </span>
        <span className='md-value' style={{ display: selectedService?.serviceType?.toLowerCase() === 'akscronjob' ? 'inline-block' : 'none' }}>
        <a href={selectedService?.portalUrl} target="_blank">AKS CronJob</a>
      </span>

      </div>

      <div className='md-row'>
        <span className='md-title'>Team:</span>
        <span className='md-value'>{teamRef.value}</span>
      </div>
      <div className='md-row'>
        <span className='md-title'>Team leader:</span>
        <span className='md-value'>{teamLeaderRef.value}</span>
      </div>
      <div className='md-row'>
        <span className='md-title'>Service description:</span>
        <span className='md-value'>{selectedService?.serviceMetadata?.description}</span>
      </div>
      <div className='md-row'>
        <span className='md-title'>Repository name:</span>
        <span className='md-value'><a href={selectedService?.repositoryUrl} target="_blank">{selectedService?.repositoryName}</a></span>
      </div>

      <div className='md-row' style={{ display: selectedService?.serviceType?.toLowerCase() === 'functionapp' ? 'block' : 'none' }}>
        <span className='md-title'>Version:</span>
        <span className='md-value'>{selectedService?.functionsExtensionVersion}</span>
      </div>

      <div className='md-row' style={{ display: selectedService?.serviceType?.toLowerCase() === 'functionapp' ? 'block' : 'none' }}>
        <span className='md-title'>Runtime:</span>
        <span className='md-value'>{selectedService?.functionsWorkerRuntime}</span>
      </div>


      <div className='md-row'>
        <span className='md-title'>NewRelic production service name:</span>
        <span className='md-value'>{selectedService?.serviceMetadata?.newRelicAppName}</span>
      </div>

      <div className='md-row'>
        <span className='md-title'>OS processor:</span>
        {
          selectedService?.use32BitWorkerProcess === "True" ?
            (
              <span className='md-value'>32 bit</span>
            ) : selectedService?.use32BitWorkerProcess === "False" ?
            (
              <span className='md-value'>64 bit</span>
            ) :
            (
               <span></span>
            )
        }

      </div>

      <div className='md-row'>
        <span className='md-title'>Tier:</span>
        <span className='md-value'>{selectedService?.serviceMetadata?.tier}</span>
      </div>
      <div className='md-row'>
        <span className='md-title'>Lifecycle:</span>
        <span className='md-value'>{selectedService?.serviceMetadata?.lifecycle}</span>
      </div>

      <div className='row-space'></div>
      <div>
        {
          selectedService?.shareTheSameRepoWith ?
            (
              <SCTable handleRowClick={handleRelatedServiceClick} title="Services with the same repository" columnsItems={columnsSharedServicesItems} rowItems={rowsSharedServicesItems}
                includesRemoveButton={false} includesSelectButton={false}
              />
            ) :
            (
              <span></span>
            )

        }
      </div>
      <div className='row-space'></div>
      <div> <SCTable title="Links" columnsItems={columnsItems} rowItems={mdEditRowTable}
        includesRemoveButton={false} includesSelectButton={false}
      /> </div>
    </>
  )
}

export default memo(ViewMetaData)
