import { useEffect, useState, useContext } from "react";
import './SelfServiceApprovalManager.css';




const SelfServiceApprovalManager = () => {


    return (
       <div>Manager</div>
    )
}

export default SelfServiceApprovalManager
