import jwt_decode from 'jwt-decode';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useContext } from 'react';
import DataContext from "../Context/DataContext";
import * as consts from '../consts';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import useLogin from './useLogin';


const useAxios = (baseUrl) => {

   // const { login, writeConsole } = useContext(DataContext)
    const { userLogin } = useLogin()

    const axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem('InfraConsoleToken')}`,
            'x-api-key': consts.API_KEY
        }
    });


    var requestCounter = 0;
    axiosInstance.interceptors.request.use(async req => {


     

        if (!localStorage.getItem('InfraConsoleToken')) {
            await userLogin();
        }
       

        if (!req.url.toLowerCase().includes('/api/v1/services/search?term')) {
            requestCounter++;
            if (!req?.ignoreSpinner) {
                document.getElementById("loader").style.display = "block";
            }
        }
        return req;
    })

    axiosInstance.interceptors.response.use(res => {
        if (!res.request.responseURL.toLowerCase().includes('/api/v1/services/search?term') &&
            requestCounter > 0) {
            requestCounter--;
            if (requestCounter === 0) {
                document.getElementById("loader").style.display = "none";
            }
        }
        return res;

    },
        async (error) => {
          
            requestCounter = 0;
            document.getElementById("loader").style.display = "none";
            document.getElementById("spinner-loader-container").style.display = "none";
            
            
            if (error?.response?.status === 400 && error?.response?.data) {
                var errorObj = parseErrorData(error?.response?.data);
                

                if (errorObj && errorObj.message !== undefined) {

                    displayErrorToast(errorObj?.message)
                    return;
                }
                else if (errorObj !== '') {

                    displayErrorToast(errorObj)
                    return;
                }
            }
            if (error?.response?.status === 401) {
                
                console.log("401 Error Unauthorized Access. The token has been refreshed.")
                const originalRequest = error.config;
                console.log(error.config)
                console.log(error)
                originalRequest._retry = true;               
                localStorage.removeItem('InfraConsoleToken')
                localStorage.removeItem('InfraConsoleName')
                localStorage.removeItem('InfraConsoleExpired')
                localStorage.removeItem('InfraConsoleUserName')
                let newAccessToken = await userLogin();                
                console.log("Token has been refreshed")
                originalRequest.headers.Authorization = `Bearer ${newAccessToken?.accessToken}`;
                return axios(originalRequest);

            }
            else {
                if (error?.response?.data != "") {

                    if (typeof (error?.response?.data) === "string") {
                        displayErrorToast(error?.response?.data)
                        return;
                    }
                    else {
                        return null;
                    }


                }
                if (error?.response?.status != "") {
                    displayErrorToast("Something went wrong. (Error: " + error.response.status + ")")
                    return;
                }
                if (error?.response?.data === "" && error?.response?.status === "") {
                    displayErrorToast("Something went wrong.")
                    return;
                }

            }

        })





    /*if (!login) {
        console.log('Login method is not available. Make sure DataContext is initialized.');
        // You might throw an error, return a default/fallback value, or handle it based on your needs       
    }
    else {
        console.log('Login method is ready.');
    }*/





    /* const refreshLogin = async () => {
 
         try {
 
             localStorage.removeItem('InfraConsoleToken')
             localStorage.removeItem('InfraConsoleName')
             localStorage.removeItem('InfraConsoleExpired')
             localStorage.removeItem('InfraConsoleUserName')
             return await login();
         } catch (error) {
             console.log(error)
             displayErrorToast(`Token Expired.`)
             // window.location.reload(true)
         }
 
     }*/

    const parseErrorData = (data) => {
        try {
            return JSON.parse(data);    
        } catch (error) {
            return data;
        }
        
    }

    const displayErrorToast = (message) => {
        const ErrorMsg = () => (
            <div>{message}</div>
        )
        toast.error(<ErrorMsg />)
    }



    return axiosInstance;
}
export default useAxios