import  DataContext  from '../Context/DataContext';
import { useContext, useEffect }  from 'react';
import Header from './Header';
import Body from './Body';
import ProjectDetailsModal from "../Modals/ProjectDetailsModal";
import ServiceBranchesModal from "../Modals/ServiceBranchesModal";
import EditServiceModal from "../Modals/EditServiceModal";
import BuildsModal from '../Modals/BuildsModal';
import ServiceMetadataModal from '../Modals/ServiceMetadataModal';




const Home = () => {
    const { title ,setTitle,modalDetailsShow, setModalDetailsShow,modalPermissionShow,setModalPermissionShow,
      modalServiceDetailsShow, setModalServiceDetailsShow, setSideBarCollapsed,
      modalEditServiceDetailsShow, setModalEditServiceDetailsShow, modalBuildShow,setModalBuildShow,
      modalEditServiceMetaDataShow, setModalEditServiceMetaDataShow } = useContext(DataContext);

 
 
  useEffect(() => {
   
    setSideBarCollapsed(false);
    
    
  
}, []);

// add option to choose environment
    return (
        <div>     
                                
            <Header />
            {/*<Body /> */}
          
        

           <ServiceBranchesModal      
           show={modalServiceDetailsShow}            
           setModalServiceDetailsShow={setModalServiceDetailsShow} />
          
          <EditServiceModal show={modalEditServiceDetailsShow}            
           setModalServiceDetailsShow={setModalEditServiceDetailsShow}  />

            <ServiceMetadataModal show={modalEditServiceMetaDataShow}            
           setModalEditServiceMetaDataShow={setModalEditServiceMetaDataShow}  />

           <BuildsModal 
            show={modalBuildShow}            
            setModalBuildShow={setModalBuildShow}
           />

        </div>
    )
}

export default Home
