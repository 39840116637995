import { useEffect, useState } from 'react';
import './ChatHeader.css';
import { useContext } from 'react';
import { DataContextChat } from '../../Context/DataContextChat';
import SCInputText from '../SCInputText';
import Avatar from './avatar.svg'

const ChatHeader = ({ text }) => {

    const { toggleChat} = useContext(DataContextChat);
    


 

    return (
        <div className='copilot-chat-header' >
            <img className='copilot-chat-header-avatar' src={Avatar} alt="avatar" />
            <span className='copilot-chat-header-text'>{text}</span>
            <span className='copilot-chat-header-close' onClick={toggleChat}>Close Chat</span>
                  
        </div>
    );
};

export default ChatHeader;