
import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import DataContext from "../Context/DataContext";
import './Workflow.css';
import SCTop from "../Components/SCTop";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';



const Workflow = () => {

    const { token, urlWorkflowType, urlRequestId, getMyRequests, generatePermissionReqObject } = useContext(DataContext);

    const [workflowType, setWorkflowType] = useState('')
    const [dynamicPermissionObj, setDynamicPermissionObj] = useState({})
    useEffect(async () => {

        if (urlWorkflowType !== null && urlRequestId !== null && token) {
         
            setWorkflowType(urlWorkflowType)
            if (urlWorkflowType.toLowerCase() === 'dynamicpermission') {
                let response = await getMyRequests();
                let modalObj = response.data?.content?.filter(r => r.requestId == urlRequestId);
               
                if (modalObj && modalObj.length > 0) {
                    let obj = generatePermissionReqObject(modalObj[0])
                    setDynamicPermissionObj(obj)
                  
                }
                else {
                    setWorkflowType('')
                }
            }

            //invoke - getMyRequests
            //get request from response by reqId 
            //write the details
            //add workflow type based on urlWorkflowType
            //add Approve & Decline button 
            // invoke API foreach button based on urlWorkflowType
        }

    }, [urlWorkflowType, urlRequestId,token])

    const handleApprove = () =>{
        alert('approved')
    }

    const handleDecline = () =>{
        alert('declined')
    }


    return (
        <div>
            <div>
                <SCTop displaySecondLine={false} displayBreadcrumbs={false} />
            </div>
            <div className="sc-main sc-workflow">
                {
                    workflowType.toLowerCase() === 'dynamicpemission' ?
                        (
                            <div>
                                <div className="wf-title">Welcome to dynamic permission approval workflow</div>
                            </div>
                        ) : workflowType.toLowerCase() === 'autocicd' ?
                            (
                                <div>
                                    <div>Welcome to automatically ci\cd approval workflow</div>
                                </div>
                            ) : workflowType === '' ?
                            (
                                <div>Sorry we didn't find any permission request that is related to this request id.</div>
                            ):
                            (
                                <div></div>
                            )
                }
                {
                    workflowType.toLowerCase() !== '' ?
                        (
                            <div>
                                <div className="wf-req-id">Request ID: {dynamicPermissionObj.requestId} requested by <span className="wf-user">{dynamicPermissionObj.userPrincipleName}</span></div>
                                <div>Request Details:</div>
                                <div>
                                    <ul>
                                        <li>
                                            <span className="wf-key">Start date</span> <span className="wf-value">{dynamicPermissionObj.grantOn}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">End date</span> <span className="wf-value">{dynamicPermissionObj.revokeOn}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Resource Group Name</span> <span className="wf-value">{dynamicPermissionObj.resourceGroupName}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Resource ID</span> <span className="wf-value">{dynamicPermissionObj.resourceId}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Resource Name</span> <span className="wf-value">{dynamicPermissionObj.resourceName}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Permissions To Secrets</span> <span className="wf-value">{dynamicPermissionObj.permissionsToSecrets}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Role Definition Names</span> <span className="wf-value">{dynamicPermissionObj.roleDefinitionNames}</span>
                                        </li>
                                        <li>
                                            <span className="wf-key">Type</span> <span className="wf-value">{dynamicPermissionObj.type}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <Stack direction="row" spacing={2}>
                                        <Button onClick={handleApprove} variant="contained" color="success">
                                            Approve
                                        </Button>
                                        <Button onClick={handleDecline} variant="contained" color="error">
                                            Decline
                                        </Button>
                                    </Stack>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )
                }
            </div>
        </div>
    )
}

export default Workflow
