import React, { useEffect, useState, useContext, useRef } from 'react'
import './SAAnalyzerTimeline.css';

import * as consts from '../../../consts';
import _ from "lodash";
import DataContext from '../../../Context/DataContext';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import SAAnalyzerTimelineChart from './SAAnalyzerTimelineChart';


const SAAnalyzerTimeline = ({ service, services }) => {

    const { servicesCached } = useContext(DataContext);
    const { LuxonDateFormatString, GetServiceNameById, translateAnalyzerCodes } = useContext(DataContextAnalyzer);
    const [timelineData, setTimelineData] = useState([])

  


    const getServiceParentByChildId = (id) => {
        let parent = services.find(s => s.serviceId === id);
        if (parent) {
            return parent
        }
        return null;
    }

    const getChildByParentId = (id) => {
        let child = services.filter(s => s.hasParent === id);
        if (child) {
            return child
        }
        return null;
    }

  

    useEffect(() => {
        let timelines = [];

        if (service && services && services.length > 0) {

           
            let combainedArray = []
         
            let parent = {};
            let child = {};
            let cloneService = { ...service };
            if (cloneService.hasParent !== null) {

                parent = getServiceParentByChildId(cloneService.hasParent)
                
                if(parent !== null){
                    parent.report = filterInsights(parent.serviceId, parent.name, parent.report);
                    combainedArray = [...combainedArray, ...parent.report];
                }
                child = getChildByParentId(parent.serviceId);
                if(child !== null){
                    child.map((c)=>{
                        c.report = filterInsights(c.serviceId, c.name, c.report);
                        combainedArray = [...combainedArray, ...c.report];
                    })
                }
            }
            else {

                parent = cloneService;

                if(parent !== null){
                    parent.report = filterInsights(parent.serviceId, parent.name, parent.report);
                    combainedArray = [...combainedArray, ...parent.report];
                }

                child = getChildByParentId(parent.serviceId);
                if(child !== null){
                    child.map((c)=>{
                        c.report = filterInsights(c.serviceId, c.name, c.report);
                        combainedArray = [...combainedArray, ...c.report];
                    })
                }
            }

            
         
           const orderedReports = combainedArray.sort((a, b) => new Date(a.occurredAt) - new Date(b.occurredAt));
            setTimelineData([...orderedReports]);

         
        }

    }, [service, services])


    const filterInsights = (id, name, report) => {

        
        const allowedModules = ['deployments', 'configuration', 'database', 'metrics', 'resourcechanges','logs','diagnostics'];
        const preventCodes = ['infra_changes', 'impact'];
        let cloneReport = [...report];
        if(cloneReport && cloneReport.length > 0)
        {
            const includedItems = _.filter(cloneReport, item => _.includes(allowedModules, item.module.toLowerCase()));

            const excludedCodes = _.reject(includedItems, item => _.includes(preventCodes, item.code.toLowerCase()));
            const sortedItems = _.orderBy(excludedCodes, item => new Date(item.occurredAt));
    
            const enrichedData = _.map(sortedItems, obj => ({
                ...obj,
                metaData: translateAnalyzerCodes(obj),
                serviceName: name,
                serviceId: id
            }));
    
            
            return enrichedData;
        }
        return [];
       
    }



    return (
        <React.Fragment>
            <SAAnalyzerTimelineChart timeline={timelineData} />
        </React.Fragment>
    )
}

export default SAAnalyzerTimeline