
import React, { useContext } from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import _ from "lodash";
import './SAPDFModal.css';
import { useEffect, useState } from "react";
import ExportPDFHtml from "./incident/ExportPDFHtml";
import { usePDF } from 'react-to-pdf';
import DataContextAnalyzer from "../../../Context/DataContextAnalyzer";
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from "@mui/material";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import spinner from '../../../Images/SmallSpinner.svg';


const SAPDFModal = ({ modules, showPDFModal, setShowPDFModal, service, valueFrom, valueTo }) => {
    //https://www.npmjs.com/package/react-to-pdf
    const { toPDF, targetRef } = usePDF({ filename: service.reportId + '.pdf' });
    const [generatingPdf, setGeneratingPdf] = useState(false)



    const HandleModalClose = () => {

        setShowPDFModal(false)
    }

   

    const options = {
        filename: service?.incident?.id ? service?.incident?.id + '.pdf' : 'report.pdf',
        method: "save",
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.MEDIUM,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.MEDIUM,
            // default is 'A4'
            format: "letter",
            // default is 'portrait'
            orientation: "portrait"
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };

    const downloadPdf = () => {
        setGeneratingPdf(true)
        generatePDF(targetRef, options).then((res) => {
            setGeneratingPdf(false)
        }).catch((error) => {
            console.log("generating pdf error:", setGeneratingPdf)
        })
    }


    return (


        <Modal
            fullscreen={false}
            show={showPDFModal}
            onHide={() => HandleModalClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ maxHeight: window.innerHeight * 0.9 }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tooltip title="Download report as a PDF file">
                    {/*<DownloadIcon style={{ cursor: 'pointer' }} onClick={() => toPDF()} />*/}
                    {
                        generatingPdf === false ?
                            (
                                <DownloadIcon style={{ cursor: 'pointer' }} onClick={() => downloadPdf()} />
                            ):
                            (
                                <div> <img src={spinner} className='sa-measurements-spinner-icon' /> <span className="sa-pdf-modal-generating-pdf">Generating PDF file</span></div>
                            )
                    }

                </Tooltip>

                <ExportPDFHtml modules={modules} targetRef={targetRef} service={service} valueFrom={valueFrom} valueTo={valueTo} />


            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={() => HandleModalClose()}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default SAPDFModal;
