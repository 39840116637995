import { createContext, useState, useContext, useEffect } from 'react';
import DataContext from "./DataContext";
import _ from "lodash";
import * as consts from '../consts';
import useAxios from '../Hooks/useAxios';




const BackgroundDataContext = createContext({});

export const DataProviderBackground = ({ children }) => {

    const { token } = useContext(DataContext);
    
    const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);
    const [ddlProjects, setDdlProjects] = useState([])
    const [ddlDBGroup, setDdlDBGroup] = useState([])

    const [isApiProjectDone, setIsApiProjectDone] = useState('');
    const [isApiDBDone, setIsApiDBDone] = useState('');
    
    useEffect(()=>{
       
        if(!token){
            return;
        }
        setDdlProjects([])
        
        Promise.all([getCICDAutoProjects(), getCICDDatabaseNames()])
        .then((results) => {
          const resAutoProjects = results[0];
          const resCICDDatabaseNames = results[1];


          if (results) {

            if (resAutoProjects?.status === 200) {
              var resource = [];
              resAutoProjects.data?.content?.map((p) => {
                resource.push({
                  name: p,
                  value: p
                })
              })
              setDdlProjects(resource)
              setIsApiProjectDone('success')
            }
            if (resCICDDatabaseNames?.status === 200) {
              var databases = [];
              resCICDDatabaseNames.data?.content?.map((db) => {
                databases.push({
                  name: db,
                  value: db
                })
              })
              setDdlDBGroup(databases)
              setIsApiDBDone('success')
            }
          }
        });

    },[token])

    const getCICDAutoProjects = async () => {

        try {
          return await instance.get(consts.requests.getCICDAutoProjects, {            
            ignoreSpinner: true
          });
    
        }
        catch (error) {
          setIsApiProjectDone('failed')
        }
    
      }
    
      const getCICDDatabaseNames = async () => {
    
        try {
          return await instance.get(consts.requests.getCICDDatabaseNames, {           
            ignoreSpinner: true
          });
        }
        catch (error) {
          setIsApiDBDone('failed')
        }
    
      }



    return (
        <BackgroundDataContext.Provider value={{
            ddlProjects, setDdlProjects,ddlDBGroup, setDdlDBGroup,isApiProjectDone,isApiDBDone
        }}>



            {children}
        </BackgroundDataContext.Provider>
    )

}

export default BackgroundDataContext;
