import { useEffect } from 'react';
import './TypingMessage.css';

const TypingMessage = ({ messages }) => {


  
    return (
      <div className="typing-container">
        <div className="typing-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };
  
  export default TypingMessage;