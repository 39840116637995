import React, { useEffect, useState, useContext } from "react";
import './SelfServicePipelineAndCluster.css';
import { toast } from 'react-toastify';
import SCDropDownList from "../SCDropDownList";
import WizardDataContext from "../../Context/WizardDataContext";
import useAxios from '../../Hooks/useAxios';
import DataContext from "../../Context/DataContext";
import PipelineTreeModal from "../../Modals/PipelineTreeModal";




const SelfServicePipelineAndCluster = () => {

    const { setShowBuildPipelineTree, selectedProject, setShowReleasePipelineTree, setDdlBranchesDev, setSelectedACRDev, setSelectedACRProd, setSelectedBranchesProd, setSelectedBranchesDev, disableRepoName,
        selectedBuildFolder, selectedReleaseFolder, ddlAcrDev, selectedACRDev, setDdlBranchesDevCached, setDdlBranchesProd, ddlBranchesDevCached, selectedACRProd, showBuildPipelineTree,
        ddlBranchesDev, ddlBranchesProd, ddlAcrProd, repoNameFromAutoComplete, selectedBranchesProd, selectedBranchesDev, ddlBranchesProdCached, setSelectedBuildFolder,
        showReleasePipelineTree, setSelectedReleaseFolder } = useContext(WizardDataContext);

    //  const { ddlProjects, setDdlProjects, ddlDBGroup, setDdlDBGroup, isApiProjectDone, isApiDBDone } = useContext(BackgroundDataContext);

    const { getSelfServiceRepositories, getSelfServiceBranchesForExsitingRepo } = useContext(DataContext);

    const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);

    useEffect(() => {
        if (selectedACRDev) {
            setDdlBranchesDev([])
            let result = ddlAcrDev.filter(a => selectedACRDev.find(x => x === a.value))
            if (result && result.length > 0) {
                var resource = [];

                result[0].branches.map((i) => (


                    resource.push({
                        name: i,
                        value: i,
                    })

                ))
                setDdlBranchesDev(resource)
                setDdlBranchesDevCached(resource)


            }
        }

    }, [selectedACRDev])

    const openPipelineTreeModal = (type) => {
        if (selectedProject === "") {
            const WarnMsg = () => (
                <div>Please select the project you're working in</div>
            )
            toast.warn(<WarnMsg />)
            return;
        }
        if (type === 'build') {
            setShowBuildPipelineTree(true)
        }
        else {
            setShowReleasePipelineTree(true)
        }

    }


    const handleChangeACRDev = (e) => {

        setSelectedACRDev(e.target.value)
        setSelectedBranchesDev([])

    }

    const handleOpenDev = () => {
        buildClusterComponents('dev');
    }

    const handleOpenProd = () => {
        buildClusterComponents('prod');
    }

    const buildClusterComponents = async (type) => {
        if (selectedProject !== "" && repoNameFromAutoComplete !== "" && disableRepoName) {

            if (type === 'dev') {

                var cloneDdlBrancedDev = [...ddlBranchesDev]
                setDdlBranchesDev([])
                let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);

                results.data.content.map((r) => {

                    if (r.name.toLowerCase() === 'dev') {

                        let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'dev');
                        if (newDdl.length > 0) {

                            newDdl[0].name = newDdl[0].name + " (Already Exist)";
                            newDdl[0].disable = true;

                        }
                    }

                    if (r.name.toLowerCase() === 'staging') {

                        let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'staging');
                        if (newDdl.length > 0) {

                            newDdl[0].name = newDdl[0].name + " (Already Exist)";
                            newDdl[0].disable = true;

                        }
                    }
                    setDdlBranchesDev([...cloneDdlBrancedDev]);
                })
            }
            if (type === 'prod') {

                var cloneDdlBrancedProd = [...ddlBranchesProd]
                setDdlBranchesProd([])
                let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);


                results.data.content.map((r) => {

                    if (r.name.toLowerCase() === 'main') {

                        let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'main');
                        if (newDdl.length > 0) {

                            newDdl[0].name = newDdl[0].name + " (Already Exist)";
                            newDdl[0].disable = true;
                        }
                    }
                    if (r.name.toLowerCase() === 'master') {

                        let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'master');
                        if (newDdl.length > 0) {

                            newDdl[0].name = newDdl[0].name + " (Already Exist)";
                            newDdl[0].disable = true;
                        }
                    }
                    setDdlBranchesProd([...cloneDdlBrancedProd]);
                })
            }
        }
        else {

            if (type === 'dev') {
                setDdlBranchesDev([...ddlBranchesDevCached])
            }
            if (type === 'prod') {
                setDdlBranchesProd([...ddlBranchesProdCached])
            }

        }

    }

    const handleChangeBranchesDev = (e) => {
        setSelectedBranchesDev(e.target.value);
    }
    const handleChangeACRProd = (e) => {
        setSelectedACRProd(e.target.value)
        setSelectedBranchesProd('')

    }

    const handleChangeBranchesProd = (e) => {
        setSelectedBranchesProd(e.target.value);
    }
    return (
        <React.Fragment>

            <div className="ss-wizard-cluster-controler-container">
                <div className='cicd-build-release'>

                    <span onClick={() => openPipelineTreeModal('build')} className='cicd-pipeline-tree-title' id='cicd-build-folders'>Build</span>
                    {
                        selectedBuildFolder === "" ?
                            (
                                <span className='cicd-pipeline-tree-value-not-selected'>Pipeline build folder was not selected</span>
                            ) :
                            (
                                <span className='cicd-pipeline-tree-value'>{selectedBuildFolder}</span>
                            )

                    }
                </div>
            </div>
            <div className="ss-wizard-cluster-controler-container">
                <div className='cicd-build-release'>
                    <span onClick={() => openPipelineTreeModal('release')} className='cicd-pipeline-tree-title' id='cicd-release-folders'>Release</span>
                    {
                        selectedReleaseFolder === "" ?
                            (
                                <span className='cicd-pipeline-tree-value-not-selected'>Pipeline release folder was not selected</span>
                            ) :
                            (
                                <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder}</span>
                            )

                    }
                </div>
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <SCDropDownList id="cicd-selected-acr-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Dev cluster*" componentState={selectedACRDev}
                    setComponentState={setSelectedACRDev}
                    size="1000" items={ddlAcrDev} handleChange={(e) => handleChangeACRDev(e)} value={selectedACRDev} />
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <SCDropDownList id="cicd-branches-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Branches *" componentState={selectedBranchesDev}
                    setComponentState={setSelectedBranchesDev} handleOpen={(e) => handleOpenDev(e)}
                    size="1000" items={ddlBranchesDev} handleChange={(e) => handleChangeBranchesDev(e)} value={selectedBranchesDev} />
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <SCDropDownList id="cicd-selected-acr-prod" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Prod Cluster" componentState={selectedACRProd}
                    setComponentState={setSelectedACRProd}
                    size="1000" items={ddlAcrProd} handleChange={(e) => handleChangeACRProd(e)} value={selectedACRProd} />
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <SCDropDownList id="cicd-branches-prod" className="md-ddl-edit" label="Branches *" componentState={selectedBranchesProd}
                    setComponentState={setSelectedBranchesProd} handleOpen={(e) => handleOpenProd(e)}
                    size="1000" items={ddlBranchesProd} handleChange={(e) => handleChangeBranchesProd(e)} value={selectedBranchesProd} />
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <PipelineTreeModal show={showBuildPipelineTree} title="Build Pipeline Folder Tree"
                    setSelectedValue={setSelectedBuildFolder} selectedValue={selectedBuildFolder}
                    setShowPipelineTree={setShowBuildPipelineTree} projectName={selectedProject} />
            </div>

            <div className="ss-wizard-cluster-controler-container">
                <PipelineTreeModal show={showReleasePipelineTree} title="Release Pipeline Folder Tree"
                    setSelectedValue={setSelectedReleaseFolder} selectedValue={selectedReleaseFolder}
                    setShowPipelineTree={setShowReleasePipelineTree} projectName={selectedProject} />
            </div>
        </React.Fragment>
    )
}

export default SelfServicePipelineAndCluster
