import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext, useCallback } from "react";
import './CardsListView.css';
import { useHistory } from 'react-router-dom';
import SCTable from "./SCTable";
import appService from '../Images/App-Services.svg';
import aksApp from '../Images/azure-aks.svg';
import cdnService from '../Images/CDN-Profiles.svg';
import functionApp from '../Images/Function-Apps.svg';
import library from '../Images/Activity-Log.svg';
import azure from '../Images/azure.svg';
import aksCron from '../Images/cronjob-icon.svg';
import * as consts from '../consts';
import React, { memo } from 'react';
import DataContextHome from '../Context/DataContextHome';


const CardsListView = () => {
  const { services, handleBreadCrumbs, setSelectedService, viewMode } = useContext(DataContext);
  const { loadMore, setLoadMore, chunkedServices, setChunkedServices,
    isLoadMoreLoading, setIsLoadMoreLoading, calcServices, handleScroll } = useContext(DataContextHome);

  const history = useHistory();
  const [servicesItems, setServicesItems] = useState([])


  const columnsItems = [{ display: true, name: "Service name", key: "ServiceName" },
  { display: true, name: "Team", key: "Team" },
  { display: true, name: "Team Leader", key: "TeamLeader" },
  { display: true, name: "Tier", key: "Tier" },
  { display: true, name: "Description", key: "Description" },
  { display: true, name: "Score", key: "Score" },
  { display: false, name: "RepositoryName", key: "RepositoryName" },
  { display: false, name: "FullDescription", key: "FullDescription" },
  { display: true, name: "Resource Type", key: "ResourceType" }, { display: true, name: "Status", key: "Status" }];

  const getResourceIcon = useCallback((type) => {

    switch (type) {
      case 'AppService':
        {
          return <div><img src={appService} alt='icon' className='type-icon' /></div>;
        }
      case 'FunctionApp':
        {
          return <div><img src={functionApp} alt='icon' className='type-icon' /></div>;
        }
      case 'Library':
        {
          return <div><img src={library} alt='icon' className='type-icon' /></div>;
        }
      case 'JsLibrary':
        {
          return <div><img src={cdnService} alt='icon' className='type-icon' /></div>;
        }
      case 'AksApp':
        {
          return <div><img src={aksApp} alt='icon' className='type-icon' /></div>;
        }
      case 'AksCronJob':
        {
          return <div><img src={aksCron} alt='icon' className='type-icon' /></div>;
        }
      default:
        return <div><img src={azure} alt='icon' className='type-icon' /></div>;
    }
  });


  const getResourceStatus = (metaData) => {

    let valid = true;

    if (metaData) {

      valid = metaData.userManagerId ? true : false
      valid = metaData.tier ? true : false
      valid = metaData.team ? true : false
      valid = metaData.manager ? true : false
      valid = metaData.description ? true : false

    }
    else {
      valid = false;
    }

    return valid ? <div className="listview-valid"></div> : <div className="listview-invalid"></div>

  }
  const getDescription = (srv) => {

    if (srv.serviceMetadata?.description) {
      if (srv.serviceMetadata?.description?.length > 40) {
        return srv.serviceMetadata?.description?.substring(0, 40) + '...';
      }
      else {
        return srv.serviceMetadata?.description?.substring(0, 40);
      }
    }
    else {
      return consts.DESC_DEFAULT;
    }
    return '';
  }


  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])


  useEffect(() => {

    calcServices();
  }, [loadMore])


  useEffect(() => {


    if (services && services.length > 0) {

      if (loadMore === 1) {
        calcServices()
      }


      /*var items = []
      services.forEach(srv => {
        var item = {
          ServiceName: srv.name,
          RepositoryName: srv.repositoryName,
          Team: srv.serviceMetadata?.team,
          TeamLeader:srv.serviceMetadata?.manager,
          Tier:srv.serviceMetadata?.tier,
          Description: getDescription(srv),
          Score: srv?.serviceScore?.scoreItems ? srv.serviceScore?.totalScore : '',
          FullDescription: srv.serviceMetadata?.description,
          ResourceType: getResourceIcon(srv.serviceType),
          Status: getResourceStatus(srv.serviceStatus)
        }

        items.push(item);
      });

      //releaseDefinitions: [{…}] // stand alone modal
      setServicesItems(items);*/
    }

  }, [viewMode, services]);

  useEffect(() => {
    var items = []
    chunkedServices.forEach(srv => {
      var item = {
        ServiceName: srv.name,
        RepositoryName: srv.repositoryName,
        Team: srv.serviceMetadata?.team,
        TeamLeader: srv.serviceMetadata?.manager,
        Tier: srv.serviceMetadata?.tier,
        Description: getDescription(srv),
        Score: srv?.serviceScore?.scoreItems ? srv.serviceScore?.totalScore : '',
        FullDescription: srv.serviceMetadata?.description,
        ResourceType: getResourceIcon(srv.serviceType),
        Status: getResourceStatus(srv.serviceMetadata)
      }

      items.push(item);
    });

    //releaseDefinitions: [{…}] // stand alone modal
    setServicesItems(items);
  }, [chunkedServices])

  const handleRemove = () => { }
  const handleSelect = () => { }

  const handleRowClick = (e) => {


    var service = services.filter(s => s?.name?.toLowerCase() === e?.ServiceName?.toLowerCase()
      && s?.repositoryName?.toLowerCase() === e?.RepositoryName?.toLowerCase())
    if (service && service.length > 0) {

      setSelectedService(service[0]);
      /* history.push('/Service')*/
      history.push({
        pathname: '/Service',
        display: 'ServiceChild',
        search: '?name=' + service[0].name
      })
    }

  }


  return (




    <div className="row">
      <div className="col-12 card-container-services-table">
        <div>
          <SCTable title="" columnsItems={columnsItems} rowItems={servicesItems} tableWidth="90" handleRowClick={(e) => handleRowClick(e)}
            handleRemove={handleRemove} handleSelect={handleSelect} includesRemoveButton={false} includesSelectButton={false}
            showLoadMore={isLoadMoreLoading}
            toolTip={{ column: 'Description', text: 'FullDescription' }}
          />

        </div>

      </div>

    </div>


  )
}

export default memo(CardsListView)
