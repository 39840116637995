import DataContext from "../Context/DataContext";
import { useContext } from "react";
import {
  DropdownButton,
  Dropdown,
  Button,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { useState, useEffect } from "react";


import Navigation from "./Navigation";
import _ from "lodash";

const Header = () => {
  const {  modalShow, setModalShow } =
    useContext(DataContext); 



  return (
    <div className="header" style={{zIndex: 1000}}
  >
      <div>
        <div>
          <Navigation
            setModalShow={setModalShow}          
          />
        </div>
       
      </div>
    </div>
  );
};

export default Header;
