import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import './Insights.css';
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import _, { forEach } from "lodash";
import SCDropDownList from "../Components/SCDropDownList";
import { Chart, registerables } from 'chart.js';
import SCLine from "../Components/SCLine";
import SelfServiceSmallTable from "../Components/SmallTables/SelfServiceSmallTable";
import PermissionSmallTable from "../Components/SmallTables/PermissionSmallTable";
import SCContainer from "../Components/SCContainer";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DataContextAnalyzer from "../Context/DataContextAnalyzer";
import LoaderModal from "../Modals/LoaderModal";
import SAAnalyzerReportModal from "../Components/ServiceAnalyzer/refactor/SAAnalyzerReportModal";
import { toast } from 'react-toastify';
import { DateTime, DateTime as luxon } from "luxon";



Chart.register(...registerables);




const Insights = () => {
    const { servicesCached, insights, setCurrentPage, requestCICDItems, userPermissionRequests, token
        , doForceInit } = useContext(DataContext);
    const { report, status, GetServiceNameById } = useContext(DataContextAnalyzer);

    const [showModalLoaderForSlack, setShowModalLoaderForSlack] = useState(false)
    const [ddlRepos, setDdlRepos] = useState([])
    const [selectedRepo, setSelectedRepo] = useState('All');
    const [techStackChartLabels, setTechStackChartLabels] = useState([]);
    const [techStackChartData, setTechStackChartData] = useState([]);
    const [techServices, setTechServices] = useState([])
    const [chart, setChart] = useState({})
    const [techChartHeight, setTechChartHeight] = useState('100')
    const [techChartWidth, setTechChartWidth] = useState('80')
    const [techChartStepSize, setTechChartStepSize] = useState(5)
    const NUMBER_OF_PULLING = 20;
    const [incidentService, setIncidentService] = useState({})
    const [requestId, setRequestId] = useState({})
    const [incidentServices, setIncidentServices] = useState([])

    const [showAnalyzerReport, setShowAnalyzerReport] = useState(false)

    const location = useLocation();



    const backgroundColor = [
        'rgba(255,0,0,0.62)', //red
        'rgba(27,146,241,0.62)', //blue
        'rgba(255,231,80,0.6)', // yellow
        'rgba(0,153,0,0.65)' // green

    ]
    const borderColor = [
        'rgba(255,0,0,0.62)', //red
        'rgba(27,146,241,0.62)', //blue
        'rgba(255,231,80,0.6)', // yellow
        'rgba(0,153,0,0.65)' // green
    ]
    const borderWidth = 1

    useEffect(() => {
        setCurrentPage('home')

    }, [])


    const buildModalReportData = (response, requestId, serviceId) => {
        setRequestId(requestId)
        let rootCauseObj = {

            data: {
                data: response?.data?.data?.incident?.rootCauseAnalysis ? response.data.data.incident.rootCauseAnalysis : null
            }
        }

        if (rootCauseObj.data.data === null) {
            const ErrorMsg = () => (
                <div>Something went wrong during the report fetching</div>
            )
            toast.error(<ErrorMsg />)
            return;

        }

        let service = {
            clusterName: null,
            collectDataStatus: null,
            engineLogs: null,
            events: null,
            hasParent: null,
            id: null,
            incident: response.data.data.incident,
            investigateStatus: null,
            isApiCallInProgress: true,
            isChangeToPendingInProgress: true,
            name: GetServiceNameById(servicesCached, serviceId),
            newRelicName: null,
            order: 0,
            project: null,
            report: response.data.data.insights.insights,
            reportAPIStatus: "success",
            reportId: response?.data?.data?.id,
            resourceGroup: null,
            resourceId: null,
            rootCause: rootCauseObj.data?.data,
            rootCauseAPIStatusAttemp: 0,
            rootCauseAnalysisStatus: null,
            serviceId: response.data.data.serviceId,
            statusAPIStart: null,
            statusAttemp: null,
            subscriptionId: null,
            type: null,
            from: response?.data?.data?.from,
            to: response?.data?.data?.to,
            summarization: response?.data?.data?.incident && response?.data?.data?.incident?.summarization ? response?.data?.data?.incident?.summarization : ''
        }
        let arr = [];
        arr.push(service)
        setIncidentService(service)
        setIncidentServices(arr)
        setShowAnalyzerReport(true)
        setShowModalLoaderForSlack(false)



    }

    useEffect(async () => {
        if (token !== "" && token !== undefined && token !== null) {
            //http://localhost:3000/?serviceId=64f138d1b952624c97bc9c1c&requestId=f3602cde-2d7f-495e-9653-ccc46ee5b3df&short=slack
            const { search } = location;
            const urlParams = new URLSearchParams(search);
            const requestId = urlParams.get('requestId');
            const serviceId = urlParams.get('serviceId');

            const short = urlParams.get('short');

            if (short === "slack" && requestId !== null && serviceId !== null) {
                setShowModalLoaderForSlack(true);
                let response = await status(requestId)
                if (response !== null) {
                    if (response.data.data.services.length > 0) {

                        let statusForService = response.data.data.services.find(s => s.id === serviceId);
                        if (statusForService) {

                            if (statusForService.rootCauseAnalysisStatus === "Succeeded") {
                                //call report
                                let reportDetails = await report(requestId, serviceId)
                                buildModalReportData(reportDetails, requestId, serviceId)
                                //display report
                            }
                            else {
                                statusInterval(requestId, serviceId, 0)
                            }
                        }

                    }

                }

            }

        }


    }, [token])

    const statusInterval = (reqId, serviceId, attemp) => {

        const intervalId = setInterval(async () => {


            try {

                attemp++;
                console.log("STATUS INTERVAL SHORT LINK: ", attemp)
                let response = await status(reqId);

                if (response.status === 200) {

                    let timeout = false

                    if (response.data.data.services.find(s => s.id === serviceId).rootCauseAnalysisStatus === "Succeeded") {
                        clearInterval(intervalId)
                        //display report
                    }


                    if (attemp === NUMBER_OF_PULLING) {
                        console.log("ATTEMP STATUS EQUAL TO LIMIT")
                        timeout = true
                        clearInterval(intervalId)
                        //display error max attemp    


                        //updateServicesReport(response.data.data.services);
                        //setMaxAttemp(true)


                    }
                    //console.log("***STATUS FOR SERVICE IS READY TO GET REPORT***", response.data.data.services)
                    //filter only the services collectDataStatus: "Succeeded" && investigateStatus: "Succeeded"                    
                    if (intervalId !== undefined) {
                        //updateServicesReport(response.data.data.services);
                    }



                }
                if (response.status !== 200) {
                    console.log("STATUS NOT EQUAL TO 200")
                    clearInterval(intervalId)
                    //display error max attemp    


                    //displayError("Services status API threw an exception: " + response.status)
                    return;


                }


            }
            catch (error) {
                console.log("Error", error)
                clearInterval(intervalId)
                // displayError("Services status API threw an exception")
                return;


            }


        }, 5000);

    }


    useEffect(() => {
        if (servicesCached && servicesCached.length > 0) {
            //  generateServicesStatusChart();
            generateNumberOfServicesChart();

        }



    }, [servicesCached])

    useEffect(() => {//taltal
        if (insights && insights.length > 0) {

            //  generateBuildsChart();
            //  generateReleasesChart();
            //  mostUsedInfraPackages();
            techStackChart();
            generateServicesFrameworks();
            generateReleasesAndBuildsCharts();

        }

        /*if (insights && Object.entries(insights).length > 0) {

            generateBuildsChart();
            generateReleasesChart();
            mostUsedInfraPackages();
            techStackChart();
        }*/



    }, [insights])

    /* const generateServicesStatusChart = () => {
         var labels = [];
         var data = [];
 
         let groupedServices = _.groupBy(servicesCached, 'serviceStatus')
 
         for (var [key, value] of Object.entries(groupedServices)) {
 
             if (key === "null") {
                 key = 'Status Undefined';
             }
             if (key === 'HasPackagesConflict') {
                 key = 'Package Conflicts';
             }
             labels.push(value.length + ' ' + key);
             data.push(value.length);
         }
 
         const ctx = document.getElementById('servicesStatusChart');
         const myChart = new Chart(ctx, {
             type: 'pie',
             data: {
                 labels: labels,
                 datasets: [{
                     label: 'Number of services with # status',
                     data: data,
                     backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(27,146,241,0.62)', 'rgba(255,231,80,0.6)'],
                     borderColor: ['rgba(0,153,0,0.65)', 'rgba(27,146,241,0.62)', 'rgba(255,231,80,0.6)'],
                     borderWidth: borderWidth
                 }]
             },
             options: {
                 plugins: {
                     title: {
                         display: true,
                         text: 'NUMBER OF SERVICES BY STATUS'
                     }
                 }
             }
         });
 
 
 
     }
 */


    const modifiedDict = (dictionary) => {
        const newDict = {};

        // Iterate over the original dictionary
        for (const key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {


                // Check if the key starts with 'v'
                if (key.startsWith('v')) {
                    // Replace 'v' with 'net' and remove any dots
                    const newKey = key.replace('v', 'net').replace(/\./g, '');
                    newDict[newKey] = dictionary[key];
                } else {
                    newDict[key] = dictionary[key];
                }
            }
        }
        let resultArray = []
        for (const key in newDict) {
            if (key.includes(';')) {
                // Split the string into an array
                let keysArray = key.split(';');

                // Iterate over the array
                for (const k of keysArray) {
                    // Check if the key contains 'netstandard'
                    if (!k.includes('netstandard') &&   !resultArray.some(item => item.framework === k)) {
                        resultArray.push({ framework: k, count: 0 }); // Push if it does not contain 'netstandard'
                        
                    }
                }
            } else {
                // If there is no semicolon, check if it contains 'netstandard'
                if (!key.includes('netstandard') &&  !resultArray.some(item => item.framework === key)) {
                    resultArray.push({ framework: key, count: 0 }); // Push the single value if it does not contain 'netstandard'
                }
            }
            
        }

        
        return {dictionary:newDict , frameworks:resultArray} 
    }




    const generateServicesFrameworks = () => {

        const groupedByNetVersion = _.groupBy(insights[0].data.techStackWithServices, 'netVersion');
        let obj = modifiedDict(groupedByNetVersion)
        

        obj.frameworks.forEach(item => {

            for (const key in obj.dictionary) {
                if (obj.dictionary.hasOwnProperty(key)) { // Checks if the property is directly on the object
                    if (key.includes(item.framework)) {
                        item.count += obj.dictionary[key].length
                    }
                }
            }

        });


        let labels = []
        let data = []

        obj.frameworks.forEach(item => {
            labels.push(item.framework)
            data.push(item.count)
        });

        let existingFrameworkChart = Chart.getChart('frameworkChartMonthly');
        if (existingFrameworkChart) {
            existingFrameworkChart.destroy();
        }


        const ctxFramework = document.getElementById('frameworkChartMonthly');
        const frameworkChart = new Chart(ctxFramework, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'services frameworks (active)',
                        data: data, // Values for Dataset 1
                        backgroundColor: 'rgba(43, 64, 255)',
                    }
                ]
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true // This enables stacking for the x-axis
                    }],
                    yAxes: [{
                        stacked: true // This enables stacking for the y-axis
                    }]
                }
            }
        });


    }

    const generateReleasesAndBuildsCharts = () => {
        //  
        console.log(insights)
        const now = DateTime.now();

        // Get the current month index (0-11)
        const currentMonthIndex = now.toFormat('MMMM');;
        const previousMonthDate = now.minus({ months: 1 }).toFormat('MMMM');;
        const twoMonthAgoDate = now.minus({ months: 2 }).toFormat('MMMM');;
        const threeMonthAgoDate = now.minus({ months: 3 }).toFormat('MMMM');;

        const fourMonthAgoDate = now.minus({ months: 4 }).toFormat('MMMM');;
        const fiveMonthAgoDate = now.minus({ months: 5 }).toFormat('MMMM');;


        let dataReleaseSuccess = []
        let dataReleaseFailed = []
        let dataBuildSuccess = []
        let dataBuildFailed = []


        insights.forEach(item => {

            dataReleaseSuccess.push(item.data.successReleaseCount)
            dataReleaseFailed.push(item.data.failedReleaseCount)
            dataBuildSuccess.push(item.data.successBuildCount)
            dataBuildFailed.push(item.data.failedBuildCount)
        });





        let existingReleasesChart = Chart.getChart('releasesChartMonthly');
        if (existingReleasesChart) {
            existingReleasesChart.destroy();
        }

        let existingBuildsChart = Chart.getChart('buildsChartMonthly');
        if (existingBuildsChart) {
            existingBuildsChart.destroy();
        }


        const ctxReleases = document.getElementById('releasesChartMonthly');
        const releasesChart = new Chart(ctxReleases, {
            type: 'bar',
            data: {
                labels: [currentMonthIndex, previousMonthDate,
                    twoMonthAgoDate, threeMonthAgoDate, fourMonthAgoDate, fiveMonthAgoDate
                ], // X-axis labels
                datasets: [
                    {
                        label: 'successful releases',
                        data: dataReleaseSuccess, // Values for Dataset 1
                        backgroundColor: 'rgba(89, 189, 89)',
                    },
                    {
                        label: 'failed releases',
                        data: dataReleaseFailed, // Values for Dataset 2
                        backgroundColor: 'rgba(255, 97, 97)',
                    }
                ]
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true // This enables stacking for the x-axis
                    }],
                    yAxes: [{
                        stacked: true // This enables stacking for the y-axis
                    }]
                }
            }
        });

        const ctxBuilds = document.getElementById('buildsChartMonthly');
        const buildsChart = new Chart(ctxBuilds, {
            type: 'bar',
            data: {
                labels: [currentMonthIndex, previousMonthDate,
                    twoMonthAgoDate, threeMonthAgoDate, fourMonthAgoDate, fiveMonthAgoDate
                ], // X-axis labels
                datasets: [
                    {
                        label: 'successful builds',
                        data: dataBuildSuccess, // Values for Dataset 1
                        backgroundColor: 'rgba(89, 189, 89)',
                    },
                    {
                        label: 'failed builds',
                        data: dataBuildFailed, // Values for Dataset 2
                        backgroundColor: 'rgba(255, 97, 97)',
                    }
                ]
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true // This enables stacking for the x-axis
                    }],
                    yAxes: [{
                        stacked: true // This enables stacking for the y-axis
                    }]
                }
            }
        });
    }

    const generateNumberOfServicesChart = () => {
        var labels = [];
        var data = [];

        let groupedServices = _.groupBy(servicesCached, 'serviceType')
        for (const [key, value] of Object.entries(groupedServices)) {

            labels.push(key);
            data.push(value.length);
        }

        let existingChart = Chart.getChart('resourceTypeChart');
        if (existingChart) {
            existingChart.destroy();
        }


        const ctx = document.getElementById('resourceTypeChart');
        const myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Number of services by resources',
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: borderWidth
                }]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Number of services by resources'
                    }
                }
            }
        });
    }


    /* const generateBuildsChart = () => {
         var labels = [];
         var data = [];
     
     
         labels.push(insights.successBuildCount + ' successful builds');
         labels.push(insights.failedBuildCount + ' failed builds');
     
         data.push(insights.successBuildCount);
         data.push(insights.failedBuildCount);
     
         var total = parseInt(insights.successBuildCount) + parseInt(insights.failedBuildCount)
     
         let existingChart = Chart.getChart('resourceBuildsChart');
         if (existingChart) {
             existingChart.destroy();
         }
     
         const ctx = document.getElementById('resourceBuildsChart');
         const myChart = new Chart(ctx, {
             type: 'doughnut',
             data: {
                 labels: labels,
                 datasets: [{
                     label: 'Number of builds',
                     data: data,
                     backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                     borderColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                     borderWidth: borderWidth
                 }]
             },
             options: {
                 plugins: {
                     title: {
                         display: true,
                         text: 'NUMBER OF BUILDS (LAST 30 DAYS) - TOTAL: ' + total
                     }
                 }
             }
         });
     
     }*/

    /*const generateReleasesChart = () => {
        var labels = [];
        var data = [];
    
    
        labels.push(insights.successReleaseCount + ' successful releases');
        labels.push(insights.failedReleaseCount + ' failed releases');
    
    
        data.push(insights.successReleaseCount);
        data.push(insights.failedReleaseCount);
    
    
        var total = parseInt(insights.successReleaseCount) + parseInt(insights.failedReleaseCount)
    
        let existingChart = Chart.getChart('resourceReleasesChart');
        if (existingChart) {
            existingChart.destroy();
        }
    
    
        const ctx = document.getElementById('resourceReleasesChart');
        const myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Number of Releases',
                    data: data,
                    backgroundColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderColor: ['rgba(0,153,0,0.65)', 'rgba(255,0,0,0.62)'],
                    borderWidth: borderWidth
                }]
            },
            options: {
    
                plugins: {
                    title: {
                        display: true,
                        text: 'NUMBER OF RELEASES (LAST 30 DAYS) - TOTAL: ' + total
                    }
                }
            }
        });
    
    }*/

    /*const mostUsedInfraPackages = () => {
        var labels = [];
        var data = [];
        var total = 0;
        insights?.mostUsedInfraPackages?.forEach(pkg => {
            labels.push(pkg.service);
            data.push(pkg.number);
            total = total + parseInt(pkg.number);
        });
    
    
    
        /* const ctx = document.getElementById('resourceMostUsedPackagesChart');
         const myChart = new Chart(ctx, {
             type: 'bar',
             data: {
                 labels: labels,
                 datasets: [{
                     label: 'usage per package',
                     data: data,
                     backgroundColor: ['rgba(27,146,241,0.62)'],
                     borderColor: ['rgba(27,146,241,0.62)'],
                     borderWidth: borderWidth
                 }]
             },
             options: {
                 indexAxis: 'y',
                 plugins: {
                     title: {
                         display: true,
                         text: 'USAGE PER PACKAGE - TOTAL: ' + total
                     },
                     legend: {
                         display: false
                     },
                 },
                 scales: {
                     y: {
                         beginAtZero: true
                     }
                 }
             }
         });*/
    //}

    const techStackChart = () => {

        var techServices = [];

        var groupByServiceName = _.groupBy(insights[0]?.data?.techStackWithServices, "serviceInfoName");
        var count = 0;
        Object.entries(groupByServiceName).forEach(([key, value]) => {
            count++;
            const total = _.sumBy(value, v => {
                return v.count;
            });
            techServices.push({ id: count, repoName: key, projectsInRepo: value, totalProjects: total })

        })
        var repoList = [];
        repoList.push({ name: 'All', value: 'All' })
        var orderedRepoName = _.orderBy(techServices, ['repoName'], ['asc']);
        orderedRepoName.forEach(v => {

            repoList.push({ name: v.repoName, value: v.id });

        });

        setDdlRepos([...repoList]);
        setTechServices(techServices);



    }
    const handleChange = (e) => {
        setSelectedRepo(e.target.value);
    }
    useEffect(() => {

        if (selectedRepo !== 'All') {
            var labels = [];
            var data = [];

            var selectedRepository = techServices.filter(t => t.id === selectedRepo)

            if (selectedRepository && selectedRepository.length > 0) {

                selectedRepository[0]?.projectsInRepo?.forEach(item => {
                    labels.push(item.netVersion)
                    data.push(item.count);
                });
            }
            setTechStackChartLabels([...labels])
            setTechStackChartData([...data])

            if (data.length === 1) {
                setTechChartHeight('14')

            }

            if (data.length > 1 && data.length <= 3) {
                setTechChartHeight('17')
            }

            if (data.length > 3 && data.length <= 5) {
                setTechChartHeight('20')
            }

            if (data.length > 5 && data.length <= 10) {
                setTechChartHeight('40')
            }
            if (data.length > 10 && data.length <= 20) {
                setTechChartHeight('85')
            }

            if (data.length > 20) {
                setTechChartHeight('100')
            }

            let max_val = _.max(data);


            if (max_val > 0 && max_val <= 5) {
                setTechChartStepSize(1)

            }
            if (max_val > 5 && max_val <= 30) {
                setTechChartStepSize(2)

            }
            if (max_val > 30 && max_val <= 80) {
                setTechChartStepSize(5)

            }
            if (max_val > 80) {
                setTechChartStepSize(10)
            }

        }
        else {
            techStackChart();
            setTechChartHeight('100')
            setTechChartStepSize(5)
        }



    }, [selectedRepo])

    const techStackChartSetup = () => {
        return {
            labels: techStackChartLabels,
            datasets: [{
                label: 'Number of projects that are implemented by # framework',
                data: techStackChartData,
                backgroundColor: ['rgba(27,146,241,0.62)'],
                borderColor: ['rgba(27,146,241,0.62)'],
                borderWidth: borderWidth
            }]
        }
    }

    const techStackChartConfig = () => {
        return {
            type: 'bar',
            data: techStackChartSetup(),
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                },
                indexAxis: 'y',
                plugins: {
                    title: {
                        display: true,
                        text: 'NUMBER OF PROJECTS THAT ARE IMPLEMENTED BY # FRAMEWORK'
                    },
                    legend: {
                        display: false
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            // forces step size to be 50 units
                            stepSize: techChartStepSize
                        }
                    }
                }
            }
        }
    }
    const techStackChartDestroy = (chart) => {
        chart.destroy();
    }

    /* const renderTechChart = () => {
         if (Object.entries(chart).length > 0) {
             techStackChartDestroy(chart)
         }
         let myChart = new Chart(document.getElementById('techStackChart'),
             techStackChartConfig()
         )
         setChart(myChart)
     }*/

    /* useEffect(() => {
     
         renderTechChart();
     
     }, [techStackChartLabels, techStackChartData])*/

    useEffect(() => {
        var labels = [];


        var groupByTechStack = _.groupBy(insights?.techStackWithServices, "netVersion");

        Object.entries(groupByTechStack).forEach(([key, value]) => {
            labels.push(key);
        })

        splitFrameworks(labels)
    }, [techServices])

    const splitFrameworks = (labels) => {
        if (labels && labels.length > 0) {

            var complexTechString = labels.filter(item => item.indexOf(';') > -1);
            createSimpleFrameworkNames(complexTechString)

            complexTechString = labels.filter(item => item.indexOf(';') === -1);
            createSimpleFrameworkNames(complexTechString)


            var disc = _.uniqBy(arrFlatData, (e) => {
                return e.name;
            });




            techServices.forEach(ts => {
                ts?.projectsInRepo.forEach(p => {

                    if (p.netVersion) {
                        if (p.netVersion.indexOf(';') > -1) {
                            var splitTech = p.netVersion.split(';');
                            splitTech.forEach(s => {
                                var entity = disc.filter(d => d.originalName === s)
                                if (entity && entity.length > 0) {
                                    entity[0].count.push(p.count)
                                }
                            });

                        }
                        else {
                            var entity = disc.filter(d => d.originalName === p.netVersion)
                            if (entity && entity.length > 0) {
                                entity[0].count.push(p.count)
                            }
                        }

                    }
                });
            });


            disc.forEach(d => {
                var total = _.sum(d.count)
                d.total = total
            });



            var chartLabels = [];
            var chartData = [];
            //build chart labels & data
            disc.forEach(element => {
                chartLabels.push(element.originalName);
                chartData.push(element.total);
            });
            setTechStackChartLabels([...chartLabels])
            setTechStackChartData([...chartData])


        }
    }
    var arrFlatData = [];
    const createSimpleFrameworkNames = (complexTechString) => {


        complexTechString.forEach(element => {

            var ele = element;
            if (element.indexOf(';') > -1) {
                ele = element.split(';');

                ele.forEach(e => {

                    arrFlatData.push({ name: e, originalName: e, count: [], total: 0 });
                });

            }
            else {

                arrFlatData.push({ name: ele, originalName: ele, count: [], total: 0 });
            }


        });

    }




    return (
        <React.Fragment>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>

            <div className="in-main">

                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Services</div>
                        {
                            insights && insights.length > 0 &&
                            (
                                <div className="in-chart-card-details-value">{insights[0].data.serviceCount}</div>

                            )
                        }
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Projects</div>
                        {
                            insights && insights.length > 0 &&
                            (
                                <div className="in-chart-card-details-value">{insights[0].data.projectCount}</div>

                            )
                        }

                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Repositories</div>
                        {
                            insights && insights.length > 0 &&
                            (
                                <div className="in-chart-card-details-value">{insights[0].data.repositoryCount}</div>

                            )
                        }

                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Teams</div>
                        {
                            insights && insights.length > 0 &&
                            (
                                <div className="in-chart-card-details-value">{insights[0].data.teamCount}</div>

                            )
                        }
                    </div>
                </div>
                <div className="in-chart-card" >
                    <div className="in-chart-card-details">
                        <div className="in-chart-card-details-name">Total Infra Packages</div>
                        {
                            insights && insights.length > 0 &&
                            (
                                <div className="in-chart-card-details-value">{insights[0].data.infraPackageCount}</div>

                            )
                        }
                    </div>
                </div>
                <div class="grid-container-insights-charts">
                    <div class="cell-insights-charts">
                        <canvas id="releasesChartMonthly" style={{ maxWidth: '700px', maxHeight: '300px', margin: 'auto', display: 'inline-flex' }}></canvas>

                    </div>
                    <div class="cell-insights-charts">
                        <canvas id="buildsChartMonthly" style={{ maxWidth: '700px', maxHeight: '300px', margin: 'auto', display: 'inline-flex' }}></canvas>
                    </div>
                    <div class="cell-insights-charts">
                        <canvas id="frameworkChartMonthly" style={{ maxWidth: '700px', maxHeight: '300px', margin: 'auto', display: 'inline-flex' }}></canvas>
                    </div>
                    <div class="cell-insights-charts">
                        <canvas id="resourceTypeChart" style={{ maxWidth: '700px', maxHeight: '300px', margin: 'auto', display: 'inline-flex' }} ></canvas>
                    </div>
                    <div className="in-tables-ss-permission">
                    <div className="in-table-container">
                        <div className="in-table-selfservice">
                            <SCContainer children={<SelfServiceSmallTable />} loading={requestCICDItems.status} />

                        </div>

                        <div className="in-table-permission">
                            <SCContainer children={<PermissionSmallTable />} loading={userPermissionRequests.status} />

                        </div>
                    </div>
                </div>
                </div>

              



                {/*
                <div className="in-chart-small services-status-chart services-status-chart" >
                <canvas id="servicesStatusChart" style={{ maxWidth: '300px', maxHeight: '300px' }}></canvas>
                 </div>
                */}



                {/*
                <div className="in-chart-medium services-tech-stack-chart" >
                <SCDropDownList wraperClassName="in-ddl-wraper" className="in-md-ddl" label="Select repo" componentState={selectedRepo} setComponentState={setSelectedRepo}
                    size="small" items={ddlRepos} handleChange={(e) => handleChange(e)} value={selectedRepo} />
                    <canvas id="techStackChart" style={{ maxWidth: techChartWidth + 'vw', maxHeight: techChartHeight + 'vh' }}></canvas>
                  </div>

                    <div className="in-chart-medium services-status-chart" >
                        <canvas id="resourceMostUsedPackagesChart" ></canvas>
                    </div>
                    */}



            </div>
            <LoaderModal showModalLoader={showModalLoaderForSlack} setShowModalLoader={setShowModalLoaderForSlack} doForceInit={doForceInit} />

            <SAAnalyzerReportModal requestId={requestId} services={incidentServices} progressBar={null} show={showAnalyzerReport}
                setShowAnalyzerReport={setShowAnalyzerReport} service={incidentService} fromHistory={true}
                withPredict={true} manageStatus={true}
            />

        </React.Fragment>
    )
}

export default Insights
