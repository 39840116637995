import * as React from 'react';
import './NotFound.css';

const NotFound = () => {
   
    return (
        <>
           <div className="container-fluid">
               <div className="row">
                    <div className="col-12 notfound-title">404</div>
               </div>
               <div className="row">
                    <div className="col-12 notfound-sub-title">Not Found</div>
               </div>
               <div className="row">
                    <div className="col-12 notfound-desc-title">The resource requested could not be found.</div>
               </div>
               </div>
        </>
    )
}

export default NotFound
