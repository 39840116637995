import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerProgressBar.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';

const ServiceAnalyzerProgressBar = ({ state, text }) => {

    const [percentage, setPercentage] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);


    useEffect(() => {
        // Example: set the progress bar to 60% after 2 seconds       
        if (state !== 8) {
            setPercentage(10 * state);
        }
        else {
            setPercentage(100)
            setFadeOut(true);
        }

    }, [state]);

    useEffect(() => {
        if (fadeOut) {
            // Fade out after 5 seconds
            const fadeOutTimeout = setTimeout(() => {
                setPercentage(0);
            }, 5000);

            return () => clearTimeout(fadeOutTimeout);
        }
    }, [fadeOut]);


    return (
        <React.Fragment>
            <div className={`service-analyzer-progress-container ${fadeOut ? 'fade-out' : ''}`}>
                <div className="service-analyzer-progress-bar" style={{ width: `${percentage}%` }}>
                    {text}
                </div>

            </div>
        </React.Fragment>
    )
}

export default ServiceAnalyzerProgressBar