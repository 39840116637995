import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext, useCallback } from "react";
import './Environments.css';
import SCTable from './SCTable';
import SCDropDownList from "./SCDropDownList";

const Environments = () => {
    const { selectedService, setMdEditEnvTable, mdEditEnvTable, mdEditReleaseTable,
        setMdEditReleaseTable, buildDefinitions, setBuildDefinitions, convertIsoDateToDate, branches, getBuildDefinitions } = useContext(DataContext);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [ddlBranches, setDdlBranches] = useState([]);

    const columnsReleaseItems = [{ display: true, name: "createdFor", key: "createdFor" },
    { display: true, name: "createdOn", key: "createdOn" },
    { display: true, name: "description", key: "description" },
    { display: true, name: "name", key: "name" },
    
    { display: true, name: "stages", key: "stages" },
    { display: true, name: "webApps", key: "webApps" },
    { display: true, name: "tags", key: "tags" }]


    const columnsItems = [{ display: false, name: "Id", key: "Id" },
    { display: true, name: "name", key: "name" },
    { display: true, name: "comments", key: "comments" },
    { display: true, name: "url", key: "url" }]


    useEffect(() => {
        if (ddlBranches && ddlBranches.length > 0) {

            setSelectedBranch(ddlBranches[0].value);
        }

    }, [ddlBranches])

  

    useEffect(() => {

        var rows = [];
        selectedService?.serviceMetadata?.environments?.forEach(element => {
            rows.push({ name: element.name, comments: element.comments, url: element.url });
        });
                
        setMdEditEnvTable([...rows])

        var res = branches.filter(b => b.serviceId === selectedService.id);
        var branchesList = []

        if (res && res?.length > 0) {

            res[0].branches.forEach(b => {
                var branchItem = { value: b, name: b }
                branchesList.push(branchItem)
            });
            setDdlBranches(branchesList);
        }

    }, [selectedService])


    useEffect(() => {

        initEnvironment();
    }, [buildDefinitions])


    useEffect(() => {

        if (selectedBranch && selectedBranch.length > 0) {
            getBuildDefinitions(selectedService.id, selectedBranch)
        }
    }, [selectedBranch])

    const handleChange = (e) => {
        setSelectedBranch(e.target.value);
    }

    const initEnvironment = () => {
        var releases = []
        buildDefinitions.forEach(bd => {

            if (bd?.builds && bd?.builds?.length > 0) {

                var releaseItem = {};
                bd?.builds.forEach(b => {
                    if (b?.releaseDefinitions && b.releaseDefinitions.length > 0) {

                        b.releaseDefinitions.forEach(rd => {

                            if (rd?.releases && rd?.releases?.length > 0) {
                                rd.releases.forEach(rel => {
                                    releaseItem = addRelease(rel);
                                    releases.push(releaseItem);
                                });
                            }
                        });
                    }
                });
            }
        });

        
        setMdEditReleaseTable([...releases])
    }

    const addRelease = (release) => {
        var objRelease = { createdFor: release.createdFor, createdOn: convertIsoDateToDate(release.createdOn), description: release.description, name: release.name }


        if (release?.stages && release?.stages?.length > 0) {
            addStages(release.stages, objRelease);
        }

        return objRelease;

    }
    const addStages = (stages, obj) => {
        obj.stages = [];
        stages.forEach(stage => {

            obj.stages.push({ name: stage.name, status: stage.status })

            if (stage?.webApps && stage?.webApps?.length > 0) {

                addWebApp(stage.webApps, obj);
            }
        });
        var stagesHtml = [];
        var count = 0;
        obj.stages.forEach(stg => {
            var divKey = 'stage' + count;
            stagesHtml.push(
                <div key={divKey}>
                    <span>Name: {stg.name}</span><br />
                    <span>Status: {stg.status}</span><br />
                </div>)

            count++

        });

        obj.stages = <>{stagesHtml}</>
        return obj;

    }

    const addWebApp = (webApps, obj) => {
        obj.webApps = []
        webApps.forEach(webApp => {

            obj.webApps.push({
                slotName: webApp.slotName, subscriptionName: webApp.subscriptionName, portalUrl: webApp.portalUrl, kind: webApp.kind
                , lastModifiedTimeUtc: convertIsoDateToDate(webApp.lastModifiedTimeUtc)
            });

            if (webApp?.tags && webApp?.tags?.length > 0) {
                addTags(webApp.tags, obj);
            }
        });


        var webAppHtml = [];
        var count = 0;
        obj.webApps.forEach(web => {
            var divKey = 'webApp' + count;
            webAppHtml.push(
                <div key={divKey}>
                    <span>Slot: {web.slotName}</span><br />
                    <span>Subscription: {web.subscriptionName}</span><br />
                    <span>Url: <a href={web.portalUrl} target="_blank">Click here</a></span><br />
                    <span>Modified: {web.lastModifiedTimeUtc}</span><br />
                </div>)

            count++;

        });

        obj.webApps = <>{webAppHtml}</>;


        return obj;

    }


    const addTags = (tags, obj) => {
        obj.tags = []
        tags.forEach(tag => {
            obj.tags.push({ name: tag.name, value: tag.value });
        });


        var tagsHtml = [];
        var count = 0;
        obj.tags.forEach(tag => {
            var divKey = 'tags' + count;
            tagsHtml.push(
                <div key={divKey}>
                    <span>{tag.name}: {tag.value}</span><br />
                </div>
            )
            count++
        });


        obj.tags = <>{tagsHtml}</>;
        return obj;
    }

    return (

        <>

            <div> <SCTable title="Environments" columnsItems={columnsItems} rowItems={mdEditEnvTable}
                includesRemoveButton={false} includesSelectButton={false}
            /> </div>
            <div className="env-latest">
                <SCDropDownList className="md-ddl" label="Select branch to render the latest releases" componentState={selectedBranch} setComponentState={setSelectedBranch}
                    size="small" items={ddlBranches} handleChange={(e) => handleChange(e)} value={selectedBranch} />
            </div>
            <div className="env-latest"> <SCTable title="Latest Releases" columnsItems={columnsReleaseItems} rowItems={mdEditReleaseTable}
                includesRemoveButton={false} includesSelectButton={false}
            /> </div>

        </>

    )
}

export default Environments
