import { createContext, useState, useContext } from 'react';
import useLogin from '../Hooks/useLogin';
import * as consts from '../consts';
import useAxios from '../Hooks/useAxios';
import DataContext from './DataContext';




const DataContextConsoleAI = createContext({});

export const DataProviderConsoleAI = ({ children }) => {

    const { token } = useContext(DataContext);

  
    const instanceConsoleAICustomerReview = useAxios(process.env.REACT_APP_CUSTOMER_REVIEW_BASE_URL);

    const getRestAutocomplete = async (text,numOfResults) => {
        
        if (token) {
            const url = consts.requests.getRestAutocomplete.replace('{text}', text).replace('{num}', numOfResults);
            return await instanceConsoleAICustomerReview.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: true
            })
        }
    }
 
    const getResturantsDetails = async (numOfResults) => {

        if (token) {
            const url = consts.requests.getTopReviewsRestaurants.replace('{numOfResults}', numOfResults);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }

    const generateReviewsCategories = async (restaurantId, month, year) => {
        if (token) {
            
            const url = consts.requests.generateReviewsCategories.replace('{restaurantId}', restaurantId).replace('{month}', month).replace('{year}', year);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }

    const generateRestaurantSummary = async (restaurantId, month, year) => {
        if (token) {
            const url = consts.requests.generateRestaurantSummary.replace('{restaurantId}', restaurantId).replace('{month}', month).replace('{year}', year);
            return await instanceConsoleAICustomerReview.get(url)
        }
    }


    const generateTrands = async (restaurantId, pMonth, pYear, cMonth, cYear) =>{
        
        if (token) {
            const url = consts.requests.generateTrands.replace('{restaurantId}', restaurantId).replace('{pMonth}', pMonth).replace('{pYear}', pYear)
            .replace('{cMonth}', cMonth).replace('{cYear}', cYear);
            try {
                return await instanceConsoleAICustomerReview.get(url)
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <DataContextConsoleAI.Provider value={{
            getResturantsDetails, generateReviewsCategories, generateRestaurantSummary,getRestAutocomplete,generateTrands
        }}>



            {children}
        </DataContextConsoleAI.Provider>
    )

}

export default DataContextConsoleAI;
