import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzer.css';
import _ from "lodash";
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { DateTime, DateTime as luxon } from "luxon";
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import SCDatePicker from '../SCDatePicker';
import SCButton from '../SCButton';
import SCTable from '../SCTable';
import ServiceAnalyzerTable from './ServiceAnalyzerTable';
import { toast } from 'react-toastify';
import SCContainer from '../SCContainer';
import { useHistory } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';









const ServiceAnalyzerSearchDateAndScan = ({ jobWorking, setValueFrom, valueFrom, setValueTo, valueTo, setDateRangeValid }) => {

    const handleChange = (e, type) => {

        if (type === 'from') {
          
            if (e.minute.toString().length === 2) {
                const inputDate = DateTime.fromJSDate(new Date(e));
                const outputDate = inputDate.toUTC().toISO({ includeMillis: true });
                checkDateValidation(outputDate, valueTo)
                setValueFrom(outputDate)
            }


        }
        if (type === 'to') {
            if (e.minute.toString().length === 2) {
                const inputDate = DateTime.fromJSDate(new Date(e));
                const outputDate = inputDate.toUTC().toISO({ includeMillis: true });
                checkDateValidation(valueFrom,outputDate)
                setValueTo(outputDate)
            }
        }


    }

    const checkDateValidation = (from, to) => {
        if (from && to) {
            const fromDate = DateTime.fromISO(from);
            const toDate = DateTime.fromISO(to);

            // Calculate the duration between the dates
            const duration = toDate.diff(fromDate);

            // Get the duration in hours
            const hoursDifference = duration.as('hours');

            // Check if the duration is within the allowed range (24 hours)
            const isWithinRange = hoursDifference <= 24 && hoursDifference > 0;

            setDateRangeValid(isWithinRange)
        }

    }

    return (
        <React.Fragment>

            <div className='deployment-changes-dates'>
                <div className='sc-datepicker-component-container service-analyzer-table-dp service-analyzer-table-right-space'>
                    <SCDatePicker id="analyzer-from-date" handleChange={(e) => handleChange(e, 'from')} disabled={jobWorking ? true : false} setValue={setValueFrom} value={valueFrom} defaultValue={valueFrom} title="From Date" />
                </div>
                <div className='sc-datepicker-component-container service-analyzer-table-dp'>
                    <SCDatePicker id="analyzer-to-date" handleChange={(e) => handleChange(e, 'to')} disabled={jobWorking ? true : false} setValue={setValueTo} value={valueTo} defaultValue={valueTo} title="To Date"
                        minDate={
                            luxon.fromISO(valueFrom)
                        }
                        maxDate={
                            luxon.fromISO(valueFrom).plus({ hours: 24 })
                        }

                    />
                </div>
            </div>

        </React.Fragment>
    )
}

export default ServiceAnalyzerSearchDateAndScan