import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext } from "react";
import './BuildDefinitions.css';
import SCTable from "./SCTable";
import SCDropDownList from "./SCDropDownList";



const BuildDefinitions = () => {

    const { selectedService, branches, getBuildDefinitions, buildDefinitions } = useContext(DataContext);
    const [linkType, setLinkType] = useState('');
    const [builds, setBuilds] = useState([]);
    const [ddlBranches, setDdlBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);



    const columnsItems = [{ display: true, name: "Name", key: "Name" },{ display: true, name: "Result", key: "Result" }, { display: true, name: "Build Number", key: "BuildNumber" }, { display: true, name: "Queue Time", key: "QueueTime" },
    { display: true, name: "Reason", key: "Reason" }, { display: true, name: "Requested For", key: "RequestedFor" }, { display: true, name: "Source Version", key: "SourceVersion" },
    { display: true, name: "Url", key: "Url" }];


    useEffect(() => {
        if (ddlBranches && ddlBranches.length > 0) {

            setSelectedBranch(ddlBranches[0].value);
        }

    }, [ddlBranches])

    useEffect(() => {

        var res = branches.filter(b => b.serviceId === selectedService.id);
        var branchesList = []

        if (res && res?.length > 0) {

            res[0].branches.forEach(b => {
                var branchItem = { value: b, name: b }
                branchesList.push(branchItem)
            });
            setDdlBranches(branchesList);
        }

    }, [selectedService])


    useEffect(() => {

        if (selectedBranch && selectedBranch.length > 0) {

            getBuildDefinitions(selectedService.id, selectedBranch)

        }




    }, [selectedBranch])

    useEffect(() => {

        var items = []
        buildDefinitions.forEach(element => {
            if (element && element.builds) {

              
                element.builds.forEach(b => {
                    var item = {
                        Name:element.name,
                        Result: b.result, BuildNumber: b.buildNumber, QueueTime: b.queueTime,
                        Reason: b.reason, RequestedFor: b.requestedFor, SourceVersion: b.sourceVersion
                        , Url: b.url
                    }

                    items.push(item);
                });

                /*releaseDefinitions: [{…}] // stand alone modal*/
                setBuilds(items);
            }
        });


    }, [buildDefinitions])


    const handleChange = (e) => {
        setSelectedBranch(e.target.value);
    }
    const handleRemove = () => { }
    const handleSelect = () => { }


    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div>
                        <div className='input-container'>
                            <SCDropDownList className="md-ddl" label="Select branch" componentState={selectedBranch} setComponentState={setSelectedBranch}
                                size="small" items={ddlBranches} handleChange={(e) => handleChange(e)} value={selectedBranch} />
                        </div>
                    </div>

                    <div> <SCTable title="Builds" columnsItems={columnsItems} rowItems={builds}
                        handleRemove={handleRemove} handleSelect={handleSelect} includesRemoveButton={false} includesSelectButton={false}
                    /> </div>
                </div>
            </div>
        </>
    )
}

export default BuildDefinitions
