import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext, useRef } from "react";
import './ServicePackages.css';
import SCTable from "./SCTable";
import SCDropDownList from "./SCDropDownList";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


const ServicePackages = () => {



    const bottomRef = useRef(null);
    const { selectedService, branches, branchDetails,setBranchDetails, getServiceBrachesInfo, services, setSelectedService } = useContext(DataContext);

    const [ddlBranches, setDdlBranches] = useState([]);
    const [ddlProject, setDdlProject] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
    const [packages, setPackages] = useState([]);
    const [dependencies, setDependencies] = useState([]);
    const history = useHistory();

    const columnsItems = [{ display: true, name: "Name", key: "Name" }, { display: true, name: "Authors", key: "Authors" }, { display: true, name: "Current Version", key: "CurrentVersion" },
    { display: true, name: "Description", key: "Description" }, { display: false, name: "FullDescription", key: "FullDescription" },
    { display: true, name: "Installed Version", key: "InstalledVersion" }, { display: true, name: "Published Date", key: "PublishedDate" },
    { display: true, name: "Tags", key: "Tags" }]


    const deptColumnsItems = [{ display: true, name: "Package", key: "Package" }, { display: true, name: "Target Framework", key: "TargetFramework" }]

    useEffect(() => {

      

       
        window.addEventListener('unload', handleEndConcert)
        return () => {
       
          window.removeEventListener('unload', handleEndConcert)
          handleEndConcert()
        }
      
    }, [])

    const handleEndConcert = () =>{
        
        setBranchDetails({})
        setDdlProject([])
    }

    useEffect(() => {

        var res = branches.filter(b => b.serviceId === selectedService.id);
        var branchesList = []
      
  
        if (res && res?.length > 0) {

            res[0].branches.forEach(b => {
                var branchItem = { value: b, name: b }
                branchesList.push(branchItem)
            });
            setDdlBranches(branchesList);
        }

    }, [selectedService])

    useEffect(() => {

        if (selectedBranch && selectedBranch.length > 0) {

            getServiceBrachesInfo(selectedService.id, selectedBranch)

        }


    }, [selectedBranch])


    useEffect(() => {

        
        if (branchDetails && branchDetails.data) {

            var items = []
            branchDetails.data.forEach(b => {
                var item = { value: b.name, name: b.name }
                items.push(item);
            });
            setDdlProject(items);


        }


    }, [branchDetails])


    const handleChange = (e) => {
        setSelectedBranch(e.target.value);
        setDependencies([]);
    }

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
        setDependencies([]);

        if (branchDetails && branchDetails.data) {
            var arr = branchDetails.data.filter(x => x.name.toLowerCase() === e.target.value.toLowerCase());

            setSelectedProjectDetails(arr[0]);
            var items = []
            arr[0].packageStatuses.forEach(p => {

                var item = {
                    Name: p.id, Authors: p.authors, CurrentVersion: p.currentVersion, Description: p.description?.substring(0, 50) + '...', FullDescription: p.description, InstalledVersion: p.installedVersion,
                    PublishedDate: p.publishedDate, Tags: p.tags?.join()
                }

                items.push(item);

            });
            setPackages(items)
        }
    }

    const handleRemove = () => { }
    const handleSelect = () => { }

    const handleRowClick = (e) => {
        // dependencies
        if (selectedProjectDetails) {

            var packageStatus = selectedProjectDetails.packageStatuses.filter(p => p.id === e.Name);
            if (packageStatus && packageStatus?.length > 0) {
                var deptArray = [];
                packageStatus[0].dependencies?.forEach((item) => {

                    item.packages.forEach((p) => {
                        deptArray.push({ Package: p, TargetFramework: item.targetFramework });
                    })
                })
                setDependencies(deptArray);
                bottomRef.current?.scrollIntoView({behavior: 'smooth'});
            }
        }
    }
    useEffect(() => {


    }, [dependencies])

    return (
        <>
            <div className='row' >
                <div className='col-12'>
                    <div className='input-container'>
                        <SCDropDownList  className="md-ddl-size" label="Select branch" componentState={selectedBranch} setComponentState={setSelectedBranch}
                           items={ddlBranches} handleChange={(e) => handleChange(e)} value={selectedBranch} />
                    </div>


                    <div className='input-container'>
                        <SCDropDownList className="md-ddl-size" label="Select project" componentState={selectedProject} setComponentState={setSelectedProject}
                            items={ddlProject} handleChange={(e) => handleProjectChange(e)} value={selectedProject} />
                    </div>

                    <div>
                        <div className="md-table-pkg">
                            <div> <SCTable title="Packages" columnsItems={columnsItems} rowItems={packages}
                                includesRemoveButton={false} includesSelectButton={false} toolTip={{ column: 'Description', text: 'FullDescription', filter:{operator:'eq'} }}
                                handleRowClick={(e) => handleRowClick(e)}
                            /> </div>
                        </div>
                        
                        <div>
                            <div ref={bottomRef} ><SCTable title="Dependencies" columnsItems={deptColumnsItems} rowItems={dependencies}
                                includesRemoveButton={false} includesSelectButton={false}
                            /></div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ServicePackages
