import { useEffect, useState, useContext, useRef } from "react";
import * as React from 'react';
import DataContext from "../Context/DataContext";
import './GroupServices.css';
import SCTop from "../Components/SCTop";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import SCInputText from "../Components/SCInputText";
import SCButton from "../Components/SCButton";
import SCTable from "../Components/SCTable";
import { toast } from 'react-toastify';
import _ from "lodash";
import Switch from '@mui/material/Switch';



function not(a, b) {
 

    b.forEach(element => {
        _.remove(a, {
            id: element.id
        });
    });

    return a;
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}


const GroupServices = () => {
    const columnsItems = [{ display: true, name: "Group name", key: "GroupName" }, { display: true, name: "Group description", key: "GroupDesc" },
    { display: true, name: "id", key: "id" }];

    const { services,servicesCached, setCurrentPage, createGroupName, groupServices, setGroupServices, 
        getGroups, updateServiceToGroup, deleteGroup,setGroupServicesCached } = useContext(DataContext);
    const [checked, setChecked] = useState([]);
    const [checkedType, setCheckedType] = useState(false);
    const [tableIndexChecked, setTableIndexChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [groupIDValue, setGroupIDValue] = useState('');
    const inputNameRef = useRef('');
    const inputDescRef = useRef('');
    const [clear, setClear] = useState(false);
    const [step, setStep] = useState(1);  
    const [servicesInGroup, setServicesInGroup] = useState({});
    const [rowItems, setRowItems] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const [isCheckBoxsNull, setIsCheckBoxsNull] = useState(true)



    const initLeft = () => {
        setLeft([])

        var setLeftItems = buildLeftServices();
        setLeft(setLeftItems);

    }

    const buildLeftServices = () => {
        var leftItem = [];
       /* if (services && services.length > 0) {
            var count = 0;
            services.forEach(s => {
                leftItem.push({ name: s.name, id: s.id, order: count })
                count++;
            });
            return leftItem;
        }*/

        if (servicesCached && servicesCached.length > 0) {
            var count = 0;
            servicesCached.forEach(s => {
                leftItem.push({ name: s.name, id: s.id, order: count })
                count++;
            });
            return leftItem;
        }
    }

    useEffect(() => {
        setCurrentPage('groups')
        initLeft();
 

    }, [])

    useEffect(() => {

        if (groupServices && groupServices.length > 0) {
            var items = [];
            groupServices.forEach(g => {
                items.push({ GroupName: g.GroupName, GroupDesc: g.GroupDesc, id: g.id })
            });
            setRowItems([...items]);
        }

    }, [groupServices])


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

   

    const handleCheckedLeft = () => {


     

        rightChecked.forEach(s => {
            _.remove(servicesInGroup.services, x => x === s.id);
        })

        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));

        setServicesInGroup({ groupId: groupIDValue, services: servicesInGroup.services })
       
    };


    //add service to group
    const handleCheckedRight = () => {

      
        var serviceIds = [];

        //current items
        leftChecked.forEach(s => {
            serviceIds.push(s.id);
        });

        //addtional items
        right.forEach(s => {
            serviceIds.push(s.id);
        });

        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
       
        setServicesInGroup({ groupId: groupIDValue, services: serviceIds })     
    };




    const handleNextButtonClick = () => {
        if (inputNameRef.current.value && inputDescRef.current.value) {
            setStep(3);

        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>Group Name & Group Description are required.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }
    }

    const handleCancelButtonClick = () => {

        clearAllInputs();

    }
    const clearAllInputs = () =>{
        setRight([]);
        var setLeftItems = buildLeftServices();
        setLeft(setLeftItems);
        setChecked(not(checked, leftChecked));      
        inputNameRef.current.value = '';
        inputDescRef.current.value = '';
        setServicesInGroup({});
        setStep(1);
    }
    const updateGroupNameWithListOfServices = (groupId, servicesIds) =>{
        updateServiceToGroup(groupId, servicesIds).then((updateResponse) => {

            if (updateResponse) {
                if (updateResponse?.status === 200) {
                    const SuccessMsg = () => (
                        <div>
                            <div>Group and services created successfully.</div>
                        </div>
                    )
                    toast.success(<SuccessMsg />)

               
                    if(!checkedType)
                    {
                        setRight([]);
                        var setLeftItems = buildLeftServices();
                        setLeft(setLeftItems);    
                        setChecked(not(checked, leftChecked));
                    }
                   

                    //update group state
                    var group = groupServices.filter(g => g.id === groupId);
                    if (group && group.length > 0) {
                        group[0].ServiceIds = [];
                        servicesInGroup.services.forEach(s => {
                            group[0].ServiceIds.push(s)
                        });
                     
                    }
                    setGroupServices([...groupServices])
                    setGroupServicesCached([...groupServices])

                }
                inputNameRef.current.value = '';
                inputDescRef.current.value = '';
                setStep(1);

            }
        });
    }
    const handleSaveButtonClick = () => {

        // servicesInGroup
        if(!checkedType)
        {
            var groupId;
            var items = [];
            createGroupName(inputNameRef.current.value, inputDescRef.current.value).then((response) => {
                if(response)
                {
                    if (response?.status === 200) {
                        groupId = response?.data;
                        items.push({ GroupName: inputNameRef.current.value, GroupDesc: inputDescRef.current.value, id: response?.data })
                       //for the edit table
                        setRowItems([...items])
                        groupServices.push({ GroupName: inputNameRef.current.value, GroupDesc: inputDescRef.current.value, id: response?.data, ServiceIds:servicesInGroup.services })
                        
                        //for the group homepage display
                        setGroupServices([...groupServices])
                        servicesInGroup.groupId = groupId;
                        updateGroupNameWithListOfServices(servicesInGroup.groupId,servicesInGroup.services);
                    }
                }
              
    
            }).then((res) => {
    
            }).catch((error) => {
                // handle errors
            });
    
        }
        else{
            
            updateGroupNameWithListOfServices(groupIDValue,servicesInGroup.services);
        }        
    

    }

    const handleButtonClick = () => {


      
        if (inputNameRef.current.value && inputDescRef.current.value) {
            var items = [];
            if (rowItems && rowItems.length > 0) {
                items = [...rowItems];

            }


            Promise.all([createGroupName(inputNameRef.current.value, inputDescRef.current.value)])
                .then((results) => {
                    const res = results[0];
                    if (results) {
                        if (res?.status === 200) {
                            const SuccessMsg = () => (
                                <div>
                                    <div>New group created successfully.</div>
                                </div>
                            )
                            toast.success(<SuccessMsg />)
                            items.push({ GroupName: inputNameRef.current.value, GroupDesc: inputDescRef.current.value, id: res?.data })
                            setRowItems([...items])

                            groupServices.push({ GroupName: inputNameRef.current.value, GroupDesc: inputDescRef.current.value, id: res?.data })
                            setGroupServices([...groupServices])
                            if (checkedType)
                                setStep(2);
                            if (!checkedType)
                                setStep(3);
                        }
                    }
                });

        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>Group Name & Group Description are required.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }

    }

    const handleRemove = (e) => {
        var selectedItem = rowItems.filter(r => r.id === rowItems[e].id);
        if (selectedItem && selectedItem.length > 0) {


            Promise.all([deleteGroup(selectedItem[0].id)])
                .then((results) => {
                    const res = results[0];
                    if (results) {
                        if (res?.status === 200) {
                            const SuccessMsg = () => (
                                <div>
                                    <div>Group deleted successfully.</div>
                                </div>
                            )
                            toast.success(<SuccessMsg />)
                            var items = [...rowItems];

                            _.remove(items, {
                                id: selectedItem[0].id
                            });

                            _.remove(groupServices, {
                                id: selectedItem[0].id
                            });
                            setRowItems([...items])                            
                            setGroupServices([...groupServices])
                            setGroupServicesCached([...groupServices])
                        }
                    }
                });
        }
    }
   
    const handleSelect = (e) => {

        

        var arrChecked = tableIndexChecked.filter(idx => idx.id === e);
        if (arrChecked && arrChecked.length === 0) {
            var chkArray = [{ id: e, checked: true }]
            setTableIndexChecked(chkArray);
            setStep(3)
        }
        else {
            arrChecked[0].checked = !arrChecked[0].checked;
            if (arrChecked[0].checked) {
                setStep(3)
            }
        }



        var tempLeftItems = buildLeftServices(); // in order to fill left state with all the services

    

        var selectedItem = rowItems.filter(r => r.id === rowItems[e].id);

        if (selectedItem && selectedItem.length > 0) {
    

            inputNameRef.current.value = selectedItem[0].GroupName
    

            inputDescRef.current.value = selectedItem[0].GroupDesc
            setGroupIDValue(selectedItem[0].id)
        }



        if (arrChecked && arrChecked.length > 0 && !arrChecked[0]?.checked) {
            setGroupIDValue('')
            setRight([]);
            return;
        }

        //get group
        var group = groupServices.filter(g => g.id === selectedItem[0].id);

        if (group && group.length > 0) {

            group[0].ServiceIds.forEach(service => {

                /*var order = services.filter(l => l.id === service);
                if (order && order.length > 0) {
                    leftChecked.push({ name: services.filter(s => s.id === service)[0].name, id: service, order: services.indexOf(order[0]) })
                }*/
                var order = servicesCached?.filter(l => l.id === service);
                if (order && order.length > 0) {
                    leftChecked.push({ name: servicesCached?.filter(s => s.id === service)[0].name, id: service, order: servicesCached.indexOf(order[0]) })
                }

            });
            setRight([].concat(leftChecked));
            leftChecked.forEach(element => {
                _.remove(tempLeftItems, {
                    id: element.id
                });
            });
            setLeft(tempLeftItems);


           

            //fill serviceIds list
            var servicesIds = [];
            leftChecked.forEach(item => {
                servicesIds.push(item.id)
            });
            setServicesInGroup({ groupId: selectedItem[0].id, services: servicesIds })
        }


    }




    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                className="sg-group-header"
           
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: '20rem',
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

        
    

    const switchHandler = (event) => {
     

        if (!checkedType) {
            setStep(2)
            setClear(false);
        }
        else {
            setStep(1)
            setClear(true);
        }

        !checkedType ? setCheckedType(true) : setCheckedType(false);
        clearAllInputs();
        setTableIndexChecked([]);
    }


    

    return (
        <>
            <div className="sc-header">
            <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main">
                <div className="row">
                    <div className="col-12 sg-toggle-type">
                        Create Group
                        <Switch color="primary" checked={checkedType} onChange={switchHandler} />
                        Edit Group
                    </div>
                </div>
                <div className="sg-container">
                    <div className={step === 1 ? 'sg-div-enable' : 'sg-div-disable'} style={{ display: !checkedType ? 'inline' : 'none' }}>
                        
                        <div className="sg-steps">Create a group name and description</div>
                      
                        <div className="sg-group-name-container">
                            <div className="align-right sg-group-name">
                                <SCInputText size="small" className="md-input"
                                    label="*Group name" refName={inputNameRef}
                                /></div>

                            <div className="align-right sg-group-desc">
                                <SCInputText size="small" className="md-input"
                                    label="*Group description" refName={inputDescRef}
                                /></div>

                            <div className="sg-button" >
                          
                                <SCButton size="medium" text="Next" handleButtonClick={handleNextButtonClick} />
                            </div>
                        </div>
                       

                    </div>

                    <div style={{ display: checkedType ? 'inline' : 'none' }}>
                        <div className="sg-steps">select a group for editing the group's values</div>
                      
                        <div className="gs-table-of-groups" >
                       
                            <SCTable  columnsItems={columnsItems} rowItems={rowItems}
                                handleSelect={handleSelect} includesRemoveButton={true} includesSelectButton={true}
                                handleRemove={handleRemove} clearCheckBoxLists={clear} allCheckBoxsNull={setIsCheckBoxsNull}
                            />
                        </div>
                       
                    </div>

                    <div className={step === 2 ? 'sg-div-enable' : 'sg-div-disable'} style={{ display: !checkedType ? 'inline' : 'none' }}>
                        <div className="sg-steps">Group details</div>
                      
                        <div className="gs-table-of-groups" >

                            <div className="sg-new-group-details"><b>Group name:</b>
                                <span className="sg-new-value">{inputNameRef.current.value}</span>
                                <b>Group description:</b>
                                <span className="sg-new-value">{inputDescRef.current.value}</span>
                            </div>
                       
                        </div>
                     
                    </div>



                    <div className={(step === 3 && !checkedType) || (checkedType && tableIndexChecked?.filter(c=>c.checked).length > 0) ? 'sg-div-enable' : 'sg-div-disable'  }>                

                        <div className="sg-steps">add or remove services from your selected group</div>
                        <div>
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item>{customList('Choices', left)}</Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center">
                                       
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedRight}
                                            disabled={leftChecked.length === 0 && !groupIDValue}
                                            aria-label="move selected right"
                                        >
                                            &gt;
                                        </Button>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCheckedLeft}
                                            disabled={rightChecked.length === 0 && !groupIDValue}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>{customList('Chosen', right)}</Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className={"sg-actions-buttons " + (step === 3 || checkedType ? 'sg-div-enable' : 'sg-div-disable')}>
                        <div className="sg-cancel-btn" style={{display: !checkedType ? 'inline' : 'none'}}>
                            <SCButton customClass="sg-custom-button" size="medium" text="Cancel" handleButtonClick={handleCancelButtonClick} />
                        </div>
                        <div className={!checkedType ? "sg-save-btn" : "sg-save-btn-edit" && tableIndexChecked?.filter(c=>c.checked).length === 0 ?'sg-div-disable' :'sg-div-enable' }>
                            <SCButton size="medium" text="Save" handleButtonClick={handleSaveButtonClick} />                           
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GroupServices
