import { Modal, Button } from "react-bootstrap";
import './LoaderModal.css';


const LoaderModal = ({ showModalLoader, setShowModalLoader, doForceInit }) => {


    const handleCloseModal = () => {
        setShowModalLoader(false)
        doForceInit()

    }


    return (
        <div>

            <Modal
                id="short-link-report-modal"
                backdrop="static" 
                keyboard={false}
                show={showModalLoader}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        Report
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>We're generating the report for this incident...</div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default LoaderModal
