import React, { useEffect, useState, useContext } from 'react'
import './SARenderComments.css';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import { TableContainer, TableHead,TableRow,TableCell,TableBody, Table, Paper } from '@mui/material';



const SARenderComments = () => {

    const {  comments } = useContext(DataContextAnalyzer);


    return (
                   
           
                <TableContainer component={Paper}>
                    <Table id="sa-analyzer-history-table-id" >
                        <TableHead>
                            <TableRow>
                                <TableCell className='sa-analyzer-comments-small-column'>DATE</TableCell>
                                <TableCell className='sa-analyzer-comments-small-column'>WHO</TableCell>
                                <TableCell className='sa-analyzer-comments-large-column'>COMMENT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                comments && comments.length > 0 &&
                                    (
                                        comments.map((comment, index) => (
                                            <TableRow>
                                                <TableCell>{comment.createdDate}</TableCell>
                                                <TableCell>{comment.createdBy}</TableCell>
                                                <TableCell>{comment.comment}</TableCell>
                                            </TableRow>
                                        ))
                                    ) 
                                   

                            }

                        </TableBody>
                    </Table>
                </TableContainer>
    

    );

}

export default SARenderComments