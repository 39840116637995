import React, { useEffect, useState, useContext } from "react";
import './SelfServiceTemplateType.css';
import WizardDataContext from "../../Context/WizardDataContext";




const SelfServiceTemplateType = () => {

    const { decision, setDecision } = useContext(WizardDataContext);


    const handleDecision = (type) =>{
        setDecision(type)
       
    }


    return (
        <React.Fragment>
            <div className="">On which platform would you like the service to be created</div>
            <div>
                <span onClick={() => handleDecision('aks')} className={decision === 'aks' ? 'ss-template-type-ask-eks-modal-item-selected' : 'ss-template-type-ask-eks-modal-item'} >AKS</span>
                <span onClick={() => handleDecision('eks')} className={decision === 'eks' ? 'ss-template-type-ask-eks-modal-item-selected' : 'ss-template-type-ask-eks-modal-item'}>EKS</span>
                <span onClick={() => handleDecision('both')} className={decision === 'both' ? 'ss-template-type-ask-eks-modal-item-selected' : 'ss-template-type-ask-eks-modal-item'}>Both</span>
            </div>
        </React.Fragment>
    )
}

export default SelfServiceTemplateType
