import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import './AdvancedSearch.css';
import SCTop from "../Components/SCTop";
import SCTable from "../Components/SCTable";
import DataContext from "../Context/DataContext";
import { useHistory } from 'react-router-dom';
import SCDropDownList from "../Components/SCDropDownList";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import _ from "lodash";


const AdvancedSearch = () => {
    const { services, getPackagesWithVersions, packagesWithVersions, getServicesByPackageAndVersion,
        setSelectedService, servicesByPackageAndVersion, setMenuSelectedItem, setCurrentPage } = useContext(DataContext);
    const history = useHistory();
    const [ddlPackages, setDdlPackages] = useState([]);
    const [ddlVersionPackages, setDdlVersionPackages] = useState([]);
    const [servicesItems, setServicesItems] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedVersionPackage, setSelectedVersionPackage] = useState('');


    const columnsItems = [{ display: true, name: "Service name", key: "ServiceName" },
    { display: true, name: "Branch", key: "Branch" },
    { display: true, name: "Project", key: "Project" },
    { display: true, name: "Repository", key: "Repository" }]


    useEffect(() => {

        setCurrentPage('advanced')
        getPackagesWithVersions()

    }, [])



    useEffect(() => {



        var packageList = []
        if (packagesWithVersions && packagesWithVersions.length > 0) {
            var orderedPackagesWithVersions = _.orderBy(packagesWithVersions, ['packageName'], ['asc']);

            orderedPackagesWithVersions.forEach(p => {
                packageList.push(p.packageName)
            });
            setDdlPackages(packageList);
        }


    }, [packagesWithVersions])



    useEffect(() => {


        if (packagesWithVersions && selectedPackage.newValue != '') {
            setDdlVersionPackages([]);
            setSelectedVersionPackage('')
            var versionList = []

            var versions = packagesWithVersions.filter(p => p?.packageName === selectedPackage?.newValue);
            versionList.push({ name: 'All', value: 'All' })

            versions.forEach(v => {

                v.versions.forEach(x => versionList.push({ name: x, value: x }));

            });
            setDdlVersionPackages([...versionList]);
        }


    }, [selectedPackage])

    useEffect(() => {


        var items = []

        if (servicesByPackageAndVersion && servicesByPackageAndVersion.length > 0) {
            //render table

            servicesByPackageAndVersion.forEach(s => {
                s.branches.forEach(b => {
                    b.projects.forEach(p => {
                        var item = {};
                        if (selectedVersionPackage != "All") {
                            item = { ServiceName: s.serviceName, Branch: b.branchName, Project: p, Repository: s.repositoryName }
                        }
                        else {
                            var htmlItem = [];
                            if (s?.packageProjectVersions) {
                                for (const [key, value] of Object.entries(s?.packageProjectVersions)) {
                                    htmlItem.push(<div key={key}><span className="adv-proj-name">{key}</span>
                                        <span className="adv-proj-value">: {value}</span></div>)
                                }

                            }
                            item = { ServiceName: s.serviceName, Branch: b.branchName, Project: htmlItem, Repository: s.repositoryName }
                        }
                        items.push(item);
                    })
                })
            })
            setServicesItems([...items])
        }

    }, [servicesByPackageAndVersion])



    const handleChange = (e) => {
        setSelectedVersionPackage(e.target.value);
    }



    useEffect(() => {



        if (selectedVersionPackage) {
            var packageName = selectedPackage.newValue;
            var packageVersion = selectedVersionPackage;
            if (selectedVersionPackage != 'All') {
                getServicesByPackageAndVersion(packageName, packageVersion)
            }
            else {
                getServicesByPackageAndVersion(packageName)
            }

        }

    }, [selectedVersionPackage])

    const handleRowClick = (e) => {

        var service = services.filter(s => s.name?.toLowerCase() === e.ServiceName?.toLowerCase()
            && s.repositoryName?.toLowerCase() === e.Repository?.toLowerCase())


        if (service && service.length > 0) {

            setSelectedService(service[0]);

            history.push({
                pathname: '/Service',
                search: '?name=' + service[0].name
            });
        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>There is no service page for this service.</div>
                </div>
            )
            toast.warn(<ErrorMsg />)

        }
    }

    return (
        <>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main">
                <div className='row adv-container'>
                    <div className='col-12'>


                        <Autocomplete
                            disablePortal

                            id="combo-box-demo"
                            options={ddlPackages}
                            sx={{ width: '400px' }}
                            renderInput={(params) => <TextField {...params} label="Select package" />}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setSelectedPackage({
                                        newValue,
                                    });
                                }
                            }}
                        />

                        <div className='input-container'>
                            <SCDropDownList className="adv-md-ddl" label="Select version" componentState={selectedVersionPackage} setComponentState={setSelectedVersionPackage}
                                disabled={Object.entries(selectedPackage).length === 0 ? true : false} size="small" items={ddlVersionPackages} handleChange={(e) => handleChange(e)} value={selectedVersionPackage} />
                        </div>

                        <div>

                            <div> <SCTable title="Services"
                                handleRowClick={(e) => handleRowClick(e)}
                                columnsItems={columnsItems} rowItems={servicesItems}
                                includesRemoveButton={false} includesSelectButton={false}
                            /> </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AdvancedSearch
