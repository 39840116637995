import React, { useEffect, useRef, useState, useContext } from 'react'
import './DynamicPermission.css';
import SCTop from "../Components/SCTop";
import * as consts from '../consts';
import axiosDynamicPermission from '../axiosDynamicPermission';
import SCDropDownList from '../Components/SCDropDownList';
import DataContext from "../Context/DataContext";
import _ from "lodash";
import SCButton from "../Components/SCButton";
import { toast } from 'react-toastify';
import SCTable from '../Components/SCTable';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DateTime, DateTime as luxon } from "luxon";
import PermissionDetailsModal from '../Modals/PermissionDetailsModal';
import SCDatePicker from '../Components/SCDatePicker';
import SCContainer from '../Components/SCContainer';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import useAxiosDynamicPermission from '../Hooks/useAxiosDynamicPermission';
import useAxios from '../Hooks/useAxios';

const DynamicPermission = () => {



    const [ddlSub, setDdlSub] = useState([])
    const [selectedSub, setSelectedSub] = useState('')

    const [ddlRG, setDdlRG] = useState([])
    const [selectedRG, setSelectedRG] = useState('')

    const [ddlResource, setDdlResource] = useState([])
    const [selectedResource, setSelectedResource] = useState('')


    const [ddlResourceRoles, setDdlResourceRoles] = useState([])
    const [selectedResourceRoles, setSelectedResourceRoles] = useState([])

    const [displayKVFields, setDisplayKVFields] = useState(false)


    /////////////KV/////////////////
    const [ddlKVCertificates, setDdlKVCertificates] = useState([])
    const [selectedKVCertificates, setSelectedKVCertificates] = useState([])

    const [ddlKVKeys, setDdlKVKeys] = useState([])
    const [selectedKVKeys, setSelectedKVKeys] = useState([])

    const [ddlKVSecrets, setDdlKVSecrets] = useState([])
    const [selectedKVSecrets, setSelectedKVSecrets] = useState([])
    /////////////KV//////////////////

    const [valueTo, setValueTo] = useState(luxon.local().plus({ 'minutes': 75 }))
    const [valueFrom, setValueFrom] = useState(luxon.local().plus({ 'minutes': 20 }))

    const [tabsValue, setTabsValue] = useState('0');

    const { token, setModalPermissionShow, modalPermissionShow, getMyRequests, getApprovalManagerDropDown, sortingAtoZ, writeConsole,
        listOfDepartments, getDepartment, setListOfDepartments, generatePermissionReqObject, setCurrentPage, setUserPermissionRequests } = useContext(DataContext);

    const [ddlHours, setDdlHours] = useState([])
    const [selectedHours, setSelectedHours] = useState(0)

    const [disableDatePicker, setDisableDatePicker] = useState(false)

    const [permissionRequstObject, setPermissionRequstObject] = useState({})

    const [ddlDeperatment, setDdlDeperatment] = useState([]);
    const [selectedManager, setSelectedManager] = useState("");
    const [myRequests, setMyRequests] = useState([])
    const [requestSubsItems, setRequestSubsItems] = useState({ status: null, data: [] });

    const [isPim, setIsPim] = useState(false)

    const tabListRef = useRef();
    const instance = useAxios(process.env.REACT_APP_BASE_URL);


    useEffect(() => {
        setCurrentPage('dynamic-permission')
    }, [])


    useEffect(async () => {
        getSub()
        refreshScheduleTable();
        buildDdlHours()
        getManagers();
    }, [])


    const getManagers = async () => {

        if (listOfDepartments && listOfDepartments.length > 0) {
            buildDdlManager(listOfDepartments);

        }

        getDepartment().then((response) => {

            if (response.data.length > 0) {
                setListOfDepartments(response.data);
                buildDdlManager(response.data);
            }
            else {
                setListOfDepartments([])
                setDdlDeperatment([])
            }
        }).catch((error) => {
            console.log('get department error', error);
        })
    }

    const buildDdlManager = (values) => {



        let managersDdl = getApprovalManagerDropDown();
        setDdlDeperatment(sortingAtoZ(managersDdl));


    }


    const buildDdlHours = () => {
        var hours = [];
        let values = [{ name: 'Between Dates', value: 0 }, { name: '1 Hour', value: 1 }, { name: '2 Hours', value: 2 }, { name: '5 Hours', value: 5 }, { name: '10 Hours', value: 10 }]
        values.map((h) => {
            hours.push({
                name: h.name,
                value: h.value
            })
        })
        setDdlHours(hours)

    }

    const initControllers = () => {
        setSelectedSub([])
        setSelectedRG([])
        setSelectedResource([])
        setSelectedResourceRoles([])
        setSelectedKVCertificates([])
        setSelectedKVKeys([])
        setSelectedKVSecrets([])
        setDisableDatePicker(false);
        setSelectedManager()
        setIsPim(false)
        setSelectedHours(0);
        setValueTo(luxon.local().plus({ 'minutes': 75 }))
        setValueFrom(luxon.local().plus({ 'minutes': 20 }))
    }

    const getSub = async () => {

        try {
            var subs = [];
            var subscriptionItems = { status: 0, data: [] };
            let response = await instance.get(consts.requests.getSub)
            if (response.data?.content?.length > 0) {
                response?.data?.content[0].subscriptions?.map((s) => {
                    subs.push({
                        name: s.name,
                        value: s.id
                    })
                })
                subscriptionItems = { status: response.status, data: sortingAtoZ(subs) };
                setRequestSubsItems(subscriptionItems)
            }
        }
        catch (error) {
            setRequestSubsItems({ status: 500, data: [] });
        }


    }



    const handleChangeSub = async (e) => {

        setDdlRG([])
        setSelectedSub(e.target.value);
        setDisplayKVFields(false);
        setSelectedRG();
        setSelectedResource();
        setSelectedResourceRoles([]);

        var url = consts.requests.getResourceTypes.replace('{subId}', e.target.value);

        try {

            let response = await instance.get(url)
            var resourceTypes = [];
            if (response.data?.content?.resourceTypes?.length > 0) {
                response?.data?.content?.resourceTypes?.map((rg) => {
                    resourceTypes.push({
                        name: rg,
                        value: rg
                    })
                })
                setDdlRG(sortingAtoZ(resourceTypes))
            }

        }
        catch (error) {

        }



    }

    const handleChangeRG = async (e) => {
        if(selectedRG === "Microsoft.KeyVault/vaults" && e.target.value !== "Microsoft.KeyVault/vaults"){
            setDisplayKVFields(false)
            setSelectedKVCertificates([])
            setSelectedKVKeys([])
            setSelectedKVSecrets([])
        }
        setSelectedRG(e.target.value)
        setSelectedResource('')
        setSelectedResourceRoles([])
        setDdlResourceRoles([])       
        setDdlResource([])

        var url = consts.requests.getResource.replace('{subId}', selectedSub).replace('{resourceType}', e.target.value);

        try {

            let response = await instance.get(url)
            var resource = [];
            if (response.data?.content?.resources?.length > 0) {

                response.data?.content?.resources?.map((r) => {
                    resource.push({
                        name: r.name,
                        value: r.id,
                        type: r.type,
                        location: r.location
                    })
                })
                setDdlResource(sortingAtoZ(resource))
            }
            else {
                handleDdlZeroItemsAlert('We didn\'t find any resource for the selected resource group.')

            }

        }
        catch (error) {
            console.log(error)
        }


    }

    const handleDdlZeroItemsAlert = (message) => {
        const WarnMsg = () => (
            <div>
                <div>{message}</div>
            </div>
        )
        toast.warn(<WarnMsg />)
    }

    const handleChangeResource = async (e) => {

        setSelectedResourceRoles([])
        setSelectedResource(e.target.value)
        var url = consts.requests.getResourceRoles.replace('{resourceId}', e.target.value);

        try {

            let response = await instance.get(url)
            var roles = [];
         
         
            if (selectedRG === "Microsoft.KeyVault/vaults" ) {                
                    setSelectedKVCertificates([])
                    setSelectedKVKeys([])
                    setSelectedKVSecrets([])
                    setDisplayKVFields(true)
                    generateKVComponents(response.data)
                               
            }
         
            var roleDefinitions = getRolesDefinitions(response.data?.content);
            if (roleDefinitions != null) {
                roleDefinitions.map((r) => {
                    roles.push({
                        name: r.name,
                        value: r.name
                    })
                })
                setDdlResourceRoles(sortingAtoZ(roles))
            }
            else {
                handleDdlZeroItemsAlert('We didn\'t find any resource roles for the selected resource.')
            }

        }
        catch (error) {
            console.log(error)
        }


    }

    const getRolesDefinitions = (content) => {
        if (content.roleDefinitions !== undefined) {
            if (content.roleDefinitions.length > 0)
                return content.roleDefinitions;
        }
        if (content.RoleDefinitions !== undefined) {
            if (content.RoleDefinitions.length > 0)
                return content.RoleDefinitions;
        }
        return null; // Property not found in either case

    }

    const generateKVComponents = (data) => {
        ////////Certificates/////////
        var certificates = [];
        data?.content?.KeyVaultAccessPolicy?.certificates?.map((c) => {
            certificates.push({
                name: c,
                value: c
            })
        })
        setDdlKVCertificates(certificates);

        ////////Keys/////////
        var keys = [];
        data?.content?.KeyVaultAccessPolicy?.keys?.map((k) => {
            keys.push({
                name: k,
                value: k
            })
        })
        setDdlKVKeys(keys)
        
        ////////Secrets/////////
        var secrets = [];
        data?.content?.KeyVaultAccessPolicy?.secrets?.map((s) => {
            secrets.push({
                name: s,
                value: s
            })
        })
        setDdlKVSecrets(sortingAtoZ(secrets))
    }
    const handleChangeKVCertificates = (e) => {
        setSelectedKVCertificates(e.target.value)
    }
    const handleChangeKVKeys = (e) => {
        setSelectedKVKeys(e.target.value)
    }
    const handleChangeKVSecrets = (e) => {
        setSelectedKVSecrets(e.target.value)
    }

    const handleChangeHours = (e) => {
        setSelectedHours(e.target.value)
        if (e.target.value > 0) {
            setDisableDatePicker(true)
        }
        else {
            setDisableDatePicker(false)
        }
    }

    const handleChangeResourceRoles = (e) => {

        setSelectedResourceRoles(e.target.value)
    }

    const dateErrorMessage = (message) => {

        const ErrorMsg = () => (
            <div>
                <div>{message}</div>
            </div>
        )
        toast.error(<ErrorMsg />)
    }

    const handleFromChange = (newValue) => {

        const dt = luxon.fromISO(newValue.toISOString());
        let fromDate = dt

        setValueFrom(fromDate);
    };
    const handleToChange = (newValue) => {

        const dt = luxon.fromISO(newValue.toISOString());
        let toDate = dt

        if (valueFrom > toDate) {
            dateErrorMessage('The start date is greater than end date (We\'re working 24-hour clock)');
        }
        else {
            setValueTo(toDate);
        }
    };



    const handleCreateRequest = async () => {
        if (selectedSub !== '' && selectedResource !== ''
            && selectedResourceRoles.length > 0 && selectedRG !== '') {
            let accessPolicy = {
                certificates: selectedKVCertificates,
                keys: selectedKVKeys,
                secrets: selectedKVSecrets,
            }

            var d1 = luxon.fromISO(DateTime.now())//.plus({ minutes: 15 })//.toFormat('dd/MM/yyyy HH:mm')
            var d2 = luxon.fromISO(valueFrom)//.toFormat('dd/MM/yyyy HH:mm')

            const diff = d2.diff(d1, ["years", "months", "days", "hours", "minutes"])



            if (!disableDatePicker && !isPim) {



                if (diff.minutes < 15 && diff.days <= 0 && diff.years <= 0
                    && diff.months <= 0 && diff.hours <= 0
                ) {
                    dateErrorMessage('The start time should be at least 15 min further from the current time');
                    return;
                }


                if (valueFrom > valueTo) {
                    dateErrorMessage('The end date must be further from the start date');
                    return;
                }
            }
            setUserPermissionRequests({ status: 0, data: [] })


            let grantDateTime =  disableDatePicker ? luxon.local().plus({ 'minutes': 20 }) : valueFrom
            let revokeDateTime = disableDatePicker ? luxon.local().plus({ 'minutes': 20 }).plus({ 'hours': selectedHours }) : valueTo

          //  const inputDate = DateTime.fromJSDate(grantDateTime);

          var outputDateGranted = null;
          var outputDateRevoked = null;

          outputDateGranted = DateTime.fromISO(grantDateTime, { zone: 'utc' });
          outputDateRevoked = DateTime.fromISO(revokeDateTime, { zone: 'utc' });

       

      
            
            try {
                let response = await instance.post(consts.requests.createSchedule, {
                    resourceId: selectedResource,
                    isPim: isPim,
                    grantDateTime: outputDateGranted.toISO(),
                    revokeDateTime: outputDateRevoked.toISO(),
                    roles: selectedResourceRoles,
                    accessPolicy: accessPolicy,
                    managerEmail: selectedManager

                })
                if (response?.status === 200) {
                    await refreshScheduleTable()
                    initControllers()
                    handleTabChange(null, "1")
                    const SuccessMsg = () => (
                        <div>
                            <div>New permission request was created successfully.</div>
                        </div>
                    )
                    toast.success(<SuccessMsg />)
                }


           


            }
            catch (error) {
                console.log(error.name)
                setUserPermissionRequests({ status: 500, data: [] })
                const ErrorMsg = () => (
                    <div>
                        <div>{error.message}</div>
                    </div>
                )
                toast.error(<ErrorMsg />)
            }


        }

    }



    const columnsItems = [
        { display: true, name: "Url", key: "url", width: "10%" },
        { display: true, name: "Status", key: "status", width: "15%" },
        { display: true, name: "Start On", key: "startOn", width: "15%" },
        { display: true, name: "End On", key: "endOn", width: "15%" },
        { display: true, name: "Resource", key: "resource", width: "15%" },
        { display: true, name: "RequestId", key: "requestId", width: "15%" },
        { display: true, name: "Is Pim", key: "isPim", width: "10%" }]

    const [requestItems, setRequestItems] = useState([]);
    const [radioValue, setRadioValue] = React.useState('');


    const handleRequestRemove = async (requestId) => {

        setUserPermissionRequests({ status: 0, data: [] })
        const url = consts.requests.deleteRequest.toString().replace('{requestId}', requestId);

        try {

            let response = await instance.delete(url);
            if (response?.status === 200) {

                var items = [];
                var allItems = [];



                items = [...requestItems];
                allItems = [...myRequests]

                _.remove(items, {
                    requestId: requestId
                });

                _.remove(allItems, {
                    requestId: requestId
                });



                setRequestItems([...items])
                setMyRequests([...allItems])
                setUserPermissionRequests({ status: response.status, data: items })


                const SuccessMsg = () => (
                    <div>
                        <div>Request was removed successfully.</div>
                    </div>
                )
                toast.success(<SuccessMsg />)
            }


        }
        catch (error) {
            setUserPermissionRequests({ status: 500, data: [] })
            console.log(error);
        }

    }




    const formatDateTime = (date) => {
        return luxon.fromISO(date).toFormat('dd/MM/yyyy HH:mm')
    }


    const refreshScheduleTable = async () => {
        setRadioValue('InProgress')


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const reqId = urlParams.get('reqId')

        if (reqId) {
            let res = await getMyRequests();
            if (res) {

                let modalObj = res.data?.content?.filter(r => r.requestId == reqId);
                if (modalObj) {

                    let obj = generatePermissionReqObject(modalObj[0])

                    setModalPermissionShow(true)
                    setPermissionRequstObject(obj)


                }
            }

        }

    }

    const handleReq = (response, type) => {

        let tableValues = [];
        if (response?.length > 0) {
            response?.map((sc) => {


                let obj = {
                    url: consts.AZURE_BASE_RESOURCE_URL + sc.resourceId,
                    status: sc.status,
                    startOn: formatDateTime(sc.grantOn),
                    endOn: formatDateTime(sc.revokeOn),
                    resource: sc.resourceName,
                    requestId: sc.requestId,
                    isPim: sc.isPim ? 'True' : 'False',
                    createdOn: sc.createdOn
                }
                tableValues.push(obj);

            })
        }
        
        tableValues.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));


        setRequestItems([...tableValues])
        if (type !== "History") {
            setUserPermissionRequests({ status: 200, data: tableValues })
        }




    }


    const handleRadioChange = async (e) => {
        setRadioValue(e.target.value);

        let reqs = myRequests;
        if (myRequests && myRequests.length === 0) {
            let res = await getMyRequests();
            setMyRequests(res?.data?.content)
            reqs = res?.data?.content;
        }



        let resultsArr = [];

        if (e.target.value === 'History') {
            resultsArr = reqs.filter(c => c.status.toLowerCase() !== 'scheduled' && c.status.toLowerCase() !== 'awaitingapproval' && c.status.toLowerCase() !== 'granted');
        }
        else {
            resultsArr = reqs.filter(c => c.status.toLowerCase() === 'scheduled' || c.status.toLowerCase() === 'awaitingapproval' || c.status.toLowerCase() === 'granted');
        }
        resultsArr = _.orderBy(resultsArr, i => i.grantOn, 'desc');
        handleReq(resultsArr, e.target.value)

    }

    const handleTabChange = async (event, newValue) => {

        setTabsValue(newValue);

        if (newValue == 1) {
            setRadioValue('InProgress');
            refreshInProgressTab(newValue, false)           
        }


    }
    const showToast = () =>{
        const SuccessMsg = () => (
            <div>
                <div>The permission has been successfully renewed</div>
            </div>
        )
        toast.success(<SuccessMsg />)
    }
    const refreshInProgressTab = async (value, withSuccess) =>{
        let res = await getMyRequests();

        if(withSuccess){
            showToast();
        }

        setMyRequests(res?.data?.content);
        let reqs = res?.data?.content;

        let resultsArr = [];
        if (value == 1) {                
            resultsArr = reqs.filter(c => c.status.toLowerCase() === 'scheduled' 
                                       || c.status.toLowerCase() === 'awaitingapproval' 
                                       || c.status.toLowerCase() === 'granted'                                           
                                       );
        }
        else {

            resultsArr = reqs.filter(c => c.status.toLowerCase() !== 'scheduled' && c.status.toLowerCase() !== 'awaitingapproval' && c.status.toLowerCase() !== 'granted');
        }

       

        resultsArr = _.orderBy(resultsArr, i => i.grantOn, 'desc');
        handleReq(resultsArr, "")
    }

    const handleChangeManager = (e) => {
        setSelectedManager(e.target.value);


    }

    const handleCheckBoxClick = (e) => {

        let checked = isPim
        setIsPim(!checked)
    }

    const handleRenew = async (reqId) => {
    
        try {

            let response = await instance.post(consts.requests.createSchedule, {
                requestId: reqId,
                isPim: true
            })
            if (response?.status === 200) {
                console.log('response - after renewal',response)
                //get request again
               
                setRadioValue('InProgress');
                refreshInProgressTab(1, true)    
            }
        }
        catch (error) {
            console.log(error.name)
            setUserPermissionRequests({ status: 500, data: [] })
            const ErrorMsg = () => (
                <div>
                    <div>{error.message}</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }




    }

    const getPimExpressionTime = (date) => {


        const originalDateTime = DateTime.fromISO(date);
        const newDateTime = originalDateTime.plus({ days: 90 });
        return formatDateTime(newDateTime)

    }

    const isUserHasActivePrmission = (start, end) => {

        if(start === '01/01/0001 00:00' || end === '01/01/0001 00:00'){
            return false
        }

        // Get the current date and time
        const currentDate = DateTime.now();

        // Define a date range (start and end dates)
        const startDate = DateTime.fromFormat(start, 'dd/MM/yyyy HH:mm');
        const endDate = DateTime.fromFormat(end, 'dd/MM/yyyy HH:mm');
        

        // Check if the current date is between the date range
        const isBetween = currentDate >= startDate && currentDate <= endDate;
        return isBetween;
    }

    const RederTableNoDataFound = () => {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ACTIONS</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>START ON</TableCell>
                            <TableCell>END ON</TableCell>
                            <TableCell>RESOURCE</TableCell>
                            <TableCell>REQUESTID</TableCell>
                            <TableCell>RENEWAL EXPIRED</TableCell>
                            <TableCell>RENEWAL REQUEST</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={9}>No data found</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    const RenderInProgressTable = ({ items }) => {


        if (!items || items.length === 0) {
            return (
                <RederTableNoDataFound />
            )
        }
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ACTIONS</TableCell>
                            <TableCell>URL</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>START ON</TableCell>
                            <TableCell>END ON</TableCell>
                            <TableCell>RESOURCE</TableCell>
                            <TableCell>REQUESTID</TableCell>
                            <TableCell>RENEWAL EXPIRED</TableCell>
                            <TableCell>RENEWAL REQUEST</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !items || items.length === 0 ?
                                (
                                    <TableRow className='anomaly-alert-table-row' >
                                        <TableCell colSpan={7}>There is no items in progress</TableCell>
                                    </TableRow>
                                ) :
                                (
                                    items.map((value, innerIndex) => (
                                        <TableRow id={'sc-row-' + innerIndex} className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            {
                                                value.status.toLowerCase() === 'awaitingapproval' || value.status.toLowerCase() === 'scheduled' ?
                                                    (
                                                        <TableCell id={'sc-td-value-remove-row-' + innerIndex}><span className='dp-renew-button' onClick={() => handleRequestRemove(value.requestId)} >Remove</span> </TableCell>
                                                    ) :
                                                    (
                                                        <TableCell id={'sc-td-value-remove-disable-row-' + innerIndex}><span className='dp-renew-button-disable' >Remove</span> </TableCell>
                                                    )
                                            }

                                            <TableCell id={'sc-td-value-url-row-' + innerIndex}><a href={value.url} target='_blank'>Click here</a></TableCell>
                                            <TableCell id={'sc-td-value-status-row-' + innerIndex}>{value.status}</TableCell>
                                            <TableCell id={'sc-td-value-startOn-row-' + innerIndex}>{value.startOn}</TableCell>
                                            <TableCell id={'sc-td-value-endOn-row-' + innerIndex}>{value.endOn}</TableCell>
                                            <TableCell id={'sc-td-value-resource-row-' + innerIndex}>{value.resource}</TableCell>
                                            <TableCell id={'sc-td-value-requestId-row-' + innerIndex}>{value.requestId}</TableCell>

                                            {
                                                value.isPim === 'True' ?
                                                    (
                                                        <React.Fragment>
                                                            <TableCell  id={'sc-td-value-exp-date-row-' + innerIndex}>{getPimExpressionTime(value.createdOn)}</TableCell>
                                                            {
                                                                value.status.toLowerCase() === 'granted' && !isUserHasActivePrmission(value.startOn, value.endOn) ?
                                                                    (
                                                                        <TableCell id={'sc-td-value-renew-row-' + innerIndex}><span className='dp-renew-button' onClick={() => handleRenew(value.requestId)}>Renewal Request</span></TableCell>                                                                        
                                                                    ) :
                                                                    (
                                                                        <TableCell id={'sc-td-value-renew-disable-row-' + innerIndex}><span className='dp-renew-button-disable' >Renewal Request</span></TableCell>
                                                                    )
                                                            }
                                                        </React.Fragment>
                                                    ) :
                                                    (
                                                        <React.Fragment>
                                                            <TableCell ></TableCell>
                                                            <TableCell ></TableCell>
                                                        </React.Fragment>
                                                    )
                                            }

                                        </TableRow>
                                    ))
                                )



                        }

                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const RenderHistoryTable = ({ items }) => {



        if (!items || items.length === 0) {
            return (
                <RederTableNoDataFound />
            )
        }

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>START ON</TableCell>
                            <TableCell>END ON</TableCell>
                            <TableCell>RESOURCE</TableCell>
                            <TableCell>REQUESTID</TableCell>
                            <TableCell>RENEWAL EXPIRED</TableCell>
                            <TableCell>RENEWAL REQUEST</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            !items || items.length === 0 ?
                                (
                                    <TableRow className='anomaly-alert-table-row' >
                                        <TableCell colSpan={7}>There is no items in the history table</TableCell>
                                    </TableRow>
                                ) :
                                (
                                    items.map((value, innerIndex) => (
                                        <TableRow id={'sc-row-' + innerIndex} className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            <TableCell id={'sc-td-value-url-row-' + innerIndex} ><a href={value.url} target='_blank'>Click here</a></TableCell>
                                            <TableCell id={'sc-td-value-status-row-' + innerIndex}>{value.status}</TableCell>
                                            <TableCell id={'sc-td-value-startOn-row-' + innerIndex}>{value.startOn}</TableCell>
                                            <TableCell id={'sc-td-value-endOn-row-' + innerIndex}>{value.endOn}</TableCell>
                                            <TableCell id={'sc-td-value-resource-row-' + innerIndex}>{value.resource}</TableCell>
                                            <TableCell id={'sc-td-value-requestId-row-' + innerIndex}>{value.requestId}</TableCell>
                                            {
                                                value.isPim === 'True' ?
                                                    (
                                                        <React.Fragment>
                                                            <TableCell id={'sc-td-value-createdOn-row-' + innerIndex}>{getPimExpressionTime(value.createdOn)}</TableCell>
                                                            {
                                                                value.status.toLowerCase() === 'revoked' && !isUserHasActivePrmission(value.startOn, value.endOn) ?
                                                                    (
                                                                        <TableCell id={'sc-td-value-renew-row-' + innerIndex}><span className='dp-renew-button' onClick={() => handleRenew(value.requestId)}>Renewal Request</span></TableCell>                                                                        
                                                                    ) :
                                                                    (
                                                                        <TableCell id={'sc-td-value-renew-disable-row-' + innerIndex}><span className='dp-renew-button-disable' >Renewal Request</span></TableCell>
                                                                    )
                                                            }
                                                        </React.Fragment>
                                                    ) :
                                                    (
                                                        <React.Fragment>
                                                            <TableCell ></TableCell>
                                                            <TableCell ></TableCell>
                                                        </React.Fragment>
                                                    )
                                            }
                                        </TableRow>
                                    ))
                                )



                        }

                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    return (
        <React.Fragment>

            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main">

                <Box sx={{ width: '100%', typography: 'body1' }} style={{ paddingLeft: '10px' }}>
                    <TabContext value={tabsValue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                            <TabList ref={tabListRef} onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab className="sc-tab" label="Create Request" value="0" id="dp-create-requests-tab" />
                                <Tab className="sc-tab" label="View Requests" value="1" id="dp-view-requests-tab" />
                            </TabList>
                        </Box>
                        <TabPanel value="0">
                            <div>
                                <div style={{ marginLeft: '5px' }}>

                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={(e) => handleCheckBoxClick(e)} />} label="Choose this option if you need the possibility of renewing the permission every day for a long period of time" />
                                    </FormGroup>

                                    <SCContainer children={

                                        <SCDropDownList id="ddl-dp-sub" className="md-ddl-edit" label="Subscription" componentState={selectedSub} setComponentState={setSelectedSub}
                                            size="1000" items={requestSubsItems.data} handleChange={(e) => handleChangeSub(e)} value={selectedSub} />

                                    } loading={requestSubsItems.status} >

                                    </SCContainer>


                                    <SCDropDownList id="ddl-dp-rg" className="md-ddl-edit" label="Resource Type" componentState={selectedRG} setComponentState={setSelectedRG}
                                        size="1000" items={ddlRG} handleChange={(e) => handleChangeRG(e)} value={selectedRG} />

                                    <SCDropDownList id="ddl-dp-resource" className="md-ddl-edit" label="Resource" componentState={selectedResource} setComponentState={setSelectedResource}
                                        size="1000" items={ddlResource} handleChange={(e) => handleChangeResource(e)} value={selectedResource} />

                                    <SCDropDownList id="ddl-dp-roles" helperText="*Multichoice field (use Esc to keep the changes)" multiple={true} className="md-ddl-edit" label="Resource Roles" componentState={selectedResourceRoles} setComponentState={setSelectedResourceRoles}
                                        size="1000" items={ddlResourceRoles} handleChange={(e) => handleChangeResourceRoles(e)} value={selectedResourceRoles} />

                                    {
                                        displayKVFields ?
                                            (
                                                <div>
                                                   
                                                 <SCDropDownList id="ddl-dp-certificates-kv" helperText="*Multichoice field (use Esc to keep the changes)" multiple={true} className="md-ddl-edit" label="Certificates" componentState={selectedKVCertificates} setComponentState={setSelectedKVCertificates}
                                                         size="1000" items={ddlKVCertificates} handleChange={(e) => handleChangeKVCertificates(e)} value={selectedKVCertificates} />
                                                     <SCDropDownList id="ddl-dp-keys-kv" helperText="*Multichoice field (use Esc to keep the changes)" multiple={true} className="md-ddl-edit" label="Keys" componentState={selectedKVKeys} setComponentState={setSelectedKVKeys}
                                                        size="1000" items={ddlKVKeys} handleChange={(e) => handleChangeKVKeys(e)} value={selectedKVKeys} />
                                                  <SCDropDownList id="ddl-dp-secrets-kv" helperText="*Multichoice field (use Esc to keep the changes)" multiple={true} className="md-ddl-edit" label="Secrets" componentState={selectedKVSecrets} setComponentState={setSelectedKVSecrets}
                                                        size="1000" items={ddlKVSecrets} handleChange={(e) => handleChangeKVSecrets(e)} value={selectedKVSecrets} />
                                            
                                                </div>
                                            ) :
                                            (
                                                <div></div>
                                            )
                                    }

                                </div>
                                <div style={{ paddingTop: '20px', paddingLeft: '5px' }}>

                                    <div>
                                        <SCDropDownList id="ddl-dp-manager" className="md-ddl-edit" label="Select Approval Manager" componentState={selectedManager} setComponentState={setSelectedManager}
                                            size="1000" items={ddlDeperatment} handleChange={(e) => handleChangeManager(e)} value={selectedManager} />

                                    </div>

                                    <div className={isPim ? 'dp-dates-container' : ''}  >

                                        <div>

                                            <SCDropDownList id="ddl-dp-permission-for" helperText="" multiple={false} className="md-ddl-edit" label="Permission For" componentState={selectedHours}
                                                setComponentState={setSelectedHours}
                                                size="1000" items={ddlHours} handleChange={(e) => handleChangeHours(e)} value={selectedHours} />

                                        </div>
                                        <div>&nbsp;</div>
                                        <div className='sc-datepicker-component-container sc-space-right'>
                                            <SCDatePicker testid="permission-from-date" id="permission-from-date"  disabled={disableDatePicker} disablePast={true} autoOk={true} setValue={setValueFrom} value={valueFrom}
                                                defaultValue={valueFrom} title="From Date" />
                                        </div>
                                        <div className='sc-datepicker-component-container'>
                                            <SCDatePicker testid="permission-to-date" id="permission-to-date"  disabled={disableDatePicker} disablePast={true} autoOk={true} setValue={setValueTo} value={valueTo}
                                                defaultValue={valueTo} title="To Date"
                                                minDate={
                                                    luxon.fromISO(valueFrom)
                                                }
                                                maxDate={
                                                    luxon.fromISO(valueFrom).plus({ days: 13 })
                                                }
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div style={{ marginTop: '20px', marginLeft: '5px' }}>
                                    <SCButton buttonId="dynamicPrmissionCreateButton" size="medium" text="Create Request" handleButtonClick={handleCreateRequest} />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value="1">

                            <div>

                                <div>
                                    <FormControl sx={{ m: 3 }} variant="standard">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-error-radios"
                                            name="quiz"
                                            value={radioValue}
                                            onChange={handleRadioChange}
                                        >
                                            <FormControlLabel id="dp-radio-req-in-progress" value="InProgress" control={<Radio  />} label="Requests in progress" />
                                            <FormControlLabel id="dp-radio-history" value="History" control={<Radio  />} label="History" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {
                                    radioValue === 'History' ?
                                        (
                                            <RenderHistoryTable items={requestItems} />
                                        ) :
                                        (
                                            <RenderInProgressTable items={requestItems} />
                                        )
                                }


                            </div>
                        </TabPanel>

                    </TabContext>
                </Box>
            </div>
            <PermissionDetailsModal show={modalPermissionShow}
                object={permissionRequstObject}
                setModalPermissionShow={setModalPermissionShow} />
        </React.Fragment>
    )
}

export default DynamicPermission
