import DataContext from "../Context/DataContext";
import { useEffect, useState, useContext, useCallback } from "react";
import './DebuggerAI.css';
import SCTable from './SCTable';
import * as consts from '../consts';
import axios from '../axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import openai from '../Images/openai.svg';
import Tooltip from '@mui/material/Tooltip';
import AIModal from '../Modals/AIModal';
import { toast } from 'react-toastify';
import _ from "lodash";
import * as DOMPurify from 'dompurify';
import { v4 as uuid } from 'uuid';



const DebuggerAI = () => {

    const { selectedService, token, aiMessages, setAIMessages, htmlContent, setHtmlContent } = useContext(DataContext);
    const [rows, setRows] = useState([]);
    const [aiModalShow, setAIModalShow] = useState(false)
    const welcome = "Hi " + localStorage.getItem('InfraConsoleName') + ", I’m Talmi, your AI developer assistant from the JET Platform Infrastructure team. I have been created by Michael Assouline and Tal Orlinsky in only 2 days and i’m the winner the JET Hackhaton 2023 (Tx to my predictive capabilities. But let’s stop talking about me, i’m here to to help you."

    useEffect(() => {

        if (token) {
            getServiceError();
        }

    }, [selectedService, token])



    const getServiceError = () => {
        if (token) {

            let url = consts.requests.getServiceNewRelicError.toString().replace('{newRelicName}'
                , selectedService?.name);
            axios.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {

                if (response?.data?.length > 0) {
                    let items = [];
                    response.data.forEach(m => {
                        var item = {
                            messageId: m.errorID, message: m.message, isUserClick: false,
                            shortMessage: m.message.length > 200 ?
                                m.message.substring(0, 200) + '...' :
                                m.message.substring(0, 200)
                        }
                        items.push(item);
                    });
                    setRows(items);



                }
                else {
                    setRows([])
                }

            }).catch((error) => { })
        }
    }

    useEffect(() => {
        if (aiMessages && aiMessages.length > 0) {

            //Array of questions

            if (aiMessages[aiMessages.length - 1].messageRole === 'user') {
                postAIRequest();
            }

        }

    }, [aiMessages])

    const postAIRequest = () => {
        const url = consts.requests.getOpenAIResults.replace('{serviceId}', selectedService?.id);
        let fw = "";
        if (selectedService?.projects && selectedService?.projects?.length > 0) {
            fw = selectedService?.projects[0].targetFramework
        }

        const sanitizedData = aiMessages.map(item => ({
            ...item,
            messageContent: DOMPurify.sanitize(item.messageContent)
        }));



        axios.post(url, {

            messages: sanitizedData,
            techstack: "c#, " + selectedService?.functionsWorkerRuntime + ', ' + fw,
            applicationType: "webapi",
            deploymentResourceType: selectedService?.serviceType,
            deploymentCloudProvider: "Azure"
        }, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {

            // changeAIButtonUI(item.messageId)
            if (response?.data && response?.data.length > 0) {
                let tempArr = [...htmlContent];
                tempArr.push({
                    type: 'text',
                    message: response?.data[response?.data?.length - 1].messageContent
                })
                setHtmlContent(tempArr)
                setAIModalShow(true)
           
                          
                setAIMessages(response?.data.filter(d => d.messageRole !== 'system'))

            }
            else {
                if (response?.data && response?.data.length === 0) {
                    const ErrorMsg = () => (
                        <div>
                            <div>Solution's API return an empty array</div>
                        </div>
                    )
                    toast.error(<ErrorMsg />)
                }
            }

        })
    }

    const handleAiClick = (item) => {


        if (token) {
            setAIMessages([])
            let messagesArr = []
        
            messagesArr.push({
                iD: uuid(),
                messageContent: welcome,
                messageRole: "assistant"
            });

            let message = encodeURIComponent(item.message);
            messagesArr.push({ messageRole: 'user', messageContent: message });
            setAIMessages(messagesArr);


        }

    }

  

    return (
        <div>
            <TableContainer component={Paper} className="ai-table">
                <Table sx={{ maxWidth: "90%" }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>

                            <TableCell>Error</TableCell>
                            <TableCell>Ask OpenAI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.messageId} >
                                <TableCell style={{ width: "90%" }} >
                                    <Tooltip title={row.message}>
                                        <span>{row.shortMessage}</span>
                                    </Tooltip>
                                </TableCell>
                                {
                                    row.isUserClick ?
                                        (
                                            <TableCell style={{ width: "10%" }} className="ai-button-used" >
                                                <img src={openai} alt='icon' className='type-icon-ai' />
                                            </TableCell>
                                        ) :
                                        (
                                            <TableCell style={{ width: "10%" }} className="ai-button" onClick={() => handleAiClick(row)}>
                                                <img src={openai} alt='icon' className='type-icon-ai' />
                                            </TableCell>
                                        )
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AIModal show={aiModalShow}
                setAIModalShow={setAIModalShow} />
        </div>
    )
}

export default DebuggerAI
