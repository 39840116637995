import React, { useState } from 'react'
import { Container, Navbar, NavDropdown, Nav, Form, FormControl, Button, NavItem } from 'react-bootstrap';
import DataContext from "../Context/DataContext";
import { useContext } from "react";
import { Link } from 'react-router-dom';
import DependencyGraph from '../DependencyGraph/DependencyGraph';
import { FaBars } from 'react-icons/fa';
import _ from "lodash";


const Navigation = ({ title, handleGroupByChange, setModalShow, handleSearch, handleReset }) => {
  const { services, setSideBarCollapsed, sideBarCollapsed, setServices, servicesCached, searchText, setSearchText } = useContext(DataContext);

  const [resultsFound, setResultsFound] = useState(-1)


  const handleSearchChanges = (e) => {
    setSearchText(e)
    var searchServices = [...servicesCached];
    var byName = _.filter(searchServices, (item) => {
      return item.name.toLowerCase().includes(e.toLowerCase()) ||
        item.metadata?.description?.toLowerCase().includes(e.toLowerCase())
    })

    var byProjectName = _.filter(searchServices, (item) => {
      return item.projects?.filter(p => p.name.toLowerCase().includes(e.toLowerCase())).length > 0
    })

    mergeSearchResults(byName, byProjectName, e)

  
  }
  const mergeSearchResults = (arrByName, arrByProject, text) => {

    var mergedArray = [...arrByName, ...arrByProject];
    const filtered = mergedArray.filter((v, i) => mergedArray.indexOf(v) === i)
    setServices([...filtered])
    if (text === '') {
      setServices([...servicesCached])
      setResultsFound(-1)
    }
    else {
      var resultsMessage = filtered.length === 0 ? 'No result was found' : filtered.length > 1 ? filtered.length + ' services were found.' : filtered.length + ' serivce was found';
      setResultsFound(resultsMessage)
    }

  }

  return (
    <div>

      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand className="navbar-header">

            <div className='row'>
              <div className='col-4 float-left'>
                <button className="navbar-toggler d-block  mb-3" type={'button'} onClick={() => setSideBarCollapsed(!sideBarCollapsed)} >
                  <FaBars size={22} />
                </button></div>
              <div className='col-4 float-left' >
                <Link className='nav-title' to="/" >INFRASTRUCTURE NUGET'S MASTER</Link>
              </div>
              <div className='col-4 float-left'>
                <Form className="d-flex search-flex">
                  <Form.Group className="mb-3 remove-md-3"  controlId="formBasicSearch">                  
                  <FormControl
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => handleSearchChanges(e.target.value)}
                  />
                    <Form.Text className="text-results" style={{ display: resultsFound < 0 ? 'none' : 'block' }}>
                    {resultsFound}
                    </Form.Text>
                  </Form.Group>
                
                  {/*<div className='col-sm float-left' style={{ textAlign: 'left', paddingLeft: '5px', display: resultsFound < 0 ? 'none' : 'block' }}>{resultsFound}</div>*/}
                </Form>
              </div>
            </div>

          </Navbar.Brand>{/* Infrasrtucture Packages Master*/}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            />




          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Navigation