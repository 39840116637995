
import DataContext from '../Context/DataContext';
import React from 'react';
import { useContext, useEffect, useState, useMemo } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaCodeBranch, FaLayerGroup, FaStickyNote, FaListOl, FaHome, FaSignInAlt, FaSignOutAlt, FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import _ from "lodash";
import './SideMenuBar.css';
import { useHistory } from 'react-router-dom';
import home from '../Images/home.png';
import nodes from '../Images/nodes.png';
import vector from '../Images/vector.png';
import group from '../Images/group.png';
import logs from '../Images/logs.png';
import login from '../Images/login.png';
import logout from '../Images/logout.png';
import grid from '../Images/grid.png';
import list from '../Images/list.png';
import user from '../Images/user.svg';
import users from '../Images/users.svg';
import dynamic from '../Images/dynamic.svg';
import autocicd from '../Images/autocicd.svg';
import cronjob from '../Images/cronjob.svg';
import analyzer from '../Images/analyzer.svg';
import SearchIcon from '@mui/icons-material/Search';
import { FaGlobeAmericas } from 'react-icons/fa';
import groups from '../Images/groups.png';
import SCCheckBox from '../Components/SCCheckBox';
import { Checkbox } from '@mui/material';
import SCNotification from '../Components/SCNotification';
import bell from '../Images/bell.svg';





const SideMenuBar = () => {
  const { sideBarCollapsed, isEditorUser, isAdminUser, menuSelectedItem, manageFilter, setManageFilter, services, setServices, servicesCached,
    setBreadcrumbs, breadcrumbs, token, handleBreadCrumbs, setModalNotificationShow, setViewMode, userAccount, handleLogout, handleNavigation, page, setPage } = useContext(DataContext);
  const history = useHistory();


  const handleClick = () => {

    //  refreshService();
  }


  useEffect(() => {

    setPage({ name: 'home', route: '' })

  }, [userAccount])


  const openWebSite = (site) => {
    switch (site) {


      case 'WIKI':
        {
          window.open(process.env.REACT_APP_WIKI, '_blank')
          break;
        }
      case 'PACK':
        {
          window.open(process.env.REACT_APP_PACK, '_blank')
          break;
        }
      case 'SQ':
        {
          window.open(process.env.REACT_APP_SQ, '_blank')
          break;
        }
      case 'NR':
        {
          window.open(process.env.REACT_APP_NR, '_blank')
          break;
        }

      default:
        return 'center'
    }
  }

  useEffect(() => {
    if (Object.entries(servicesCached).length > 0) {

      let filterElements = manageFilter.filter(m => m.type === 'checked')

      let filterServices = [].concat(servicesCached).filter((item) => {//serviceType

        for (var key in filterElements) {
          if (item.serviceType === filterElements[key].name)
            return true;
        }
        return false;
      });

      if (filterElements.length === 0) {
        filterServices = [].concat(servicesCached);
      }

      setServices([...filterServices])
    }
  }, [manageFilter])

  const handleCheckBoxClick = (type) => {
    let item = manageFilter.filter(x => x.name === type)[0];
    if (item.type === 'checked') {
      item.element = <FaRegSquare />
      item.type = 'unchecked'
    }
    else {
      item.element = <FaRegCheckSquare />
      item.type = 'checked'
    }

    setManageFilter([...manageFilter]);
  }

  const openNotification = () => {
    setModalNotificationShow(true)

  }


  return (
    <div className="side-container">

      <ProSidebar collapsed={sideBarCollapsed}>

        <Menu iconShape="square" onClick={() => handleClick()}>



          <MenuItem className={page?.name?.toLowerCase() === 'home' ? 'selected-menu-item' : 'unselected-menu-item'} onClick={() => handleNavigation('home', '')}>
            <div className='float-left'>
              <img className='menu-icon' src={home} alt="home" />
            </div>
            <div className='float-left menu-text'>
              Home
            </div>
          </MenuItem>

          <MenuItem className='menu-title'>Applications</MenuItem>


          {/*<MenuItem icon={<FaListOl />} onClick={() => computeServicesAndOpenModal()}>Summary</MenuItem>*/}

          <MenuItem className={page?.name?.toLowerCase() === 'catalog' ? 'selected-menu-item' : 'unselected-menu-item'} onClick={() => handleNavigation('catalog', 'Catalog')}>
            <div className='float-left'>
              <img className='menu-icon' src={vector} alt="vector" />
            </div>
            <div className='float-left menu-text'>
              Service Catalog
            </div>
          </MenuItem>





          <MenuItem className={page?.name?.toLowerCase() === 'advanced' ? 'selected-menu-item' : 'unselected-menu-item'} onClick={() => handleNavigation('advanced', 'Advanced')}>
            <div className='float-left'>
              <SearchIcon />
            </div>
            <div className='float-left menu-text'>
              Search View
            </div>
          </MenuItem>

          {/*
          <MenuItem className={page?.name?.toLowerCase() === 'infrarelations' ? 'selected-menu-item' : 'unselected-menu-item'} onClick={() => handleNavigation('infraRelations', 'InfraRelations')}>
            <div className='float-left'>
              <img className='menu-icon' src={nodes} alt="nodes" />
            </div>
            <div className='float-left menu-text'>
              Infra Relations
            </div>
          </MenuItem>
  */}
          {/*
          <MenuItem className={page?.name?.toLowerCase() === 'group-services' ? 'selected-menu-item' : 'unselected-menu-item'}
            onClick={() => handleNavigation('groups', 'group-services')} style={{ display: isEditorUser || isAdminUser  ? 'flex' : 'none' }}>
            <div className='float-left'>
              <img className='menu-icon' src={groups} alt="groups" />
            </div>
            <div className='float-left menu-text'>
              Manage Groups
            </div>
          </MenuItem>
        */}
          <MenuItem className={page?.name?.toLowerCase() === 'manage-users' ? 'selected-menu-item' : 'unselected-menu-item'}
            onClick={() => handleNavigation('manageUsers', 'manage-users')} style={{ display: isAdminUser ? 'flex' : 'none' }}>
            <div className='float-left'>
              <img className='menu-icon users-icon' src={users} alt="users" />
            </div>
            <div className='float-left menu-text'>
              Admin
            </div>
          </MenuItem>


          {
            process.env.REACT_APP_DISPLAY_PERMISSION == 'true' ?
              (
                <MenuItem className={page?.name?.toLowerCase() === 'dynamic-permission' ? 'selected-menu-item' : 'unselected-menu-item'}
                  onClick={() => handleNavigation('dynamicPermission', 'dynamic-permission')}>
                  <div className='float-left'>
                    <img className='menu-icon dynamic-icon' src={dynamic} alt="users" />
                  </div>
                  <div className='float-left menu-text'>
                    Permission
                  </div>
                </MenuItem>

              ) :
              (
                <span></span>
              )
          }
          {
            process.env.REACT_APP_DISPLAY_AUTOCICD == 'true' ?
              (
                <MenuItem className={page?.name?.toLowerCase() === 'auto-cicd' ? 'selected-menu-item-cicd' : 'unselected-menu-item-cicd'}
                  onClick={() => handleNavigation('autoCicd', 'auto-cicd')}>
                  <div className='float-left'>
                    <img className='menu-icon autocicd-icon' src={autocicd} alt="users" />
                  </div>
                  <div className='float-left menu-text-cicd'>
                    DevOps Self Service
                  </div>
                </MenuItem>

              ) :
              (
                <span></span>
              )
          }

          {
            process.env.REACT_APP_DISPLAY_CRONJOBSMONITOR == 'true' ?
              (
                <MenuItem className={page?.name?.toLowerCase() === 'cronjobs-monitor' ? 'selected-menu-item-cicd' : 'unselected-menu-item-cicd'}
                  onClick={() => handleNavigation('CronJobsMonitor', 'cronJobs-monitor')}>
                  <div className='float-left'>
                    <img className='menu-icon cronJobs-monitor-icon' src={cronjob} alt="CronJobsMonitor" />
                  </div>
                  <div className='float-left menu-text'>
                    CronJobs Monitor
                  </div>
                </MenuItem>

              ) :
              (
                <span></span>
              )

          }



          <MenuItem className={page?.name?.toLowerCase() === 'analyzer' ? 'selected-menu-item-cicd' : 'unselected-menu-item-cicd'}
            onClick={() => handleNavigation('Analyzer', 'analyzer')}>
            <div className='float-left'>
              <img className='menu-icon analyzer-icon' src={analyzer} alt="CronJobsMonitor" />
            </div>
            <div className='float-left menu-text'>
              Analyzer
            </div>
          </MenuItem>


          <MenuItem className='menu-title'>Tools</MenuItem>

          <MenuItem className='unselected-menu-item' onClick={() => openWebSite('NR')} >
            <div className='float-left icon-margin'>
              <FaGlobeAmericas />
            </div>
            <div className='float-left menu-text icon-margin'>
              NewRelic
            </div>
          </MenuItem>


          <MenuItem className='unselected-menu-item' onClick={() => openWebSite('SQ')} >
            <div className='float-left icon-margin'>
              <FaGlobeAmericas />
            </div>
            <div className='float-left menu-text icon-margin'>
              SonarQube
            </div>
          </MenuItem>

          {/*
           <MenuItem className='unselected-menu-item' onClick={() => openWebSite('PACK')} >
            <div className='float-left icon-margin'>
              <FaGlobeAmericas />
            </div>
            <div className='float-left menu-text icon-margin'>
              Pact.io
            </div>
          </MenuItem>
          */}

          <MenuItem className='menu-title'>Links</MenuItem>



          <MenuItem className='unselected-menu-item' onClick={() => openWebSite('WIKI')} >
            <div className='float-left icon-margin'>
              <FaGlobeAmericas />
            </div>
            <div className='float-left menu-text icon-margin'>
              Infra Wiki
            </div>
          </MenuItem>


          <MenuItem className='menu-title'>Notifications</MenuItem>



          <MenuItem className='unselected-menu-item' onClick={() => openNotification()} >
            <div className='float-left'>
              <img className='menu-icon notification-icon' src={bell} alt="notification" />
            </div>
            <div className='float-left menu-text-cicd'>
              <SCNotification />
            </div>
          </MenuItem>



          <hr />


          <MenuItem className={page?.name?.toLowerCase() === 'login' ? 'selected-menu-item' : 'unselected-menu-item'}
            style={{ display: !userAccount ? 'inline-block' : 'none' }}
            onClick={() => handleNavigation('login', 'login')}>
            <div className='float-left'>
              <img className='menu-icon' src={login} alt="login" />
            </div>
            <div className='float-left menu-text'>
              Log in
            </div>
          </MenuItem>


          <MenuItem className={menuSelectedItem?.toLowerCase() === 'logout' ? 'selected-menu-item' : 'unselected-menu-item'}
            style={{ display: userAccount ? 'inline-block' : 'none' }}
            onClick={() => handleLogout()}>
            <div className='float-left'>
              <img className='menu-icon' src={logout} alt="logout" />
            </div>
            <div className='float-left menu-text'>
              Log out
            </div>
          </MenuItem>

          <MenuItem className='unselected-menu-item no-cursor' style={{ display: userAccount ? 'inline-block' : 'none' }}>
            <div className='float-left'>
              <img className='menu-icon' src={user} alt="user" />
            </div>
            <div className='float-left menu-text'>
              {userAccount ? userAccount.user : ''}
            </div>
          </MenuItem>




        </Menu>
      </ProSidebar>
    </div>
  )
}

export default SideMenuBar
