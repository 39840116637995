import './SCTable.css';
import Table from '@mui/material/Table';
import DataContext from '../Context/DataContext';

import _ from "lodash";
import { useState, useContext, useEffect } from 'react';
import SCCheckBox from './SCCheckBox';
import SCLinkButton from './SCLinkButton';
import { Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { FaEllipsisV } from 'react-icons/fa';


const SCTable = ({ title, columnsItems, rowItems, tableWidth, includesRemoveButton,
    includesSelectButton, clearCheckBoxLists, showLoadMore,customClass,
    allCheckBoxsNull = true, handleRemove, handleSelect, handleRowClick,
    customHeaderClass, includesUpdateButton, customColumnWidth = false, removeButtonText ="Remove",
    handleUpdate, toolTip = { column: '', text: '', filter: {key:'', operator:'', value:'', toolTipTextFromColumn:''} } }) => {
    const { mdEditRowTable } = useContext(DataContext);
    const hideColumns = columnsItems.filter(c => c.display === false);
    const [width, setWidth] = useState(0);
    const [dynamicClass, setDynamicClass] = useState([]);
    const [dynamicRows, setDynamicRows] = useState([]);


    useEffect(() => {

        var validCol = columnsItems.filter(c => c.display === true);
        var numberOfColumns = validCol.length;

        if (includesRemoveButton || includesSelectButton) {
            numberOfColumns++;

        }

        if (!customColumnWidth) {
            var calcWidth = (100 / numberOfColumns) + '%';
            setWidth(calcWidth);
        }




    }, [columnsItems])



    useEffect(() => {


        if (clearCheckBoxLists) {

            setDynamicClass([])
        }

    }, [clearCheckBoxLists])




    const handleCheckBoxClick = (index) => {

        var isItemExist = dynamicClass?.filter(d => d.id === rowItems[index].id);
        var items = [];
        if (isItemExist.length > 0) {
            items = [...dynamicClass];
            _.remove(items, {
                id: isItemExist[0].id
            });
        }
        else {

            var item = rowItems[index].id;
            items.push({ id: item });
        }

        setDynamicClass([...items])

        if (items.length === 0)
            allCheckBoxsNull(true)
        else {
            allCheckBoxsNull(false)
        }
        handleSelect(index);
    }

    const ReturnHtml = (val) => {

        return (
            <div>{val.val.ResourceType}</div>
        )
    }

    var indexUniqueId = 0;
    const RenderTable = () => {

        if (rowItems && rowItems.length) {

            return (
                rowItems && rowItems?.map((row, index) => (
                    <>
                        <span key={`sc-span-key-${index}`} style={{ display: 'none' }}>{indexUniqueId = 0}</span>
                        <tr id={`sc-row-${index}`} key={`sc-row-key-${index}`} className="line-saperator" style={{ backgroundColor: index % 2 === 1 ? '#F9FAFC' : '#ffffff' }}
                            onClick={handleRowClick ? () => handleRowClick(row) : null}
                        >
                            {
                                includesRemoveButton || includesSelectButton ?
                                    (

                                        <td key={`sc-action-key-${index}`} id={`sc-td-action-${index}`} style={{ display: 'inline-cell' }}>

                                            <div className="columns" key={`sc-div-container-key-${index}`}>
                                                <div className="column sc-select-btn" style={{ display: includesSelectButton ? 'inline-cell' : 'none' }}>

                                                    <SCCheckBox id={row.id} handleClick={() => handleCheckBoxClick(index)}
                                                        customClass={`table-check-box ${dynamicClass?.filter(d => d.id === row.id).length > 0 ? 'sc-check-box-checked' : 'sc-check-box'}`} ></SCCheckBox>

                                                </div>
                                                <div className="column sc-saperator-line">
                                                    <FaEllipsisV color='#C0C0C0' size={10} />
                                                </div>
                                                <div className="column sc-table-remove sc-remove-btn" style={{ display: includesRemoveButton ? 'inline-cell' : 'none' }}>

                                                    <SCLinkButton text={removeButtonText} handleLinkClick={() => handleRemove(index)} />

                                                </div>
                                                <div className="column sc-saperator-line" style={{ display: includesUpdateButton ? 'inline-cell' : 'none' }}>
                                                    <FaEllipsisV color='#C0C0C0' size={10} />
                                                </div>
                                                <div className="column sc-table-remove sc-remove-btn" style={{ display: includesUpdateButton ? 'inline-cell' : 'none' }}>

                                                    <SCLinkButton text="Update" handleLinkClick={() => handleUpdate(index)} />

                                                </div>

                                            </div>
                                        </td>
                                    ) : (<></>)
                            }


                            {
                                Object.keys(row).map((key, internaIndex) => (



                                    isDisplay(key) ?
                                        (
                                            <td className='sc-table-value' style={{ display: 'table-cell' }} key={`sc-td-key-${internaIndex}`} >
                                                {

                                                    key.toLowerCase() === "url" ?
                                                        (
                                                            <span id={`sc-tr-${index}-td-value-${key}-${indexUniqueId}`}> <a href={row[key]} target="_blank" >Click here</a></span>
                                                        ) :
                                                        (
                                                            toolTip?.column === key ?
                                                                (
                                                                    toolTip?.filter.operator === 'eq' ?
                                                                        (
                                                                            row[toolTip?.filter.key] ===  toolTip?.filter.value ?
                                                                                (
                                                                                    <Tooltip title={toolTip.text !== '' ? row[toolTip.text] : row[toolTip?.filter?.toolTipTextFromColumn] !== '' ? row[toolTip?.filter?.toolTipTextFromColumn] : 'Not found'}>
                                                                                        <span id={`sc-tr-${index}-td-value-${key}-${indexUniqueId}`} >{row[key]}<InfoIcon className='sc-table-info-icon' /></span>
                                                                                    </Tooltip>
                                                                                ):
                                                                                (
                                                                                    <span id={`sc-tr-${index}-td-value-${key}-${indexUniqueId}`} >{row[key]} </span>
                                                                                )
                                                               
                                                                        ):
                                                                        (
                                                                            <span id={`sc-tr-${index}-td-value-${key}-${indexUniqueId}`} >{row[key]} </span>
                                                                        )                                                                
                                                                ):
                                                                (
                                                                    <span id={`sc-tr-${index}-td-value-${key}-${indexUniqueId}`} >{row[key]} </span>
                                                                )
                                                        )
                                                }

                                            </td>
                                        ) : (
                                            <></>
                                        )



                                ))
                            }


                        </tr>
                    </>
                ))
            )
        }
        else {
            return (<tr><td>No data found</td></tr>)
        }

    }

    const isDisplay = (key) => {



        let res = columnsItems?.filter(c => c.key.toLowerCase() === key.toLowerCase())[0].display;
        if (res) {
            indexUniqueId = indexUniqueId + 1
        }

        return res;
    }

    const RenderHeaders = () => {
        if (includesRemoveButton || includesSelectButton) {
            if (columnsItems.filter(c => c.name === 'Actions').length === 0) {
                if (!customColumnWidth) {
                    columnsItems.unshift({ display: true, name: "Actions", key: "Actions" })
                }
                else {
                    columnsItems.unshift({ display: true, name: "Actions", key: "Actions", width: '10%' })
                }


            }
        }
        return (

            customColumnWidth == true ?
                (
                    columnsItems && columnsItems?.map((c, index) => (
                        <th className='sc-column sc-table-title' style={{ display: c.display ? 'table-cell' : 'none', width: width }} key={`sc-th-key-${index}`}>{c.name}</th>

                    ))
                ) :
                (
                    columnsItems && columnsItems?.map((c, index) => (
                        <th className='sc-column sc-table-title' style={{ display: c.display ? 'table-cell' : 'none' }} width={c.width} key={`sc-th-key-${index}`}>{c.name}</th>
                    ))
                )


        )
    }

    return (
        <>
            <div className='sc-table-header'>{title}</div>
            <Table className={customClass} style={{ width: tableWidth ? tableWidth + '%' : '100%', margin: 'auto' }}>
                <thead className='sc-thead-sticky'>
                    <tr className='line-saperator'>
                        <RenderHeaders />
                    </tr>
                </thead>

                <tbody>
                    <>
                        <RenderTable />

                    </>

                </tbody>

            </Table>
            {showLoadMore && <div className='sc-table-load-more'>Loading more services...</div>}
        </>
    )
}

export default SCTable
