import './SearchServices.css';
import * as React from 'react';
import { useState, useContext, useCallback } from 'react';
import DataContext from "../Context/DataContext";
import _ from "lodash";
import DataContextHome from "../Context/DataContextHome";

import { useHistory } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import spinner from '../Images/SmallSpinner.svg';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

import appService from '../Images/App-Services.svg';
import aksApp from '../Images/azure-aks.svg';
import eksApp from '../Images/amazon-eks.svg';

import cdnService from '../Images/CDN-Profiles.svg';
import functionApp from '../Images/Function-Apps.svg';
import library from '../Images/Activity-Log.svg';
import azure from '../Images/azure.svg';
import aksCron from '../Images/cronjob-icon.svg';
import { AccountCircle, LocationOn } from '@mui/icons-material';
import { ListItemIcon, ListItemText, ListItem } from '@mui/material';


import { v4 as uuid } from 'uuid';


const SearchServices = () => {
  const { servicesCached, currentPage, setSelectedService, freeTextSearch } = useContext(DataContext);
  const { toggleExcludeValue, setToggleExcludeValue } = useContext(DataContextHome);
  const history = useHistory();
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)


  const getServiceById = (id) => {
    return servicesCached?.filter(s => s.id === id);
  }

  const getResourceIcon = useCallback((id) => {
    let type = servicesCached?.filter(s => s.id === id);
    if (type === undefined || type.length === 0) {
      return "";
    }
    switch (type[0].serviceType) {
      case 'AppService':
        {
          return <div><img src={appService} alt='icon' className='type-icon' /></div>;
        }
      case 'FunctionApp':
        {
          return <div><img src={functionApp} alt='icon' className='type-icon' /></div>;
        }
      case 'Library':
        {
          return <div><img src={library} alt='icon' className='type-icon' /></div>;
        }
      case 'JsLibrary':
        {
          return <div><img src={cdnService} alt='icon' className='type-icon' /></div>;
        }
      case 'AksApp':
        {
          return <div><img src={aksApp} alt='icon' className='type-icon' /></div>;
        }
      case 'EksWebApp':
        {
          return <div><img src={eksApp} alt='icon' className='type-icon' /></div>;
        }
      case 'AksCronJob':
        {
          return <div><img src={aksCron} alt='icon' className='type-icon' /></div>;
        }
      default:
        return <div><img src={azure} alt='icon' className='type-icon' /></div>;
    }
  });




  const handleAutoChange = (value) => {




    if (value.length <= 2) {
      setOptions([])
    }
    if (value != '' && value.length > 2) {
      setLoading(true)
      freeTextSearch(value, toggleExcludeValue).then((response) => {
        let arr = [];





        const sortedData = _.orderBy(response.data, [(obj) => {
          const name = obj.name.toLowerCase();
          if (name.toLowerCase().startsWith(value)) {
            return 0;
          } else if (name.toLowerCase().includes(value)) {
            return 1;
          } else {
            return 2;
          }
        }, 'name'], ['asc', 'asc']);







        sortedData.forEach(d => {
          arr.push({ name: d.name, id: d.id, icon: getResourceIcon(d.id) })
        });


        setLoading(false)
        //  setOptions([...arr])



        setOptions(arr)


      }, (error) => {
        console.log('Error', error)
        setLoading(false)
      })
    }
  }



  const handleUserClick = (event, serviceId) => {

    if (!serviceId) {

      const ErrorMsg = () => (
        <div>
          <div>Please choose one of the services from the list below</div>
        </div>
      )
      toast.error(<ErrorMsg />)
      return false;
    }
    else {
      var service = getServiceById(serviceId);
      setSelectedService(service[0]);
      history.push({
        pathname: '/Service',
        display: 'ServiceChild',
        search: '?name=' + service[0].name
      })
    }
  }



  return (
    <>

      <div style={{ display: currentPage === 'services' ? 'inline' : 'none' }}>

        <Autocomplete
          freeSolo
          id="search-services"
          disableClearable
          options={options}
          onChange={(e, value) => handleUserClick(e, value.id)}

          getOptionLabel={(option) => {
            return option.name ?? option;
          }}
          filterOptions={(options) => options}

          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Service"
              InputProps={{
                ...params.InputProps,
                type: "search"
              }}
              onChange={(event) =>
                handleAutoChange(event.target.value)
              }
            />
          )}
          renderOption={(props, option) => {

            return (
              <li {...props} key={uuid()}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </li>
            );
          }}

        />



        {
          loading ?
            (
              <div className='search-spinner' ><img src={spinner} alt='icon' className='spinner-icon' /><span className="search-spinner-text">Please wait...</span></div>
            ) :
            (
              <></>
            )
        }
      </div>

    </>
  )
}

export default SearchServices
