import SCBreadCrumbs from "../Components/SCBreadCrumbs";
import SCLine from "../Components/SCLine";
import SCHeader from "../Components/SCHeader";
import grid from '../Images/grid.svg';
import list from '../Images/list.svg';
import './SCTop.css';
import DataContext from '../Context/DataContext';
import { useContext , useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { FaLayerGroup } from 'react-icons/fa';




const SCTop = ({displayFirstLine = true, displaySecondLine = true, displayBreadcrumbs=true, displayFilter=true,totalAfterFilter}) => {
    const { setViewMode, viewMode, currentPage } = useContext(DataContext);
  
    const changeView = (view) => {
        setViewMode(view);
      }

   
    
   
    return (
        <>
             <div className="header-row" >
                <div>
                    <SCHeader />
                </div>
            </div>
        
            <div className="row br-row" >
                <div className="col-8 " style={{display:displayBreadcrumbs ? 'inline-block' : 'none'}}>
                    <SCBreadCrumbs totalAfterFilter={totalAfterFilter}  />     
                       
                </div>              
              
                <div className="col-4" style={{display:currentPage === 'services'  ? 'inline-block' : 'none'}}>                            
                   {/*  
                <div className={`float-right filter-grid ${viewMode === 'grid' ? "boxing-selected" : "boxing"}`}  onClick={() => changeView('grid')}>
                   <img className='menu-icon filter-black' src={grid} alt="grid" />
                   </div>
                   */}
                    {/*
                   <div style={{marginRight: '1rem'}} className={`float-right ${viewMode === 'list' ? "boxing-selected" : "boxing"}`} onClick={() => changeView('list')}>
                   <img className='menu-icon filter-black' src={list} alt="list"   />
                   </div>
                  */}
                   {/*
                   <div style={{marginRight: '1rem'}} className={`float-right  ${viewMode === 'group' ? "boxing-selected" : "boxing"}`}  onClick={() => changeView('group')}>
                   <FaLayerGroup className="fa-layer-group" />
                   </div>*/}
                </div>              
               
            </div>
            <div className="row" style={{display:displaySecondLine ? 'inline-block' : 'none'}}>
                <div className="col-12">
             
                </div>
            </div>
        </>
    )
}

export default SCTop
