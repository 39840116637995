// ChatComponent.jsx

import React, { useContext, memo } from 'react';

import './ChatComponent.css';
import InfraCopilot from './InfraCopilot';
import { DataContextChat } from '../../Context/DataContextChat';

const ChatComponent = () => {

    const { isChatOpen } = useContext(DataContextChat);

    return (
        <React.Fragment>
            {
                isChatOpen &&
                (
                    <div className={`chat-wrapper ${isChatOpen ? 'open' : ''}`}>
                        <InfraCopilot isChatOpen={isChatOpen} />
                    </div>
                )
            }
        </React.Fragment>

    );
};

export default ChatComponent;
