import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SelfServiceDetails from './SelfServiceDetails';
import SelfServiceRepo from './SelfServiceRepo';
import SelfServicePipelineAndCluster from './SelfServicePipelineAndCluster';
import SelfServiceDB from './SelfServiceDB';
import SelfServiceApprovalManager from './SelfServiceApprovalManager';
import SelfServiceTemplateType from './SelfServiceTemplateType';
import './SelfServiceWizard.css';


const SelfServiceWizard = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [submit, setSubmit] = React.useState(false)

  const handleNext = () => {
    if (activeStep < 5) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else {
      setSubmit(true)
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const RenderStepComponent = ({ step }) => {

    switch (step) {
      case 0:
        {
          return <div className='service-wizard-step-container'><SelfServiceTemplateType /></div>
        }
      case 1:
        {

          return <div className='service-wizard-step-container'><SelfServiceDetails /></div>
        }
      case 2:
        {
          
          return <div className='service-wizard-step-container'><SelfServiceRepo /></div>
        }
      case 3:
        {
          
          return <div className='service-wizard-step-container'><SelfServicePipelineAndCluster /></div>
        }
      case 4:
        {
          
          return <div className='service-wizard-step-container'><SelfServiceDB /></div>
        }
      case 5:
        {
          
          return <div className='service-wizard-step-container'><SelfServiceApprovalManager /></div>
        }


      default:
        return <div>xxxxxx</div>;
    }

  }

  return (
    <React.Fragment>

      <div className='service-wizard-container'>
        <RenderStepComponent step={activeStep} />
      </div>
      <div className='service-wizard-stepper'>
        <MobileStepper
          variant="progress"
          steps={6}
          position="static"
          activeStep={activeStep}
          sx={{
            maxWidth: 400, // Set maximum width
            flexGrow: 1,   // Allow it to grow              
          }}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === 6 || submit === true}>
              {
                activeStep < 5 ?
                  (
                    <span>Next</span>
                  ) :
                  (
                    <span>Submit</span>
                  )
              }
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0 || submit === true}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </div>

    </React.Fragment>
  );
}
export default SelfServiceWizard