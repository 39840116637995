import React, { useEffect, useState, useContext } from 'react'
import './SAAnalyzerComments.css';
import _ from "lodash";
import { Autocomplete, Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { DateTime, DateTime as luxon } from "luxon";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import * as consts from '../../../consts';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import DataContext from '../../../Context/DataContext';
import SCTextArea from '../../SCTextArea';
import { v4 as uuid } from 'uuid';
import spinner from '../../../Images/SmallSpinner.svg';









const SAAnalyzerComments = ({ service, requestId }) => {

    const { servicesCached, formatDateTime } = useContext(DataContext);
    const { reportCommentPost, reportCommentGet, comments, setComments } = useContext(DataContextAnalyzer);
   
    const [incidentComment, setIncidentComment] = useState('')
    const [commentsLoading, setCommentsLoading] = useState(false)

    

    useEffect(async () => {

        try {
            setCommentsLoading(true)
         
            let comments = await reportCommentGet(requestId,service.serviceId);
            if (comments.status === 200) {
                let commentState = []
                if (comments.data?.data?.length > 0) {

                    commentState = comments.data.data.map((d) => {

                        return {
                            createdDate: formatDateTime(d.createdAt),
                            createdBy: d.user,
                            comment: d.content,
                            status: 'done',
                            id: uuid()
                        }

                    })
                }
                setComments(commentState);
                setCommentsLoading(false)
            }

        } catch (error) {
            setCommentsLoading(false)
            const ErrorMsg = () => (
                <div>Something went wrong while fetching the data.</div>
              )
              toast.warn(<ErrorMsg />)
        }


    }, [service])


    const handleNewComment = () => {

        //there is no option to open new comment if the user is alrready opened one

        let items = [...comments].filter(c => c.status === 'new');
        if(items && items.length > 0){
            const WarnrMsg = () => (
                <div>Please complete or cancel the current comment.</div>
              )
              toast.warn(<WarnrMsg />)
        }
        else{
            const now = DateTime.now();
            const formattedDateTime = now.toFormat('dd/MM/yyyy HH:mm');
            let commentEntity = {
                createdDate: formattedDateTime,
                createdBy: localStorage.getItem('InfraConsoleName') + " (" + localStorage.getItem('InfraConsoleUserName') + ")",
                comment: '',
                status: 'new',
                id: uuid()
    
            }
            setComments([...comments, commentEntity]);
        }
        

    }

    const handleComment = (e) => {
        setIncidentComment(e.target.value)
    }

    const handleSaveComment = async (id) => {

        const updatedItems = comments.map(item => {
            if (item.id === id) {
                return { ...item, status: 'pending', comment: incidentComment };
            }
            return item;
        });
        setIncidentComment('')
        setComments(updatedItems);



        let items = [...comments].filter(c => c.status === 'new');
        if (items && items.length > 0) {
            try {
                const arrayOfApiModelObjects = items.map(obj => ({
                    user: obj.createdBy,
                    content: incidentComment,
                    requestId: requestId,
                    serviceId: service.serviceId
                }));
                let response = await reportCommentPost(arrayOfApiModelObjects)
                const updatedItemsAfterSuccess = comments.map(item => {
                    if (item.id === id) {
                        return { ...item, status: 'done', comment: incidentComment };
                    }
                    return item;
                });

                setComments(updatedItemsAfterSuccess);
            } catch (error) {
                const ErrorMsg = () => (
                    <div>Something went wrong while saving the data.</div>
                  )
                  toast.warn(<ErrorMsg />)
            }
        }


    }

    const handleCancelComment = (id) => {
        const updatedItems = comments.filter(item => item.id !== id);
        setComments(updatedItems);
    }

    return (

        <div>
            <div className='sa-analyzer-comments-add-new' onClick={() => handleNewComment()}>+ Add New Comment</div>
            <div>
                <TableContainer component={Paper}>
                    <Table id="sa-analyzer-history-table-id" >
                        <TableHead>
                            <TableRow>
                                <TableCell className='sa-analyzer-comments-small-column'>DATE</TableCell>
                                <TableCell className='sa-analyzer-comments-small-column'>WHO</TableCell>
                                <TableCell className='sa-analyzer-comments-large-column'>COMMENT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                comments && comments.length > 0 ?
                                    (
                                        comments.map((comment, index) => (
                                            <TableRow>
                                                <TableCell>{comment.createdDate}</TableCell>
                                                <TableCell>{comment.createdBy}</TableCell>
                                                {
                                                    comment.status === 'new' ?
                                                        (
                                                            <TableCell>
                                                                <div>
                                                                    <SCTextArea rows={1.9} size="small" name='comment' className="md-input" label="Comment"
                                                                        handleInputValueChange={(e) => handleComment(e)} value={incidentComment} />
                                                                </div>
                                                                <div>
                                                                    <span className='sa-analyzer-comments-add-new sa-analyzer-comments-button' onClick={() => handleSaveComment(comment.id)}>Save</span>
                                                                    <span className='sa-analyzer-comments-add-new sa-analyzer-comments-button-cancel' onClick={() => handleCancelComment(comment.id)}>Cancel</span>
                                                                </div>
                                                            </TableCell>
                                                        ) : comment.status === 'pending' ?
                                                            (
                                                                <TableCell>{comment.comment} <span><img src={spinner} alt='icon' className='spinner-icon' /></span></TableCell>
                                                            ) :
                                                            (
                                                                <TableCell>{comment.comment}</TableCell>
                                                            )
                                                }
                                            </TableRow>
                                        ))
                                    ) :
                                    (
                                        commentsLoading === true ?
                                            (
                                                <TableRow>
                                                    <TableCell colSpan={3}>Fetching comments <img src={spinner} alt='icon' className='spinner-icon' /></TableCell>
                                                </TableRow>
                                            ) :
                                            (
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )

                                    )

                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default SAAnalyzerComments