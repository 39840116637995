import { useRef, useCallback, useEffect } from "react";


export default function useRefInputDescriptionRefCallback(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}