import React, { useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import _ from "lodash";
import { createClientMessage } from 'react-chatbot-kit';
import ServiceUtils from './ServiceUtils';
//https://infrastructure-analyzer-assistants-dev-infra.internal.tenbis.cloud/swagger/index.html


var serviceUtils = null;

class ServiceAnalyzerModule {

  constructor(createChatBotMessage, setState, state, context) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setState;
    this.state = state;
    this.context = context
    serviceUtils = new ServiceUtils(setState, state, context)
    context.startSignalRConnection()
  }



  async handleCreateAnalyzerChat(message) {


    try {

      return await this.context.createCopilotChat(message)

    } catch (error) {

    }


  }

  async createSilenceeUserMessage(userMessage, id = new Date().getTime()) {

    
    
    serviceUtils.createUserMessage(userMessage)
    let response = await this.context.createCopilotChat("from create silence message")
    await this.handleSendMessage(userMessage, response.chatSession.id)


  };
//taltal
  async handleSendMessage(userMessage, cID) {
  //  alert('invoke api before: ' + this.context.isNewRequest.current)
  //  alert('invoke api after ' + this.context.isNewRequest.current)

  
    console.log('*********************', userMessage)

    this.context.setDisableUserChatSendBtn(true)
    serviceUtils.createTypingMessage()
    let reciveMessage = await this.context.sendCopilotMessage(userMessage, cID)
    serviceUtils.sendMessage(reciveMessage)

  }




  async createTypingMessage() {
    let currentUUID = uuid()

    const messageTyping = this.createChatBotMessage({ message: "", type: 'typing', id: currentUUID });

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, messageTyping]
    }));

  }




}


export default ServiceAnalyzerModule;