import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState, useContext } from 'react'
import DataContext from "./Context/DataContext";


const SCProgressBar = () => {

    const { progressBarValue, progressBarText, progressBarShow } = useContext(DataContext);

    const [number, setNumber] = useState(0)
    const [text, setText] = useState('')
    const [show, setShow] = useState(false)

    useEffect(() => {

      

       if(progressBarShow){
        setNumber(5)
        setText('Send data to the server')
      
       }
       setShow(progressBarShow)
    }, [progressBarShow])

    useEffect(() => {

        
        setNumber(progressBarValue)
        if(progressBarValue === 100){
            setText('The process has been completed successfully')
            
        }
        else{
            setText(progressBarText)
        }
        

     


    }, [progressBarValue])

    return (
        <div>
            {
                show ?
                    (
                        <div id="progressBarCICD" style={{ position: 'absolute' }} >
                            <div className="progrees-bar-cicd-container">
                                <ProgressBar now={number} variant={number === 100 ? 'success' : ''} label={number + '%'} animated />

                                <div className="progress-text">
                                    {text}
                                </div>
                            </div>

                        </div>
                    ) :
                    (
                        <div></div>
                    )
            }
        </div>

    )
}

export default SCProgressBar
