import React, { useEffect, useState, useContext, useRef } from 'react'
import './ServiceAnalyzerTimelineChart.css';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import * as consts from '../../consts';
import _ from "lodash";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const { DateTime } = require('luxon');

const ServiceAnalyzerTimelineChart = ({ timeline }) => {

    const { servicesCached } = useContext(DataContext);
    const { LuxonDateFormatString, GetServiceNameById, translateAnalyzerCodes, BulletColorByModule } = useContext(DataContextAnalyzer);
    const [hoveredBullet, setHoveredBullet] = useState(null);
    const [pipesRange, setPipesRange] = useState(15)
    const [filterModule, setFilterModule] = useState('')

    const dataRef = useRef(null);
    const arrayRef = useRef([]);

    //const NUMBER_OF_PIPES = pipesRange;

    let bulletCount = 0;
    let currentTime = {};


    const handleBulletHover = (index) => {
        setHoveredBullet(index);


    };

    const handleBulletLeave = () => {
        setHoveredBullet(null);
    };

    const handleFilterType = (type) => {
        if (filterModule === type) {
            setFilterModule('')
        }
        else {
            setFilterModule(type)
        }

    }

    const getBulletAndModalPosition = (timestamp, start, end, isModal = false) => {

        const timestampOccurredAtString = timestamp;
        const firstObjectOccurredAtString = start;
        const lastObjectOccurredAtString = end;

        // Parse date strings into Luxon DateTime objects
        const timestampOccurredAt = DateTime.fromISO(timestampOccurredAtString);
        const firstObjectOccurredAt = DateTime.fromISO(firstObjectOccurredAtString);
        const lastObjectOccurredAt = DateTime.fromISO(lastObjectOccurredAtString);

        // Calculate bulletPosition
        let bulletPosition =
            ((timestampOccurredAt.diff(firstObjectOccurredAt).milliseconds) /
                (lastObjectOccurredAt.diff(firstObjectOccurredAt).milliseconds)) *
            100;
        if (isModal) {
            bulletPosition = bulletPosition - 7;

        }


        return bulletPosition;
    }

    const handleChangeSlot = (value) => {
        setPipesRange(value)
    }



    const RenderTimelineChart = ({ timeline }) => {

        if (!timeline || timeline.length === 0) {
            return (
                <span></span>
            )
        }


        var firstObject = timeline[0];
        var lastObject = timeline[timeline.length - 1];

        const startDate = new Date(firstObject.occurredAt);
        const endDate = new Date(lastObject.occurredAt);

        let bulletTimestamps = [];

        timeline.map((tl) => {

            if (filterModule === "") {

                bulletTimestamps.push(
                    {
                        serviceName: tl.serviceName,
                        module: tl.module,
                        code: tl.code,
                        metaData: translateAnalyzerCodes(tl),
                        occurredAt: tl.occurredAt
                    }
                )

            }
            else if (filterModule === tl.module.toLowerCase()) {

                bulletTimestamps.push(
                    {
                        serviceName: tl.serviceName,
                        module: tl.module,
                        code: tl.code,
                        metaData: translateAnalyzerCodes(tl),
                        occurredAt: tl.occurredAt
                    }
                )


            }


        })




        // Calculate the total hours between the start and end dates
        const totalHours = Math.abs(endDate - startDate) / 36e5;

        // Define the number of pipes you want
        const numberOfPipes = pipesRange //NUMBER_OF_PIPES;

        // Calculate the interval between each pipe
        const pipeInterval = totalHours / (numberOfPipes - 1);
        //  const bulletPosition = ((bulletTimestamp - startDate) / (endDate - startDate)) * 100;

        const options = {
            day: '2-digit',
            month: '2-digit',
        };
        // Create an array of timeline items with both time and date

        const timelineItems = Array.from({ length: numberOfPipes }, (_, index) => {
            const hoursToAdd = index * pipeInterval;

            const time = new Date(startDate.getTime() + hoursToAdd * 60 * 60 * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const date = new Date(startDate.getTime() + hoursToAdd * 60 * 60 * 1000).toLocaleDateString('en-IL', options);
            return { time, date };
        });



        const maxHeight = () => {


            const groupedData = _.groupBy(bulletTimestamps, item => `${item.occurredAt}_${item.code}`);
            const biggestGroup = _.maxBy(Object.values(groupedData), 'length').length;
            return biggestGroup * 17

        }
        let modalObjectPosition = []
        return (
            <div className="timeline" style={{ top: `${maxHeight()}px` }}>
                {timelineItems.map((item, index) => {
                    const position = (index / (numberOfPipes - 1)) * 100;

                    return (
                        <React.Fragment key={index}>
                            <div className="pipe" style={{ left: `${position}%` }}></div>
                            <div className="label" style={{ left: `${position - 1}%` }}>{item.date} {item.time}</div>
                        </React.Fragment>
                    );
                })}

                {/* Create bullet */}
                {bulletTimestamps.map((timestamp, index) => {


                    if (currentTime === timestamp.occurredAt) {
                        bulletCount++
                    }
                    else {
                        bulletCount = 0
                        currentTime = timestamp.occurredAt
                    }

                    modalObjectPosition.push({
                        index: index,
                        position: bulletCount
                    })

                    if (index === bulletTimestamps.length - 1) {
                        dataRef.current = modalObjectPosition;

                    }


                    return (
                        timestamp && timestamp?.occurredAt ?
                            (
                                <div
                                    key={index}
                                    className="bullet-base"
                                    style={{
                                        left: `${getBulletAndModalPosition(timestamp.occurredAt, firstObject.occurredAt, lastObject.occurredAt)}%`,
                                        bottom: `${bulletCount * 15}px`, // Adjust the vertical spacing 
                                        backgroundColor: BulletColorByModule(timestamp.module)
                                    }}
                                    onMouseEnter={() => handleBulletHover(index)}
                                    onMouseLeave={handleBulletLeave}
                                ></div>
                            ) :
                            (
                                <React.Fragment />
                            )

                    );
                })}

                {/* Modal for hovered bullet */}
                {hoveredBullet !== null && (


                    bulletTimestamps[hoveredBullet] && bulletTimestamps[hoveredBullet].occurredAt ?
                        (
                            <div
                                className="modal-x"
                                style={{
                                    left: `${getBulletAndModalPosition(bulletTimestamps[hoveredBullet].occurredAt, firstObject.occurredAt, lastObject.occurredAt, true)}%`,
                                    //bottom: '10px', // Adjust the distance above the bullet
                                    bottom: `${dataRef.current.find(item => item.index === hoveredBullet).position * 15 + 35}px`,
                                    zIndex: 3, // Ensure the modal appears above all bullets
                                }}
                            >
                                <div className='time-line-div-container'>
                                    <div className='time-line-service-regular'><b>{bulletTimestamps[hoveredBullet].serviceName}</b></div>
                                    <div className='time-line-service-regular'>{bulletTimestamps[hoveredBullet].code}</div>
                                    <div className='time-line-service-regular'>{bulletTimestamps[hoveredBullet].metaData}</div>
                                    <div className='time-line-service-regular'>{LuxonDateFormatString(bulletTimestamps[hoveredBullet].occurredAt, 'dd/MM hh:mm a')}</div>
                                </div>
                            </div>
                        ) :
                        (
                            <React.Fragment />
                        )

                )}
            </div>
        );
    }

    const RenderMap = () => {



        return (
            <Tooltip title="Click on the bullets for filter the charts by module">
                <div className='timeline-chart-map-legend'>
                    {
                        timeline.find(item => item.module.toLowerCase() === 'configuration') ?
                            (
                                <span className='bullet-map-container' >Configuration<span onClick={() => handleFilterType('configuration')}
                                    className={`bullet-map-red ${filterModule === 'configuration' ? 'bullet-map-base-border' : 'bullet-map-base'}`}></span></span>
                            ) :
                            (
                                <React.Fragment></React.Fragment>
                            )
                    }
                    {
                        timeline.find(item => item.module.toLowerCase() === 'database') ?
                            (
                                <span className='bullet-map-container' >Database<span onClick={() => handleFilterType('database')}
                                    className={`bullet-map-green ${filterModule === 'database' ? 'bullet-map-base-border' : 'bullet-map-base'}`}></span></span>
                            ) :
                            (
                                <React.Fragment></React.Fragment>
                            )
                    }
                    {
                        timeline.find(item => item.module.toLowerCase() === 'deployments') ?
                            (
                                <span className='bullet-map-container'>Deployments<span onClick={() => handleFilterType('deployments')}
                                    className={`bullet-map-blue ${filterModule === 'deployments' ? 'bullet-map-base-border' : 'bullet-map-base'}`}></span></span>
                            ) :
                            (
                                <React.Fragment></React.Fragment>
                            )
                    }
                    {
                        timeline.find(item => item.module.toLowerCase() === 'metrics') ?
                            (
                                <span className='bullet-map-container' >Metrics<span onClick={() => handleFilterType('metrics')}
                                    className={`bullet-map-pink ${filterModule === 'metrics' ? 'bullet-map-base-border' : 'bullet-map-base'}`} ></span></span>
                            ) :
                            (
                                <React.Fragment></React.Fragment>
                            )
                    }
                    {
                        timeline.find(item => item.module.toLowerCase() === 'resourcechanges') ?
                            (
                                <span className='bullet-map-container'>Resource Changes<span onClick={() => handleFilterType('resourcechanges')}
                                    className={`bullet-map-yellow ${filterModule === 'resourcechanges' ? 'bullet-map-base-border' : 'bullet-map-base'}`}></span></span>
                            ) :
                            (
                                <React.Fragment></React.Fragment>
                            )
                    }
                </div>
            </Tooltip>
        )
    }


    return (
        <React.Fragment>
            <RenderMap />

            <div className='timeline-slider'>



                <Box sx={{ width: 300 }}>
                    <Typography id="non-linear-slider-timeline" gutterBottom>
                        Range: {pipesRange} Dates
                    </Typography>
                    <Slider
                        aria-label="Pipes"
                        defaultValue={15}
                        valueLabelDisplay="auto"
                        getAriaValueText={handleChangeSlot}
                        step={5}
                        marks
                        min={15}
                        max={105}
                    />
                </Box>
            </div>
            <div className='render-timeline-chart'>
                <RenderTimelineChart timeline={timeline} />
            </div>
        </React.Fragment>
    )
}

export default ServiceAnalyzerTimelineChart