import React, { useEffect, useState, useContext, useRef, lazy } from 'react'
import './ServiceAnalyzerTable.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import ServiceAnalyzerReportModal from './ServiceAnalyzerReportModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import * as consts from '../../consts';
import ServiceAnalyzerReportInfraModal from './ServiceAnalyzerReportInfraModal';
import { DateTime, DateTime as luxon } from "luxon";
import _ from "lodash";


const ServiceAnalyzerTable = ({ data, reqId, setProgressBar, progressBar }) => {

    const { servicesCached } = useContext(DataContext);
    const { report, rootCauseAnalysis, status } = useContext(DataContextAnalyzer);
    const [servicesAndStatus, setServicesAndStatus] = useState([])
    const [showAnalyzerReport, setShowAnalyzerReport] = useState(false)
    const [showAnalyzerInfraReport, setShowAnalyzerInfraReport] = useState(false)

    const [insightsPerService, setInsightsPerService] = useState([])
    const [infraInsights, setInfraInsights] = useState({})

    const [selectedReportServiceName, setSelectedReportServiceName] = useState('');
    const [selectedReportServiceID, setSelectedReportServiceID] = useState('');

    const [reportForService, setReportForService] = useState([])
    const [reportForServiceCache, setReportForServiceCache] = useState([])

    const NUMBER_OF_PULLING_ROOT = 2;
    const dataAIRef = useRef(null);

    const GENERATE_REPORT = "Generating Analysis Report...";
    const STARTING_AI = "Starting AI analysis. Pulling number: ";


    useEffect(() => {
        setReportForService([])
        setReportForServiceCache([])
        setServicesAndStatus([])
    }, [])




    useEffect(async () => {
        if (!data) {
            return;
        }

        let majors = data.services.filter(d => d.requested === true);
        majors.map(async (m) => {

            const commonIds = data.services
                .filter(item => m.dependencies.includes(item.id))
                .map(item => item);

            m.services = commonIds;
        })

        updateChangeDetected(majors);




    }, [data])




    useEffect(() => {

        if (data && data.status === 'Done' && progressBar.state === 3 || progressBar.state === 4) {

            if (progressBar.state < 4) {
                setProgressBar({ state: 4, text: 'Prepering Analysis Report...' })
            }



        }
      
        let www = reportForService.some(r => r.report === null);
        //if (data?.stop === true && reportForService.length > 0 && reportForService.length === data.services.length) {
        if (data?.stop === true && reportForService.length > 0
            && reportForService.length === data.services.filter(d => d.collectDataStatus !== "Unknown" && d.investigateStatus !== "Unknown").length) {

            let www = reportForService.some(r => r.report === null);
            if (!www) {
                let filteredReports = [...reportForService]
                filteredReports.forEach(item => {
                    _.remove(item.report, reportItem =>
                        _.includes(['infra_changes', 'impact'], reportItem.code)
                    );
                });

                //check if i have root cause
                let functions = filteredReports.filter((r) => r.incident !== null && r.incident?.risk?.toLowerCase() === 'high');
                if (functions.length === 0) {
                    setProgressBar({ state: 8, text: 'Process Completed Successfully' })

                }
                else {

                    if (progressBar.state < 5) {
                        setProgressBar({ state: 5, text: 'Starting AI Analysis' })
                    }


                    functions.forEach((func) => {
                        console.log("root cause interval")
                        if (func.rootCauseStatus === null) {
                            startInterval(func, functions)
                        }

                    });
                }
            }

        }


    }, [reportForService])

    const startInterval = (func, functions) => {

        const intervalId = setInterval(async () => {
            func.rootCauseStatus = "InProcess"
            try {
                func.attempt++;
                if (progressBar.state < 6) {
                    setProgressBar({ state: 6, text: 'Searching For Root Cause Incident...' })
                }

                let response = await status(reqId);

                if (response.status === 200) {



                    let serviceStatus = response.data.data.services.filter(ser =>
                        (ser.rootCauseAnalysisStatus === "Succeded" || ser.rootCauseAnalysisStatus === "Unknown" || ser.rootCauseAnalysisStatus === "Failed") && ser.id === func.id);

                    if (serviceStatus && serviceStatus.length > 0) {

                        switch (serviceStatus[0].rootCauseAnalysisStatus) {
                            case "Succeded":
                                {
                                    func.rootCauseStatus = "Done";
                                    await handleGetRootCauseAnalysis(func)
                                    clearStatusInterval(intervalId, functions, response.data.data.services)
                                    break;
                                }
                            case "Unknown":
                            case "Failed":
                                {
                                    if (func.attempt === NUMBER_OF_PULLING_ROOT) {
                                        console.log("FUNCTION END INTEVAL", func)
                                        func.rootCauseStatus = "Unknown";
                                        clearStatusInterval(intervalId, functions, response.data.data.services)
                                    }
                                    break;
                                }


                            default:
                                break;
                        }

                    }




                }
                if (response.status !== 200) {
                    console.log("STATUS NOT EQUAL TO 200", response.status)
                    clearInterval(intervalId)



                }


            }
            catch (error) {
                console.log("Error", error)
                clearInterval(intervalId)



            }


        }, 10000);
    }

    const clearStatusInterval = (intervalId, functions, responseStatus) => {
        clearInterval(intervalId)
        let functionsDone = functions.filter(f => f.rootCauseStatus === "Unknown" || f.rootCauseStatus === "Done");
        if (functionsDone.length === functions.length) {
            setProgressBar({ state: 8, text: 'Process Completed Successfully' })
            //setUserProcessMessage('')
        }

    }

    const handleGetRootCauseAnalysis = async (func) => {
        // clearInterval(intevalId)
        if (progressBar.state < 7) {
            setProgressBar({ state: 7, text: 'Prepering The Root Cause Analysis...' })
        }
        let rootCauseResposne = await getRootCauseAnalysis(func.reportId);
        let reportForServiceUpdate = [...reportForService]
        let objectToUpdate = _.find(reportForServiceUpdate, { 'id': func.id });

        if (objectToUpdate) {
            // Update the properties of the object
            _.assign(objectToUpdate, { rootCause: rootCauseResposne.data.data });
            // You can update other properties as needed           
            setReportForService([...reportForServiceUpdate]);
            setReportForServiceCache([...reportForServiceUpdate])


        }

    }


    const updateChangeDetected = async (clonedObject) => {



        clonedObject?.map(async (status) => {
            //Parent service


            status.fetchReport = 'NotStarted'

            //GET PARENT REPORT    
            let parentReportObj = null;
            if (status.collectDataStatus === "Succeded" && status.investigateStatus === "Succeded"
                && reportForService.filter((r) => r.id === status.id).length === 0
            ) {
                status.fetchReport = 'Started'


                let reportResposne = await getReportDetailsByServiceId(status.id, reqId);
                console.log("REPORT RUNNING DONE", status.id)
                status.fetchReport = 'Done'
                if (reportResposne?.data?.data?.insights?.length > 0) {

                    parentReportObj = {
                        reportId: reportResposne?.data?.data.id, id: status.id, parentId: null,
                        report: reportResposne?.data?.data?.insights, incident: reportResposne?.data?.data?.incident,
                        events: reportResposne?.data?.data?.events, rootCause: null, rootCauseStatus: null,
                        attempt: 0
                    };
                    setReportForService(prevArray => [...prevArray, parentReportObj]);
                    setReportForServiceCache(prevArray => [...prevArray, parentReportObj])

                }
                else {
                    let parentReportObj = {
                        reportId: reportResposne?.data?.data.id,
                        id: status.id, parentId: null, report: [], incident: null, events: null
                        , rootCause: null, rootCauseStatus: null, attempt: 0
                    };


                    setReportForService(prevArray => [...prevArray, parentReportObj]);
                    setReportForServiceCache(prevArray => [...prevArray, parentReportObj]);
                }

            }



            //Child
            if (status.services.length > 0) {
                status.services.map(async (child) => {

                    child.fetchReport = "NotStarted"
                    let childReportObj = null;
                    if (child.collectDataStatus === "Succeded" && child.investigateStatus === "Succeded"
                        && reportForService.filter((r) => r.id === child.id).length === 0) {
                        child.fetchReport = "Started"

                        let reportResponse = await getReportDetailsByServiceId(child.id, reqId);
                        console.log("REPORT RUNNING DONE", child.id)
                        child.fetchReport = "Done"



                        if (reportResponse?.data?.data?.insights?.length > 0) {


                            childReportObj = {
                                reportId: reportResponse?.data?.data.id, id: child.id,
                                parentId: status.id, report: reportResponse?.data?.data?.insights,
                                incident: reportResponse?.data?.data?.incident,
                                events: reportResponse?.data?.data?.events, rootCause: null, rootCauseStatus: null,
                                attempt: 0
                            };


                            setReportForService(prevArray => [...prevArray, childReportObj]);
                            setReportForServiceCache(prevArray => [...prevArray, childReportObj]);


                        }
                        else {
                            childReportObj = {
                                reportId: reportResponse?.data?.data.id, id: child.id, parentId: status.id, report: [],
                                incident: null, events: null
                                , rootCause: null, rootCauseStatus: null,
                                attempt: 0
                            };

                            setReportForService(prevArray => [...prevArray, childReportObj]);
                            setReportForServiceCache(prevArray => [...prevArray, childReportObj]);

                        }

                    }

                })

            }


        })

        setServicesAndStatus(clonedObject)
    }



    const getRootCauseAnalysis = async (reportId) => {
        return await rootCauseAnalysis(reportId);
    }


    const getReportDetailsByServiceId = async (serviceId, reqId) => {
        return await report(reqId, serviceId);

    }


    const GetServiceNameById = (id) => {
        let name = servicesCached.filter(ser => ser.id === id);
        if (name && name.length > 0) {
            return name[0].name
        }
        return "Service does not exist in the catalog";
    }

    const ReturnDivByStatus = (status, fetch) => {

        switch (status) {


            case "Pending":
                {
                    return (
                        <div className='linear-wipe'>Pending</div>
                    )
                }
            case "InProcess":
                {
                    return (
                        <div className='linear-wipe'>In Process</div>
                    )
                }
            case "Failed":
                {
                    return (
                        <div className='analyzer-service-process-failed'>Failed</div>
                    )
                }
            case "Succeded":
                {
                    return (
                        <div className='analyzer-service-process-success'>Succeded</div>
                    )
                }
            case "Unknown":
                {
                    return (
                        <div className='analyzer-service-process-unknown'>Unknown</div>
                    )
                }
            default:
                break;
        }
    }

    const getReport = async (serviceId, reqId) => {


        let service = reportForService.filter(r => r.id === serviceId);
        if (service && service.length > 0 && service[0].report !== null) {
            setInsightsPerService(service[0].report);
            setShowAnalyzerReport(true)
            setSelectedReportServiceName(GetServiceNameById(serviceId))
            setSelectedReportServiceID(serviceId)
        }

    }
    const RenderChangesDetected = (props) => {


        if (reportForService && reportForService.length > 0) {

            let service = reportForService.filter(r => r.id === props.id);
            if (service && service.length > 0) {

                let hasReport = []
                if (service[0]?.report) {

                    let filteredArray = []
                    filteredArray = removeDiagnoseFromReport(service[0]?.report);

                    hasReport = filteredArray.filter(s => s.code.toLowerCase() !== consts.INFRA_CHANGES && s.code.toLowerCase() !== consts.HEALTH_CHANGES)
                }


                return (
                    <React.Fragment>
                        {
                            service[0].incident !== null && hasReport.length > 0 ?
                                (
                                    <TableCell><CheckBoxIcon /></TableCell>
                                ) :
                                (
                                    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                                )
                        }
                        {

                            hasReport !== null && hasReport.length > 0 ?
                                (
                                    <TableCell><CheckBoxIcon /></TableCell>
                                ) :
                                (
                                    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                                )
                        }

                        <TableCell>{displayChangesModule(hasReport)}</TableCell>
                    </React.Fragment>
                )

            }
        }
        return (
            <React.Fragment>
                <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                <TableCell></TableCell>
            </React.Fragment>
        )



    }


    const RenderChildCells = ({ value }) => {

        if (!value) {
            return (
                <React.Fragment>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <TableCell >{GetServiceNameById(value.id)} - {value.id}</TableCell>
                <TableCell>{ReturnDivByStatus(value.collectDataStatus)}</TableCell>
                <TableCell>{ReturnDivByStatus(value.investigateStatus)}</TableCell>
            </React.Fragment>
        )


    }
    const removeDiagnoseFromReport = (array) => {
        if (!array || array?.length === 0) {
            return [];
        }
        let clonedArray = [...array]
        let diagnoseList = [consts.DIAGNOSTICS_SCALING_OPERATIONS, consts.DIAGNOSTICS_ALERT, consts.DIAGNOSTICS_CPU_DRILL_DOWN, consts.DIAGNOSTICS_EVENTS_LOGS,
        consts.DIAGNOSTICS_EVENTS_LOGS, consts.DIAGNOSTICS_MEMORY_DRILL_DOWN, consts.DIAGNOSTICS_KV_APP_SETTINGS,
        consts.DIAGNOSTICS_SNAT_PORT_EXHAUSTION, consts.DIAGNOSTICS_SWAP, consts.DIAGNOSTICS_TCP,
        consts.DIAGNOSTICS_SSL, consts.DIAGNOSTICS_RESTART]


        let filteredArray = []
        filteredArray = clonedArray.filter(r => !diagnoseList.includes(r.code.toLowerCase()));
        return filteredArray;
    }

    const displayChangesModule = (values, id) => {


        let filteredArray = []
        filteredArray = removeDiagnoseFromReport(values);


        let changes = [];
        if (filteredArray) {
            filteredArray.map((value) => {
                if (!changes.includes(value.module)) {
                    changes.push(value.module)
                }
            })
        }

        return changes.join(' | ');
    }



    const RenderChildChangeDetected = ({ value }) => {

        if (!value) {
            return (
                <React.Fragment></React.Fragment>
            )
        }



        if (reportForService && reportForService.length > 0) {

            let service = reportForService.filter(r => r.id === value.id);

            if (service && service.length > 0) {

                let hasReport = []
                if (service[0]?.report) {
                    //hasReport = service[0]?.report?.filter(s => s.code.toLowerCase() !== consts.INFRA_CHANGES && s.code.toLowerCase() !== consts.HEALTH_CHANGES)

                    let filteredArray = []
                    filteredArray = removeDiagnoseFromReport(service[0]?.report);

                    hasReport = filteredArray.filter(s => s.code.toLowerCase() !== consts.INFRA_CHANGES && s.code.toLowerCase() !== consts.HEALTH_CHANGES)
                }



                return (
                    <React.Fragment>
                        {
                            service[0].incident !== null && hasReport.length > 0 ?
                                (
                                    <TableCell><CheckBoxIcon /></TableCell>
                                ) :
                                (
                                    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                                )
                        }
                        {

                            hasReport !== null && hasReport.length > 0 ?
                                (
                                    <TableCell><CheckBoxIcon /></TableCell>
                                ) :
                                (
                                    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                                )
                        }
                        <TableCell>{displayChangesModule(hasReport)}</TableCell>
                    </React.Fragment>
                )

            }
        }


        return (
            <React.Fragment>
                <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                <TableCell></TableCell>
            </React.Fragment>
        )

    }

    const displayDetailsLink = (value) => {

        if (reportForServiceCache && reportForServiceCache?.length > 0) {
            let service = reportForServiceCache.filter(x => x.id === value);
            if (service && service?.length > 0) {

                let insightsWitoutInfra = service[0]?.report?.filter(s => s.code.toLowerCase() !== consts.INFRA_CHANGES && s.code.toLowerCase() !== consts.HEALTH_CHANGES)
                if (insightsWitoutInfra && insightsWitoutInfra?.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    const RenderChildLinks = ({ value }) => {

        if (!value) {
            return (
                <TableCell></TableCell>
            )
        }
        return (

            /*value.collectDataStatus === "Succeded" && value.investigateStatus === "Succeded"
                && reportForService.filter((r) => r.id === value.id).length > 0
                && reportForService.filter((r) => r.id === value.id)[0].report !== null
                ?
                (
                    <TableCell><div className='enable-div' onClick={() => getReport(value.id, reqId)}>Details</div></TableCell>
                ) :
                (
                    <TableCell><div className='disabled-div'>Details</div></TableCell>
                )*/
            value.collectDataStatus === "Succeded" && value.investigateStatus === "Succeded"
                && displayDetailsLink(value.id) && progressBar.state === 8
                ?
                (
                    <TableCell><div className='enable-div' onClick={() => getReport(value.id, reqId)}>Details</div></TableCell>
                ) :
                (
                    <TableCell><div className='disabled-div'>Details</div></TableCell>
                )


        )


    }

    const getReportInfra = () => {
        //setInsightsPerService(report);

        setShowAnalyzerInfraReport(true)
        let name = "Infrastructure Details & Microsoft Health";
        setSelectedReportServiceName(name)

    }

    const RenderInfraInsights = () => {



        if (reportForServiceCache && reportForServiceCache.length > 0) {


            let clonedReportForService = [...reportForServiceCache]
            let infraInsightsExist = [];
            let healthInsightsExist = [];
            clonedReportForService.map((item) => {

                if (infraInsightsExist === null || infraInsightsExist?.length === 0) {
                    infraInsightsExist = item?.report?.filter(r => r.module.toString().toLowerCase() === "resourcechanges" && r.code.toLowerCase() === consts.INFRA_CHANGES)
                }

                if (healthInsightsExist === null || healthInsightsExist?.length === 0) {
                    healthInsightsExist = item?.report?.filter(r => r.code.toString().toLowerCase() === "impact")
                }



            })
            return (
                <React.Fragment>
                    {
                        infraInsightsExist && infraInsightsExist.length > 0 ?// && progressBar.state === 8 ?
                            (
                                <TableRow>
                                    <TableCell>Infrastructure changes detected</TableCell>
                                    <TableCell><div onClick={() => getReportInfra()} className='enable-div'>Details</div></TableCell>
                                </TableRow>
                            ) :
                            (

                                <TableRow>
                                    <TableCell>Infrastructure changes not detected</TableCell>
                                    <TableCell><div className='disabled-div'>Details</div></TableCell>
                                </TableRow>
                            )
                    }
                    {
                        healthInsightsExist && healthInsightsExist.length > 0 ?// && progressBar.state === 8 ?
                            (
                                <TableRow>
                                    <TableCell>Microsoft health detected</TableCell>
                                    <TableCell><div onClick={() => getReportInfra()} className='enable-div'>Details</div></TableCell>
                                </TableRow>
                            ) :
                            (
                                <TableRow>
                                    <TableCell>Microsoft health not detected</TableCell>
                                    <TableCell><div className='disabled-div'>Details</div></TableCell>
                                </TableRow>
                            )
                    }

                    <ServiceAnalyzerReportInfraModal show={showAnalyzerInfraReport} serviceName={selectedReportServiceName}
                        setShowAnalyzerInfraReport={setShowAnalyzerInfraReport} modules={{
                            infra: infraInsightsExist,
                            health: healthInsightsExist
                        }} />

                </React.Fragment>
            )




        }
        if (!data?.stop || data.stop === false) {


            return (
                <React.Fragment>
                    <TableRow>
                        <TableCell><div className='linear-wipe'>Fetching data for Infrastructure Changes</div></TableCell>
                        <TableCell><div className='disabled-div'>Details</div></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><div className='linear-wipe'>Fetching data for Microsoft Health</div></TableCell>
                        <TableCell><div className='disabled-div'>Details</div></TableCell>
                    </TableRow>
                </React.Fragment>
            )



        }
        return (
            <span></span>
        )
    }

    const RenderRisk = ({ value }) => {
        let service = reportForServiceCache?.filter(x => x.id === value.id);
        if (service && service.length > 0) {
            if (service[0]?.incident) {

                if (service[0]?.incident?.risk) {
                    return (
                        <TableCell>{service[0].incident.risk}</TableCell>
                    )
                }
                return (
                    <TableCell></TableCell>
                )
            }
        }

        return (
            <TableCell></TableCell>
        )
    }
    const RenderRecommandation = ({ value }) => {



        let service = reportForServiceCache?.filter(x => x.id === value.id);

        if (service && service.length > 0) {
            if (service[0]?.report) {


                let filteredArray = []
                filteredArray = removeDiagnoseFromReport(service[0]?.report);



                if (filteredArray.length < service[0]?.report.length) {
                    return (
                        <TableCell><CheckBoxIcon /></TableCell>
                    )
                }
                return (
                    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
                )
            }
        }

        return (
            <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
        )
    }




    return (
        <React.Fragment>




            <div className='analyzer-service-space'>
                <TableContainer component={Paper} className='service-analyzer-table-box'>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Collect Data Status</TableCell>
                                <TableCell>Investigate Service Status</TableCell>
                                <TableCell>Incident Detected</TableCell>
                                <TableCell>Changes Detected</TableCell>
                                <TableCell>Changes Description</TableCell>
                                <TableCell>Recommendation</TableCell>
                                <TableCell>
                                    <div style={{ position: 'relative' }}>Risk
                                        <div className='analyzer-service-beta' >BETA</div>
                                    </div>
                                </TableCell>
                                <TableCell>Report</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                servicesAndStatus && servicesAndStatus.length > 0 ?
                                    (

                                        servicesAndStatus.map((service, index) => (
                                            <React.Fragment>
                                                <TableRow className='analyzer-service-major-element' key={`name-element` + index}>
                                                    <TableCell >{GetServiceNameById(service.id)}- {service.id}</TableCell>
                                                    <TableCell>{ReturnDivByStatus(service.collectDataStatus)}</TableCell>

                                                    <TableCell>{ReturnDivByStatus(service.investigateStatus)}</TableCell>

                                                    <RenderChangesDetected id={service.id} />
                                                    <RenderRecommandation value={service} />
                                                    <RenderRisk value={service} />
                                                    {

                                                        service.collectDataStatus === "Succeded" && service.investigateStatus === "Succeded"
                                                            && displayDetailsLink(service.id) && progressBar.state === 8
                                                            ?
                                                            (
                                                                <TableCell><div className='enable-div' onClick={() => getReport(service.id, reqId)}>Details</div></TableCell>
                                                            ) :
                                                            (
                                                                <TableCell><div className='disabled-div'>Details</div></TableCell>
                                                            )
                                                    }


                                                </TableRow>

                                                <React.Fragment>
                                                    {
                                                        service.services.map((s, innerIndex) => (


                                                            <TableRow className='analyzer-service-child-element' key={`name-sub-service-element` + innerIndex}>

                                                                <RenderChildCells value={s} />

                                                                <RenderChildChangeDetected value={s} />


                                                                <RenderRecommandation value={s} />

                                                                <RenderRisk value={s} />

                                                                <RenderChildLinks value={s} />


                                                            </TableRow>

                                                        ))
                                                    }
                                                </React.Fragment>
                                                <TableRow>
                                                    <TableCell className='analyzer-service-cell-space' colSpan={4}>&nbsp;</TableCell>
                                                </TableRow>
                                            </React.Fragment>

                                        ))

                                    ) :
                                    (
                                        <span></span>
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='analyzer-service-space'></div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Insights Type</TableCell>
                                <TableCell>Report</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data?.status === "Done" ?
                                    (
                                        <RenderInfraInsights />
                                    ) :
                                    (
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell className='linear-wipe'>Scanning for Microsoft health updates</TableCell>
                                                <TableCell><div className='disabled-div'>Details</div></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='linear-wipe'>Scanning for Infrastructure changes</TableCell>
                                                <TableCell><div className='disabled-div'>Details</div></TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                            }

                        </TableBody>

                    </Table>
                </TableContainer>

            </div>
            <ServiceAnalyzerReportModal show={showAnalyzerReport} serviceName={selectedReportServiceName} serviceId={selectedReportServiceID}
                setShowAnalyzerReport={setShowAnalyzerReport} data={insightsPerService} servicesAndStatus={servicesAndStatus}
                reportForService={reportForServiceCache} isDone={data}
            />



        </React.Fragment>
    )
}

export default ServiceAnalyzerTable