import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerRootCause.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';

const ServiceAnalyzerRootCause = ({ data, insights }) => {


  
    const RenderRootCause = ({ value }) => {


        if (value && value.length > 0) {
            let modules = '';
            for (let i = 0; i < value.length; i++) {
                if (value.length === i + 1) {
                    modules += value[i].module
                }
                else {
                    modules += value[i].module + ', '
                }

            }
            let cause = value.length === 1 ? 'cause: ' : 'causes: ';
            let text = 'The AI mechanism was detected ' + value.length + ' major root ' + cause + modules;

            return (
                <TableCell colSpan={5}>
                    {text}
                </TableCell>
            )
        }
        return (
            <TableCell colSpan={5}>

            </TableCell>
        )

    }

    const RenderAIModule = ({ value, type }) => {




        switch (type) {
            case "classifications":
                {

                    return (

                        value?.map((v) => (
                            <TableRow>
                                <TableCell>{type}</TableCell>
                                <TableCell>{getInsightByValue(v.insightId)}</TableCell>
                                <TableCell>{getAIExplanation(v.classifiedModules)}</TableCell>
                            </TableRow>
                        ))


                    )
                }


            default:
                return (
                    <TableRow>
                        <TableCell colSpan={3}></TableCell>
                    </TableRow>
                )
        }


    }

    const getAIExplanation = (values) => {
        return (

            values.map((v) => (
                <div>
                    <div>{v.module}: {v.explanation}</div>
                    <div>Score: {v.score}</div>
                </div>
            ))

        )
    }


    const getInsightByValue = (id) => {
        return Object.entries(insights).map(([k, v]) => {

            if (insights[k] && k !== 'RootCause' && k !== 'Summary') {
                let t = insights[k].some(obj => obj.id === id)
                if (t) {
                    return k
                }
                return ''

            }

        });

    }


    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>AI MODULE</TableCell>
                            <TableCell>INSIGHT</TableCell>
                            <TableCell>EXPLANATION, MODULE AND SCORE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(data).map(([key, value], index) => (


                                key !== 'rootCauses' ?
                                    (
                                        <RenderAIModule value={value} type={key} />
                                    ) :
                                    (

                                        <TableRow>
                                            <RenderRootCause value={value} />
                                        </TableRow>

                                    )







                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default ServiceAnalyzerRootCause