import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './SelfServiceModalStepper.css';
import DataContext from '../../Context/DataContext';

const steps = ['Select self service flow', 'Select platform', 'Summary'];

const SelfServiceModalStepper = ({ setRepoDecision, repoDecision, setDecision, decision, setCloudDecision, cloudDecision,
    setModalShow, typeDecision, setTypeDecision, setMigrationType, migrationType }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const { isAdminUser } = React.useContext(DataContext)

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleTypeDecision = (type) => {
        setTypeDecision(type)

    }
    const handleRepoDecision = (type) => {
        setRepoDecision(type)
    }
    const handleDecision = (type) => {
        setDecision(type)

    }
    const handleCloudDecision = (type) => {

        setCloudDecision(type)
    }

    const handleMigrationDecision = (type) =>{
        setMigrationType(type)
    }

    const handleSubmit = () => {
        setModalShow(false)

    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                handleSubmit()
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                        {
                            activeStep === 0 ?
                                (
                                    <div>
                                        <span onClick={() => handleTypeDecision('new')} className={typeDecision === 'new' ? 'stepper-item-selected' : 'stepper-item'} id='self-service-btn-new-project'>Create New Project</span>
                                        <span onClick={() => handleTypeDecision('migrate')} className={typeDecision === 'migrate' ? 'stepper-item-selected' : 'stepper-item'} id='self-service-btn-service-migration'>Service Migration</span>
                                    </div>
                                ) : activeStep === 1 ?
                                    (
                                        typeDecision === 'new' ?
                                            (
                                                <div>
                                                    {
                                                        isAdminUser && 
                                                        (
                                                            <React.Fragment>
                                                                <div className="self-service-stapper-instruction">Source code repository platform</div>
                                                                <div>
                                                                    <span onClick={() => handleCloudDecision('azure')} className={cloudDecision === 'azure' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-azure'>Azure Devops</span>
                                                                    <span onClick={() => handleCloudDecision('github')} className={cloudDecision === 'github' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-github'>GitHub</span>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }

                                                    <div className="self-service-stapper-instruction">On which platform would you like the service to be created</div>
                                                    <div>
                                                        <span onClick={() => handleDecision('aks')} className={decision === 'aks' ? 'ask-eks-modal-item-selected' : 'ask-eks-modal-item'} id='self-service-btn-aks'>AKS</span>
                                                        <span onClick={() => handleDecision('eks')} className={decision === 'eks' ? 'ask-eks-modal-item-selected' : 'ask-eks-modal-item'} id='self-service-btn-eks'>EKS</span>
                                                        <span onClick={() => handleDecision('both')} className={decision === 'both' ? 'ask-eks-modal-item-selected' : 'ask-eks-modal-item'} id='self-service-btn-both'>Both</span>
                                                    </div>

                                                    <div className="self-service-stapper-instruction">Do you want to create a new repository or using existing repository?</div>
                                                    <div>
                                                        <span onClick={() => handleRepoDecision('new')} className={repoDecision === 'new' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-nr' >New Repository</span>

                                                        <span onClick={() => handleRepoDecision('existing')} className={repoDecision === 'existing' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-er'>Existing Repository</span>

                                                    </div>
                                                </div>
                                            ) :
                                            (
                                                <div>
                                                   {
                                                     isAdminUser &&
                                                     (
                                                     <React.Fragment>
                                                         <div className="self-service-stapper-instruction">Source code repository platform</div>
                                                         <div>
                                                             <span onClick={() => handleCloudDecision('azure')} className={cloudDecision === 'azure' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-azure'>Azure Devops</span>
                                                             <span onClick={() => handleCloudDecision('github')} className={cloudDecision === 'github' ? 'ask-eks-repo-modal-item-selected' : 'ask-eks-repo-modal-item'} id='self-service-btn-github'>GitHub</span>
                                                         </div>
                                                     </React.Fragment>
                                                     )
                                                   }
                                                    <div>
                                                        <div className="">Into which platform would you like to migrate the service</div>
                                                        <div>
                                                        <span onClick={() => handleMigrationDecision('serviceMigrationAks')} className={migrationType === 'serviceMigrationAks' ? 'ask-eks-modal-item-selected' : 'ask-eks-modal-item'} id='self-service-btn-migrationAks'>AKS</span>
                                                        <span onClick={() => handleMigrationDecision('serviceMigrationEks')} className={migrationType === 'serviceMigrationEks' ? 'ask-eks-modal-item-selected' : 'ask-eks-modal-item'} id='self-service-btn-migrationEks'>EKS</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            )

                                    ) :
                                    (
                                        <ul className='self-service-modal-stepper-ul'>
                                            {
                                                  isAdminUser && cloudDecision === 'azure' &&
                                                  (
                                                    <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Repository platform - Azure </li>
                                                  )                                                 
                                            }
                                            {
                                                 isAdminUser && cloudDecision !== 'azure' &&
                                                 (
                                                   <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Repository platform - GitHub </li>
                                                 )
                                            }
                                            {
                                                typeDecision === 'new' ?
                                                    (
                                                        <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Create new project </li>
                                                    ) :
                                                    (
                                                        <React.Fragment>
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Service migration</li>
                                                            {
                                                                migrationType === 'serviceMigrationAks' ?
                                                                (
                                                                    <li className='self-service-modal-stepper-li self-service-stapper-instruction'>AKS</li>
                                                                ):
                                                                (
                                                                    <li className='self-service-modal-stepper-li self-service-stapper-instruction'>EKS</li>
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    )
                                            }
                                            {
                                                typeDecision === 'new' &&
                                                (
                                                    decision === 'aks' ?
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform AKS</li>
                                                        ) : decision === 'eks' ?
                                                            (
                                                                <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform EKS</li>
                                                            ) :
                                                            (
                                                                <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform AKS & EKS</li>
                                                            )
                                                )

                                            }
                                            {
                                                typeDecision === 'new' &&
                                                (
                                                    repoDecision === 'new' ?
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Create new repository</li>
                                                        ) :
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Edit existing repository</li>
                                                        )
                                                )

                                            }
                                        </ul>

                                    )

                        }

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            id='self-service-btn-back'
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button id={activeStep === steps.length - 1 ? 'self-service-btn-finish' : 'self-service-btn-next'} onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
export default SelfServiceModalStepper