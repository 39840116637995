import jwt_decode from 'jwt-decode';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useContext } from 'react';
import DataContext from "../Context/DataContext";
import * as consts from '../consts';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useState } from 'react';
import { EventType } from "@azure/msal-browser";
import * as msal from "@azure/msal-browser";
import { useHistory, useParams } from 'react-router-dom';
import { DataContextChat } from '../Context/DataContextChat';



const useLogin = () => {

  


  const history = useHistory();
  const [isEditorUser, setIsEditorUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isUserAuth, setIsUserAuth] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const [userToken, setUserToken] = useState('');
  const [loginState, setLogin] = useState({
    error: null,
    isAuthenticated: false,
    user: {},
  });

  /*const getTokenExpiredDate = async () =>{
    const msalInstance = initPublicClientApplication();
     const currentSession = await msalInstance.acquireTokenSilent({
      account:  accounts[0],
      scopes: ['User.Read'],
    });  
    return currentSession.expiresOn;
    
  }*/

  const userLogin = async () => {
    
    try {
      const msalInstance = initPublicClientApplication();
     
      
      const accounts = msalInstance.getAllAccounts();
      console.log('LOGIN!!!',accounts)


      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      msalInstance.addEventCallback(
        (event) => {
          // set active account after redirect
          if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload.account
          ) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
      
      let authResult = await msalInstance.handleRedirectPromise();
      
      const account = msalInstance.getActiveAccount();
      
      if (!account) {
        
        // redirect anonymous user to login page        
        
      
        //OPEN TEMP
        msalInstance.loginRedirect();
        //OPEN TEMP
        
      } else {
        
        return await getToken(account, msalInstance, getScopes());
        
      }
    }
    catch (error) {
      
      console.log(error);
    }
  };

  const handleLoginState = (name, username, accessToken, isLogout) => {

    setLogin(
      !isLogout ? { isAuthenticated: true } : { isAuthenticated: false }
    );
    setIsUserAuth(true);
    /*setUserAccount({
      user: !isLogout ? name : null,
      userName: !isLogout ? username : null,
    });*/
    setUserToken(!isLogout ? accessToken : null);

    console.log('TOKEN')
  };

  const handleLogout = () => {
    const msalInstance = initPublicClientApplication();
    msalInstance.logout();
    handleLoginState(null, null, null, true);
    localStorage.removeItem('InfraConsoleToken')
    localStorage.removeItem('InfraConsoleName')
    localStorage.removeItem('InfraConsoleExpired')
    localStorage.removeItem('InfraConsoleUserName')
  };

  const initPublicClientApplication = () => {
    
    const msalConfig = {
      auth: {
        clientId: process.env.REACT_APP_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        scopes: getScopes()      
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    return new msal.PublicClientApplication(msalConfig);
  }
  const getScopes = () => {
    
    const scopesArray = {

      impersonation: process.env.REACT_APP_SCOPES_DEFAULT,
    };
    return Object.values(scopesArray);
  }


  const getToken = async (accountInfo, msalInstance, scopes) => {
    //let request: msal.SilentRequest = {
    let request = {
      scopes: scopes,
      account: accountInfo // (or null?)
      
    };

    try {
      let tokenResponse = await msalInstance.acquireTokenSilent(request);
      //save token to local storage
      localStorage.setItem('InfraConsoleToken', tokenResponse?.accessToken)
      const decoded = jwt_decode(tokenResponse?.accessToken);

      localStorage.setItem('InfraConsoleName', decoded.name)
      localStorage.setItem('InfraConsoleUserName', decoded.upn)
      localStorage.setItem('InfraConsoleExpired', decoded.exp)
      localStorage.setItem('InfraConsoleUserId', decoded.oid)

      handleLoginState(
        accountInfo.name,
        accountInfo.username,
        tokenResponse?.accessToken,
        false
      );

      if (tokenResponse?.idTokenClaims?.roles && tokenResponse?.idTokenClaims?.roles.length > 0) {

        setIsEditorUser(true)
        setIsAdminUser(true)
      }
      //return tokenResponse.accessToken;
      return tokenResponse;

    }
    catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        history.push('/NotAuthorized')
        return msalInstance.acquireTokenRedirect(request);
      } else {
        console.log(error);
      }
    }


  };







  return { userToken, isUserAuth, userAccount, isEditorUser, isAdminUser, loginState, userLogin };
}
export default useLogin