import React, { useEffect, useState, useContext, useRef, lazy } from 'react'
import './SADump.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as consts from '../../../consts';
import { DateTime, DateTime as luxon } from "luxon";
import _ from "lodash";
import DataContext from '../../../Context/DataContext';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import SCLine from '../../SCLine';
import SCButton from '../../SCButton';
import spinner from '../../../Images/SmallSpinner.svg';




const SADump = ({ service, requestId }) => {

    const { getDump, postDump, getPODS, createAKSNamespace, dump, setDump, loadingDumpStatus, setLoadingDumpStatus } = useContext(DataContextAnalyzer);

   

    const MAX_OBJECT_ITEM_ANALYSIS = 100
    const START_INTERVAL_DURATION = 20000
    const ATTEMPT = 20


    useEffect(async () => {

        let dumpReady = dump.filter(x => x.state === 'done');
        dumpReady.map(async (d) => {

            try {
               
                d.file = d.data.data.content.memoryDumpUrl;
                d.state = 'fileReady';
                setDump((prevArray) =>
                prevArray.map((item) =>
                    item.pod === d.pod
                        ? {
                            ...item,
                            state: 'fileReady',
                            file: d.data.data.content.memoryDumpUrl
                        }
                        : item
                )
            );
                /*const a = document.createElement('a');
                a.href = d.data.data.content.memoryDumpUrl;
                let fileName = service.name + '_dump.dmp'
                a.download = fileName

               
                a.click();

                
                window.URL.revokeObjectURL(d.data.data.content.memoryDumpUrl);*/
            } catch (error) {
                console.error('Error downloading file:', error);
            }


        })

    }, [dump])



    const getRequestDumpAndDetails = async (pod, namespace) => {
        setLoadingDumpStatus("Generating request id for dump data...")
        let response = await postDump(namespace, pod, MAX_OBJECT_ITEM_ANALYSIS);

        if (response.status === 200 && response.data.content) {
            setDump(prevObject => [...prevObject, { requestId: response.data.content, namespace: namespace, pod: pod, data: [], file:'', state: 'created' }]);
            setLoadingDumpStatus("Fetching memory dump measurements...")
            let data = await getServiceDump(response.data.content);
            if (data.status === 200) {
                setDump((prevArray) =>
                    prevArray.map((item) =>
                        item.pod === pod
                            ? {
                                ...item,
                                state: 'started',
                                data: data
                            }
                            : item
                    )
                );
                console.log("***** start interval ******")
                startInterval(response.data.content, pod, 0)
            }



        }
    }

    //data
    const getServiceDump = async (requestId) => {
        return await getDump(requestId);
    }

    const startInterval = (requestId, pod, count) => {

        const intervalId = setInterval(async () => {
            try {
                let data = await getServiceDump(requestId);
                count++
                setLoadingDumpStatus("Dump return with status " + data.data.content.requestStatus)
                console.log("***** dump interval number  ******", count)
                if (data.status === 200) {
                    if (data.data.content.requestStatus === "Completed") {
                        setLoadingDumpStatus("done")
                        clearInterval(intervalId)
                        setDump((prevArray) =>
                            prevArray.map((item) =>
                                item.pod === pod
                                    ? {
                                        ...item,
                                        state: 'done',
                                        data: data
                                    }
                                    : item
                            )
                        );
                    }
                    if (data.data.content.requestStatus === "Failed") {
                        setLoadingDumpStatus("done")
                        console.log("**** dump fetch failed ****")
                        //alert error
                    }



                }
                if (count === ATTEMPT) {
                    setLoadingDumpStatus("done")
                    console.log("**** end by max attemp ****")
                    clearInterval(intervalId)
                }
            } catch (error) {
                console.log("**** dump fetch failed due to exception ****", error)
                clearInterval(intervalId)
            }

        }, START_INTERVAL_DURATION);
    }


    const handleGetDumpFile = async () => {
        const controller = new AbortController();
        setDump([])
        setLoadingDumpStatus("Starting process...")
        let namespace = createAKSNamespace(service.clusterName, service.name);
        try {
            setLoadingDumpStatus("Get pods for AKS namespace...")
            let response = await getPODS(namespace);
            if (response.status === 200 && response.data.content.length > 0) {
                let podsArray = response.data.content;
                podsArray.map((pod) => {
                    getRequestDumpAndDetails(pod, namespace)
                })


            }
            return () => {
                controller.abort(); // Cancel the fetch request
            };

        } catch (error) {
            /* console.log("Error in scan api", error)*/
        }
    }



    return (
        <React.Fragment>
            <div >
                <SCButton text="Create Dump File" handleButtonClick={() => handleGetDumpFile()} />
            </div>
            <div>
                {
                    loadingDumpStatus !== "" && loadingDumpStatus !== "done" ?
                        (
                            <div className='sa-measurements-linear-wipe'><img src={spinner} className='sa-measurements-spinner-icon' />{loadingDumpStatus}</div>
                        ) :
                        (
                            <span></span>
                        )
                }
            </div>


            {
                dump && dump.filter(x => x.state === 'fileReady').length > 0 ?
                    (
                        dump.map(d => (
                            <div >
                                <div className='sa-dump-message'>Dump file for POD: <span className='sa-dump-pod'>{d.pod}</span> has been completed.</div>
                                <div><a href={d.file} download={d.file}>Download</a></div>
                            </div>
                        ))

                    ) :
                    (
                        <span></span>
                    )
            }

        </React.Fragment>
    )
}

export default SADump