import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerSummary.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ServiceAnalyzerTimeline from './ServiceAnalyzerTimeline';

const ServiceAnalyzerSummary = ({ data, setValue, insights, servicesAndStatus, serviceId, reportForService, isDone }) => {

    const { servicesCached, formatDateTime } = useContext(DataContext);
    const { LuxonDateFormatString } = useContext(DataContextAnalyzer);
    const [modules, setModules] = useState({})



    useEffect(async () => {
        if (!data) {
            return;
        }
        let cloneModules = { ...data };
        if (cloneModules.hasOwnProperty("Summary")) {
            delete cloneModules["Summary"];
            setModules(cloneModules)
        }

    }, [data])



    const RenderDateTime = (values) => {

        if (values.values.length > 0) {
            return (
                <div>{LuxonDateFormatString(values.values[0].occurredAt)}</div>
            )
        }
        return (
            <div>-</div>
        )
    }

    const RenderDetectedChanges = (values) => {
        if (values.values.length > 0) {
            return (
                <div><CheckBoxIcon /></div>
            )
        }
        return (
            <div><CheckBoxOutlineBlankIcon /></div>
        )
    }

    const handleChangeTab = (key) => {
        switch (key) {
            case "Configuration":
                {
                    setValue(2)
                    break;
                }
            case "Database":
                {
                    setValue(3)
                    break;
                }
            case "Deployments":
                {
                    setValue(4)
                    break;
                }
            case "Diagnostics":
                {
                    setValue(5)
                    break;
                }
            case "Logs":
                {
                    setValue(6)
                    break;
                }
            case "Metrics":
                {
                    setValue(7)
                    break;
                }
            case "ResourceChanges":
                {
                    setValue(8)
                    break;
                }


            default:
                break;
        }
    }

   

    const RenderTimeline = () => {
        
        if(insights && insights.length > 0){
            const modulesToCheck = ['deployments', 'configuration', 'database', 'metrics', 'resourcechanges'];
            const hasModule = insights.some(item => modulesToCheck.includes(item.module.toLowerCase()));
            if (hasModule) {
                
                return (
                    <div className='service-analyzer-summary-timeline-container'>
                        <div className='service-analyzer-summary-timeline-container-title'>Timeline</div>
                        <div className='service-analyzer-summary-timeline-contorller'>
                            <ServiceAnalyzerTimeline serviceId={serviceId}
                                reportForService={reportForService}
                            />
                        </div>
                    </div>
                )
                
              } else {
               
                return (
                    <span>We have not found any insight that can be displayed on the timeline</span>
                )
                
              }
        }
        return(
            <React.Fragment></React.Fragment>
        )

       
    }


    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date Time</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Changes detected</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(modules).map(([key, values], index) => (
                                <React.Fragment>
                                    <TableRow key={`name-element` + index}>
                                        {/*   <TableCell >{GetServiceNameById(values.id)}</TableCell>*/}
                                        <TableCell >
                                            <RenderDateTime values={values} />
                                        </TableCell>
                                        {
                                            key !== "Summary" ?
                                                (

                                                    key === "Metrics" ?
                                                        (
                                                            <TableCell>Metrics(Traffic, Response Time, CPU, Memory)</TableCell>
                                                        ) :
                                                        key === "Logs" ?
                                                            (
                                                                <TableCell>Logs Error</TableCell>
                                                            ) :
                                                            (
                                                                <TableCell>{key}</TableCell>
                                                            )


                                                ) :
                                                (
                                                    <React.Fragment />
                                                )

                                        }
                                        <TableCell>
                                            <RenderDetectedChanges values={values} />
                                        </TableCell>
                                        <TableCell >
                                            {
                                                values.length > 0 ?
                                                    (
                                                        <div className='service-analyzer-summary-details' onClick={() => handleChangeTab(key)} >Details</div>
                                                    ) :
                                                    (
                                                        <div className='service-analyzer-summary-details-disable'  >Details</div>
                                                    )
                                            }
                                        </TableCell>


                                    </TableRow>
                                </React.Fragment>

                            ))


                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                isDone.status === "Done" ?
                    (
                        <RenderTimeline  />
                    ) :
                    (
                        <div className='service-analyzer-summary-details-timeline-msg'>The Timeline has not been generated yet. Please wait until the end of the scanning.</div>
                    )
            }



        </React.Fragment>
    )
}

export default ServiceAnalyzerSummary