import * as React from 'react';
import './SCComponents.css';




const SCLinkButton = ({text, handleLinkClick, customClass}) => {


    return (
        <>
            <div onClick={handleLinkClick} className={`sc-link-button ${ customClass }` }  >{text}</div>
        </>
    )
}

export default SCLinkButton
