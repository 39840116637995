import * as React from 'react';
import SCInputText from './SCInputText';
import SCTextArea from './SCTextArea';
import './EditMetaData.css';
import SCDropDownList from './SCDropDownList';
import SCLinkButton from './SCLinkButton';
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import SCButton from './SCButton';
import { v4 as uuid } from 'uuid';
import _ from "lodash";
import DataContext from "../Context/DataContext";
import SCTable from './SCTable';
import { toast } from 'react-toastify';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { width } from '@mui/system';
import UserAutoComplete from './UserAutoComplete';
import useRefTeamLeaderCallback from '../Hooks/Ref/useRefTeamLeaderCallback'
import useRefTeamLeaderIdCallback from '../Hooks/Ref/useRefTeamLeaderIdCallback'


const EditMetaData = () => {

    const { selectedService, saveServiceDetails, setMdEditRowTable, listOfTeams,
        mdEditRowTable, mdEditEnvTable, setMdEditEnvTable, mdEditTagsTable,
        setMdEditTagsTable, setServicesTags, servicesTags, listOfUsers, listOfDepartments, initDeperatmentManager, initSubDeperatmentManager,
        getTeams, getUsers, getDepartment, setListOfDepartments, handleTeamNameById, setListOfUsers, setListOfTeams, initTiers
    } = useContext(DataContext);

    const [linksTable, setLinksTable] = useState([]);
    const [linkId, setLinkId] = useState('');
    const [linkType, setLinkType] = useState('');
    const [linkUrlValue, setLinkUrlValue] = useState('');
    const [typeDescriptionValue, setTypeDescriptionValue] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [newRelicName, setNewRelicName] = useState('')
    const [environmentLinkId, setEnvironmentLinkId] = useState('');
    const [environmentNameValue, setEnvironmentNameValue] = useState('');
    const [environmentUrlValue, setEnvironmentUrlValue] = useState('');
    const [environmentCommentsValue, setEnvironmentCommentsValue] = useState('');
    const [ddlTier, setDdlTier] = useState([]);
    const [ddlLifecycle, setDdlLifecycle] = useState([]);
    const [ddlDeperatmentManager, setDdlDeperatmentManager] = useState([]);
    const [ddlSubDeperatmentManager, setDdlSubDeperatmentManager] = useState([]);

    const [manager, setManager] = useState('');
    const [teamName, setTeamName] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [selectedDeperatmentManager, setSelectedDeperatmentManager] = useState({});
    const [selectedSubDeperatmentManager, setSelectedSubDeperatmentManager] = useState({})
    const [selectedTier, setSelectedTier] = useState({})
    const [selectedLifecycle, setSelectedLifecycle] = useState({})



    const [selectedSubDepartment, setSelectedSubDepartment] = useState('');

    const [tagValue, setTagValue] = useState('');
    const [tagKey, setTagKey] = useState('');

    const [serviceData, setServiceData] = useState({
        team: '',
        teamLeader: '',
        repo: '',
        description: ''
    })

    const [selectedTeam, setSelectedTeam] = useState({})

    const subDeptManagerRef = useRef('');

    const [teamLeaderRef] = useRefTeamLeaderCallback();
    const [teamLeaderIdRef] = useRefTeamLeaderIdCallback();


    const columnsItems = [{ display: false, name: "Id", key: "Id" }, { display: true, name: "Type", key: "Type" },
    { display: true, name: "Description", key: "Description" }, { display: true, name: "Url", key: "Url" }];

    const items = [{ value: 'Documentation', name: 'Documentation' }, { value: 'Swagger', name: 'Swagger' },
    { value: 'Dashboard', name: 'Dashboard' }, { value: 'Logs', name: 'Logs' }, { value: 'Other', name: 'Other' }]


    const columnsEnvItems = [{ display: false, name: "Id", key: "Id" }, { display: true, name: "Name", key: "Name" },
    { display: true, name: "Comments", key: "Comments" }, { display: true, name: "Url", key: "Url" }]


    const columnsTagsItems = [{ display: false, name: "Id", key: "Id" }, { display: true, name: "Key", key: "Key" },
    { display: true, name: "Value", key: "Value" }]






    const [ddlTeams, setDdlTeams] = useState([]);
    useEffect(() => {


        var teams = [];
        if (listOfTeams.length > 0) {
            listOfTeams.map((item => {
                teams.push({
                    name: item.name,
                    value: item.id
                })
            }))
            setDdlTeams(teams)

        }
        else {
            getTeams().then((response) => {

                if (response.data.length > 0) {
                    response.data.map((item => {
                        teams.push({
                            name: item.name,
                            value: item.id
                        })
                    }))
                    setDdlTeams(teams)
                }
            })
        }

        setDdlLifecycle([{name:'Production',value:'Production'},{name:'Experimental',value:'Experimental'},
        {name:'Deprecated',value:'Deprecated'},{name:'Staging',value:'Staging'},{name:'Retiring',value:'Retiring'},{name:'Retired',value:'Retired'}])

    }, [])



    useEffect(() => {



        var environmentsRows = [];
        selectedService?.serviceMetadata?.environments?.forEach(element => {
            environmentsRows.push({ id: element.id, name: element.name, comments: element.comments, url: element.url });
        });

        setMdEditEnvTable([...environmentsRows])


        var tagsRows = [];
        let count = 0;
        selectedService?.serviceMetadata?.tags?.forEach(element => {
            tagsRows.push({ id: count, key: element.key, value: element.value });
            count++;
        });

        setMdEditTagsTable([...tagsRows])



        var linksRows = [];
        selectedService?.serviceMetadata?.links?.forEach(element => {
            linksRows.push({ id: element.id, type: element.type, description: element.description, url: element.url });
        });


        setServiceData({
            team: selectedService?.serviceMetadata?.team,
            teamLeader: selectedService?.serviceMetadata?.manager,
            description: selectedService?.serviceMetadata?.description
        });



        initMetaDataDetails(selectedService);

        setMdEditRowTable([...linksRows])

      
        setDdlDeperatmentManager(initDeperatmentManager());

       
        setDdlSubDeperatmentManager(initSubDeperatmentManager());


        setDdlTier(initTiers())

        initLifeCycle();


    }, [selectedService])


    const initLifeCycle = () =>{
        if(ddlLifecycle.length > 0){
            let value = selectedService.serviceMetadata.lifecycle === null ? 'Production' : selectedService.serviceMetadata.lifecycle
            setSelectedLifecycle(value)
        }
    }

    const initMetaDataDetails = (service) => {


        setNewRelicName(service?.serviceMetadata?.newRelicAppName)
        setSelectedTeam(service?.serviceMetadata?.teamId)
        setSelectedSubDeperatmentManager(service?.serviceMetadata?.subDepartmentId)
        setSelectedDeperatmentManager(service?.serviceMetadata?.departmentId)
        setSelectedTier(service?.serviceMetadata?.tier);
        setSelectedLifecycle(service?.serviceMetadata?.lifecycle)
       

        let teamDetailsObj = handleTeamNameById(selectedService?.serviceMetadata?.teamId);
        teamLeaderRef.value = teamDetailsObj.manager;

        let manager = listOfTeams.filter(l => l.id === service?.serviceMetadata?.teamId)
        if (manager.length > 0) {
            teamLeaderIdRef.value = manager[0].managerUserId;

        }

    }


    const handleChange = (e) => {
        setLinkType(e.target.value);


    }


    const handleInputValueChange = (e) => {
        setLinkUrlValue(e.target.value)

    }
    const handleDescriptionValueChange = (e) => {
        setTypeDescriptionValue(e.target.value)

    }



    const handleButtonClick = () => {

       // let teamManagerId = listOfTeams.filter(l => l.id === selectedTeam)[0].managerUserId

        var obj = {};
        obj.links = mdEditRowTable;
        obj.serviceName = selectedService.name;
        obj.repositoryName = selectedService.repositoryName
        obj.manager = teamLeaderRef.value;
        //obj.userManagerId = Object.entries(teamLeaderIdRef).length === 0 ? teamManagerId : teamLeaderIdRef.value ;
        obj.userManagerId = teamLeaderIdRef.value;
        obj.departmentId = selectedDeperatmentManager;
        obj.subDepartmentId = selectedSubDeperatmentManager
        obj.teamId = selectedTeam
        obj.description = serviceData.description;
        obj.newRelicAppName = newRelicName
        obj.environments = mdEditEnvTable;
        obj.tags = mdEditTagsTable;
        obj.tier = selectedTier;
        obj.lifecycle = selectedLifecycle;

        let tagsTemp = {};
        Object.assign(tagsTemp, servicesTags);
        /*mdEditTagsTable.forEach(element => {



            if (servicesTags[element.key]) {
                servicesTags[element.key] = element.value;
            }
            else {
                tagsTemp[element.key] = element.value;
            }

        });
        setServicesTags([...tagsTemp])*/

        setServicesTags([...mdEditTagsTable])

        saveServiceDetails(obj, selectedService.id, selectedService)

    }

    const handleLinkClick = () => {

        if (linkType && typeDescriptionValue && linkUrlValue) {
            mdEditRowTable.push({ id: uuid(), type: linkType, description: typeDescriptionValue, url: linkUrlValue });
            setMdEditRowTable([...mdEditRowTable])
            setLinkType('');
            setLinkUrlValue('');
            setTypeDescriptionValue('');
        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>All the links inputs are required.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }

    }

    const handleTagsLinkClick = () => {
        if (tagKey && tagKey) {
            mdEditTagsTable.push({ key: tagKey, value: tagValue })
            setMdEditTagsTable([...mdEditTagsTable])

            setTagKey('');
            setTagValue('');


        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>All the tags inputs are required.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }

    }

    const handleEnvironmentLinkClick = () => {

        if (environmentNameValue && environmentCommentsValue && environmentUrlValue) {
            mdEditEnvTable.push({ id: uuid(), name: environmentNameValue, comments: environmentCommentsValue, url: environmentUrlValue })
            setMdEditEnvTable([...mdEditEnvTable])
            setEnvironmentLinkId('');
            setEnvironmentNameValue('');
            setEnvironmentUrlValue('');
            setEnvironmentCommentsValue('');

        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>All the environments inputs are required.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }

    }

    const handleRemove = (e) => {


        var items = [];
        if (mdEditRowTable[e].id) {
            items = [...mdEditRowTable];
            _.remove(items, {
                id: mdEditRowTable[e].id
            });
            //setLinksTable([...items])
            setMdEditRowTable([...items])
        }


    }
    const handleUpdate = (e) => {

        if (mdEditRowTable[e].id === linkId) {

            mdEditRowTable[e].type = linkType;
            mdEditRowTable[e].url = linkUrlValue;
            mdEditRowTable[e].description = typeDescriptionValue;
            setMdEditRowTable([...mdEditRowTable])

            const WarnMsg = () => (
                <div>
                    <div>Table row was updated. please click on SAVE button in order to save the data.</div>
                </div>
            )
            toast.warn(<WarnMsg />)
        }
        else {
            const ErrorMsg = () => (
                <div>
                    <div>Nothing to update. please select item from the table.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }
    }

    const handleSelect = (e) => {


        if (mdEditRowTable[e].id !== linkId) {

            let entity = _.find(mdEditRowTable, { id: mdEditRowTable[e].id });
            setLinkId(entity.id);
            setLinkType(entity.type)
            setLinkUrlValue(entity.url)
            setTypeDescriptionValue(entity.description);


        }
        else {
            setLinkId('')
            setLinkType('')
            setLinkUrlValue('')
            setTypeDescriptionValue('');
        }
    }

    const handleTagsRemove = (e) => {
        var items = [];
        if (mdEditTagsTable[e].key) {
            items = [...mdEditTagsTable];
            _.remove(items, {
                key: mdEditTagsTable[e].key
            });
            setMdEditTagsTable([...items])
            delete servicesTags[mdEditTagsTable[e].key]
            setServicesTags(servicesTags);

        }

    }

    const handleEnvRemove = (e) => {
        var items = [];
        if (mdEditEnvTable[e].id) {
            items = [...mdEditEnvTable];
            _.remove(items, {
                id: mdEditEnvTable[e].id
            });
            setMdEditEnvTable([...items])
        }
    }
    const handleTagsUpdate = (e) => {
        if (mdEditTagsTable[e].key === tagKey) {

            mdEditTagsTable[e].Id = tagKey;
            mdEditTagsTable[e].key = tagKey;
            mdEditTagsTable[e].value = tagValue;
            setMdEditTagsTable([...mdEditTagsTable])

            const WarnMsg = () => (
                <div>
                    <div>Table row was updated. please click on SAVE button in order to save the data.</div>
                </div>
            )
            toast.warn(<WarnMsg />)
        }
        else {

            const ErrorMsg = () => (
                <div>
                    <div>Nothing to update. please select item from the table.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }
    }
    const handleEnvUpdate = (e) => {

        if (mdEditEnvTable[e].id === environmentLinkId) {

            mdEditEnvTable[e].name = environmentNameValue;
            mdEditEnvTable[e].comments = environmentCommentsValue;
            mdEditEnvTable[e].url = environmentUrlValue;
            setMdEditEnvTable([...mdEditEnvTable])

            const WarnMsg = () => (
                <div>
                    <div>Table row was updated. please click on SAVE button in order to save the data.</div>
                </div>
            )
            toast.warn(<WarnMsg />)
        }
        else {

            const ErrorMsg = () => (
                <div>
                    <div>Nothing to update. please select item from the table.</div>
                </div>
            )
            toast.error(<ErrorMsg />)
        }
    }
    const handleTagsSelect = (e) => {
        if (mdEditTagsTable[e].key !== tagKey) {

            let entity = _.find(mdEditTagsTable, { key: mdEditTagsTable[e].key });
            setTagKey(entity.key)
            setTagValue(entity.value)

        }
        else {
            setTagKey('')
            setTagValue('')
        }

    }
    const handleEnvSelect = (e) => {

        if (mdEditEnvTable[e].id !== environmentLinkId) {

            let entity = _.find(mdEditEnvTable, { id: mdEditEnvTable[e].id });
            setEnvironmentLinkId(entity.id)
            setEnvironmentNameValue(entity.name)
            setEnvironmentCommentsValue(entity.comments)
            setEnvironmentUrlValue(entity.url)
        }
        else {
            setEnvironmentLinkId('')
            setEnvironmentNameValue('')
            setEnvironmentCommentsValue('')
            setEnvironmentUrlValue('');
        }

    }
    const handleNewRelicChange = (e) => {
        setNewRelicName(e.target.value)
    }
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);

        if (newTabIndex === 1) {
            //   initMetaDataDetails(selectedService)
        }
    };

    const handleServiceData = (e) => {

        const { name, value } = e.target;

        setServiceData((prev) => {
            return {
                ...prev, [name]: value
            }
        })

    }

    const handleChangeDeperatmentManager = (e) => {
        setSelectedDeperatmentManager(e.target.value);
    }
    const handleChangeSubDeperatmentManager = (e) => {
        setSelectedSubDeperatmentManager(e.target.value);
    }
    const handleChangeTier = (e) => {
        setSelectedTier(e.target.value);
    }

    const handleChangeLifecycle = (e) =>{
        setSelectedLifecycle(e.target.value);
    }



    const handleSelectedTeam = (e) => {
        setSelectedTeam(e.target.value);

        let manager = listOfTeams.filter(l => l.id === e.target.value)
        if (manager.length > 0) {
            teamLeaderRef.value = manager[0].managerName;
            teamLeaderIdRef.value = manager[0].managerUserId;


        }

    }

    return (
        <>
            <div className='row md-save-button'>
                <SCButton text="Save Changes" customClass={'md-save-custom'} handleButtonClick={handleButtonClick} size='medium'></SCButton>
            </div>
            <div className='row md-boxes'>
                <Box>
                    <Box sx={{ display: 'flex' }} >
                        <Tabs sx={{ flex: 2 }}
                            value={tabIndex}
                            onChange={handleTabChange}
                            orientation="vertical"

                        >
                            <Tab label="Configuration" className='md-left-menu-nav' />
                            <Tab label="Details" className='md-left-menu-nav' />
                            <Tab label="Links" className='md-left-menu-nav' />
                            <Tab label="Environments" className='md-left-menu-nav' />
                            <Tab label="Tags" className='md-left-menu-nav' />

                        </Tabs>
                        <Box sx={{ margin: 2, flex: 10 }}>
                            {tabIndex === 0 && (
                                <Box>
                                    <div className='md-col md-service-details-title'><b>Configuration</b></div>
                                    <div className='md-col md-col-configuration'>
                                        <div className='input-container' >

                                            <SCInputText size="small" className="md-input" label="NewRelic Production Service Name"
                                                text="NewRelic Production service name"
                                                value={

                                                    newRelicName

                                                } handleInputValueChange={handleNewRelicChange}
                                            />
                                        </div>

                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container' >

                                            <SCDropDownList className="md-ddl-edit" label="Tier" componentState={selectedTier} setComponentState={setSelectedTier}
                                                size="1000" items={ddlTier} handleChange={(e) => handleChangeTier(e)} value={selectedTier} />
                                        </div>
                                    </div>

                                    <div className='md-col'>
                                        <div className='input-container' >

                                            <SCDropDownList className="md-ddl-edit" label="Life Cycle" componentState={selectedLifecycle} setComponentState={setSelectedLifecycle}
                                                size="1000" items={ddlLifecycle} handleChange={(e) => handleChangeLifecycle(e)} value={selectedLifecycle} />
                                        </div>
                                    </div>

                                </Box>
                            )}
                            {tabIndex === 1 && (
                                <Box>
                                    <div className='md-col md-service-details-title'><b>Service Details</b></div>
                                    <div className='md-col'>
                                        <div className='input-container' >

                                            <SCDropDownList className="md-ddl-edit" label="Department Manager" componentState={selectedDeperatmentManager} setComponentState={setSelectedDeperatmentManager}
                                                size="1000" items={ddlDeperatmentManager} handleChange={(e) => handleChangeDeperatmentManager(e)} value={selectedDeperatmentManager} />
                                        </div>

                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container' >


                                            <SCDropDownList className="md-ddl-edit" label="Sub Department Manager" componentState={selectedSubDeperatmentManager} setComponentState={setSelectedSubDeperatmentManager}
                                                size="1000" items={ddlSubDeperatmentManager} handleChange={(e) => handleChangeSubDeperatmentManager(e)} value={selectedSubDeperatmentManager} />

                                        </div>

                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container' >
                                            <SCDropDownList className="md-ddl-edit" label="Team Name" componentState={selectedTeam} setComponentState={setSelectedTeam}
                                                size="1000" items={ddlTeams} handleChange={(e) => handleSelectedTeam(e)} value={selectedTeam} />

                                        </div>

                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>


                                            <TextField
                                                disabled="true"
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        width: 400
                                                    }
                                                }}
                                                inputProps={
                                                    { readOnly: true }
                                                } value={teamLeaderRef.value} label="Team Leader" InputLabelProps={{ shrink: true }} />


                                        </div>
                                        <div className='md-col'>
                                            <div className='input-container'>

                                                <SCTextArea size="small" name='description' className="md-input" label="Service Description"
                                                    handleInputValueChange={handleServiceData} value={serviceData?.description} />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            )}
                            {tabIndex === 2 && (
                                <Box>
                                    <div className='md-col'>
                                        <div className='links'>Links</div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCDropDownList className="md-ddl" label="*Link type" componentState={linkType} setComponentState={setLinkType}
                                                size="small" items={items} handleChange={(e) => handleChange(e)} value={linkType} />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter type description" value={typeDescriptionValue}
                                                handleInputValueChange={(e) => handleDescriptionValueChange(e)} text="Swagger for dev environment..." />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter link url" value={linkUrlValue}
                                                handleInputValueChange={(e) => handleInputValueChange(e)} text="http://..." />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCLinkButton text="Add link" handleLinkClick={handleLinkClick}></SCLinkButton>
                                        </div>

                                        <div className='md-col'>
                                            <div> <SCTable title="List of links" columnsItems={columnsItems} rowItems={mdEditRowTable}
                                                includesUpdateButton={true} handleUpdate={handleUpdate} handleRemove={handleRemove} handleSelect={handleSelect} includesRemoveButton={true} includesSelectButton={true}
                                            /> </div>
                                        </div>


                                    </div>
                                </Box>
                            )}
                            {tabIndex === 3 && (
                                <Box>
                                    <div className='md-col md-service-env-title'><b>Service Environments</b></div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter environment name" value={environmentNameValue}
                                                handleInputValueChange={(e) => setEnvironmentNameValue(e.target.value)} text="Dev / Stage / Prod" />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter environment url" value={environmentUrlValue}
                                                handleInputValueChange={(e) => setEnvironmentUrlValue(e.target.value)} text="http://azure.portal..." />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCTextArea size="small" className="md-input" label="*Comments for specific environment" text="Environment's comments"
                                                value={environmentCommentsValue}
                                                handleInputValueChange={(e) => setEnvironmentCommentsValue(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCLinkButton text="Add environments" handleLinkClick={handleEnvironmentLinkClick}></SCLinkButton>
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div> <SCTable title="Environments" columnsItems={columnsEnvItems} rowItems={mdEditEnvTable}
                                            includesUpdateButton={true} handleUpdate={handleEnvUpdate} handleRemove={handleEnvRemove} handleSelect={handleEnvSelect} includesRemoveButton={true} includesSelectButton={true}
                                        /> </div>
                                    </div>
                                </Box>
                            )}
                            {tabIndex === 4 && (
                                <Box>
                                    <div className='md-col md-service-env-title'><b>Service Tags</b></div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter tag key" value={tagKey}
                                                handleInputValueChange={(e) => setTagKey(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCInputText size="small" className="md-input" label="*Enter tag value" value={tagValue}
                                                handleInputValueChange={(e) => setTagValue(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div className='input-container'>
                                            <SCLinkButton text="Add tag" handleLinkClick={handleTagsLinkClick}></SCLinkButton>
                                        </div>
                                    </div>
                                    <div className='md-col'>
                                        <div> <SCTable title="Tags" columnsItems={columnsTagsItems} rowItems={mdEditTagsTable}
                                            includesUpdateButton={true} handleUpdate={handleTagsUpdate} handleRemove={handleTagsRemove} handleSelect={handleTagsSelect}
                                            includesRemoveButton={true} includesSelectButton={true}
                                        /> </div>
                                    </div>
                                </Box>
                            )}

                        </Box>
                    </Box>
                </Box>

            </div>


        </>
    )
}

export default EditMetaData
