import React, { useEffect, useContext } from 'react'
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import ServiceAnalyzer from '../Components/ServiceAnalyzer/ServiceAnalyzer';



const ServiceAnalyzerPageOld = () => {

    const {  setCurrentPage } = useContext(DataContext);
 

    useEffect(() => {
       
        setCurrentPage('analyzer')

    }, [])

  
  
    return (
        <div>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main analyzer-container">
            <ServiceAnalyzer />
            </div>
         

        </div>
    )
}

export default ServiceAnalyzerPageOld