import _ from "lodash";


class Queue {
    constructor() {
        this.set = new Set();
    }

    // Add an item to the queue
    enqueue(item) {
        this.set.add(item);
       // this.printQueue();
    }

    // Remove and return the first item from the queue
    dequeue() {
        const firstItem = this.peek();
        this.set.delete(firstItem);
        return firstItem;
    }

    // Return the first item in the queue without removing it
    peek() {
        return this.set.values().next().value;
    }

    // Get the size of the queue
    size() {
        return this.set.size;
    }
    // Check if the queue is empty
    isEmpty() {
        return this.size() === 0;
    }

    // Clear the queue
    clear() {
        this.set.clear();
    }

    // Update an item in the queue
    update(oldItem, newItem) {
        if (this.set.has(oldItem)) {
            this.set.delete(oldItem);
            this.set.add(newItem);
            return true;
        }
        return false;
    }

    // Remove an item from the queue
    remove(item) {

        const foundItem = Array.from(this.set).find(obj => obj[this.property] === item[this.property]);
        
        if (foundItem) {           
          this.set.delete(foundItem);       
          return true;
        }
        return false;
      }

    // Get an array representation of the queue
    toArray() {
        return Array.from(this.set);
    }


    isItemExist(item) {
       // this.printQueue();
        let isEqual = false;
        const setToArray = Array.from(this.set)
       

        setToArray.map(obj => {
            if (_.isEqual(obj, item)) {
                isEqual = true;
            }
        });


        return isEqual;

    }

    printQueue() {
        let array = Array.from(this.set)
        console.log('Queue:', array);
    }
}
export default Queue;

