import axios from 'axios';
import * as consts from './consts';
import { toast } from 'react-toastify';


const analyzerDeploymentChangesAxios = axios.create({
  baseURL: process.env.REACT_APP_ANALYZER_DEPLOYMENT_CHANGES,
  headers: {
    Accept: "application/json",
  },
})

analyzerDeploymentChangesAxios.interceptors.request.use(async req => {
  if (!req?.ignoreSpinner) {
    document.getElementById("loader").style.display = "block";
  }
  return req;

})

analyzerDeploymentChangesAxios.interceptors.response.use(

  response => {

    document.getElementById("loader").style.display = "none";

    return response;
  },
  error => {
    if (error.response) {
      // Error response received with a status code
      document.getElementById("loader").style.display = "none";
      switch (error.response.status) {
        case 502:
          displayErrorToast('Something went wrong.')
          break;
        case 400:
          {
            if (error?.response?.data?.message) {
              displayErrorToast(error?.response?.data?.message)
            }
            else {
              displayErrorToast('Something went wrong.')
            }

            break;
          }
        case 401:
          {
            displayErrorToast('Token Expired. We\'re trying to connect you automatically to the platform.');
            setTimeout(() => {
              localStorage.removeItem('InfraConsoleToken')
              localStorage.removeItem('InfraConsoleName')
              localStorage.removeItem('InfraConsoleExpired')
              localStorage.removeItem('InfraConsoleUserName')
              window.location.reload(true);

            }, 2500)
            break;
          }
        case 404:
          {
            if (error?.response?.data?.message) {
              displayErrorToast(error?.response?.data?.message)
            }

            break;
          }


        default:
          break;
      }
    } else if (error.request) {
      // Request sent but no response received
      console.log('Request:', error.request);
    } else {
      // Other errors occurred
      console.log('Error:', error.message);
    }
    return Promise.reject(error); // Pass the error along to the next error handler
  }
);



const displayErrorToast = (message) => {
  const ErrorMsg = () => (
    <div>{message}</div>
  )
  toast.error(<ErrorMsg />)
}






export default analyzerDeploymentChangesAxios;