
import React from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import _ from "lodash";
import './ServiceAnalyzerResourceChangeModal.css';
import { useEffect, useState } from "react";


const ServiceAnalyzerResourceChangeModal = ({ show, setShowAnalyzerResourceChange, data }) => {

    const AnalyzerReportModalClose = () => {

        setShowAnalyzerResourceChange(false)
    }

    return (


        <Modal
            fullscreen={false}
            show={show}
            onHide={() => AnalyzerReportModalClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ maxHeight: window.innerHeight * 0.9 }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Changes:
                    <span className="resource-changes-modal-title">
                        {
                            data.changesCount === 1 ?
                                (
                                    <span>We've found {data.changesCount} {data.changeType}</span>
                                ) :
                                (
                                    <span>We've found {data.changesCount} {data.changeType}s</span>
                                )
                        }
                    </span>
                    <div>
                       <div>
                       <span className="resource-changes-modal-sub-title"><b>Target ResourceId:</b></span><span className="resource-changes-modal-sub-title">{data.targetResourceId}</span>
                       </div>
                       <div>
                       <span className="resource-changes-modal-sub-title"><b>Target Resource Type:</b></span><span className="resource-changes-modal-sub-title">{data.targetResourceType}</span>
                       </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="notification-modal-container">
                <div>
                    {
                        data.changes ?
                            (
                                <ul>
                                    {Object.entries(data.changes).map(([key, value]) => (
                                        <li className="resource-changes-modal-li resource-changes-modal-border-bottom" key={key}>
                                            <div><strong>{key}:</strong></div>
                                            <div>
                                                <span>Change Category: </span><span>{value.changeCategory}</span>
                                            </div>
                                            <div>
                                                <span>New Value: </span><span>{value.newValue}</span>
                                            </div>
                                            <div>
                                                <span>Previous Value: </span><span>{value.previousValue}</span>
                                            </div>
                                            <div>
                                                <span>Property ChangeType: </span><span>{value.propertyChangeType}</span>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            ) :
                            (
                                <span></span>
                            )
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={() => AnalyzerReportModalClose()}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ServiceAnalyzerResourceChangeModal;
