import React, { useEffect, useState, useContext } from "react";
import './SelfServiceRepo.css';
import WizardDataContext from "../../Context/WizardDataContext";
import BackgroundDataContext from "../../Context/BackgroundDataContext";
import * as consts from '../../consts';
import SCDropDownList from "../SCDropDownList";
import useAxios from '../../Hooks/useAxios';
import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import DataContext from "../../Context/DataContext";
import SCInputText from "../SCInputText";



const SelfServiceRepo = () => {

    const { setSelectedProject, selectedProject, setDdlFeeds, ddlFeeds, setSelectedACRDev, setSelectedACRProd, setSelectedBranchesProd, setSelectedBranchesDev, disableRepoName,
        setDisableRepoName, setRepoNameFromAutoComplete, setOptions, options, repositories, setRepositories, setDdlAcrDev, setDdlAcrProd, decision,
        setRepoNameValue, repoNameValue, setSelectedFeed, selectedFeed, appNameRef, setRegexSolutionNameError, setAppNameValue, appNameValue } = useContext(WizardDataContext);

    const { ddlProjects, setDdlProjects, ddlDBGroup, setDdlDBGroup, isApiProjectDone, isApiDBDone } = useContext(BackgroundDataContext);

    const { getSelfServiceRepositories, getSelfServiceEnvironments } = useContext(DataContext);

    const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);

    const pattern = /^[A-Z][A-Za-z]*(?:\.[A-Z][A-Za-z]*)*$/;


    useEffect(async () => {


        if (selectedProject) {

            let response = await Promise.allSettled([getSelfServiceRepositories(selectedProject), getSelfServiceEnvironments(selectedProject)])
            if (response[0].status === "fulfilled") {
                setRepositories([])
                var repositories = [];
                response[0].value.data.content.map((a) => {

                    repositories.push({
                        id: a.id,
                        name: a.name
                    })
                })
                setRepositories(repositories)
            }

            if (response[1].status === "fulfilled") {
                var resourceDev = [];
                var resourceProd = [];

                response[1].value.data.content.map((a) => {

                    if (a.name.toLowerCase().includes("-prod-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
                        resourceProd.push({
                            name: a.displayName + " (" + a.managedKubernetesService + ")",
                            value: a.id,
                            managedKubernetesService: a.managedKubernetesService,
                            branches: a.allowedBranches
                        })
                    }
                    if (a.name.toLowerCase().includes("-dev-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
                        resourceDev.push({
                            name: a.displayName + " (" + a.managedKubernetesService + ")",
                            value: a.id,
                            managedKubernetesService: a.managedKubernetesService,
                            branches: a.allowedBranches
                        })
                    }
                })


                setDdlAcrDev(resourceDev)
                setDdlAcrProd(resourceProd)


            }
        }

    }, [selectedProject])

    const fillFeedDropdown = (selectedProject) => {


        let url = consts.requests.getCICDAutoFeed.replace('{projectName}', selectedProject);
        instance.get(url).then((response) => {

            var resource = [];
            if (response?.data?.content) {
                response.data.content?.map((feed) => {
                    resource.push({
                        name: feed.name,
                        value: feed.name
                    })
                })
            }
            else {
                setDdlFeeds([])
            }

            setDdlFeeds(resource)


        }).catch((error) => {
            setDdlFeeds([])
        })





    }

    const handleChangeProject = (e) => {


        setSelectedProject(e.target.value)
        fillFeedDropdown(e.target.value);
        setSelectedACRDev([])
        setSelectedACRProd([])
        setSelectedBranchesProd('')
        setSelectedBranchesDev([])

    }

    const handleUserClick = () => { }

    const handleDelete = (value, newInput) => {


        setSelectedACRDev([])
        setSelectedACRProd([])
        if (newInput === "") {
            setDisableRepoName(false)
            setRepoNameFromAutoComplete("")

        }
        else {
            setDisableRepoName(true)
            setRepoNameFromAutoComplete(newInput)
        }
    }

    const handleAutoChange = (value) => {

        const autoCompleteArray = _.filter(repositories, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
        setOptions(autoCompleteArray)

    }
    const getOptionLabel = (option) => {
        return `${option.name}`;
    }

    const handleRepoNameChange = (e) => {
        setRepoNameValue(e.target.value)

    }
    const handleChangeFeed = (e) => {
        setSelectedFeed(e.target.value)

    }

    const handleBlur = (e) => {


        if (pattern.test(e.target.value)) {
            setRegexSolutionNameError(false);

        }

    }

    const handleSolutionNameChange = (e) => {
        if (pattern.test(e.target.value)) {
            setAppNameValue(e.target.value)

        }
    }

    return (
        <React.Fragment>
            <div className="ss-wizard-repo-controler-container">
                <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Projects *" componentState={selectedProject} setComponentState={setSelectedProject}
                    size="1000" items={ddlProjects} handleChange={(e) => handleChangeProject(e)} value={selectedProject} />
            </div>
            <div className="ss-wizard-repo-controler-container">
                <Autocomplete
                    freeSolo
                    id="search-repos"
                    disableClearable
                    options={options}
                    onChange={(e, value) => handleUserClick(e, value)}

                    getOptionLabel={getOptionLabel}
                    onInputChange={(event, newInputValue) => {
                        handleDelete(event, newInputValue);
                    }}
                    filterOptions={(options) => options}
                    renderInput={(params) => (
                        <TextField
                            className='cicd-repos'
                            {...params}
                            label="Existing Repositories"
                            InputProps={{
                                ...params.InputProps,
                                type: "search",

                            }}

                            InputLabelProps={{ shrink: true }}
                            onChange={(event) =>
                                handleAutoChange(event.target.value)
                            }



                        />
                    )}
                />
            </div>
            <div className="ss-wizard-repo-controler-container">
                <SCInputText id="cicd-repository-name" required={true} size="small" className="md-input" label="New Repository" value={repoNameValue}
                    disabled={disableRepoName} handleInputValueChange={(e) => handleRepoNameChange(e)} />
            </div>
            <div className="ss-wizard-repo-controler-container">
                <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Feed Name *" componentState={selectedFeed} setComponentState={setSelectedFeed}
                    size="1000" items={ddlFeeds} handleChange={(e) => handleChangeFeed(e)} value={selectedFeed} />
            </div>
            <div className="ss-wizard-repo-controler-container">
                <SCInputText id="cicd-solution-name" refName={appNameRef} handleInputValueBlur={handleBlur} required={true} size="small"
                    className="md-input" label="Solution Name" value={appNameValue}
                    handleInputValueChange={(e) => handleSolutionNameChange(e)} />
            </div>

        </React.Fragment>
    )
}

export default SelfServiceRepo
