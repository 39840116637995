import * as React from 'react';
import './SCComponents.css';

const SCButton = ({text, handleButtonClick, size, customClass = "", buttonId=""}) => {


    return (
        <React.Fragment>
          <div id={buttonId} className={customClass + (size === 'medium' ? " sc-button-medium" : " sc-button")} onClick={handleButtonClick}>
              <span className='sc-button-text' >{text}</span>
          </div>
        </React.Fragment>
    )
}

export default SCButton
