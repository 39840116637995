import { Modal, Button } from "react-bootstrap";
import './CloseIncidentModal.css';
import SCTextArea from "../Components/SCTextArea";
import { useState } from "react";


const CloseIncidentModal = ({ modalCloseIncidentShow, setModalCloseIncidentShow }) => {

    const [incidentComment, setIncidentComment] = useState('')


    const handleCloseModal = () => {
        setModalCloseIncidentShow(false)

    }



    const handleYes = () => {

        setModalCloseIncidentShow(false)
    }

    const handleNo = () => {
        setModalCloseIncidentShow(false)
    }

    const handleComment = (e) => {
        setIncidentComment(e.target.value)
    }

    return (
        <div>

            <Modal
                dialogClassName="sa-close-incident-modal-custom-modal-content"
                show={modalCloseIncidentShow}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        Warning!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sa-close-incident-modal">Are you sure you want to close the incident?</div>
                    <div><SCTextArea rows={1.9} size="small" name='comment' className="md-input" label="Comment"
                        handleInputValueChange={(e) => handleComment(e)} value={incidentComment} /> </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleYes()}>Yes</Button>
                    <Button variant="outline-success" onClick={() => handleNo()}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default CloseIncidentModal
