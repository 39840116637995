import { useContext, useEffect } from "react";
import './CardListGroupView.css';
import DataContext from "../Context/DataContext";
import { useHistory } from 'react-router-dom';
import SCCard from "./SCCard";
import React, { memo } from 'react';





const CardListGroupView = () => {
    const { services, groupServices, setSelectedService } = useContext(DataContext);
    const history = useHistory();



    

    const handleCardClick = (serviceId) => {
        var service = getServiceById(serviceId);
        setSelectedService(service[0]);
        history.push({
            pathname: '/Service',
            display: 'ServiceChild',
            search: '?name=' + service[0].name
        })
    }
    const getServiceById = (id) => {
        return services.filter(s => s.id === id);
    }

    const buildCardService = (id) => {
        var service = getServiceById(id);

        if (service && service.length) {

            return (
                <>
                    <SCCard item={service[0]} theme="white" />
                </>
            );
        }
    }




    return (
        <>

           
                  
                        {
                            groupServices && groupServices?.map((service, index) => (
                                <div key={index} className='tom'>
                                    <fieldset className="field-border" >

                                        <legend className="legend-border" >{service.GroupName} - {service.GroupDesc}</legend>                                        
                                        <div className="ella">
                                            {
                                                service?.ServiceIds?.map((s, index) => (
                                                    <div key={index} className="card-item-container-group pointer"
                                                        onClick={() => handleCardClick(s)}>{buildCardService(s)}</div>

                                                ))
                                            }
                                        </div>
                                                                               
                                    </fieldset>


                                </div>

                            ))
                        }
                  
           
           
        </>
    )
}

export default memo(CardListGroupView)
