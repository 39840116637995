import * as React from 'react';
import './NotAuthorized.css';

const NotAuthorized = () => {
   
    return (
        <>
           <div className="container-fluid">
               <div className="row">
                    <div className="col-12 notauthorized-title">401</div>
               </div>
               <div className="row">
                    <div className="col-12 notauthorized-sub-title">Not Authorized</div>
               </div>
               <div className="row">
                    <div className="col-12 notauthorized-desc-title">You are not authorized to view this page. please try to logout and login again.</div>
               </div>
               </div>
        </>
    )
}

export default NotAuthorized
