import React, { useEffect, useState, useContext, useRef, lazy } from 'react'
import './SAMeasurements.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import * as consts from '../../../consts';
import { DateTime, DateTime as luxon } from "luxon";
import _ from "lodash";
import DataContext from '../../../Context/DataContext';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import SCLine from '../../SCLine';
import spinner from '../../../Images/SmallSpinner.svg';
import SCButton from '../../SCButton';
import SCDropDownList from '../../SCDropDownList';



const SAMeasurements = ({ service, requestId }) => {

    const { requestCounters, getPODS, getCounters, createAKSNamespace, counters, setCounters, loadingStatus, setLoadingStatus, ddlValueRef } = useContext(DataContextAnalyzer);
    const { formatDateTime } = useContext(DataContext);


    const INTERVAL_FOR_COUNTERS = 2;
    const INTERVAL = 5;
    const DURATION_IN_SECONDS = 30
    const START_INTERVAL_DURATION = 40000

    const intervalRef = useRef(0)
    const durationRef = useRef(0)


    const [selectedValue, setSelectedValue] = useState(0)

    useEffect(() => {
        const storedValue = localStorage.getItem('selectedCountersValue');
        if (storedValue !== null && (storedValue == 10 || storedValue == 20 || storedValue == 30)) {
            setSelectedValue(storedValue);
        }
    }, []);

    useEffect(() => {
        if (selectedValue == 10 || selectedValue == 20 || selectedValue == 30) {
            localStorage.setItem('selectedCountersValue', selectedValue);
        }
    }, [selectedValue]);



    const BuildChart = ({ counter }) => {


        let textArray = [];
        let chartArray = [];
        for (const [key, group] of Object.entries(counter.counters)) {

            const values = group.map(item => item.value);
            const allValuesSame = values.every((value, index, array) => value === array[0]);


            if (!allValuesSame) {
                chartArray.push({ key: key, value: group })
            }
            else {
                textArray.push({ key: key, value: group })
            }

        }



        return (
            <div>
                {textArray.length > 0 ? (

                    <TableContainer component={Paper} className='sa-measurements-table'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>MEASUREMENT</TableCell>
                                    <TableCell>VALUE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {textArray.map((text, index) => (
                                    <TableRow className={`${index % 2 === 1 ? "sa-services-table-row" : "sa-services-table-row-gray"}`} >
                                        <TableCell>{text.key}</TableCell>
                                        <TableCell>{text.value[0].value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                ) : (
                    <span></span>
                )}
                {chartArray.length > 0 ? (
                    <div className='sa-measurements-container'>
                        {chartArray.map((chart, index) => (
                            <div className='sa-measurements-line-chart sa-measurements-box' >{buildDataForChart(chart)}</div>
                        ))}
                    </div>
                ) : (
                    <span></span>
                )}
            </div>
        );

    }
    const buildDataForChart = (chart) => {
        const labels = chart.value.map(item => formatDateTime(item.timestamp));
        const data = chart.value.map(item => item.value);

        return <SCLine title={chart.key} labels={labels} data={data} yAxisTitle="Values" xAxisTitle="Date time" />

    }




    const getRequestCounterAndDetails = async (pod, namespace) => {
        setLoadingStatus("Generating request id for counters...")
        let response = await requestCounters(namespace, pod, intervalRef.current === 0 ? 1 : intervalRef.current, durationRef.current === 0 ? 15 : durationRef.current);
        if (response.status === 200 && response.data.content.requestId) {
            setCounters(prevObject => [...prevObject, { requestId: response.data.content.requestId, namespace: namespace, pod: pod, data: [], counters: [], state: 'created' }]);
            setLoadingStatus("Fetching counters and measurements...")
            let data = await getServiceCounters(response.data.content.requestId);
            if (data.status === 200) {
                setCounters((prevArray) =>
                    prevArray.map((item) =>
                        item.pod === pod
                            ? {
                                ...item,
                                state: 'started',
                                data: data
                            }
                            : item
                    )
                );

                console.log("***** start interval ******")
                var count = 0;
                startInterval(response.data.content.requestId, pod, count)
            }
        }
    }




    const startInterval = (requestId, pod, count) => {


        const intervalId = setInterval(async () => {

            try {

                console.log("***** start interval ******", count)
                let response = await getServiceCounters(requestId);
                count++

                setLoadingStatus("Counters return with status " + response.data.content.requestStatus)
                if (response.data.content.requestStatus === "Completed") {
                    console.log("***** end interval ******")
                    clearInterval(intervalId)
                    setLoadingStatus("done")

                    if (response.data.content.counters.events && response.data.content.counters.events.length > 0) {
                        const groupedCounters = _.groupBy(response.data.content.counters.events, 'name');
                        setCounters((prevArray) =>
                            prevArray.map((item) =>
                                item.pod === pod
                                    ? {
                                        ...item,
                                        state: 'done',
                                        data: response,
                                        counters: groupedCounters
                                    }
                                    : item
                            )
                        );
                    }
                }
                if (response.data.content.requestStatus === "Failed") {
                    console.log("***** end interval - failed ******")
                    clearInterval(intervalId)
                    setTimeout(() => { setLoadingStatus("done") }, [3000])

                }
                if (INTERVAL_FOR_COUNTERS === count) {
                    console.log("***** end interval - max attempt ******")
                    clearInterval(intervalId)
                    setTimeout(() => { setLoadingStatus("done") }, [3000])
                }


            } catch (error) {
                console.log("**** counters fetch failed due to exception ****", error)
                setLoadingStatus("done")
                clearInterval(intervalId)
            }

        }, START_INTERVAL_DURATION);
    }


    const getServiceCounters = async (requestId) => {
        return await getCounters(requestId);
    }

    const handleGetCounters = async () => {
        //  const controller = new AbortController();
        setCounters([])
        setLoadingStatus("Starting process...")
        let namespace = createAKSNamespace(service.clusterName, service.name);
        try {
            setLoadingStatus("Get pods for AKS namespace...")
            let response = await getPODS(namespace);
            if (response.status === 200 && response.data.content) {
                let podsArray = response.data.content;
                podsArray.map((pod) => {
                    getRequestCounterAndDetails(pod, namespace)
                })


            }
            /*  return () => {
                  controller.abort(); // Cancel the fetch request
              };*/

        } catch (error) {
            /* console.log("Error in scan api", error)*/



        }
    }



    const handleChange = (event) => {

        ddlValueRef.current = event.target.value;

        switch (event.target.value) {
            case 10:
                {
                    intervalRef.current = 1;
                    durationRef.current = 15;
                    break;
                }
            case 20:
                {
                    intervalRef.current = 5;
                    durationRef.current = 30;
                    break;
                }
            case 30:
                {
                    intervalRef.current = 10;
                    durationRef.current = 60;
                    break;
                }


            default:
                break;
        }
        setSelectedValue(event.target.value)

    };
    return (
        <React.Fragment>
            <div>

                <span className='sa-measurements-header-btn'>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Select duration and interval</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            ref={ddlValueRef}
                            value={selectedValue}
                            label="Select duration and interval"
                            onChange={handleChange}
                            className='sa-measurements-ddl'
                        >
                            <MenuItem value={10}>Get metrics on the next 15 seconds with interval of 1 second</MenuItem>
                            <MenuItem value={20}>Get metrics on the next 30 seconds with interval of 5 second</MenuItem>
                            <MenuItem value={30}>Get metrics on the next 60 seconds with interval of 10 second</MenuItem>
                        </Select>
                    </FormControl>
                </span>
                <div className='sa-measurements-header-btn sa-measurements-get-counter-btn' onClick={() => handleGetCounters()}>
                    <div className='sa-measurements-get-counter-btn-text'>Get Counters</div>
                </div>

            </div>
            {
                loadingStatus !== "" && loadingStatus !== "done" ?
                    (
                        <div className='sa-measurements-linear-wipe'><img src={spinner} className='sa-measurements-spinner-icon' />{loadingStatus}</div>
                    ) :
                    (
                        <span></span>
                    )
            }

            {
                counters && counters.filter(x => x.state === 'done').length > 0 ?
                    (
                        counters.map(counter => (
                            <div>
                                <div><span className='sa-measurements-pod-name'>Pod name: </span> {counter.pod}</div>
                                <div>
                                    <BuildChart counter={counter} />
                                </div>
                            </div>
                        ))

                    ) :
                    (
                        <span></span>
                    )
            }

        </React.Fragment>
    )
}

export default SAMeasurements
