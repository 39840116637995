import { Modal, Button } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import './SelfServiceKVModal.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DataContext from "../Context/DataContext";
import SCDropDownList from "../Components/SCDropDownList";
import SCInputText from "../Components/SCInputText";

const SelfServiceKVModal = React.memo(({ modalShow, setModalShow, acrDev, branchesDev, acrProd, branchesProd, setKeyVaultPayloadData, keyVaultPayloadData,
    setSelectedEKSAndKV, openByEdit
}) => {
    const [ddlSubscriptions, setDdlSubscriptions] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [value, setValue] = React.useState("0");
    const [kvItems, setKVItems] = useState([])
    const [ddlKVList, setDdlKVList] = useState([])
    const [ddlSecrets, setDdlSecrets] = useState([])
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
    const [selectedKVObject, setSelectedKVObject] = useState([]);
    const [activeEnv, setActiveEnv] = useState('')
    const [selectedSecrets, setSelectedSecrets] = useState([]);
    const [prefixValue, setPrefixValue] = useState([]);
    const { getSelfServiceSubscriptions, getKVNames, getSecrets } = useContext(DataContext);



    useEffect(() => {

        if (keyVaultPayloadData.length === 0) {
            setPrefixValue([])
            setSelectedSecrets([])
            setActiveEnv('')
            setSelectedKVObject([])
            setSelectedSubscriptions([])
        }

    }, [keyVaultPayloadData])


    useEffect(() => {



        let listItems = [];

        //dev
        branchesDev.forEach((str, index) => {
            let item = { "env": str, "clusters": acrDev };
            listItems.push(item);
        });

        //prod

        let item = { "env": branchesProd, "clusters": acrProd };
        listItems.push(item);


        setKVItems(listItems)
        setActiveEnv(listItems[0].env)




        if (modalShow) {
            setPrefixValue([])
            setSelectedSecrets([])
            setSelectedKVObject([])
            setSelectedSubscriptions([])


            let subArr = []
            let kvNameArr = []
            let secretsArr = []
            let prefixArr = []
            keyVaultPayloadData.forEach((kv) => {

                if (kv.keyVaultName !== undefined && kv.secret !== undefined && kv.subscriptionId !== undefined) {
                    //create subscription
                    subArr.push({ "env": kv.environment, "value": kv.subscriptionId })

                    //create key vault name
                    let kvUrl = ddlKVList.find(x => x.name === kv.keyVaultName).value;
                    kvNameArr.push({ "env": kv.environment, "value": kvUrl })


                    //create secrets
                    const prefix = "prefix:";

                    // Find and save the object that starts with the prefix
                    const removedObject = [...kv.secret].find(value => value.startsWith(prefix));

                    // Filter out the object that starts with the prefix
                    let fliteredSecrets = [...kv.secret].filter(value => !value.startsWith(prefix));

                    secretsArr.push({ "env": kv.environment, "value": fliteredSecrets })


                    //create prefix
                    if (removedObject) {
                        let valueAfterColon = removedObject.split(":")[1];
                        prefixArr.push({ "env": kv.environment, "value": valueAfterColon })
                    }



                }
            })
            setSelectedSubscriptions(subArr)
            setSelectedKVObject(kvNameArr)
            setSelectedSecrets(secretsArr)
            setPrefixValue(prefixArr)
        }



    }, [modalShow])
//taltal
    useEffect(async () => {

        if (kvItems && kvItems.length > 0 && modalShow) {
            
            try {
                let response = await getSelfServiceSubscriptions()
                let subs = []
                if (response.status === 200) {
                    setSubscriptions(response.data.content)
                    if (response.data.content.length > 0) {
                        kvItems.forEach((env) => {
                            if (env !== '' && env?.env !== '') {
                                response.data.content.forEach((s) => {
                                    subs.push({ name: s.displayName, value: s.subscriptionId, env: env.env })
                                })
                            }

                        })

                        setDdlSubscriptions(subs)
                    }
                    else {
                        //show alert
                    }

                }
            } catch (error) {
                //show alert
            }
        }



    }, [kvItems])


    useEffect(async () => {
        if (selectedSubscriptions && selectedSubscriptions.length > 0) {

            try {

                //get subid by activeEnv
                let response = await getKVNames(selectedSubscriptions.find(x => x.env === activeEnv).value)

                if (response.status === 200) {

                    if (response.data.content.length > 0) {
                        let clone = [...ddlKVList]
                        response.data.content.map((k) => {
                            const index = clone.findIndex(item => item.name === k.keyVaultName && item.value === k.keyVaultUrl && item.env === activeEnv);


                            if (index === -1) {
                                //object not exist
                                clone.push({ name: k.keyVaultName, value: k.keyVaultUrl, env: activeEnv })



                            }

                        })
                        setDdlKVList([...clone])
                    }
                    else {
                        //show alert
                    }

                }
            } catch (error) {
                //show alert
            }

        }
    }, [selectedSubscriptions])


    useEffect(async () => {

        if (selectedKVObject && selectedKVObject.length > 0) {

            try {
                let response = await getSecrets(selectedKVObject.find(x => x.env === activeEnv).value)


                if (response.status === 200) {

                    if (response.data.content.length > 0) {
                        let clone = [...ddlSecrets]
                        response.data.content.map((s) => {
                            const index = clone.findIndex(item => item.name === s.secretName && item.value === s.secretName && item.env === activeEnv);
                            if (index === -1) {
                                //object not exist
                                clone.push({ name: s.secretName, value: s.secretName, env: activeEnv })
                            }

                        })
                        setDdlSecrets([...clone])
                    }
                    else {
                        //show alert
                    }

                }



            } catch (error) {
                //show alert
            }

        }

    }, [selectedKVObject])


    const resetTabs = () => {
        setValue("0");
        setActiveEnv(kvItems[parseInt(0)].env)
    }

    const handleCloseModal = () => {
        setModalShow(false)

        resetTabs()

        if (!openByEdit) {

            setSelectedEKSAndKV('No')

        }


    }

    const ensureEnvironments = (arr) => {
        
        kvItems.forEach(item => {
            if (item.env !== '') {
                if (!arr.some(subscription => subscription.env === item.env)) {
                    arr.push({ env: item.env, value: [] });
                }
            }

        });
        return arr;
    }


    const handleSubmitModal = () => {

        //remove the array if not has sub, kv name, secrets

        setModalShow(false)

        resetTabs()

      
        const mergedArray = ensureEnvironments(selectedSubscriptions).concat(ensureEnvironments(selectedKVObject),
        ensureEnvironments(selectedSecrets),  ensureEnvironments(prefixValue));


        // Group objects by the env property
        const result = mergedArray.reduce((acc, obj) => {
            const key = obj.env;
            if (!acc[key]) {
                acc[key] = [];
            }


            acc[key].push(obj.value);


            return acc;


        }, {});





        let eksSecretsInput = []
        Object.keys(result).forEach(key => {
            const dataArray = result[key];
            
            if (dataArray.length === 4) {

              

                if((dataArray[2] === "" || dataArray[2] === undefined || dataArray[2].length === 0) &&
                    (dataArray[3] === "" || dataArray[3] === undefined || dataArray[3].length === 0)
                ){
                    return;
                }

                if (dataArray[0] !== "" && dataArray[0] !== undefined && dataArray[0].length > 0 &&
                    dataArray[1] !== "" && dataArray[1] !== undefined && dataArray[1].length > 0 &&
                    dataArray[2] !== "" && dataArray[2] !== undefined) {

                    if (dataArray[3] !== "" && dataArray[3] !== undefined) {
                        dataArray[2].push("prefix:" + dataArray[3])
                    }
                    eksSecretsInput.push({ "keyVaultName": ddlKVList.find(x => x.value === dataArray[1]).name, "environment": key, "subscriptionId": dataArray[0], "secret": dataArray[2] })
                }
             }


        });

        

        setKeyVaultPayloadData(eksSecretsInput)


    }


    const handleChange = (event, newValue) => {

        setValue(newValue);
        setActiveEnv(kvItems[parseInt(newValue)].env)

    };

    const handleChangeSubscriptions = (e, env) => {

      


        let updatedPrefixValue = [...prefixValue].filter(x=>x.env !== env);
        setPrefixValue(updatedPrefixValue)
       
        let updatedKVObject = [...selectedKVObject].filter(x=>x.env !== env);
        setSelectedKVObject(updatedKVObject)

        let updatedSecrets = [...selectedSecrets].filter(x=>x.env !== env);
        setSelectedSecrets(updatedSecrets)


        _.remove(ddlKVList, { env: env });
        setDdlKVList([...ddlKVList])


        _.remove(ddlSecrets, { env: env });
        setDdlSecrets([...ddlSecrets])


        const index = selectedSubscriptions.findIndex(item => item.env === env);
        if (index !== -1) {
            // If the object exists, update its value
            let clonedArr = [...selectedSubscriptions];
            clonedArr[index].value = e.target.value;
            setSelectedSubscriptions([...clonedArr])
        } else {
            // If the object does not exist, add it to the array
            setSelectedSubscriptions([...selectedSubscriptions, { env: env, value: e.target.value }]);
        }


    }


    const handleChangeKVName = (e, env) => {

        let updatedSecrets = [...selectedSecrets].filter(x=>x.env !== env);
        setSelectedSecrets(updatedSecrets)

        _.remove(ddlSecrets, { env: env });
        setDdlSecrets([...ddlSecrets])
        

        const index = selectedKVObject.findIndex(item => item.env === env);
        if (index !== -1) {
            // If the object exists, update its value
            let clonedArr = [...selectedKVObject];
            clonedArr[index].value = e.target.value;
            setSelectedKVObject([...clonedArr])
        } else {
            // If the object does not exist, add it to the array
            setSelectedKVObject([...selectedKVObject, { env: env, value: e.target.value }]);
        }


    }



    const handleChangeSecrets = (e, env) => {


        const index = selectedSecrets.findIndex(item => item.env === env);
        if (index !== -1) {
            // If the object exists, update its value
            let clonedArr = [...selectedSecrets];
            clonedArr[index].value = e.target.value;
            setSelectedSecrets([...clonedArr])
        } else {
            // If the object does not exist, add it to the array
            setSelectedSecrets([...selectedSecrets, { env: env, value: e.target.value }]);
        }
    }



    const handleSecretPrefixChange = (e, env) => {




        const index = prefixValue.findIndex(item => item.env === env);
        if (index !== -1) {
            // If the object exists, update its value
            let clonedArr = [...prefixValue];
            clonedArr[index].value = e.target.value;
            setPrefixValue([...clonedArr])
        } else {
            // If the object does not exist, add it to the array
            setPrefixValue([...prefixValue, { env: env, value: e.target.value }]);
        }

    }




    return (
        <div>

            <Modal
                show={modalShow}
                onHide={handleCloseModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                        Please select the Key Vaults that are related to your environment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value.toString()}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    {
                                        kvItems && kvItems.length > 0 && kvItems?.map((item, index) => (
                                            <Tab label={item.env} value={index.toString()} key={`modal--key-name-element` + index} />
                                        ))
                                    }

                                </TabList>
                            </Box>
                            {
                                kvItems?.map((item, index) => (
                                    <TabPanel value={index.toString()} key={`panel-element` + index}>
                                        <React.Fragment>
                                            <div className='cicd-title'>Subscriptions</div>
                                            <SCDropDownList id={`cicd-kv-modal-element-sub-` + index} className="md-ddl-edit" label="Subscriptions" componentState={selectedSubscriptions !== "" && selectedSubscriptions?.find(x => x.env === item.env) ? selectedSubscriptions?.find(x => x.env === item.env).value : ''}
                                                setComponentState={setSelectedSubscriptions} exceptStateType={[]}
                                                deleteWithQuery={selectedSubscriptions?.filter(x => x.env !== item.env)}
                                                size="1000" items={ddlSubscriptions?.filter(x => x.env === item.env)} handleChange={(e) => handleChangeSubscriptions(e, item.env)}
                                                value={selectedSubscriptions !== "" && selectedSubscriptions?.find(x => x.env === item.env) ? selectedSubscriptions?.find(x => x.env === item.env).value : ''} />


                                            <div className='cicd-title'>KeyVault Name</div>
                                            <SCDropDownList id={`cicd-kv-modal-element-name-` + index} className="md-ddl-edit" label="KeyVault" componentState={selectedKVObject !== "" && selectedKVObject?.find(x => x.env === item.env) ? selectedKVObject?.find(x => x.env === item.env).value : ''}
                                                setComponentState={setSelectedKVObject} exceptStateType={[]}
                                                deleteWithQuery={selectedKVObject?.filter(x => x.env !== item.env)}
                                                size="1000" items={ddlKVList?.filter(x => x.env === item.env)} handleChange={(e) => handleChangeKVName(e, item.env)}
                                                value={selectedKVObject !== "" && selectedKVObject?.find(x => x.env === item.env) ? selectedKVObject?.find(x => x.env === item.env).value : ''} />


                                            <div className='cicd-title'>Secrets</div>
                                            <SCDropDownList id={`cicd-kv-modal-element-secrets-` + index} helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit"
                                                multiple={true} label="Secrets" componentState={selectedSecrets !== "" && selectedSecrets?.filter(x => x.env === item.env).length > 0 ? selectedSecrets?.filter(x => x.env === item.env)[0].value : []}
                                                setComponentState={setSelectedSecrets} exceptStateType={[]}
                                                deleteWithQuery={selectedSecrets?.filter(x => x.env !== item.env)}
                                                size="1000" items={ddlSecrets?.filter(x => x.env === item.env)} handleChange={(e) => handleChangeSecrets(e, item.env)} value={selectedSecrets !== "" && selectedSecrets?.filter(x => x.env === item.env).length > 0 ? selectedSecrets?.filter(x => x.env === item.env)[0].value : []} />



                                            {
                                                kvItems && kvItems.length > 0 &&
                                                (
                                                    kvItems.map((kv, index) => (
                                                        kv.env === item.env &&
                                                        (
                                                            <div>

                                                                <SCInputText id={`cicd-kv-modal-element-prefix-` + index} text="Provide a secrets prefix (10bis-orders-*) " size="small"
                                                                    className="md-input cicd-prefix-name" label={"Prefix (" + kv.env + ")"} value={prefixValue.find(x => x.env === item.env) ? prefixValue.find(x => x.env === item.env).value : ''}

                                                                    handleInputValueChange={(e) => handleSecretPrefixChange(e, kv.env)} />
                                                            </div>
                                                        )
                                                    ))
                                                )
                                            }




                                        </React.Fragment>
                                    </TabPanel>
                                ))

                            }

                        </TabContext>
                    </Box>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleCloseModal()}>Close</Button>
                    <Button variant="btn btn-primary" onClick={() => handleSubmitModal()}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
});

export default SelfServiceKVModal
