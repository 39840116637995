import { useEffect, useState } from 'react';
import './SCContainer.css';
import "../App.css";

const SCContainer = ({ loading, children }) => {

    const [load, setLoad] = useState(false)

    useEffect(() => {
        // Get the element
        const loader = document.getElementById("loader");
        // Create a MutationObserver
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.attributeName === 'style') {
              const display = loader.style.display;
              if (display === 'none' && loading === 0) {                    
                setLoad(true)
                
              }             
            }
          }
        });            
        observer.observe(loader, { attributes: true, attributeFilter: ['style'] });
    
       

        return () => {
          // Disconnect the observer when the component unmounts
          observer.disconnect();
        };
      }, []);


      useEffect(()=>{
        
        if(loading === 0){         
          setLoad(true)
        }
        if(loading >= 200){
          setLoad(false)
        }
        

      },[loading])
   

    return (

        <div>
            {
                load ?
                    (
                        <div>
                            <div id="spinner-loader-container" className="loader-container" />   
                        </div>
                    ) :
                    (
                        <span></span>
                    )
            }
            {children}
        </div>
    )
}

export default SCContainer
