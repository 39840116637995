import React from "react";
import { Modal, Button, Form, Dropdown, DropdownButton, Table } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useEffect, useState } from "react";
import PackageStatuses from "../PackageCard/PackageStatuses";
import { FaCheck, FaTimes, FaTimesCircle } from 'react-icons/fa';
import _ from "lodash";
import { v4 as uuid } from 'uuid';


const ServiceMetadataModal = ({ show }) => {
    const { selectedService, setModalEditServiceMetaDataShow, getAllMetaData, servicesMetaData, modalEditServiceMetaDataShow } =
        useContext(DataContext);

   


    const handleModalHide = () => {
        setModalEditServiceMetaDataShow(false)
    }

    useEffect(() => {
        
    }, [modalEditServiceMetaDataShow])
   

    const TableDetails = () => {
      
    
        return <>
            <Form.Group className="mb-3" controlId="formBasicTeam">
                <Form.Label ><b>Team:</b> {selectedService?.serviceMetadata?.team}</Form.Label>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicTLName">
                <Form.Label ><b>Team Leader Name:</b> {selectedService?.serviceMetadata?.manager}</Form.Label>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicTLName">
                <Form.Label ><b>Repository Name:</b> {selectedService?.repositoryName}</Form.Label>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicLink">


                <div className="col-6 float-left">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                selectedService?.serviceMetadata &&
                selectedService?.serviceMetadata?.links?.map((link, index) => (
                    <tr key={index}>                                                   
                        <td>{link.type}</td>
                        <td>{link.displayName}</td>
                        <td><a href={link.linkUrl} target='_blank'>Click here</a></td>
                    </tr>
                ))
                 }


                        </tbody>
                    </Table>
                </div>


            </Form.Group>


        </>
    }

    const translateType = (key) => {

        switch (key.toLowerCase()) {
            case 'documentation':
                return 1;
            case 'swagger':
                return 2;
            case 'dashboard':
                return 3;
            case 'logs':
                return 4;
            case 'other':
                return 5;

            default:
                break;
        }
    }
    return (
        <>
            <Modal
                fullscreen={true}
                show={show}
                onHide={() => handleModalHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            /*  onEntered={() => modalShown()}*/
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="card-title-text-notification-valid">{selectedService?.name}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>              
                          <TableDetails />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleModalHide()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ServiceMetadataModal;
