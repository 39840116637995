import React from "react";
import { Modal, Button, Table, Dropdown, DropdownButton } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useEffect, useState } from "react";
import PackageStatuses from "../PackageCard/PackageStatuses";
import { FaCheck, FaTimes } from 'react-icons/fa';
import _ from "lodash";

const BuildsModal = ({ show }) => {
    const { selectedService, setModalBuildShow, getBuildDefinitions, branches, buildDefinitions, setBuildDefinitions } =
        useContext(DataContext);

        const [selectedBranch, setSelectedBranch] = useState('');


    useEffect(() => {

        setBuildDefinitions([])

    }, [selectedService])


    const modalShown = () => {
      

    }

    const handleModalHide = () => {

       setModalBuildShow(false)
       setBuildDefinitions({});
       setSelectedBranch('');
    }

    const handleSelect = (e) => {
        setSelectedBranch(e);
        getBuildDefinitions(selectedService.id, e)
    }

    const BranchesSelection = () => {
        var branch = branches.filter(b => b.serviceId === selectedService.id)[0];
       
        return <div>
           <div className="col-1 float-left "><b>Select branch:</b></div>
           <div className="col-11 float-left">
               
           <DropdownButton id="dropdown-basic-button"                
                onSelect={handleSelect}
                variant="outline-success"
                value={selectedBranch}
                title={selectedBranch ? selectedBranch : 'Select branch'}
            >
                {
                    branch && branch?.branches?.map((b, index) => (
                        <Dropdown.Item key={index} eventKey={b} active={selectedBranch.toLowerCase() === b.toLowerCase() ? true : false}>{b}</Dropdown.Item>
                    ))
                }
            </DropdownButton>

               </div>            
        </div>
    }

    return (
        <>
            <Modal
                fullscreen={true}
                show={show}
                onHide={() => handleModalHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onEntered={() => modalShown()}

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="card-title-text-notification-valid">{selectedService.name}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*
                    <DropdownButton key={linkType} id="dropdown-basic-button"
                        title={linkType ? linkType : 'Select Link Type'}
                        onSelect={handleSelect}
                        value={linkType}
                        variant="outline-success"
                    >
                        <Dropdown.Item eventKey='documentation' active={linkType === 'documentation' ? true : false}>Documentation</Dropdown.Item>
                        <Dropdown.Item eventKey='swagger' active={linkType === 'swagger' ? true : false}>Swagger</Dropdown.Item>
                        <Dropdown.Item eventKey='dashboard' active={linkType === 'dashboard' ? true : false}>Dashboard</Dropdown.Item>
                        <Dropdown.Item eventKey='logs' active={linkType === 'logs' ? true : false}>Logs</Dropdown.Item>
                        <Dropdown.Item eventKey='other' active={linkType === 'other' ? true : false}>other</Dropdown.Item>
                    </DropdownButton>
                  */}
                   




                    <div>
                        <BranchesSelection />
                        <div className="row-marge"><b>Repository name:</b> {selectedService.name}</div>
                        <div className="row-marge"><b>Repository url:</b> <a href={selectedService.repositoryUrl} target="_blank">Click here</a></div>
                        <div className="row-marge"><b>Commit id:</b> {selectedService.commitId}</div>
            
                                          
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Result</th>
                                    <th>Build Number</th>
                                    <th>Queue Time</th>
                                    <th>Reason</th>
                                    <th>Requested For</th>
                                    <th>Source Version</th>
                                    <th>Url</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>

                                    {
                                        buildDefinitions && buildDefinitions?.builds?.map((b, index) => (
                                            <tr key={index}>
                                                <td style={ b.result.toLowerCase() === 'succeeded' ? { color:'green' } : { color:'red' }}>{b.result}</td>
                                                <td>{b.buildNumber}</td>
                                                <td>{b.queueTime}</td>
                                                <td>{b.reason}</td>
                                                <td>{b.requestedFor}</td>
                                                <td>{b.sourceVersion}</td>
                                                <td><a href={b.url} target="_blank" >Click here</a></td>
                                            </tr>
                                        ))
                                    }
                                </>

                                {/*
                                <tr>
                                    <td style={{ color: 'green' }}>succeeded</td>
                                    <td>20220313.1</td>
                                    <td>2022-03-13T16:05:46.240Z</td>
                                    <td>Eden Ezra</td>
                                    <td>batchedCI</td>
                                    <td>https://dev.azure.com/tenbis/TenBis/_build/results?buildId=61544</td>
                                </tr>

                                <tr>
                                    <td style={{ color: 'red' }}>failed</td>
                                    <td>20220313.1</td>
                                    <td>2022-03-13T16:05:46.240Z</td>
                                    <td>Eden Ezra</td>
                                    <td>batchedCI</td>
                                    <td>https://dev.azure.com/tenbis/TenBis/_build/results?buildId=61544</td>
                </tr>*/}

                            </tbody>
                        </Table>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleModalHide()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BuildsModal;
