import React, { useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import _ from "lodash";


class ServiceCatalogModule  {
   
  constructor(createChatBotMessage, setState, state, context) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setState;
    this.state = state;
    this.context = context
  }

  
  async handleSendMessage (userMessage)  {
    
    let currentUUID = uuid()

    const messageTyping = this.createChatBotMessage({ message: "", type: 'typing', id: currentUUID });

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, messageTyping]
    }));


    try {

      let reciveMessage = await this.context.sendCatalogMessage( this.context.assistantId, userMessage)
     
      if (reciveMessage.status === 200) {
        this.context.setThreadId(reciveMessage.data.threadId)
      }
      if (messageTyping.message.id === currentUUID) {
        messageTyping.message.message = 'done';
      }

      if (reciveMessage?.data?.messages) {
        const htmlContent = this.context.parseRecivedMessage(reciveMessage.data.messages[reciveMessage.data.messages.length - 1].message)

        const message = this.createChatBotMessage({ message: htmlContent, type: 'html' });


        this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message]
        }));
      }
      else{
        if (messageTyping.message.id === currentUUID) {
          messageTyping.message.message = 'done';
        }
        const htmlContent = this.context.parseRecivedMessage("Sorry i didn't have an answer to you...")
        const message = this.createChatBotMessage({ message: htmlContent, type: 'html' });
        this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, message]
        }));
      }


    } catch (error) {
      if (messageTyping.message.id === currentUUID) {
        messageTyping.message.message = 'done';
      }
      const htmlContent = this.context.parseRecivedMessage("Sorry i didn't have an answer to you...")
      const message = this.createChatBotMessage({ message: htmlContent, type: 'html' });
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message]
      }));
    }


  }

}


export default ServiceCatalogModule;