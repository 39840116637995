import { useEffect, useState, useContext } from "react";
import * as React from 'react';
import DataContext from "../Context/DataContext";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './ServicePage.css';
import EditMetaData from "../Components/EditMetaData";
import SCBreadCrumbs from "../Components/SCBreadCrumbs";
import SCLine from "../Components/SCLine";
import SCHeader from "../Components/SCHeader";
import SCTop from "../Components/SCTop";
import ViewMetaData from "../Components/ViewMetaData";
import BuildDefinitions from "../Components/BuildDefinitions";
import ServicePackages from "../Components/ServicePackages";
import useQuery from '../Hooks/useQuery';
import ServiceScore from "../Components/ServiceScore";
import Environments from "../Components/Environments";
import DebuggerAI from "../Components/DebuggerAI";
import ServiceRelations from "./ServiceRelations";




const ServicePage = () => {
    const { setCurrentPage, handleBreadCrumbs, isEditorUser, isAdminUser,selectedService } = useContext(DataContext);
    const query = useQuery();

    const [value, setValue] = useState('1');


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        setCurrentPage('servicespage')
        if (query.get("name")) {
            handleBreadCrumbs(query.get("name"), '/Service')
        }
    }, [])






    return (
        <>
            <div className="sc-header">
                <SCTop />
            </div>
            <div className="sc-main">
                <div className="row sc-tabs">
                    <div className="col-12">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab style={{ display: isEditorUser || isAdminUser ? 'flex' : 'none' }} className="sc-tab" label="Edit Service Settings" value="0" />
                                        <Tab className="sc-tab" label="Metadata" value="1" />
                                        <Tab className="sc-tab" label="Projects" value="2" />
                                        <Tab className="sc-tab" label="Build definitions" value="3" />
                                        <Tab className="sc-tab" label="Environments" value="4" />
                                        <Tab style={{ display: selectedService?.serviceScore?.scoreItems ? 'inline-flex' : 'none' }} className="sc-tab" label="Score" value="5" />
                                       {/* <Tab style={{ display: selectedService?.serviceRelations && selectedService?.serviceRelations.length > 0 ? 'inline-flex' : 'none' }} className="sc-tab" label="Relations" value="6" />*/}
                                        {/*<Tab className="sc-tab" label="Debugger AI" value="7" />*/}

                                    </TabList>
                                </Box>
                                <TabPanel value="0">
                                    <EditMetaData />
                                </TabPanel>
                                <TabPanel value="1">
                                    <ViewMetaData />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ServicePackages />
                                </TabPanel>
                                <TabPanel value="3">
                                    <BuildDefinitions />
                                </TabPanel>
                                <TabPanel value="4">
                                    <Environments />
                                </TabPanel>
                                <TabPanel value="5">
                                    <ServiceScore />
                                </TabPanel>
                                {/*  <TabPanel value="6">
                                    <ServiceRelations />
                                </TabPanel>
                                <TabPanel value="7">
                                    <DebuggerAI />
                                </TabPanel>*/}
                            </TabContext>
                        </Box>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ServicePage
