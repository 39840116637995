import { useEffect, useState, useContext } from "react";
import './PermissionSmallTable.css';
import SCTable from "../SCTable";
import _ from "lodash";
import DataContext from "../../Context/DataContext";
import * as consts from '../../consts';



const PermissionSmallTable = () => {

    const { userPermissionRequests,formatDateTime } = useContext(DataContext);
   
    const [topItems, setTopItems] = useState([]);


    const columnsItems = [{ display: true, name: "Status", key: "status" },{ display: true, name: "Start On", key: "grantOn" },
    { display: true, name: "End On", key: "revokeOn" },{ display: true, name: "Resource", key: "resourceName" },{ display: true, name: "Url", key: "url" }
,{ display: false, name: "resourceId", key: "resourceId" }];



    useEffect(() => {


        if(userPermissionRequests.data.length > 0)
        {
            const arrayByStatus = userPermissionRequests.data.filter((u=>u.status === 'AwaitingApproval' || u.status === 'Scheduled'))
            const top5Objects = _.take(arrayByStatus, 5);
            const specificProperties = _.map(top5Objects, obj => _.pick(obj, ['status', 'grantOn','revokeOn','resourceName','resourceId']));

            const modifiedArray = specificProperties.map(obj => {
                
                return {
                  ...obj,
                  grantOn: formatDateTime(obj.grantOn),
                  revokeOn: formatDateTime(obj.revokeOn),
                  url: consts.AZURE_BASE_RESOURCE_URL + obj.resourceId
                };
              });

            setTopItems(modifiedArray)

        }


    }, [userPermissionRequests])


  

    return (
       
            <div>
            
            <SCTable  title="Permissions Requests" columnsItems={columnsItems}
            rowItems={topItems} />

            </div>
       
    )
}

export default PermissionSmallTable
