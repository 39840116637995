// in MessageParser.jsx

import React, { useContext, useEffect } from 'react';
import useAxios from '../../Hooks/useAxios';
import * as consts from '../../consts';
import useLogin from '../../Hooks/useLogin';
import { DataContextChat } from '../../Context/DataContextChat';

const MessageParser = ({ children, setState, actions, ...props }) => {

  const instance = useAxios(process.env.REACT_APP_BASE_URL);
  const { chatModuleLocation, getModuleNameByPath, startSignalRConnection, connection } = useContext(DataContextChat);

 


  const parse = (message) => {
  
    actions.handleAnalyzerModule(message);
   
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
          props
        });
      })}
    </div>
  );
};

export default MessageParser;
