import React, { useContext } from 'react';
import DataContextConsoleAI from '../../Context/DataContextConsoleAI';
import './HtmlContentWidget.css';
import TypingCursorMessage from './TypingCursorMessage';


const HtmlContentWidget = ({ message, html = null, customClass , click = null }) => {



  if (!message && html === null) {
    return (
      <div></div>
    )
  }





  {/* <TypingCursorMessage message={message.message} />*/ }
  {/* <div className='console-ai-chat-container' dangerouslySetInnerHTML={{ __html: message.message }} /> 
*/}
  return (


    html === null ?
      (
        <div className='console-ai-chat-container' dangerouslySetInnerHTML={{ __html: message.message }} />
      ) :
      (
        <div className={`console-ai-chat-container ${customClass}`}  dangerouslySetInnerHTML={{ __html: html }} onClick={click} />
      )



  );
};

export default HtmlContentWidget;
