import './SCToggle.css';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';


const SCToggle = ({ handleToggleChange, isChecked, title }) => {
  
    return (
        <div style={{ width: '350px' }} >
            <span>{title}</span>
            <span style={{ marginLeft: '10px' }}>
                <FormControlLabel className='valid-services'
                    control={
                        <Switch id="toggle-ss-qa"

                            sx={{
                                '& .MuiSwitch-thumb': {
                                    color: 'lightgray'
                                },

                                '& .Mui-checked .MuiSwitch-thumb': {
                                    color: '#1976d2'
                                }


                            }}
                            checked={isChecked} onChange={(e) => handleToggleChange(e)} />
                    }
                />
            </span>
        </div>
    )
}

export default SCToggle
