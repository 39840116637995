import { Modal, Button } from "react-bootstrap";
import './SelfServiceAIGenerationModal.css';


const SelfServiceAIGenerationModal = ({ modalShow, setModalShow }) => {


   


    return (
        <div>

            <Modal
                show={modalShow}   
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="alert-modal"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="self-service-ai-generation-ai-title">
                    Our AI is currently processing your request...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>We're generating the self-service request for you using our AI-powered engine.</div>
                    <div>It will close automatically when the process is complete.</div>
                </Modal.Body>
               
            </Modal>
        </div>

    )
}

export default SelfServiceAIGenerationModal
