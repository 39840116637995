import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const SCLine = ({ labels, data, title,yAxisTitle,xAxisTitle }) => {
    const chartRef = useRef();
    const chartInstanceRef = useRef(null); // Store the chart instance

    useEffect(() => {
        const myChartRef = chartRef.current.getContext('2d');
        

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
          }


        chartInstanceRef.current =  new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: labels, //['January', 'February', 'March', 'April', 'May', 'June'],
                //labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [
                    {
                        label: title, // 'My First Dataset',
                        data: data, //[65, 59, 80, 81, 56, 55],
                        //data: [65, 59, 80, 81, 56, 55],
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1
                    }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: yAxisTitle,
                        },
                    },
                    x: {
                        title: {
                            display: true,
                            text: xAxisTitle,
                        },
                    },
                }
            }
        });
    }, [labels, data, title,yAxisTitle,xAxisTitle]);

    return <canvas ref={chartRef} />;
};

export default SCLine;
