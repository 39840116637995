
import React from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useEffect, useState } from "react";
import './PermissionDetailsModal.css';
import { useHistory, useLocation } from 'react-router-dom'


const PermissionDetailsModal = ({ show, object }) => {
    const { setModalPermissionShow } =
        useContext(DataContext);

    const location = useLocation()
    const history = useHistory()
  

    const handleModalHide = () => {
        setModalPermissionShow(false)
        const queryParams = new URLSearchParams(location.search)

        if (queryParams.has('reqId')) {
            queryParams.delete('reqId')
            history.replace({
                search: queryParams.toString(),
            })
        }

    }



    return (
        <>

            <Modal
                fullscreen={false}
                show={show}
                onHide={() => handleModalHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        REQUEST DETAILS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div><span className="permission-modal-table-title">Status:</span>{object.status}</div>
                    <div><span className="permission-modal-table-title">Created On:</span>{object.createdOn}</div>
                    <div><span className="permission-modal-table-title">From Date:</span>{object.grantOn}</div>
                    <div><span className="permission-modal-table-title">To Date:</span>{object.revokeOn}</div>
                    <div><span className="permission-modal-table-title">Resource Group Name:</span>{object.resourceGroupName}</div>
                    <div><span className="permission-modal-table-title">Resource Name:</span>{object.resourceName}</div>
                    <div><span className="permission-modal-table-title">Role Definition Names:</span>{object.roleDefinitionNames}</div>

                    {
                        object.permissionsToCertificates !== 'None' ?
                            (
                                <div><span className="permission-modal-table-title">permissionsToCertificates:</span>{object.permissionsToCertificates}</div>
                            ) : (<>
                            </>
                            )
                    }
                    {
                        object.permissionsToKeys !== 'None' ?
                            (
                                <div><span className="permission-modal-table-title">permissionsToKeys:</span>{object.permissionsToKeys}</div>
                            ) : (<>
                            </>
                            )
                    }
                    {
                        object.permissionsToSecrets !== 'None' ?
                            (
                                <div><span className="permission-modal-table-title">permissionsToSecrets:</span>{object.permissionsToSecrets}</div>
                            ) : (<>
                            </>
                            )
                    }
                    <div><span className="permission-modal-table-title">Type:</span>{object.type}</div>
                    <div><span className="permission-modal-table-title">User Name:</span>{object.userPrincipleName}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleModalHide()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PermissionDetailsModal;
