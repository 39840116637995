import React from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useEffect, useState } from "react";
import PackageStatuses from "../PackageCard/PackageStatuses";
import { FaCheck, FaTimes } from 'react-icons/fa';
import _ from "lodash";

const ServiceBranchesModal = ({ setModalDetailsShow, show }) => {
    const { selectedService, groupPackagesInstance, setModalServiceDetailsShow, services, branchDetails, branches, getServiceBrachesInfo } =
        useContext(DataContext);

    const [activePackageTitle, setActivePackageTitle] = useState('');
    const [groupedByServiceName, setGroupedByServiceName] = useState([]);
    const [tabDetails, setTabDetails] = useState({});

    const [currentServiceBranches, setCurrentServiceBranches] = useState([]);

    useEffect(() => {

        if (services && services.length > 0) {
            // get repository name
            var servicesByRepos = services.filter(s => s.repositoryName === selectedService.repositoryName);

            var grouped = _.groupBy(servicesByRepos, "name")[selectedService.name];
            setGroupedByServiceName(grouped);
            if (grouped && grouped.length > 0) {
                setTabDetails(grouped[0])
                buildBranchRelations(grouped[0])
            }

        }


    }, [selectedService])


    useEffect(() => {

        if (branchDetails && selectedService) {
            setCurrentServiceBranches(branches?.filter(b => b.serviceId === selectedService.id)[0]);
        }


    }, [branchDetails, selectedService, branches])

    const handleModalHide = () => {

        /*  setActivePackageTitle('')
          setProjectItem('')*/
        setTabDetails(groupedByServiceName && groupedByServiceName.length ? groupedByServiceName[0] : {});
        setModalServiceDetailsShow(false)
    }
    const setTabsNameAndDetails = (branchName) => {

        getServiceBrachesInfo(selectedService.id, branchName)

        /*var tab = groupedByServiceName.filter(g => g.branchName === key);
        if (tab && tab.length > 0) {
            setTabDetails(tab[0].branchName)        
            buildBranchRelations(tab[0]);
        }*/
    }

    const buildBranchRelations = (tab) => {
        /*   var arr = [];
   
           _.forEach(tab.projects, (p) => {
               var branchDetails = {};
               branchDetails.name = p.name;
               //name
               _.forEach(p.packageStatuses, (ps) => {
   
                   var item = {};
   
                   item.authors = ps.authors;
                   //authors
                   item.currentVersion = ps.currentVersion;
                   //currentVersion
                   item.id = ps.id;
                   //id
                   item.installedVersion = ps.installedVersion;
                   //installedVersion
                   item.publishedDate = ps.publishedDate;
                   //publishedDate                
   
                   if (!branchDetails.packageStatuses) {
                       branchDetails.packageStatuses = [];
                   }
                   branchDetails.packageStatuses.push(item);
   
               })
               arr.push(branchDetails)
           })    
           setBranchDetails(arr)*/



    }

    return (
        <>


            <Modal
                fullscreen={true}
                show={show}
                onHide={() => handleModalHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="card-title-text-notification-valid">{selectedService.name}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/*<Tabs activeKey={tabDetails.branchName} */}
                    <Tabs
                        onSelect={(k) => setTabsNameAndDetails(k)} className="mb-3">

                        {currentServiceBranches &&
                            currentServiceBranches?.branches?.map((b, index) => (
                                <Tab eventKey={b} title={b} key={index} >

                                    <div><b>PROJECTS:</b></div>
                                    {
                                        branchDetails && branchDetails?.data?.map((b, index) => (
                                            <div key={index} >

                                                <div className="service-modal-service-name">{b.name} </div>
                                                {
                                                    b.packageStatuses && b.packageStatuses.map((p, index) => (
                                                        <div key={index}>
                                                            <span className="service-modal-pckage-details"><b>Package name: </b>{p.id}</span>
                                                            <span className="service-modal-pckage-details"><b>Installed version: </b>{p.installedVersion}</span>
                                                            <span className="service-modal-pckage-details"><b>latest version: </b>{p.currentVersion}</span>
                                                            <span className="service-modal-pckage-details"><b>Author: </b>{p.authors}</span>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        ))
                                    }


                                </Tab>
                            ))}

                        {/*  {currentServiceBranches &&
                            currentServiceBranches?.branches.map((b, index) => (
                                <Tab eventKey={b} title={b} key={index} >

                                    <div><b>PROJECTS:</b></div>
                                    {
                                        branchDetails && branchDetails?.map((b, index) => (
                                            <div key={index} >
                                                <div  className="service-modal-service-name">{b.name} </div>
                                                {
                                                    b.packageStatuses && b.packageStatuses.map((p, index) => (
                                                        <div key={index}>
                                                            <span className="service-modal-pckage-details"><b>Package name: </b>{p.id}</span>                                                             
                                                            <span className="service-modal-pckage-details"><b>Installed version: </b>{p.installedVersion}</span> 
                                                             <span className="service-modal-pckage-details"><b>latest version: </b>{p.currentVersion}</span>
                                                             <span className="service-modal-pckage-details"><b>Author: </b>{p.authors}</span>                                                             
                                                             </div>
                                                    ))
                                                }

                                            </div>
                                        ))
                                    }

                                </Tab>
                            ))}
                   */}
                    </Tabs>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={() => handleModalHide()}>Close</Button>
                </Modal.Footer>
            </Modal>


        </>
    );
};

export default ServiceBranchesModal;
