import { createContext, useState, useContext, useEffect, useRef } from 'react';
import useRefTeamLeaderCallback from '../Hooks/Ref/useRefTeamLeaderCallback';
import useRefTeamLeaderIdCallback from '../Hooks/Ref/useRefTeamLeaderIdCallback';
import useRefTeamNameCallback from '../Hooks/Ref/useRefTeamNameCallback';





const WizardDataContext = createContext({});

export const DataProviderWizard = ({ children }) => {
    const [serviceNameValue, setServiceNameValue] = useState('');
    const [regexServiceNameError, setRegexServiceNameError] = useState(false);
    const [regexSolutionNameError, setRegexSolutionNameError] = useState(false)
    const [selectedSolutionType, setSelectedSolutionType] = useState('');
    const [serviceDescription, setServiceDescription] = useState('')
    const [selectedTeam, setSelectedTeam] = useState('')
    const [selectedDeperatmentManager, setSelectedDeperatmentManager] = useState('');
    const [selectedSubDeperatmentManager, setSelectedSubDeperatmentManager] = useState('')
    const [ddlSubDeperatmentManager, setDdlSubDeperatmentManager] = useState([]);
    const [ddlDeperatmentManager, setDdlDeperatmentManager] = useState([]);
    const [ddlSolutionType, setDdlSolutionType] = useState([])
    const [decision, setDecision] = useState('')
    const [selectedTier, setSelectedTier] = useState('')
    const [ddlTier, setDdlTier] = useState([]);
    const [ddlFeeds, setDdlFeeds] = useState([])
    const [selectedACRDev, setSelectedACRDev] = useState([])
    const [selectedACRProd, setSelectedACRProd] = useState([])
    const [selectedProject, setSelectedProject] = useState('')
    const [selectedFeed, setSelectedFeed] = useState('')
    const [selectedBranchesProd, setSelectedBranchesProd] = useState('')
    const [selectedBranchesDev, setSelectedBranchesDev] = useState([])
    const [disableRepoName, setDisableRepoName] = useState(false)
    const [repoNameFromAutoComplete, setRepoNameFromAutoComplete] = useState('');
    const [options, setOptions] = useState([])
    const [repositories, setRepositories] = useState([])
    const [ddlAcrDev, setDdlAcrDev] = useState([])
    const [ddlAcrProd, setDdlAcrProd] = useState([])
    const [repoNameValue, setRepoNameValue] = useState('');
    const [appNameValue, setAppNameValue] = useState('');
    const [showBuildPipelineTree, setShowBuildPipelineTree] = useState(false);
    const [showReleasePipelineTree, setShowReleasePipelineTree] = useState(false);
    const [selectedBuildFolder, setSelectedBuildFolder] = useState("");
    const [selectedReleaseFolder, setSelectedReleaseFolder] = useState("");
    const [ddlBranchesDev, setDdlBranchesDev] = useState([])
    const [ddlBranchesProd, setDdlBranchesProd] = useState([])
    const [ddlBranchesDevCached, setDdlBranchesDevCached] = useState([])
    const [ddlBranchesProdCached, setDdlBranchesProdCached] = useState([])

    

    const [teamLeaderRef] = useRefTeamLeaderCallback();
    const [teamLeaderIdRef] = useRefTeamLeaderIdCallback();
    const [teamNameRef] = useRefTeamNameCallback();
    const appNameRef = useRef(null)




    return (
        <WizardDataContext.Provider value={{
            serviceNameValue, setServiceNameValue, regexServiceNameError, setRegexServiceNameError, selectedSolutionType, setSelectedSolutionType,
            serviceDescription, setServiceDescription, selectedTeam, setSelectedTeam, selectedDeperatmentManager, setSelectedDeperatmentManager,
            selectedSubDeperatmentManager, setSelectedSubDeperatmentManager, teamLeaderRef, teamLeaderIdRef, teamNameRef,
            ddlSubDeperatmentManager, setDdlSubDeperatmentManager, ddlDeperatmentManager, setDdlDeperatmentManager, decision, setDecision,
            ddlSolutionType, setDdlSolutionType, selectedTier, setSelectedTier, ddlTier, setDdlTier, selectedProject, setSelectedProject,
            selectedFeed, setSelectedFeed, ddlFeeds, setDdlFeeds, selectedACRDev, setSelectedACRDev, selectedACRProd, setSelectedACRProd,
            selectedBranchesProd, setSelectedBranchesProd, selectedBranchesDev, setSelectedBranchesDev, disableRepoName, setDisableRepoName,
            repoNameFromAutoComplete, setRepoNameFromAutoComplete, options, setOptions, repositories, setRepositories, ddlAcrDev, setDdlAcrDev,
            ddlAcrProd, setDdlAcrProd, repoNameValue, setRepoNameValue, appNameRef, regexSolutionNameError, setRegexSolutionNameError,
            appNameValue, setAppNameValue, showBuildPipelineTree, setShowBuildPipelineTree, showReleasePipelineTree, setShowReleasePipelineTree,
            selectedBuildFolder, setSelectedBuildFolder,selectedReleaseFolder, setSelectedReleaseFolder,ddlBranchesDev, setDdlBranchesDev,
            ddlBranchesProd, setDdlBranchesProd,ddlBranchesDevCached, setDdlBranchesDevCached,ddlBranchesProdCached, setDdlBranchesProdCached

        }}>



            {children}
        </WizardDataContext.Provider>
    )

}

export default WizardDataContext;
