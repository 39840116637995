import { useContext } from "react";
import DataContext from "../Context/DataContext";
import React, { useEffect } from "react";


const Guard = ({ children ,admin = false }) =>{    
    const { isEditorUser, isAdminUser } = useContext(DataContext);

        if(isEditorUser)
        {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )
        }
        if(admin && isAdminUser)
        {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )
        }
        else{
            return(
                <div>You are not authorized to view this page (401)</div>
            )
        }
        
}
export default Guard

