import { useEffect, useState, useContext } from "react";
import './SelfServiceSmallTable.css';
import SCTable from "../SCTable";
import _ from "lodash";
import DataContext from "../../Context/DataContext";




const SelfServiceSmallTable = () => {

    const { requestCICDItems } = useContext(DataContext);
   
    const [topItems, setTopItems] = useState([]);


    const columnsItems = [{ display: true, name: "App Name", key: "appName" },{ display: true, name: "Repo Name", key: "repoName" },
    { display: true, name: "approval Status", key: "approvalStatus" },{ display: true, name: "process Status", key: "processStatus" },
    { display: true, name: "Repo Url", key: "repoUrl" }];


    
   

    useEffect(() => {


        if(requestCICDItems.data.length > 0)
        {
            const top5Objects = _.take(requestCICDItems.data, 5);
            const specificProperties = _.map(top5Objects, obj => _.pick(obj, ['appName', 'repoName','approvalStatus','processStatus','repoUrl']));
            setTopItems(specificProperties)

        }


    }, [requestCICDItems])


  

    return (
       
            <div>
            
            <SCTable customColumnWidth="true" title="Self-Service Requests" columnsItems={columnsItems}
            rowItems={topItems} />

            </div>
       
    )
}

export default SelfServiceSmallTable
